<template>
    <div class="row card-panel blue-ugb white-text" style="font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif">
        <blockquote style="font-size:16px" class="m0">De no definir fechas individuales, se tomarán en cuenta las fechas globales definidas en uonline para los pagos. Por lo general, las fechas para preespecialización son las mismas que las de uonline, así que no debería definir fechas para estos.</blockquote>
    </div>
    <div class="row">
        <div class="col m3">
            <label>Carrera</label>
            <select v-model="codcar" class="browser-default">
                <option value="0">Todas</option>
                <option v-for="carrera in carreras" :key="carrera.car_codigo" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Tipo</label>
            <select class="browser-default" v-model="codtpg">
                <option value="0">Todos</option>
                <option v-for="tipo in tipos_procesos" :key="tipo" :value="tipo.tpg_codigo">{{tipo.tpg_descripcion}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Fechas</label>
            <select class="browser-default" v-model="fechas_filter">
                <option value="-1">Todos</option>
                <option value="0">Sin Fechas</option>
                <option value="1">Con Fechas</option>
            </select>
        </div>
        <div class="col m3">
            <label>Buscar</label>
            <input type="text" v-model="search">
        </div>
    </div>
      <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                <caption>PROCESOS AUTORIZADOS</caption>
                <tbody>
                    <template v-for="(proceso) in _procesos_autorizados" :key="proceso">
                        <tr class="black white-text">
                            <th>Fecha</th>
                            <th>Carrera</th>
                            <th>Tipo</th>
                            <th>Descripción</th>
                            <th>Matrícula</th>
                            <th>Cuota</th>
                            <th>Cant. Cuotas</th>
                            <th>Módulos</th>
                            <th>Grupos</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td class="center">{{proceso.aut_fecha}}</td>
                            <td>{{proceso.car_nombre}}</td>
                            <td>{{proceso.tpg_descripcion}}</td>
                            <td>{{proceso.pgr_descripcion}}</td>
                            <td style="text-align:right">${{proceso.aut_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td style="text-align:right">${{proceso.aut_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td class="center">{{proceso.aut_cant_cuotas}}</td>
                            <td class="center">                           
                                <div v-show="proceso.pgr_moa_modulos_autorizados.length > 0">
                                    <span data-badge-caption="" class="badge new blue">{{proceso.pgr_moa_modulos_autorizados.length}}</span>
                                </div>
                            </td>
                            <td class="center" style="position:relative">
                                <span data-badge-caption class="badge new green darken-1">{{proceso.pgr_grh_grupo_horario.length}}</span>
                            </td>
                            <td class="center"><a href="#!" @click="modalFechasAutorizacion(proceso.aut_codigo)"><i class="material-icons">date_range</i></a></td>
                        </tr>
                        <tr v-show="proceso.pgr_grh_grupo_horario.length > 0">
                            <th colspan="10" class="center">GRUPOS</th>
                        </tr>

                        <template v-for="(detalle) in proceso.pgr_grh_grupo_horario" :key="detalle">
                            <tr v-show="(fechas_filter == -1 || (fechas_filter == 0 && detalle.pgr_fpg_fecha_pago_grupo.length == 0) || (fechas_filter == 1 && detalle.pgr_fpg_fecha_pago_grupo.length > 0))">
                                <td colspan="10">
                                    <ul class="collection m0 p0">
                                        <li class="collection-item m0 p0">
                                            <span class="" data-badge-caption="">
                                            <span data-badge-caption="" class="badge new green darken-2">{{detalle.grh_descripcion}}</span>
                                            | <span data-badge-caption="" class="badge new grey darken-2">                                          
                                            <b>MATRÍCULA:</b> ${{detalle.grh_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}} 
                                            | <b>CUOTA:</b> ${{detalle.grh_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}} 
                                            | <b>CUOTAS:</b> {{detalle.grh_cant_cuotas}}
                                            | <b>CAPACIDAD:</b> {{detalle.grh_capacidad}}
                                            | <b>INSCRITOS:</b> {{detalle.pgr_ipg_inscripcion_proceso_graduacion.filter(x=>x.ipg_estado).length}}                                       
                                            </span>
                                            <!-- <a href="#!" class="right" style="margin-left: 10px" @click="modalFechasGrupo(_index_proceso, _index_detalle)"><i class="material-icons">date_range</i></a> -->
                                            <a href="#!" class="right" style="margin-left: 10px" @click="modalFechasGrupo(proceso.aut_codigo, detalle.grh_codigo)"><i class="material-icons">date_range</i></a>
                                            <span class="new badge right" data-badge-caption="" v-if="detalle.pgr_fpg_fecha_pago_grupo.length > 0"><i class="material-icons">check_circle</i></span>                                        
                                            <span class="new badge right red darken-2" data-badge-caption="" v-if="detalle.pgr_fpg_fecha_pago_grupo.length == 0"><i class="material-icons">warning</i></span>                                        
                                        </span>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </template>

                    </template>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-fecha-autorizacion" style="width: 70%">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content">
            <div class="row m0 p0" v-if="index_proceso_autorizado >= 0 && procesos_autorizados[index_proceso_autorizado] != undefined">
                <div class="row m0 p0">
                    <div class="col m12">
                        <label>Carrera</label>
                        <p>{{procesos_autorizados[index_proceso_autorizado].car_nombre}}</p>
                    </div>
                    <div class="col m2">
                        <label>Tipo</label>
                        <p>{{procesos_autorizados[index_proceso_autorizado].tpg_descripcion}}</p>
                    </div>
                    <div class="col m6">
                        <label>Descripción</label>
                        <p>{{procesos_autorizados[index_proceso_autorizado].pgr_descripcion}}</p>
                    </div>
                    <div class="col m1">
                        <label>Matrícula</label>
                        <p>${{procesos_autorizados[index_proceso_autorizado].aut_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</p>
                    </div>
                    <div class="col m1">
                        <label>Cuota</label>
                        <p>${{procesos_autorizados[index_proceso_autorizado].aut_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</p>
                    </div>
                    <div class="col m2">
                        <label>Cant. Cuotas</label>
                        <p>{{procesos_autorizados[index_proceso_autorizado].aut_cant_cuotas}}</p>
                    </div>
                </div>
            </div>

            <div class="row m0 p0" v-if="index_proceso_autorizado >= 0 && procesos_autorizados[index_proceso_autorizado] != undefined">
                <table class="table tbl-condensed tbl-bordered" style="font-size: 12px; margin-top:10px">
                    <thead>
                        <tr>
                            <th style="width:5%">#</th>
                            <th style="width:10%">Descripción</th>
                            <!-- <th style="width:40%">Docente</th> -->
                            <th style="width:5%">Capacidad</th>
                            <th style="width:5%">Matrícula</th>
                            <th style="width:5%">Cuota</th>
                            <th style="width:8%">Cant. Cuotas</th>
                            <th style="width:10%">Mód. Prog.</th>
                            <th style="width:5%">Autofin.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(grupo, index) in procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario" :key="grupo">
                            <td class="center">{{index+1}}</td>
                            <td>{{grupo.grh_descripcion}}</td>
                            <!-- <td>{{(grupo.pla_emp_empleado == null ? 'SIN DOCENTE ASIGNADO':grupo.pla_emp_empleado.emp_apellidos_nombres)}}</td> -->
                            <td class="center">{{grupo.grh_capacidad}}</td>

                            <td style="text-align: right">${{grupo.grh_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td style="text-align: right">${{grupo.grh_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td class="center">
                                {{grupo.grh_cant_cuotas}}
                            </td>
                            
                            <td class="center">{{grupo.pgr_dhg_detalle_horario_grupo.length}} de {{procesos_autorizados[index_proceso_autorizado].pgr_moa_modulos_autorizados.length}}</td>
                            <td class="center"><label><input type="checkbox" @click.prevent="" class="filled-in" :checked="grupo.grh_autofinanciado" /><span></span></label></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="clearfix"></div>
            <br/>
            <div class="row m0 p0">
                <div class="card-panel red darken-4 white-text" v-if="!this.fechas_autorizacion">
                    Las fechas y/o montos no coinciden entre los grupos, deberá editarlas individualmente. <i class="material-icons right">warning</i>
                </div>
                <table class="table tbl-condensed tbl-bordered" v-if="index_proceso_autorizado >= 0 && this.fechas_autorizacion && procesos_autorizados[index_proceso_autorizado] != undefined" style="font-size:12px">
                    <caption>TODOS LOS GRUPOS TENDRÁN LAS MISMAS FECHAS DE PAGO</caption>
                    <thead>
                        <tr>
                            <th style="width:5%">#</th>
                            <th style="width: 65%">Arancel</th>
                            <th style="width: 10%">Fecha Limite</th>
                            <th style="width: 10%">Monto</th>
                            <th style="width: 10%">Mora</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr v-for="fecha in fechas" :key="fecha">
                            <td class="center">{{fecha.fpg_orden}}</td>
                            <td>
                                <select class="browser-default" v-model="fecha.fpg_codtmo">
                                    <option value="0">Seleccione</option>
                                    <option v-for="arancel in aranceles" :key="arancel" :value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                                </select>
                            </td>
                            <td>
                                <datepicker v-model="fecha.fpg_fecha_limite" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" inlineWithInput />
                            </td>
                            <td><input style="text-align:right" type="text" v-model="fecha.fpg_monto" /></td>
                            <td class="center"><label><input type="checkbox" class="filled-in" v-model="fecha.fpg_mora" /><span></span></label></td>
                        </tr>
                    </thead>
                </table>
                <br />
                <button type="button" class="btn green darken-2" @click="setFechaAutorizacion()">{{sin_fechas ? 'CREAR':'ACTUALIZAR'}} <i class="material-icons right">save</i></button>
            </div>
        </div>
    </div>

    <div class="modal" id="modal-fecha-grupo" style="width: 70%">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content">
            <div class="row m0 p0" v-if="index_proceso_autorizado >= 0 && procesos_autorizados[index_proceso_autorizado] != undefined">
                <div class="row m0 p0">
                    <div class="col m12">
                        <label>Carrera</label>
                        <p>{{procesos_autorizados[index_proceso_autorizado].car_nombre}}</p>
                    </div>
                    <div class="col m2">
                        <label>Tipo</label>
                        <p>{{procesos_autorizados[index_proceso_autorizado].tpg_descripcion}}</p>
                    </div>
                    <div class="col m6">
                        <label>Descripción</label>
                        <p>{{procesos_autorizados[index_proceso_autorizado].pgr_descripcion}}</p>
                    </div>
                    <div class="col m1">
                        <label>Matrícula</label>
                        <p>${{procesos_autorizados[index_proceso_autorizado].aut_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</p>
                    </div>
                    <div class="col m1">
                        <label>Cuota</label>
                        <p>${{procesos_autorizados[index_proceso_autorizado].aut_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</p>
                    </div>
                    <div class="col m2">
                        <label>Cant. Cuotas</label>
                        <p>{{procesos_autorizados[index_proceso_autorizado].aut_cant_cuotas}}</p>
                    </div>
                </div>
            </div>

            <div class="row m0 p0" v-if="index_grupo >= 0">
                <table class="table tbl-condensed tbl-bordered" style="font-size: 12px; margin-top:10px">
                    <thead>
                        <tr>
                            <th style="width:10%">Descripción</th>
                            <!-- <th style="width:40%">Docente</th> -->
                            <th style="width:5%">Capacidad</th>
                            <th style="width:5%">Matrícula</th>
                            <th style="width:5%">Cuota</th>
                            <th style="width:8%">Cant. Cuotas</th>
                            <th style="width:10%">Mód. Prog.</th>
                            <th style="width:5%">Autofin.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_descripcion}}</td>
                            <td class="center">{{procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_capacidad}}</td>

                            <td style="text-align: right">${{procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td style="text-align: right">${{procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td class="center">
                                {{procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_cant_cuotas}}
                            </td>
                            
                            <td class="center">{{procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].pgr_dhg_detalle_horario_grupo.length}} de {{procesos_autorizados[index_proceso_autorizado].pgr_moa_modulos_autorizados.length}}</td>
                            <td class="center"><label><input type="checkbox" @click.prevent="" class="filled-in" :checked="procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_autofinanciado" /><span></span></label></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="clearfix"></div>
            <br/>
            <div class="row m0 p0">
                <div class="card-panel red darken-4 white-text" v-if="!this.fechas_autorizacion">
                    Las fechas y/o montos no coinciden entre los grupos, deberá editarlas individualmente. <i class="material-icons right">warning</i>
                </div>
                <table class="table tbl-condensed tbl-bordered" v-if="index_proceso_autorizado >= 0 && this.fechas_autorizacion && this.procesos_autorizados[index_proceso_autorizado] != undefined" style="font-size:12px">
                    <thead>
                        <tr>
                            <th style="width:5%">#</th>
                            <th style="width: 65%">Arancel</th>
                            <th style="width: 10%">Fecha Limite</th>
                            <th style="width: 10%">Monto</th>
                            <th style="width: 10%">Mora</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr v-for="fecha in fechas" :key="fecha">
                            <td class="center">{{fecha.fpg_orden}}</td>
                            <td>
                                <select class="browser-default" v-model="fecha.fpg_codtmo" disabled style="color:black">
                                    <option value="0">Seleccione</option>
                                    <option v-for="arancel in aranceles" style="color:black" :key="arancel" :value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                                </select>
                            </td>
                            <td>
                                <datepicker v-model="fecha.fpg_fecha_limite" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" inlineWithInput />
                            </td>
                            <td><input style="text-align:right; color:black" type="text" disabled v-model="fecha.fpg_monto" /></td>
                            <td class="center"><label><input type="checkbox" class="filled-in" v-model="fecha.fpg_mora" /><span></span></label></td>
                        </tr>
                    </thead>
                </table>
                <br />
                <button type="button" class="btn blue-ugb" @click="setFechaGrupo()">{{sin_fechas ? 'CREAR':'ACTUALIZAR'}} <i class="material-icons right">save</i></button>
                <button type="button" class="btn red darken-4" @click="setFechaAutorizacion(true)">Copiar a todos los grupos <i class="material-icons right">warning</i></button>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import M from 'materialize-css'
        export default {
        components: {
            Datepicker
        },
        data(){
            return {
                procesos_autorizados: [],
                index_proceso_autorizado: -1,
                index_grupo: -1,
                fechas: [],
                modales: [],
                modal_fechas_autorizacion: {},
                modal_fechas_grupo: {},
                aranceles: [],
                fechas_autorizacion: true,
                sin_fechas: false,
                carreras: [],
                codcar: 0,
                tipos_procesos: [],
                codtpg: 0,
                fechas_filter: -1,
                search: ''
            }
        },
        async mounted()
        {
            await this.getProcesosAutorizados();
            await this.getAranceles();
            await this.getCarreras();
            await this.getTiposProcesos();
            var elems = document.querySelectorAll(".modal");
            this.modales = M.Modal.init(elems, {
                dismissible: false
            });      
        },
        methods: {
            async getCarreras()
            {
                await this.axios.get('/ProcesosGraduacionAutorizacion/Carreras')
                .then(response => {
                    this.carreras = response.data;
                });
            },
            async getProcesosAutorizados()
            {
                this.$root.startLoading();
                await this.axios.get("/ProcesosGraduacionAutorizacion")
                    .then(response => {
                    this.procesos_autorizados = response.data.filter(x=>x.pgr_grh_grupo_horario.length > 0);
                });
                this.$root.stopLoading();
            },
            async modalFechasAutorizacion(codaut)
            {
                var index = this.procesos_autorizados.findIndex(x=>x.aut_codigo == codaut);
                await this.getFechasAutorizacion(index);
                if(this.fechas.length == 0 && this.fechas_autorizacion)
                {
                    this.fechas = [];
                    var i_codtmo = 1;
                    for(let i = 0; i <= this.procesos_autorizados[index].aut_cant_cuotas; i++)
                    {
                        this.fechas.push({
                            fpg_codtmo: i_codtmo,
                            fpg_fecha_limite: null,
                            fpg_monto: i_codtmo == 1 ? this.procesos_autorizados[index].aut_matricula:this.procesos_autorizados[index].aut_cuota,
                            fpg_mora: true,
                            fpg_orden: i + 1,
                            fpg_codigo: 0
                        });
                        i_codtmo++;
                    }
                }
                this.index_proceso_autorizado = index;
                this.modal_fechas_autorizacion = M.Modal.getInstance(document.querySelector("#modal-fecha-autorizacion"));
                this.modal_fechas_autorizacion.open();
            },
            async getFechasAutorizacion(index)
            {
                this.fechas_autorizacion = true;
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionAutorizacion/' + this.procesos_autorizados[index].aut_codigo + '/Fechas')
                .then(response => {
                    if(response.status == 200)
                    {
                        this.sin_fechas = false;
                        var data = response.data;
                        for(let i = 0; i <= data.length - 1; i++)
                        {
                            data[i].fpg_fecha_limite = new Date(data[i].fpg_fecha_limite);
                        }
                        this.fechas = data;
                    }
                    else
                    {
                        this.sin_fechas = true;
                    }
                })
                .catch(error => {
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                        this.fechas_autorizacion = false;
                    }
                });
                this.$root.stopLoading();
            },
            async getAranceles()
            {
                this.$root.startLoading();
                await this.axios.get('/Aranceles')
                .then(response => {
                    this.aranceles = response.data;
                });
                this.$root.stopLoading();
            },
            async setFechaAutorizacion(copy_all)
            {
                copy_all = copy_all || false;
                if(copy_all)
                {
                    if(!confirm('¿Desea copiar estas fechas para todos los grupos?\nUna vez hecho esto no podrá revertir el cambio.'));
                }
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionGrupoFechas/' + this.procesos_autorizados[this.index_proceso_autorizado].aut_codigo + '/Fechas', this.fechas)
                .then(() => {
                    this.sin_fechas = false;
                    M.toast({html: 'Las fechas han sido actualizadas.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            //async modalFechasGrupo(index_autorizacion, index_grupo)
            async modalFechasGrupo(codaut, codgrh)
            {
                this.fechas_autorizacion = true;
                this.index_proceso_autorizado = this.procesos_autorizados.findIndex(x=>x.aut_codigo == codaut);
                this.index_grupo = this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario.findIndex(x=>x.grh_codigo == codgrh);
                await this.getFechasGrupo(this.index_grupo);
                this.modal_fechas_grupo = M.Modal.getInstance(document.querySelector("#modal-fecha-grupo"));
                this.modal_fechas_grupo.open();
            },
            async getFechasGrupo(index_grupo)
            {
                var codgrh = this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_codigo;
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionGrupo/' + codgrh + '/Fechas')
                .then(response => {
                    if(response.data.length > 0)
                    {
                        this.sin_fechas = false;
                        var data = response.data;
                        for(let i = 0; i <= data.length - 1; i++)
                        {
                            data[i].fpg_fecha_limite = new Date(data[i].fpg_fecha_limite);
                        }
                        this.fechas = data;
                    }
                    else
                    {
                        this.sin_fechas = true;
                        var i_codtmo = 1;
                        this.fechas = [];
                        for(let i = 0; i <= this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[this.index_grupo].grh_cant_cuotas; i++)
                        {
                            this.fechas.push({
                                fpg_codtmo: i_codtmo,
                                fpg_fecha_limite: null,
                                fpg_monto: i_codtmo == 1 ? this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[this.index_grupo].grh_matricula:this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[this.index_grupo].grh_cuota,
                                fpg_mora: true,
                                fpg_orden: i + 1,
                                fpg_codigo: 0
                            });
                            i_codtmo++;
                        }
                    }
                });
                this.$root.stopLoading();
            },
            async setFechaGrupo()
            {
                var codgrh = this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[this.index_grupo].grh_codigo;
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionGrupoFechas/' + codgrh + '/FechasGrupo', this.fechas)
                .then(() => {
                    this.sin_fechas = false;
                    M.toast({html: 'Las fechas han sido actualizadas.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async getTiposProcesos()
            {
                await this.axios.get('/TipoProcesoGraduacion')
                .then(response => {
                    this.tipos_procesos = response.data.filter(x=>x.tpg_estado);
                });
            }
        },
        computed: {
            _procesos_autorizados() {
                return this.procesos_autorizados
                           .filter(x=>x.car_codigo == (this.codcar == 0 ? x.car_codigo:this.codcar)
                                    && x.tpg_codigo == (this.codtpg == 0 ? x.tpg_codigo:this.codtpg)
                                    &&
                                    (
                                        x.car_nombre.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
                                        x.tpg_descripcion.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
                                        x.pgr_descripcion.toLowerCase().indexOf(this.search.toLowerCase()) != -1
                                    )        
                            );
            }
        }
    }
</script>