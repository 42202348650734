<template>
    <div class="row">
        <div class="col m12">
            <h5>Horarios que tienen bloque programado en aula virtual.</h5>
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <div class="col m3">
                <label>Facultad</label>
                <select class="browser-default" v-model="codfac" @change="codcar = 0">
                    <option value="0">Todas</option>
                    <option v-for="facultad in facultades_filter" :key="facultad" :value="facultad.fac_codigo">{{ facultad.fac_nombre }}</option>
                </select>
            </div>
            <div class="col m3">
                <label>Carrera</label>
                <select class="browser-default" v-model="codcar">
                    <option value="0">Todas</option>
                    <option v-for="carrera in carreras" :key="carrera" :value="carrera.car_codigo">{{ carrera.car_nombre }}</option>
                </select>
            </div>
            <div class="col m2">
                <button class="btn blue-ugb btn-small" @click="getHorarios()" style="margin-top: 20px">Recargar <i class="material-icons right">refresh</i></button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                <tbody>
                    <template v-for="facultad in _facultades" :key="facultad">
                        <tr class="black white-text" v-show="facultad.ra_car_carreras.length > 0">
                            <th colspan="8" class="center">{{ facultad.fac_nombre }}</th>
                        </tr>
                        <template v-for="carrera in facultad.ra_car_carreras" :key="carrera">
                            <tr class="black white-text">
                                <th colspan="8" class="center">{{ carrera.car_nombre }}</th>
                            </tr>
                            <template v-for="plan in carrera.ra_pla_planes" :key="plan">
                                <tr class="blue-ugb white-text">
                                    <th colspan="8" class="center">{{ plan.pla_nombre }}</th>
                                </tr>
                                <tr class="teal darken-2 white-text">
                                    <th class="center">Grupo</th>
                                    <th class="center">Materia</th>
                                    <th class="center">Max. Alu.</th>
                                    <th class="center">Autofin.</th>
                                    <th class="center">Virtual</th>
                                    <th class="center">Publicado</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <template v-for="horario in plan.ra_hor_horarios" :key="horario">
                                    <tr style="border: solid 2px black">
                                        <td class="center">{{horario.hor_descripcion}}</td>
                                        <td>{{horario.mat_codigo}} - {{(horario.plm_alias || horario.mat_nombre)}}</td>
                                        <td class="center">{{horario.hor_max_alumnos}}</td>
                                        <td class="center">{{horario.hor_financiado}}</td>
                                        <td class="center">{{horario.hor_virtual}}</td>
                                        <td class="center"><label><input type="checkbox" class="filled-in" disabled v-bind:checked="horario.hor_publicado"><span></span></label></td>
                                        <td class="center"><a href="#!" @click="editarHorario(horario.hor_codigo, plan.pla_codigo, horario.mat_codigo)"><i class="material-icons">create</i></a></td>
                                        <td class="center"><a href="#!" @click="eliminarHorario(horario.hor_codigo)"><i class="material-icons">delete</i></a></td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">
                                            <ul class="collection">
                                                <template v-for="detalle in horario.ra_dho_det_horarios_gr" :key="detalle">
                                                    <li class="collection-item">
                                                        {{detalle.dh_dia_f}} | {{detalle.man_nomhor}} | {{detalle.edi_nombre}} | {{detalle.aul_nombre_corto}} | {{(detalle.dho_tipo == 'T') ? 'Teoría':'Práctica'}} | {{(detalle.dho_tipo == 'P' ? detalle.dho_capacidad:horario.hor_max_alumnos)}} Alumnos <span v-show="detalle.dho_virtual" class="badge new green darken-1" data-badge-caption=""> Virtual</span>
                                                    </li>
                                                </template>
                                            </ul>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </div>

    <ModificarHorario ref="modificarHorario"></ModificarHorario>
</template>

<script>
import ModificarHorario from '../components/ModificarHorarioComponent.vue'
import M from 'materialize-css'
    export default {
        components: {
            ModificarHorario
        },
        data(){
            return {
                facultades: [],
                modales:[],
                codcar: 0,
                codfac: 0,
                codmat: '',
                codpla: 0,
                facultades_filter: []
            }
        },
        async mounted()
        {
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
            await this.getHorarios();
        },
        methods: {
            async getHorarios()
            {
                this.codfac = 0;
                this.codcar = 0;
                this.$root.startLoading();
                await this.axios.get('/Horarios/HorariosBloqueVirtual')
                .then(response => {
                    this.facultades = response.data.data;
                    this.facultades_filter = response.data.facultades;
                });
                this.$root.stopLoading();
            },
            async editarHorario(codhor, codmat, codpla)
            {
                this.codmat = codmat;
                this.codpla = codpla;
                this.$root.startLoading();
                await this.$refs.modificarHorario.getHorario(codhor, 0, 0, 0, 0, this.codpla, this.codmat, false, 'S');
                this.$root.stopLoading();
            }
        },
        computed: {
            carreras()
            {
                var carreras = [];
                for(let i = 0; i <= this.facultades_filter.length - 1; i++)
                {
                    for(let j = 0; j <= this.facultades_filter[i].ra_car_carreras.length - 1; j++)
                    {
                        if(this.codfac == 0 |this.facultades_filter[i].fac_codigo == this.codfac)
                        carreras.push(this.facultades_filter[i].ra_car_carreras[j]);
                    }
                }
                return carreras;
            },
            _facultades()
            {
                var facultades = [];
                for(let i = 0; i <= this.facultades.length - 1; i++)
                {
                    if(this.codfac == 0 || this.facultades[i].fac_codigo == this.codfac)
                    {
                        var carreras = [];
                        for(let j = 0; j <= this.facultades[i].ra_car_carreras.length - 1; j++)
                        {
                            if(this.codcar == 0 || this.facultades[i].ra_car_carreras[j].car_codigo == this.codcar)
                            {
                                carreras.push(this.facultades[i].ra_car_carreras[j]);
                            }
                        }
                        var facultad = {
                            fac_codigo: this.facultades[i].fac_codigo,
                            fac_nombre: this.facultades[i].fac_nombre,
                            ra_car_carreras: carreras
                        };
                        //facultad.ra_car_carreras = carreras;
                        facultades.push(facultad);
                    }
                }
                /*facultades = this.facultades.filter(x=>
                                                        x.fac_codigo == (this.codfac == 0 ? x.fac_codigo:this.codfac) &&
                                                        x.ra_car_carreras.findIndex(y=>y.car_codigo == (this.codcar == 0 ? y.car_codigo:this.codcar)) != -1
                                                    );*/
                return facultades;
            }
        }
    }
</script>