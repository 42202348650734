<template>
    <div class="row">
        <form method="post" @submit.prevent="setPonderacion()">
            <div class="col m4">
                <label>Tipo Proceso</label>
                <select class="browser-default" v-model="ponderacion.pon_codtpg">
                    <option value="">Seleccione</option>
                    <option v-for="tipo in tipos_procesos" :key="tipo" :value="tipo.tpg_codigo">{{ tipo.tpg_descripcion }}</option>
                </select>
                <small class="sm-validation">{{$validation(validations, 'pon_codtpg')}}</small>
            </div>
            <div class="col m4">
                <label>Descripción</label>
                <input type="text" v-model="ponderacion.pon_ponderacion"/>
                <small class="sm-validation">{{$validation(validations, 'pon_ponderacion')}}</small>
            </div>
            <div class="col m2">
                <label>Porcentaje</label>
                <input type="number" min="1" max="100" v-model="ponderacion.pon_porcentaje" />
                <small class="sm-validation">{{$validation(validations, 'pon_porcentaje')}}</small>
            </div>
            <div class="col m2">
                <br/>
                <button class="btn blue-ugb btn-small" type="submit">Agregar</button>
            </div>
        </form>
    </div>
    
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                <thead>
                    <tr>
                        <th>Orden</th>
                        <th>Tipo</th>
                        <th>Ponderación</th>
                        <th>Porcentaje</th>
                        <th>Total</th>
                        <th>Activo</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="ponderacion in ponderaciones_proceso" :key="ponderacion">
                        <td class="center">{{ponderacion.pon_orden}}</td>
                        <td class="center">{{ponderacion.tpg_descripcion}}</td>
                        <td>{{ponderacion.pon_ponderacion}}</td>
                        <td class="center">{{ponderacion.pon_porcentaje}}%</td>
                        <td class="center">{{totalPorcentaje(ponderacion.pon_codtpg)}}%</td>
                        <td class="center"><label><input type="checkbox" class="filled-in" :checked="ponderacion.pon_activo" @click="setEstado(ponderacion.pon_codigo, $event.target.checked)"/><span></span></label></td>
                        <td class="center"><a href="#!" @click="eliminarPonderacion(ponderacion.pon_codigo)"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                ponderaciones: [],
                tipos_procesos: [],
                ponderaciones_proceso: [],
                ponderacion: {},
                validations: []
            }
        },
        async mounted(){
            await this.getTiposProcesos();
            await this.getPonderaciones();
            this.resetPonderacion();
        },
        methods: {
            async getTiposProcesos()
            {
                await this.axios.get('/TipoProcesoGraduacion')
                .then(response => {
                    this.tipos_procesos = response.data.filter(x=>x.tpg_estado);
                })
            },
            resetPonderacion()
            {
                this.ponderacion = {};
                this.ponderacion.pon_codtpg = '';
                this.ponderacion.pon_porcentaje = 0;
                this.ponderacion.pon_activo = true;
                this.ponderacion.pon_codigo = 0;
                this.ponderacion.pon_orden = 0;
                this.ponderacion.pon_ponderacion = '';
            },
            async getPonderaciones()
            {
                await this.axios.get('/ProcesosGraduacionPonderaciones')
                .then(response => {
                    this.ponderaciones_proceso = response.data;
                });
            },
            async setPonderacion()
            {
                this.validations = [];
                this.ponderacion.pon_porcentaje = parseInt(this.ponderacion.pon_porcentaje);
                await this.axios.post('/ProcesosGraduacionPonderaciones', this.ponderacion)
                .then(async () => {
                    await this.getPonderaciones();
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                    }
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
            },
            async eliminarPonderacion(codpon)
            {
                if(!confirm('¿Desea eliminar esta ponderación?')) return false;
                await this.axios.delete('/ProcesosGraduacionPonderaciones/' + codpon)
                .then(async () => {
                    await this.getPonderaciones();
                });
            },
            async setEstado(codpon, estado)
            {
                await this.axios.post('/ProcesosGraduacionPonderaciones/' + codpon + '/Estado/' + estado)
                .then(async () => {
                    this.getPonderaciones();
                });
            },
            totalPorcentaje(codtpg)
            {
                var result = this.ponderaciones_proceso.filter(x=>x.pon_codtpg == codtpg);
                var total = 0;
                for(let i = 0; i <= result.length - 1; i++)
                {
                    total += result[i].pon_porcentaje;
                }
                return total;
            }
        }
    }
</script>