<template>
    <div class="row card-panel">
        <div class="col m3">
            <label>Buscar</label>
            <input type="text" v-model="search" />
        </div>
        <div class="col m3">
            <label>Facultad</label>
            <select class="browser-default" v-model="codfac" @change="codcar = 0">
                <option value="0">Todas</option>
                <option v-for="facultad in _facultades_filter" :key="facultad" :value="facultad.fac_codigo">{{facultad.fac_nombre}}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Carrera</label>
            <select class="browser-default" v-model="codcar" @change="codmat = ''">
                <option value="0">Todas</option>
                <option v-for="carrera in _carreras_filter" :key="carrera" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Materia</label>
            <select class="browser-default" v-model="codmat">
                <option value="">Todas</option>
                <option v-for="materia in _materias_filter" :key="materia" :value="materia.mat_nombre">{{materia.mat_nombre}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
                <thead>
                    <tr>
                        <th style="width: 20%">Facultad</th>
                        <th style="width: 35%">Carrera</th>
                        <th style="width: 8%">Plan</th>
                        <th>Ciclo</th>
                        <th style="width: 34%">Materia</th>
                        <th style="width: 3%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="materia in _materias" :key="materia">
                    <td>{{materia.fac_nombre}}</td>
                    <td>{{materia.car_nombre}}</td>
                    <td>{{materia.pla_nombre}}</td>
                    <td class="center">{{ materia.plm_ciclo }}</td>
                    <td>{{materia.mat_codigo}} - {{materia.mat_nombre}}</td>
                    <td class="center"><a href="#!" @click="modalMateria(materia.pla_codigo, materia.mat_codigo)"><i class="material-icons">list</i></a></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-grupos" style="width:80%">
        <div class="modal-content">
            <div class="row m0 p0">
                <div class="col m12">
                    <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                        <thead>
                            <tr>
                                <th>Grupo</th>
                                <th>Docente</th>
                                <th>Materia</th>
                                <th>Capacidad</th>
                                <th>Virtual</th>
                                <th>Autofin.</th>
                                <th>Activo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(horario, index) in horarios" :key="horario">
                                <tr v-bind:class="{'grey lighten-2': (index == index_grupo)}">
                                    <td class="center"><a href="#!" @click="getInscritos(horario.hor_codigo, index)"><b>{{horario.hor_descripcion}}</b></a></td>
                                    <td>{{horario.emp_apellidos_nombres}}</td>
                                    <td>{{horario.hor_codmat}} - {{horario.mat_nombre}}</td>
                                    <td class="center">{{horario.hor_max_alumnos}}</td>
                                    <td class="center">{{horario.hor_virtual}}</td>
                                    <td class="center">{{horario.hor_financiado}}</td>
                                    <td class="center">{{horario.hor_publicado}}</td>
                                </tr>
                                <tr v-show="horario.car_virtual == 'P'">
                                    <td colspan="7">
                                        <ul class="collection">
                                            <li class="" v-for="detalle in horario.detalle" :key="detalle">- {{detalle.dh_dia_f}} {{detalle.man_nomhor}} {{detalle.edi_nombre}} {{detalle.aul_nombre_corto}} {{detalle.dho_tipo == 'T' ? 'Teoría':'Práctica'}}</li>
                                        </ul> 
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row m0 p0">
                <div class="col m5">
                   <table class="table tbl-condensed tbl-bordered" style="font-size:12px" v-show="estudiantes.length > 0">
                    <caption>INSCRITOS</caption>
                        <thead>
                            <tr>
                                <th style="width: 10%">#</th>
                                <th style="width: 20%">Carnet</th>
                                <th style="width: 70%">Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(estudiante, index) in estudiantes" :key="estudiante">
                                <td class="center">{{index+1}}</td>
                                <td class="center">{{estudiante.per_carnet}}</td>
                                <td>{{estudiante.per_apellidos_nombres}}</td>
                            </tr>
                        </tbody>
                   </table>
                </div>
                <div class="col m7">
                    <table class="table tbl-condensed tbl-bordered" style="font-size:12px" v-show="equivalencias.length > 0">
                        <caption>EQUIVALENCIAS REGISTRADAS EN SISTEMA</caption>
                        <thead>
                            <tr>
                                <th>Carrera</th>
                                <th>Plan</th>
                                <th>Materia</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="equivalencia in equivalencias" :key="equivalencia">
                                <td>{{equivalencia.carrera}}</td>
                                <td>{{equivalencia.plan}}</td>
                                <td>{{equivalencia.codigoMateria}} - {{equivalencia.materia}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
export default {
    data(){
        return {
            materias: [],
            codfac: 0,
            codcar: 0,
            codmat: '',
            search: '',
            horarios: [],
            equivalencias: [],
            modales: [],
            modal_grupos: [],
            estudiantes: [],
            index_grupo: -1
        }
    },
    async mounted()
    {
        this.modales = M.Modal.init(document.querySelectorAll('.modal'), null);
        await this.getMaterias();
    },
    methods: {
        async getMaterias()
        {
            this.$root.startLoading();
            await this.axios.get('/MateriasAutorizadas/Programadas')
            .then(response => {
                this.materias = response.data;
            });
            this.$root.stopLoading();
        },
        async modalMateria(codpla, codmat)
        {
            this.index_grupo = -1;
            this.estudiantes = [];
            this.equivalencias = [];
            await this.getHorarios(codpla, codmat);
            await this.getEquivalencias(codpla, codmat);
            this.modal_grupos = M.Modal.getInstance(document.querySelector('#modal-grupos'));
            this.modal_grupos.open(); 
        },
        async getHorarios(codpla, codmat)
        {
            this.$root.startLoading();
            await this.axios.get('/Horarios/Materia/' + codpla + '/' + codmat)
            .then(response => {
                this.horarios = response.data;
            });
            this.$root.stopLoading();
        },
        async getEquivalencias(codpla, codmat)
        {
            this.$root.startLoading();
            await this.axios.get('/MateriasAutorizadas/Equivalencias/' + codpla + '/' + codmat)
            .then(response => {
                this.equivalencias = response.data;
            });
            this.$root.stopLoading();
        },
        async getInscritos(codhor, index)
        {
            this.index_grupo = index;
            this.$root.startLoading();
            await this.axios.get('/Horarios/'+codhor+'/Inscritos')
            .then(response => {
                if(response.status == 200)
                {
                    this.estudiantes = response.data.inscritos;
                }
                else
                {
                    this.estudiantes = [];
                }
            });
            this.$root.stopLoading();
        }
    },
    computed: {
        _materias(){
            return this.materias.filter(x=>
                        (x.fac_nombre.toLowerCase().indexOf(this.search.toLocaleLowerCase()) != -1 ||
                        x.car_nombre.toLowerCase().indexOf(this.search.toLocaleLowerCase()) != -1 ||
                        x.mat_codigo.toLowerCase().indexOf(this.search.toLocaleLowerCase()) != -1 ||
                        x.mat_nombre.toLowerCase().indexOf(this.search.toLocaleLowerCase()) != -1) &&
                        x.fac_codigo == (this.codfac == 0 ? x.fac_codigo : this.codfac) && 
                        x.car_codigo == (this.codcar == 0 ? x.car_codigo : this.codcar) && 
                        x.mat_nombre == (this.codmat == 0 ? x.mat_nombre : this.codmat)
                    );
        },
        _carreras_filter(){
            var carreras = [];
            for(let i = 0; i <= this.materias.length - 1; i++)
            {
                if(carreras.findIndex(x=>x.car_codigo == this.materias[i].car_codigo) == -1)
                {
                    if(this.codfac == 0 || this.codfac == this.materias[i].fac_codigo)
                    {
                        carreras.push({
                            car_codigo: this.materias[i].car_codigo,
                            car_nombre: this.materias[i].car_nombre,
                        });
                    }
                }
            }
            return carreras.sort((a,b) => (a.car_nombre > b.car_nombre) ? 1 : ((b.car_nombre > a.car_nombre) ? -1 : 0));
        },
        _facultades_filter(){
            var facultades = [];
            for(let i = 0; i <= this.materias.length - 1; i++)
            {
                if(facultades.findIndex(x=>x.fac_codigo == this.materias[i].fac_codigo) == -1)
                {
                    facultades.push({
                        fac_codigo: this.materias[i].fac_codigo,
                        fac_nombre: this.materias[i].fac_nombre,
                    });
                }
            }
            return facultades.sort((a,b) => (a.fac_nombre > b.fac_nombre) ? 1 : ((b.fac_nombre > a.fac_nombre) ? -1 : 0));
        },
        _materias_filter(){
            var materias = [];
            for(let i = 0; i <= this.materias.length - 1; i++)
            {
                if(materias.findIndex(x=>x.mat_codigo == this.materias[i].mat_codigo) == -1)
                {
                    if(this.codcar == 0 || this.codcar == this.materias[i].car_codigo)
                    {
                        materias.push({
                            mat_codigo: this.materias[i].mat_codigo,
                            mat_nombre: this.materias[i].mat_nombre,
                        });
                    }
                }
            }
            return materias.sort((a,b) => (a.mat_nombre > b.mat_nombre) ? 1 : ((b.mat_nombre > a.mat_nombre) ? -1 : 0));
        }
    }
}
</script>
