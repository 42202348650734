<template>
    <div class="row">
        <div class="col m12">
            <ul class="tabs" id="tab">
                <li class="tab col s2"><a href="#carreras">Carreras</a></li>
                <li class="tab col s2"><a href="#materias">Materias</a></li>
            </ul>
        </div>
    </div>
    <div class="row" id="carreras">
        <div class="row">
            <div class="col m12">
                <div class="col m3">
                    <label>Carrera</label>
                    <select class="browser-default" v-model="carrera.car_codigo">
                        <option value="0">Seleccione</option>
                        <option v-for="carrera in carreras_filter" :key="carrera" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
                    </select>
                </div>
                <div class="col m2">
                    <br>
                    <button class="btn blue-ugb btn-small" @click="setCarrera()">Agregar</button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col m12">
                <div class="col m4">
                    <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                        <thead>
                            <tr>
                                <th>Carrera</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(carrera, index) in carreras_asociadas" :key="carrera" v-bind:class="{'grey lighten-3': index == index_carrera_asociada}">
                                <td style="width: 90%"><a href="#!" @click="getAsociadas(index)">{{carrera.car_nombre}}</a></td>
                                <td class="center"><a href="#!"><i class="material-icons">delete</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col m4">
                    <div class="row">
                        <div class="col m8">
                            <label>Carrera a Asociar</label>
                            <select class="browser-default" v-model="codcar">
                                <option value="0">Seleccione</option>
                                <option v-for="carrera in carreras_asignar_filter" :key="carrera" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
                            </select>
                        </div>
                        <div class="col m4">
                            <button class="btn blue-ugb btn-small" @click="asignarCarrera()">Asignar</button>
                        </div>
                    </div>

                    <div class="row">
                        <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                            <thead>
                                <tr>
                                    <th>Carrera Asociada</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="carrera in asociadas" :key="carrera">
                                    <td style="width: 90%">{{carrera.car_nombre}}</td>
                                    <td class="center"><a href="#!" @click="eliminarAsociada(carrera.car_codigo)"><i class="material-icons">delete</i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" id="materias">
        <div class="col m4">
            Materias
        </div>
    </div>

</template>

<script>
import M from 'materialize-css'
export default {
    data()
    {
        return {
            carreras: [],
            carreras_asociadas: [],
            carrera: {},
            tabs: [],
            asociadas: [],
            codcar: 0,
            index_carrera_asociada: -1
        }
    },
    async mounted()
    {
        this.resetCarrera();
        await this.getCarreras();
        await this.getCarrerasAsociadas();
        this.tabs = M.Tabs.init(document.querySelector('#tab'), null);
    },
    methods: {
        async getCarreras()
        {
            await this.axios.get('/Carreras/All')
            .then(response => {
                this.carreras = response.data;
            });
        },
        async getCarrerasAsociadas()
        {
            await this.axios.get('/AsociacionCarrera')
            .then(response => {
                this.carreras_asociadas = response.data;
            });
        },
        resetCarrera()
        {
            this.carrera.car_codigo = 0;
        },
        setCarrera()
        {
            var index_carrera = this.carreras.findIndex(x=>x.car_codigo == this.carrera.car_codigo);
            if(index_carrera != -1)
            {
                this.carreras[index_carrera].ra_car_carreras = [];
                this.carreras_asociadas.push(this.carreras[index_carrera]);
                this.resetCarrera();
            }
        },
        async eliminarAsociada(codcar)
        {
            if(!confirm('¿Desea eliminar esta carrera?')) return false;
            var index_asociada = this.asociadas.findIndex(x=>x.car_codigo == codcar);
            await this.axios.delete('/AsociacionCarrera/' + this.carreras_asociadas[index_asociada].car_codigo + '/' + codcar)
            .then(async () => {
                this.asociadas.splice(index_asociada, 1);
                this.getCarrerasAsociadas();
            });
        },
        getAsociadas(index)
        {
            this.asociadas = this.carreras_asociadas[index].ra_car_carreras;
            this.index_carrera_asociada = index;
        },
        async asignarCarrera()
        {
            var payload = {
                asc_codcar: this.carreras_asociadas[this.index_carrera_asociada].car_codigo,
                asc_codcar_asc: this.codcar
            };
            await this.axios.post('/AsociacionCarrera', payload)
            .then(async () => {
                this.getAsociadasCarrera(payload.asc_codcar);
            });
        },
        async getAsociadasCarrera(codcar)
        {
            await this.axios.get('/AsociacionCarrera/' + codcar + '/Asociadas')
            .then(response => {
                this.asociadas = response.data;
                this.carreras_asociadas[this.index_carrera_asociada].ra_car_carreras = response.data;
            });
        }
    },
    computed: {
        carreras_filter(){
            return this.carreras.filter(x=>this.carreras_asociadas.findIndex(y=>y.car_codigo == x.car_codigo) == -1 && x.car_virtual == 'P');
        },
        carreras_asignar_filter()
        {
            if(this.index_carrera_asociada == -1) return [];
            var codfac = this.carreras.find(x=>x.car_codigo == this.carreras_asociadas[this.index_carrera_asociada].car_codigo).car_codfac;
            var codcar = this.carreras_asociadas[this.index_carrera_asociada].car_codigo;
            return this.carreras.filter(x=>this.asociadas.findIndex(y=>y.car_codigo == x.car_codigo) == -1 && x.car_codfac == codfac && x.car_codigo == codcar && x.car_virtual == 'P');
        }
    }
}
</script>
