import {createWebHistory, createRouter} from 'vue-router'
import Home from '@/views/Home.vue'
import Carreras from '@/views/Carreras'
import Planes from '@/views/Planes'
import Login from '@/views/Login'
import Horarios from '@/views/Horarios'
import Docentes from '@/views/Docentes'
import Logout from '@/views/Logout'
import Proyeccion from '@/views/Proyeccion'
import Administracion from '@/views/Administracion'
import Virtual from '@/views/Virtual'
import Restricciones from '@/views/Restricciones'
import Cruzadas from '@/views/Cruzadas'
import ProcesosGraduacion from '@/views/ProcesosGraduacion'
import ProcesosGraduacionGrupos from '@/views/ProcesosGraduacionGrupos'
import ProcesosGraduacionFechas from '@/views/ProcesosGraduacionFechas'
import ProcesosGraduacionInscripcion from '@/views/ProcesosGraduacionInscripcion'
import ProcesosGraduacionEstudiantes from '@/views/ProcesosGraduacionEstudiantes'
import MateriasProgramadas from '@/views/MateriasProgramadas'
import ProcesosGraduacionConfiguracion from '@/views/ProcesosGraduacionConfiguracion'
import ProcesosGraduacionNotas from '@/views/ProcesosGraduacionNotas'
import AsociacionCarreras from '@/views/AsociacionCarreras'
import GeneracionHorarios from '@/views/GeneracionHorarios'
import DisponibilidadHorario from '@/views/DisponibilidadHorario'
import SinHorarios from '../components/SinHorariosComponent'
import SinDocente from '../components/SinDocenteComponent'
import HorariosAulaVirtualComponent from '../components/HorariosAulaVirtualComponent'
import GruposComponent from '../components/GruposComponent'
import Choques from '@/views/Choques'
import AccesosUOnline from '@/views/AccesosUOnline.vue'
import Generacion from '@/views/Generacion.vue'
import Grupos from '@/views/Grupos.vue'
import BloquesRegional from '@/views/BloquesRegional.vue'
import Egreso from '@/views/Egreso.vue'
import Fechas from '@/views/Fechas.vue'
import FechasVirtuales from '@/views/FechasVirtuales.vue'
import FechasNotasVirtuales from '@/views/FechasNotasVirtuales.vue'
import AulasMateria from '@/views/AulasMateria.vue'
import BloquesLibres from '@/views/BloquesLibres.vue'
import AulaBloques from '@/views/AulaBloques.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Carreras',
        name: 'Carreras',
        component: Carreras
    },
    {
        path: '/Planes',
        name: 'Planes',
        component: Planes
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login
    },
    {
        path: '/Horarios',
        name: 'Horarios',
        component: Horarios
    },
    {
        path: '/Docentes/:id',
        component: Docentes,
        name: 'Docentes'
    },
    {
        path: '/Logout',
        component: Logout,
        name: 'Logout'
    },
    {
        path: '/Proyeccion',
        component: Proyeccion,
        name: 'Proyeccion'
    },
    {
        path: '/Administracion',
        component: Administracion,
        name: 'Administracion'
    },
    {
        path: '/Virtual',
        component: Virtual,
        name: 'Virtual'
    },
    {
        path: '/Restricciones',
        component: Restricciones,
        name: 'Restricciones'
    },
    {
        path: '/Cruzadas',
        component: Cruzadas,
        name: 'Cruzadas'
    },
    {
        path: '/ProcesosGraduacion',
        component: ProcesosGraduacion,
        name: 'ProcesosGraduacion'
    },
    {
        path: '/ProcesosGraduacionGrupos',
        component: ProcesosGraduacionGrupos,
        name: 'ProcesosGraduacionGrupos'
    },
    {
        path: '/ProcesosGraduacionFechas',
        component: ProcesosGraduacionFechas,
        name: ProcesosGraduacionFechas
    },
    {
        path: '/ProcesosGraduacionInscripcion',
        component: ProcesosGraduacionInscripcion,
        name: ProcesosGraduacionInscripcion
    },
    {
        path: '/ProcesosGraduacionEstudiantes',
        component: ProcesosGraduacionEstudiantes,
        name: ProcesosGraduacionEstudiantes
    },
    {
        path: '/ProcesosGraduacionConfiguracion',
        component: ProcesosGraduacionConfiguracion,
        name: ProcesosGraduacionConfiguracion
    }
    ,
    {
        path: '/MateriasProgramadas',
        component: MateriasProgramadas,
        name: MateriasProgramadas
    },
    {
        path: '/ProcesosGraduacionNotas',
        component: ProcesosGraduacionNotas,
        name: ProcesosGraduacionNotas
    },
    {
        path: '/AsociacionCarreras',
        component: AsociacionCarreras,
        name: AsociacionCarreras
    },
    {
        path: '/GeneracionHorarios',
        component: GeneracionHorarios,
        name: GeneracionHorarios
    },
    {
        path: '/SinHorarios',
        component: SinHorarios, 
        name: SinHorarios
    },
    {
        path: '/SinDocente',
        component: SinDocente, 
        name: SinDocente
    },
    {
        path: '/DisponibilidadHorario',
        component: DisponibilidadHorario,
        name: DisponibilidadHorario
    },
    {
        path: '/HorariosAulaVirtualComponent',
        component: HorariosAulaVirtualComponent,
        name: HorariosAulaVirtualComponent
    },
    {
        path: '/Choques',
        component: Choques,
        name: Choques
    },
    {
        path: '/AccesosUOnline',
        component: AccesosUOnline,
        name: AccesosUOnline
    },
    {
        path: '/Generacion',
        component: Generacion,
        name: Generacion
    },
    {
        path: '/Grupos',
        component: Grupos,
        name: Grupos
    },
    {
        path: '/Grupo/:codpla/:ciclo/:codgrh',
        component: GruposComponent,
        name: 'GruposComponent'
    },
    {
        path: '/BloquesRegional',
        component: BloquesRegional,
        name: 'BloquesRegional'
    },
    {
        path: '/Egreso',
        component: Egreso,
        name: 'Egreso'
    },
    {
        path: '/Fechas',
        component: Fechas,
        name: 'Fechas'
    },
    {
        path: '/FechasVirtuales',
        component: FechasVirtuales,
        name: 'FechasVirtuales'
    },
    {
        path: '/FechasNotasVirtuales',
        component: FechasNotasVirtuales,
        name: 'FechasNotasVirtuales'
    },
    {
        path: '/AulasMateria',
        component: AulasMateria,
        name: 'AulasMateria'
    },
    {
        path: '/BloquesLibres',
        component: BloquesLibres,
        name: 'BloquesLibres'
    },
    {
        path: '/AulaBloques',
        component: AulaBloques,
        name: 'AulaBloques'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;