<template>
    <div class="row">
        <div class="col m12">
            <h5>Asignación de aulas para prácticas</h5>
            <blockquote>
                Seleccione la o las aulas donde se imparten las asignaturas que requieren uso de laboratorio.
            </blockquote>
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <div class="col m3">
                <label>Facultad</label>
                <select class="browser-default" v-model="codfac" @change="getMaterias()">
                    <option value="0">Todas</option>
                    <option v-for="facultad in facultades" :key="facultad" :value="facultad.fac_codigo">{{ facultad.fac_nombre }}</option>
                </select>
            </div>
            <div class="col m3">
                <label><input type="radio" v-model="mat_aulas" name="mat_aulas" value="X"><span>Todas</span></label>
                <label><input type="radio" v-model="mat_aulas" name="mat_aulas" value="S"><span>Con Aulas</span></label>
                <label><input type="radio" v-model="mat_aulas" name="mat_aulas" value="N"><span>Sin Aulas</span></label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col m12">
            <div class="col m6">
                <table class="table tbl-condensed tbl-bordered" style="font-size: 12px;">
                    <thead>

                    </thead>
                    <tbody>
                        <template v-for="facultad in registros" :key="facultad">
                            <tr class="green darken-2 white-text">
                                <th colspan="3">{{ facultad.fac_nombre }}</th>
                            </tr>

                            <template v-for="carrera in facultad.ra_car_carreras" :key="carrera">
                                <tr v-show="showCarrera(carrera.ra_mat_materias)" class="black white-text">
                                    <th colspan="3">{{ carrera.car_nombre }}</th>
                                </tr>

                                <template v-for="materia in carrera.ra_mat_materias" :key="materia">
                                    <tr v-show="showRow(materia.mat_aulas)" v-bind:class="{'grey lighten-1':codmat_selected == materia.mat_codigo && codpla_selected == materia.pla_codigo}">
                                        <td style="width: 10%">{{ materia.pla_nombre }}</td>
                                        <td style="width: 75%">{{ materia.mat_codigo }} - {{ materia.mat_nombre }}</td>
                                        <td style="width: 15%" class="center">
                                            <a href="#!" class="btn blue-ugb btn-small" @click="getAulasMaterias(materia.pla_codigo, materia.mat_codigo)">{{ materia.mat_aulas }} <i class="material-icons right">list</i></a>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>

            <div class="col m6" v-show="codpla_selected != 0">
                <div class="row">
                    <div class="col m12">
                        <div class="col m6">
                            <select class="browser-default" v-model="aula">
                                <option value="0">Seleccione</option>
                                <template v-for="aula in aulas" :key="aula">
                                    <option v-show="aulas_materia.findIndex(x=>x.aul_codigo == aula.aul_codigo) == -1" :value="aula.aul_codigo">{{ aula.edi_nombre }} - {{ aula.aul_nombre_corto }} (Cap. {{ aula.aul_capacidad }})</option>
                                </template>
                            </select>
                        </div>
                        <div class="col m2">
                            <button class="btn blue-ugb btn-small" @click="setAula()" :disabled="aula == 0">Agregar</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col m12">
                        <blockquote>
                            Debe agregar todas las aulas donde se imparte o se puede impartir la materia, para ampliar las posibilidades en la generación de horarios.
                            <i class="material-icons right">info</i>
                        </blockquote>
                        <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                            <thead>
                                <th style="width: 40%">Edificio</th>
                                <th style="width: 40%">Aula</th>
                                <th style="width: 10%">Capacidad</th>
                                <th style="width: 10%"></th>
                            </thead>
                            <tbody>
                                <template v-for="aula in aulas_materia" :key="aula">
                                    <tr>
                                        <td>{{ aula.edi_nombre }}</td>
                                        <td>{{ aula.aul_nombre_corto }}</td>
                                        <td class="center">{{ aula.aul_capacidad }}</td>
                                        <td class="center"><a href="#!" @click="eliminarAulasMaterias(aula.aul_codigo)"><i class="material-icons">delete</i></a></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                facultades: [],
                codfac: 0,
                registros: [],
                aulas: [],
                aula: 0,
                aulas_materia: [],
                codpla_selected: 0,
                codmat_selected: '',
                mat_aulas: 'X'
            }
        },
        async mounted()
        {
            await this.getFacultades();
            await this.getMaterias();
            await this.getAulas();
        },
        methods: {
            async getFacultades()
            {
                await this.axios.get('/Facultades?permitidas=true')
                .then(response => {
                    this.facultades = response.data;
                });
            },
            async getMaterias()
            {
                this.codpla_selected = 0;
                this.codmat_selected = '';
                await this.axios.get('/MateriaAula/Materias/' + this.codfac)
                .then(response => {
                    this.registros = response.data;
                });
            },
            async getAulas()
            {
                await this.axios.get('/Aulas/Laboratorios')
                .then(response => {
                    this.aulas = response.data;
                });
            },
            async getAulasMaterias(codpla, codmat)
            {
                this.codpla_selected = codpla;
                this.codmat_selected = codmat;
                await this.axios.get('/MateriaAula/' + codpla + '/' + codmat)
                .then(response => {
                    this.aulas_materia = response.data;
                });
            },
            async setAula()
            {
                var data = {
                    aam_codpla: this.codpla_selected,
                    aam_codmat: this.codmat_selected,
                    aam_aula: this.aula,
                    aam_fecha: new Date(),
                    aam_activo: true
                };
                await this.axios.post('/MateriaAula', data)
                .then(async () => {
                    this.aula = 0;
                    await this.getAulasMaterias(this.codpla_selected, this.codmat_selected);
                    this.setCantidad();
                });
            },
            async eliminarAulasMaterias(aula)
            {
                if(!confirm('¿Desea eliminar esta asignación?')) return false;
                await this.axios.delete('/MateriaAula/' + this.codpla_selected + '/' + this.codmat_selected + '/' + aula)
                .then(async () => {
                    this.aula = 0;
                    await this.getAulasMaterias(this.codpla_selected, this.codmat_selected);
                    this.setCantidad();
                });
            },
            setCantidad()
            {
                this.registros.forEach(facultad=>{
                    facultad.ra_car_carreras.forEach(carrera => {
                        carrera.ra_mat_materias.forEach(materia => {
                            if(materia.pla_codigo == this.codpla_selected && materia.mat_codigo == this.codmat_selected)
                            {
                                materia.mat_aulas = this.aulas_materia.length;
                            }
                        })
                    })
                })
            },
            showRow(mat_aulas)
            {
                if(this.mat_aulas == 'X') return true;
                if(this.mat_aulas == 'S' && mat_aulas > 0) return true;
                if(this.mat_aulas == 'N' && mat_aulas == 0) return true;
                return false;
            },
            showCarrera(materias)
            {
                if(this.mat_aulas == 'X') return true;
                if(this.mat_aulas == 'S' && materias.filter(x=>x.mat_aulas > 0).length != 0) return true;
                if(this.mat_aulas == 'N' && materias.filter(x=>x.mat_aulas == 0).length > 0) return true;
                return false;
            }
        }
    }
</script>
