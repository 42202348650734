<template>
    <div class="row">
        <div class="col s12">
            <ul class="tabs">
                <li class="tab col s3"><a href="#tab-accesos">Accesos</a></li>
                <li class="tab col s3"><a href="#tab-ponderaciones">Ponderaciones</a></li>
                <li class="tab col s3"><a href="#tab-opciones">Accesos Menú</a></li>
            </ul>
        </div>
    </div>

    <div class="row" id="tab-accesos">
        <div class="col m12 card-panel">
            <div class="row">
                <div class="col m3">
                    <label>Empleado</label>
                    <select class="browser-default" v-model="codemp">
                        <option value="0">Seleccione</option>
                        <option v-for="empleado in _empleados" :key="empleado" :value="empleado.emp_codigo">{{empleado.emp_apellidos_nombres}} ({{empleado.plz_nombre}})</option>
                    </select>
                </div>
                <div class="col m4">
                    <br/>
                    <button class="btn blue-ugb btn-small" @click="setEmpleado()">Agregar</button>
                </div>
            </div>
            <div class="row">
                <div class="col m4">
                    <table class="table striped tbl-condensed tbl-bordered" style="font-size:12px">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(empleado, index) in empleados_autorizados" :key="empleado" v-bind:class="{'grey lighten-2': (index == index_empleado)}">
                                <td>
                                    <a href="#!" @click="getAccesos(empleado.emp_codigo)">{{empleado.emp_apellidos_nombres}}</a>
                                    <br>
                                    <small>{{empleado.plz_nombre}}</small>
                                </td>
                                <td class="center"><a href="#!" @click="eliminarEmpleadoAcceso(empleado.emp_codigo)"><i class="material-icons">delete</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col m8">
                    <table class="table tbl-condensed" style="font-size:12px" v-show="index_empleado >= 0">
                        <thead>
                            <th style="width: 80%">Carrera</th>
                            <th>Activo</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>MARCAR TODOS</td>
                                <td class="center"><label><input type="checkbox" class="filled-in" @click="setAccesoTodos($event.target.checked)" /><span></span></label></td>
                            </tr>
                            <tr v-for="carrera in carreras" :key="carrera">
                                <td>{{carrera.car_nombre}}
                                    <br>
                                    <small><b>{{carrera.fac_nombre}}</b></small>
                                </td>
                                <td class="center"><label><input type="checkbox" class="filled-in" @click="setAcceso(carrera.car_codigo, $event.target.checked)" :checked="accesos.find(x=>x.acu_codcar == carrera.car_codigo && x.acu_activo) != undefined" /><span></span></label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="row" id="tab-ponderaciones">
        <div class="col m6">
            <div class="row card-panel">
                <PonderacionesComponent />
            </div>
        </div>
    </div>

    <div class="row" id="tab-opciones">
        <div class="col m12">
            <div class="row card-panel">
                <ProcesosGraduacionAccesosComponent />
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
import PonderacionesComponent from '../components/PonderacionesComponent.vue';
import ProcesosGraduacionAccesosComponent from '../components/ProcesosGraduacionAccesosComponent.vue';
    export default {
        components:{
            PonderacionesComponent,
            ProcesosGraduacionAccesosComponent
        },
        data(){
            return {
                carreras: [],
                empleados: [],
                empleados_autorizados: [],
                accesos: [],
                codemp: 0,
                index_empleado: -1,
                tabs: []
            }
        },
        async mounted()
        {
            await this.getCarreras();
            await this.getEmpleados();
            await this.getEmpleadosAutorizados()
            this.tabs = M.Tabs.init(document.querySelectorAll('.tabs'), null);
        },
        methods: {
            async getCarreras()
            {
                if(this.carreras.length > 0) return;
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionAccesoCarrera/Carreras')
                .then(response => {
                    this.carreras = response.data;
                });
                this.$root.stopLoading();
            },
            async getEmpleados()
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionAccesoCarrera/Empleados')
                .then(response => {
                    this.empleados = response.data;
                });
                this.$root.stopLoading();
            },
            async getAccesos(codemp)
            {
                this.index_empleado = this.empleados_autorizados.findIndex(x=>x.emp_codigo == codemp);
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionAccesoCarrera/' + codemp + '/Accesos')
                .then(response => {
                    this.accesos = response.data;
                });
                this.$root.stopLoading();
            },
            async setEmpleado()
            {
                var result = this.empleados.find(x=>x.emp_codigo == this.codemp);
                this.empleados_autorizados.unshift({
                    emp_codigo: result.emp_codigo,
                    emp_apellidos_nombres: result.emp_apellidos_nombres
                });
                await this.getAccesos(result.emp_codigo);
                this.codemp = 0;
            },
            async getEmpleadosAutorizados()
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionAccesoCarrera/EmpleadosAutorizados')
                .then(response => {
                    this.empleados_autorizados = response.data;
                });
                this.$root.stopLoading();
            },
            async setAcceso(codcar, estado)
            {
                var payload = {
                    acu_codemp: this.empleados_autorizados[this.index_empleado].emp_codigo,
                    acu_codcar: codcar,
                    acu_activo: estado
                };
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionAccesoCarrera/' + payload.acu_codemp, payload)
                .then(async () => {
                    
                });
                this.$root.stopLoading();
            },
            async eliminarEmpleadoAcceso(codemp)
            {
                if(!confirm('¿Desea eliminar todos los accesos?')) return false;
                var index = this.empleados_autorizados.findIndex(x=>x.emp_codigo == codemp);
                this.$root.startLoading();
                await this.axios.delete('/ProcesosGraduacionAccesoCarrera/' + codemp)
                .then(() => {
                    this.empleados_autorizados.splice(index, 1);
                    this.index_empleado = -1;
                });
                this.$root.stopLoading();
            },
            async setAccesoTodos(estado)
            {
                for(let i = 0; i <= this.carreras.length - 1; i++)
                {
                    await this.setAcceso(this.carreras[i].car_codigo, estado);
                }
                await this.getAccesos(this.empleados_autorizados[this.index_empleado].emp_codigo);
            }
        },
        computed: {
            _carreras()
            {
                return this.carreras.filter(x=>this.carreras.findIndex(y=>y.car_codigo == x.car_codigo) == -1);
            },
            _empleados(){
                return this.empleados.filter(x=>this.empleados_autorizados.findIndex(y=>y.emp_codigo == x.emp_codigo) == -1);
            }
        }
    }
</script>