<template>
  <div class="row m0 p0">
    <div class="col m12 card blue-ugb white-text m0 p0">
        <div class="card-content m0">
            <p>Gestione las materias de carreras virtuales asi como de presenciales impartidas en modalidad virtual. <i class="material-icons right">info</i></p>
            <button class="btn white black-text" @click="actualizarVirtuales()">Actualizar Bloques Virtuales <i class="material-icons right">update</i></button>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col m12 card-panel">
        <div class="col m5">
            <table class="table tbl-condensed striped bordered" style="font-size:12px">
                <caption>PLANES ACTIVOS</caption>
                <caption style="padding-bottom: 10px;"><input v-model="search_carrera" type="text" placeholder="Buscar..." @keyup="paginarPlanes()"></caption>
                <thead>
                    <th>Carrera</th>
                    <th>Plan</th>
                </thead>
                <tbody>
                    <tr v-for="plan in planes_view" v-bind:key="plan" v-bind:class="(codpla == plan.pla_codigo ? 'blue darken-3 white-text':'')">
                        <td>{{plan.ra_car_carreras.car_nombre}}</td>
                        <td class="center"><a href="#!" @click="seleccionarPlan(plan.pla_codigo)" v-bind:class="(codpla == plan.pla_codigo ? 'white-text':'')">{{plan.pla_nombre}}</a></td>
                    </tr>
                </tbody>
                <tfoot style="font-size:10px">
                    <tr class="m0 p0">
                        <td colspan="3" class="m0 p0">
                            <ul class="pagination m0 p0">
                                <li v-for="i in totalPaginasPlanes" v-on:click="cambiarPaginaPlanes(i)" v-bind:key="i" v-bind:class="(i == pagina_planes ? 'active blue-ugb':'')"><a style="font-size:12px" href="#!">{{i}}</a></li>
                            </ul>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="col m5">
                <table v-show="materias.length > 0" class="table tbl-condensed bordered striped" style="font-size:12px">
                    <caption>MATERIAS AUTORIZADAS</caption>
                    <caption style="padding-bottom: 10px;"><input v-model="search_materia" type="text" placeholder="Buscar..." @keyup="paginarMaterias()"></caption>
                    <thead>
                        <tr>
                            <th style="width:10%">Ciclo</th>
                            <th style="width:80%">Materia</th>
                            <th style="width:10%">Grupos</th>
                        </tr>
                    </thead>   
                    <tbody>
                        <tr v-for="materia in materias_view" v-bind:key="materia" v-bind:class="(codmat == materia.plm_codmat ? 'blue darken-3 white-text':'')">
                            <td class="center">{{materia.plm_ciclo}}</td>
                            <td>
                                <a href="#!" @click="seleccionarMateria(materia.plm_codmat)" v-bind:class="(codmat == materia.plm_codmat ? 'white-text':'')">
                                    {{materia.mat_codigo}} - {{(materia.plm_alias || materia.mat_nombre)}}
                                </a>
                            </td>
                            <td class="center">{{materia.hor_cantidad}}</td>
                        </tr>
                    </tbody>
                    <tfoot style="font-size:10px">
                        <tr class="m0 p0">
                            <td colspan="3" class="m0 p0">
                                <ul class="pagination m0 p0">
                                    <li v-for="i in totalPaginasMaterias" v-on:click="cambiarPaginaMaterias(i)" v-bind:key="i" v-bind:class="(i == pagina_materias ? 'active blue-ugb':'')"><a style="font-size:12px" href="#!">{{i}}</a></li>
                                </ul>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="col m2" v-show="(codmat != '')">
                <table class="table tbl-condensed bordered striped m0 p0" style="font-size:12px">
                    <caption>GRUPOS</caption>
                    <tbody>                                        
                        <tr v-for="horario in horarios_materia" v-bind:key="horario"> 
                            <td class="center"><a href="#!" @click="editarHorario(horario.hor_codigo)">{{horario.hor_descripcion}}</a></td>
                            <td class="center">{{horario.hor_max_alumnos}}</td>
                        </tr>
                        <tr><th>TOTAL</th><td class="center">{{horarios_materia.reduce((a, b) => a + b.hor_max_alumnos, 0)}}</td></tr>
                        <tr>
                            <th>PROYECCIÓN</th><td class="center">{{(proyeccion.prc_total || 0)}}</td>
                        </tr>
                        <tr>
                            <th>ESTÁNDAR</th><td class="center">{{(proyeccion.prc_estandar || 0)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    <div class="row m0 p0" v-show="(codmat != '')">
        <div class="col m12"><a class="right" href="#!" @click="editarHorario(0, 0, 0, 0, 0)" title="Agregar horario"><i style="font-size:30px" class="material-icons">add_circle</i></a></div>
    </div>
    <div class="row" v-if="(horarios_materia.length > 0)">
        <div class="col m12 card-panel">
            <label><input type="checkbox" @change="filtrarVirtual($event)" class="filled-in" true-value="true" false-value="false" v-model="solo_virtual"><span>SOLO VIRTUALES</span></label>
            <table class="table tbl-condensed bordered striped white" style="font-size:12px;" v-show="horarios_materia_view.length > 0">
                <caption class="white-text">HORARIOS PROGRAMADOS</caption>
                <thead>
                    <tr>
                        <th style="width:15%">Grupo</th>
                        <th style="width:30%">Materia</th>
                        <th style="width:10%">Max. Alu.</th>
                        <th style="width:5%">Finan.</th>
                        <th style="width:5%">Virt.</th>
                        <th style="width:5%">Publi.</th>
                        <th style="width:5%"></th>
                        <th style="width:5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="horario in horarios_materia_view" v-bind:key="horario">
                        <tr>
                            <td class="center">{{horario.hor_descripcion}}</td>
                            <td>{{(horario.plm_alias || horario.mat_nombre)}}</td>
                            <td class="center">{{horario.hor_max_alumnos}}</td>
                            <td class="center">{{horario.hor_financiado}}</td>
                            <td class="center">{{horario.hor_virtual}}</td>
                            <td class="center"><label><input type="checkbox" class="filled-in" disabled v-bind:checked="horario.hor_publicado"><span></span></label></td>
                            <td class="center"><a href="#!" @click="editarHorario(horario.hor_codigo)"><i class="material-icons">create</i></a></td>
                            <td class="center"><a href="#!" @click="eliminarHorario(horario.hor_codigo)"><i class="material-icons">delete</i></a></td>
                        </tr>
                        <tr >
                            <td colspan="9">
                                <ul class="collection">
                                    <li class="collection-item" v-for="detalle in horario.detalle" v-bind:key="detalle">
                                        {{detalle.dh_dia_f}} | {{detalle.man_nomhor}} | {{detalle.edi_nombre}} | {{detalle.aul_nombre_corto}} | {{(detalle.dho_tipo == 'T') ? 'Teoría':'Práctica'}} | {{(detalle.dho_tipo == 'P' ? detalle.dho_capacidad:horario.hor_max_alumnos)}} Alumnos <span v-show="detalle.dho_virtual" class="badge new green darken-1" data-badge-caption=""> Virtual</span>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
  </div>
  <ModificarHorario ref="modificarHorario"></ModificarHorario>
</template>

<script>
import ModificarHorario from '../components/ModificarHorarioComponent'
import M from 'materialize-css'
    export default {
        name: 'Virtual',
        components: {
            ModificarHorario
        },
        data(){
            return {
                facultades: [],
                codfac: 0,
                carreras: [],
                codcar: 0,
                carreras_view: [],
                planes: [],
                planes_view: [],
                codpla: 0,
                codmat: '',
                materias: [],
                materias_view: [],
                horarios_materia: [],
                horarios_materia_view: [],
                registros: 5,
                pagina_planes: 1,
                pagina_materias: 1,
                solo_virtual: true,
                modales:[],
                proyeccion: {},
                search_carrera: '',
                search_materia: '',
            }
        },
        async created()
        {
            this.$root.startLoading();
            if(this.planes.length == 0)
            {
                await this.axios.get('/Planes/Activos/?permitidos=true')
                .then(response => {
                    this.planes = response.data;
                    this.planes_view = response.data;
                });
            }
            this.paginarPlanes();
            this.$root.stopLoading();
        },
        mounted()
        {
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
        },
        methods: {
            paginarPlanes()
            {    
                this.planes_view = this.planes.filter(x=>x.ra_car_carreras.car_nombre.toLowerCase().indexOf(this.search_carrera.toLowerCase()) != -1).slice(0, this.registros);               
            },
            cambiarPaginaPlanes(p)
            {
                if(p == this.pagina_planes)
                {
                    return;
                }
                this.materias = [];
                this.horarios_materia = [];
                this.codpla = 0;
                this.materiaSeleccionada = '';
                var inicio = (p - 1) * this.registros;
                this.pagina_planes = p;
                this.planes_view = this.planes.slice(inicio, this.registros + inicio);
            },
            async seleccionarPlan(id)
            {
                this.$root.startLoading();
                this.materiaSeleccionada = '';
                this.materias = [];
                this.horarios_materia = [];
                this.codpla = id;
                this.hor_codpla = id;
                this.materiaSeleccionada = '';
                await this.axios.get('/MateriasAutorizadas/' + id + '/CantidadHorarios/')
                .then(response => {
                    if(response.data.length > 0)
                    {
                        this.materias = response.data;
                    }
                });
                this.paginarMaterias();
                this.$root.stopLoading();
            },
            paginarMaterias()
            {    
                this.materias_view = this.materias.filter(x=>x.mat_nombre.toLowerCase().indexOf(this.search_materia.toLowerCase()) != -1).slice(0, this.registros);               
            },
            cambiarPaginaMaterias(p)
            {
                if(p == this.pagina_materias)
                {
                    return;
                }
                this.horarios_materia = [];
                this.materiaSeleccionada = '';
                var inicio = (p - 1) * this.registros;
                this.pagina_materias = p;
                this.materias_view = this.materias.slice(inicio, this.registros + inicio);
            },
             async seleccionarMateria(id)
            {
                this.$root.startLoading();
                this.codmat = '';
                this.detalle_horario = [];
                this.horarios_materia = [];
                this.codmat = id;
                this.hor_codmat = id;
                var materia = this.materias.find(x=>x.mat_codigo == id);
                this.mat_nombre = materia.mat_codigo + ' - ' + materia.mat_nombre;
                await this.axios.get('/Horarios/Materia/' + this.codpla + '/' + this.codmat)
                .then(response => {
                    this.horarios_materia = response.data;
                    this.horarios_materia_view = this.horarios_materia.filter(x=>x.hor_virtual == (this.solo_virtual ? 'S':x.hor_virtual));
                });
                await this.axios.get('/Proyecciones/' + this.hor_codpla + '/' + this.hor_codmat)
                .then(response => {
                    this.proyeccion = response.data;
                });
                this.$root.stopLoading();
            },
            filtrarVirtual(event)
            {
                this.solo_virtual = event.target.checked;
                this.horarios_materia_view = this.horarios_materia.filter(x=>x.hor_virtual == (this.solo_virtual ? 'S':x.hor_virtual));
            },
            async editarHorario(codhor)
            {
                this.$root.startLoading();
                await this.$refs.modificarHorario.getHorario(codhor, 0, 0, 0, 0, this.codpla, this.codmat, false, 'S');
                this.$root.stopLoading();
            },
            async getHorario(codhor)
            {
                await this.axios.get('/Horarios/' + codhor)
                .then(response => {
                    var index_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                    if(index_horario == -1)
                    {
                        this.horarios_materia.push(response.data);
                    }
                    else
                    {
                        this.horarios_materia[index_horario] = response.data;
                    }
                });
                this.horarios_materia_view = this.horarios_materia.filter(x=>x.hor_virtual == (this.solo_virtual ? 'S':x.hor_virtual));
            },
            async eliminarHorario(codhor)
            {
                if(confirm('¿Desea eliminar este horario?.\nSi existen estudiantes inscritos no podrá hacerlo.'))
                {
                    this.$root.startLoading();
                    await this.axios.delete('/Horarios/' + codhor)
                    .then(() => {
                        this.removerHorario(codhor);
                        M.toast({html: 'El horario ha sido eliminado.'});
                    })
                    .catch(error => {
                        var data = error.response.data;
                        M.toast({html: data.message});
                    });
                    this.$root.stopLoading();
                }
            },
            removerHorario(codhor)
            {
                var index_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                if(index_horario != -1)
                {
                    this.horarios_materia.splice(index_horario, 1);
                }
                this.horarios_materia_view = this.horarios_materia.filter(x=>x.hor_virtual == (this.solo_virtual ? 'S':x.hor_virtual));
            },
            async actualizarVirtuales()
            {
                this.$root.startLoading();
                await this.axios.post('/Horarios/ActualizarVirtual')
                .then(() => {
                    M.toast({html: 'Los bloques han sido actualizados.'});
                })
                .catch(() =>{
                    M.toast({html: 'Los bloques no pudieron ser actualizados, intente nuevamente.'});
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            totalPaginasPlanes: function() {
                var total = this.planes.filter(x=>x.ra_car_carreras.car_nombre.toLowerCase().indexOf(this.search_carrera.toLowerCase()) != -1).length;
                return parseInt(Math.ceil((total / this.registros)));
            },
            totalPaginasMaterias: function() {
                var total = this.materias.filter(x=>x.mat_nombre.toLowerCase().indexOf(this.search_materia.toLowerCase()) != -1).length;
                return parseInt(Math.ceil((total / this.registros)));
            }
        }   
    }
</script>