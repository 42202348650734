<template>
    <!--<div class="modal" id="modal-buscar-docente" style="width:70%; margin-top:20px">
        <div class="modal-content">-->
            <div class="row m0 p0">
                <ul class="collection">
                    <li class="item-collection blue-ugb white-text center">{{car_nombre}} | {{pla_nombre}} | {{codmat}} - {{mat_nombre}}</li>
                </ul>
            </div>
            <span class="badge new" data-badge-caption="">{{ codhor }}</span>
            <div class="row card-panel">
                <form @submit.prevent="buscarDocente">
                    <div class="col m4">
                        <label>Buscar</label>
                        <input type="text" v-model="busqueda">
                    </div>
                    <div class="col m3 input-field">
                        <label><input type="checkbox" @change="buscarDocente" class="filled-in" v-model="disponiblidad"><span>Solo disponibilidad.</span></label>
                    </div>           
                    <div class="col m2" v-show="(disponiblidad && habilitar_bloque)">
                        <label>Día</label>
                        <select class="browser-default" @change="filtrarBloques" v-model="dia">
                            <option value="0">Cualquiera</option>
                            <option v-for="dia in dias" v-bind:key="dia" v-bind:value="dia.dh_codigo">{{dia.dh_dia_f}}</option>
                        </select>
                    </div>
                    <div class="col m2" v-show="(disponiblidad && habilitar_bloque)">
                        <label>Horario</label>
                        <select class="browser-default" v-model="codman">
                            <option value="0">Cualquiera</option>
                            <option v-for="bloque in bloques_view" v-bind:key="bloque" v-bind:value="bloque.man_codigo">{{bloque.man_nomhor}}</option>
                        </select>
                    </div>
                    <div class="col m1 input-field">
                        <button type="submit" class="btn green darken-2"><i class="material-icons">search</i></button>
                    </div>
                </form>
            </div>
            <div class="row m0" v-show="codhor == 0">
                <div class="card-panel red darken-2 white-text">
                    El horario no ha sido guardado por lo que no es posible validar la disponibilidad de horarios, solo la de materias. <i class="material-icons right">warning</i>
                </div>
            </div>
            <div class="row">                
                <table class="table tbl-condensed bordered stripped" id="tbl-resultado-docentes" style="font-size:12px">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Docente</th>
                            <th>Tipo Contrato</th>
                            <th>Unidad</th>
                            <th>Grupos</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="docente in docentes" v-bind:key="docente">
                            <td>{{docente.emp_codigo}}</td>
                            <td><a target="_blank" v-bind:href="('/Docentes/' + docente.emp_codigo)">{{docente.emp_apellidos_nombres}}</a></td>
                            <td>{{docente.tpl_nombre}}</td>
                            <td>{{docente.uni_nombre}}</td>
                            <td class="center"><a href="#!" @click="verHorarios(docente.emp_codigo)">{{docente.hor_cantidad}}</a></td>
                            <td class="center">{{docente.emp_estado}}</td>
                            <td class="center"><a href="#!" @click="seleccionarDocente(docente.emp_codigo)"><i class="material-icons">check_circle</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        <!--</div>
    </div>-->
    <div class="modal" id="modal-ver-horarios">
        <a href="#!" class="right modal-close btn red darken-4 white-text" style="position:absolute; top:0px; right:0px">&times;</a>
        <div class="modal-content">
            <ListaHorarios ref="listaHorarios"></ListaHorarios>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
import ListaHorarios from '../components/ListaHorariosComponent.vue'
export default {
    name: 'BuscarDocente',
    components: {
        ListaHorarios
    },
    data() {
        return {
            termino: '',
            modales: [],
            disponiblidad: true,
            busqueda: '',
            docentes: [],
            dia: 0,
            codman: 0,
            bloques_view: [],
            mat_nombre: '',
            car_nombre: '',
            pla_nombre: '',
            codemp_seleccionado: 0,
            codemp: 0,
            dias: [],
            bloques_clase: [],
            codmat: '',
            codpla: 0,
            habilitar_bloque: false,
            codhor: 0
        }
    },
    methods: {        
        async buscarDocente() {
            this.$root.startLoading();
            await this.axios.get('/Empleados/' + this.codpla + '/' + this.codmat + '/' + this.dia + '/' + this.codman + '/' + this.disponiblidad + '/' + this.codhor + '/?busqueda=' + this.busqueda)
            .then(response => {
                this.docentes = response.data;
            });
            this.$root.stopLoading();
        },
        async init(codmat, codpla, habilitar_bloque, codhor){
            this.codmat = codmat;
            this.codpla = codpla;
            this.codhor = codhor;
            this.habilitar_bloque = habilitar_bloque;
            if(this.dias.length == 0)
            {
                await this.axios.get('/Dias')
                .then(response => {
                    this.dias = response.data
                });
            }

            if(this.bloques_clase.length == 0)
            {
                await this.axios.get('/Bloques')
                .then(response => {
                    this.bloques_clase = response.data;
                    this.bloques_view = response.data;
                });
            }

            await this.axios.get('/Planes/' + this.codpla + '/Materias/' + this.codmat)
            .then(response => {
                this.mat_nombre = response.data.plm_alias || response.data.ra_mat_materias.mat_nombre;
            });
            await this.axios.get('/Planes/' + this.codpla)
            .then(response => {
                this.car_nombre = response.data.ra_car_carreras.car_nombre;
                this.pla_nombre = response.data.pla_nombre;
            });

            await this.buscarDocente();
        },
        seleccionarDocente(codemp)
        {
            this.$parent.seleccionarDocenteDisponibilidad(codemp, this.dia, this.codman);            
        },
        filtrarBloques(event){
            this.codman = 0;
            this.dia = event.target.value;
            this.bloques_view = this.bloques_clase.filter(x=>x.dh_codigo == this.dia);
        },
        async verHorarios(codemp)
        {
            this.$root.startLoading();
            await this.$refs.listaHorarios.getHorarios(codemp);
            var modal = document.querySelector('#modal-ver-horarios')
            var instance = M.Modal.getInstance(modal);
            instance.open(); 
            this.$root.stopLoading();
        }
    }
}
</script>