<template>
<div class="card-panel" v-if="(horarios.length == 0)">
    SIN HORARIOS PROGRAMADOS.
</div>
<table class="table tbl-condensed bordered striped white" style="font-size:12px;" v-if="(horarios.length > 0)">
    <caption class="white-text">HORARIOS PROGRAMADOS</caption>
    <thead>
        <tr>
            <th style="width:20%">Plan</th>
            <th style="width:15%">Grupo</th>
            <th style="width:30%">Materia</th>
            <th style="width:10%">Max. Alu.</th>
            <th style="width:5%">Finan.</th>
            <th style="width:5%">Virt.</th>
            <th style="width:5%">Publi.</th>
            <!--<th style="width:5%"></th>
            <th style="width:5%"></th>-->
        </tr>
    </thead>
    <tbody>
        <template v-for="horario in horarios" v-bind:key="horario">
            <tr>
                <td class="center">{{horario.car_nombre_corto}} - {{horario.pla_nombre}}</td>
                <td class="center">{{horario.hor_descripcion}}</td>
                <td>{{(horario.plm_alias || horario.mat_nombre)}}</td>
                <td class="center">{{horario.hor_max_alumnos}}</td>
                <td class="center">{{horario.hor_financiado}}</td>
                <td class="virtual">{{horario.hor_virtual}}</td>
                <td class="center"><label><input type="checkbox" class="filled-in" disabled v-bind:checked="horario.hor_publicado"><span></span></label></td>
                <!--<td class="center"><a href="#!" @click="editarHorario(horario.hor_codigo)"><i class="material-icons">create</i></a></td>
                <td class="center"><a href="#!" @click="eliminarHorario(horario.hor_codigo)"><i class="material-icons">delete</i></a></td>-->
            </tr>
            <tr >
                <td colspan="9">
                    <ul class="collection">
                        <li class="collection-item" v-for="detalle in horario.detalle" v-bind:key="detalle">
                            {{detalle.dh_dia_f}} | {{detalle.man_nomhor}} | {{detalle.edi_nombre}} | {{detalle.aul_nombre_corto}} | {{(detalle.dho_tipo == 'T') ? 'Teoría':'Práctica'}} | {{(detalle.dho_tipo == 'P' ? detalle.dho_capacidad:horario.hor_max_alumnos)}} Alumnos
                        </li>
                    </ul>
                </td>
            </tr>
        </template>
    </tbody>
</table>
<!--<ModificarHorario ref="modificarHorario"></ModificarHorario>-->
</template>

<script>
//import ModificarHorario from './ModificarHorarioComponent.vue'
import M from 'materialize-css'

export default {
    name: 'ListaHorarios',
    components: {
        /* ModificarHorario */
    },
    data(){
        return{
            horarios: []
        }
    },
    methods: {
        async getHorarios(codemp) {
            await this.axios.get('/Empleados/' + codemp + '/Horarios/')
            .then(response => {
                this.horarios = response.data
            });
        },
        async editarHorario(codhor)
        {
            this.$root.startLoading();
            await this.$refs.modificarHorario.getHorario(codhor);
            this.$root.stopLoading();
        },
        async eliminarHorario(codhor)
        {
            if(confirm('¿Desea eliminar este horario?'))
            {
                this.$root.startLoading();
                await this.axios.delete('/Horarios/' + codhor)
                .then(() => {

                })
                .catch(error => {
                    var data = error.response.data;
                    M.toast({html: data.message});
                });
                this.$root.stopLoading();
            }
        }
    }
}
</script>