<template>
    
    <div class="row">
        <div class="col m5">
            <div class="row">
                <div class="col m5">
                    <label>Facultad</label>
                    <select class="browser-default" @change="getCarreras()" v-model="codfac">
                        <option value="0">Todas</option>
                        <option v-for="facultad in facultades" :value="facultad.fac_codigo" :key="facultad">{{ facultad.fac_nombre }}</option>
                    </select>
                </div>
                <div class="col m5">
                    <label>Carreras</label>
                    <select class="browser-default" @change="getGruposCreados()" v-model="codcar">
                        <option value="0">Todas</option>
                        <option v-for="carrera in carreras" :value="carrera.car_codigo" :key="carrera">{{ carrera.car_nombre }}</option>
                    </select>
                </div>
                <div class="col m2">
                    <label>Grupo</label>
                    <select class="browser-default" @change="getGruposCreados()" v-model="grupo">
                        <option value="X">Todos</option>
                        <option v-for="grupo in grupos" :key="grupo" :value="grupo">{{ grupo }}</option>
                    </select>
                </div>
            </div>
            <div style="height: 400px; overflow: auto;">
                <table class="table tbl-condensed tbl-bordered" style="font-size: 12px; width: 100%;">
                    <tbody>
                        <template v-for="(facultad, _index_facultad) in horarios" :key="facultad">
                            <tr class="black white-text" >
                                <th :colspan="colspan">{{ facultad.fac_nombre }}</th>
                            </tr>
                            <template v-for="(carrera, _index_carrera) in facultad.ra_car_carreras" :key="carrera">
                                <tr class="blue-ugb white-text">
                                    <th :colspan="colspan">{{ carrera.car_nombre }}</th>
                                </tr>

                                <template v-for="(plan, _index_plan) in carrera.ra_pla_planes" :key="plan">
                                    <tr class="green darken-4 white-text">
                                        <th :colspan="colspan">{{ plan.pla_nombre }}</th>
                                    </tr>

                                    <template v-for="(ciclo, _index_ciclo) in plan.plm_ciclo" :key="ciclo">
                                        <tr>
                                            <th :colspan="colspan - 1" class="center">
                                                CICLO {{ ciclo.plm_ciclo }}
                                                
                                            </th>
                                            <th>
                                                <div class="row m0 p0">
                                                    <div class="col m6 m0 p0">
                                                        <select class="browser-default" v-bind:id="('grh_' + plan.pla_codigo + '_' + ciclo.plm_ciclo)">
                                                            <option v-for="grupo in grupos_horario.filter(x=>ciclo.grh_grupos.findIndex(y=>y.hor_codgrh == x.grh_codigo) == -1)" :key="grupo" :value="grupo.grh_codigo">{{ grupo.grh_codigo }}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col m6 m0 p0">
                                                        <button class="btn btn-primary btn-small" @click="crearGrupo(ciclo.grh_grupos, plan.pla_codigo, ciclo.plm_ciclo)"><i class="material-icons">add</i></button>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>

                                        <template v-for="(grupo, _index_grupo) in ciclo.grh_grupos" :key="grupo" >
                                            <tr v-bind:class="{'orange lighten-3': selectedGrupo(_index_facultad, _index_carrera, _index_plan, _index_ciclo, _index_grupo)}">
                                                <th :colspan="colspan - 1">
                                                    <a href="#!" @click="verDetalle(plan.pla_codigo, ciclo.plm_ciclo, grupo.hor_codgrh, _index_facultad, _index_carrera, _index_plan, _index_ciclo, _index_grupo)">GRUPO {{ grupo.hor_codgrh }}</a>
                                                </th>
                                                <th class="center">
                                                    <a href="#!" @click="eliminarGrupo(plan.pla_codigo, ciclo.plm_ciclo, grupo.hor_codgrh)"><i class="material-icons">delete</i></a>
                                                </th>
                                            </tr>
                                            <tr v-bind:class="{'grey lighten-1': selectedGrupo(_index_facultad, _index_carrera, _index_plan, _index_ciclo, _index_grupo)}">
                                                <th style="width: 65%">MATERIA</th>
                                                <th>INS.</th>
                                                <th>CAP.</th>
                                                <th>PRY.</th>
                                                <th>LAB.</th>
                                                <th>PUBLICADO</th>
                                            </tr>
                                            <tr v-for="materia in grupo.ra_mat_materias" :key="materia" v-bind:class="{'grey lighten-2': selectedGrupo(_index_facultad, _index_carrera, _index_plan, _index_ciclo, _index_grupo)}">
                                                <td>{{ materia.mat_codigo }} - {{ materia.mat_nombre }}</td>
                                                <td class="center">{{ materia.mai_inscritos }}</td>
                                                <td class="center">{{ materia.hor_max_alumnos }}</td>
                                                <td class="center">{{ materia.prc_total }}</td>
                                                <td class="center">{{ materia.plm_laboratorio == 'S' ? 'SI':'NO' }}</td>
                                                <td class="center"><label><input type="checkbox" :checked="materia.hor_publicado" @click.prevent="" class="filled-in"><span></span></label></td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col m7" v-show="index_grupo != -1">
            <GruposComponent ref="gruposComponent"></GruposComponent>
        </div>
    </div>
</template>

<script>
    
    import GruposComponent from '../components/GruposComponent'
    import M from 'materialize-css'
    export default {
        components: {
            GruposComponent
        },
        data(){
            return {
                horarios: [],
                facultades: [],
                carreras: [],
                codfac: 0,
                codcar: 0,
                colspan: 6,
                detalle_grupo: [],
                index_facultad: -1, index_carrera: -1, index_plan: -1, index_ciclo: -1, index_grupo: -1,
                selected_dho: 0,
                selected_dia: 0,
                selected_codman: 0,
                selected_aula: 0,
                aulas_disponibles: [],
                modales:[],
                grupos: [],
                grupo: 'X',
                grupos_horario: []
            }
        },
        async mounted()
        {
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
            await this.getFacultades();
            await this.getGruposHorario();
        },
        methods: {
            async getGruposCreados(){
                this.index_facultad = -1; this.index_carrera = -1; this.index_plan = -1; this.index_ciclo = -1; this.index_grupo = -1;
                this.detalle_grupo = [];
                this.selected_dho = 0;
                this.$root.startLoading();
                await this.axios.get('/MateriasAutorizadas/GruposCreados/' + this.codfac + '/' + this.codcar + '/' + this.grupo)
                .then(response => {
                    this.horarios = response.data.records;
                    this.grupos = response.data.grupos;
                });
                this.$root.stopLoading();
            },
            async getGruposHorario()
            {
                this.$root.startLoading();
                await this.axios.get('/GrupoHorario')
                .then(response => {
                    this.grupos_horario = response.data.filter(x=>x.grh_codigo != 'Au');
                });
                this.$root.stopLoading();
            },
            selectDetalle(coddho)
            {
                this.selected_codman = 0;
                this.selected_aula = 0;
                this.selected_dia = 0;
                this.selected_dho = coddho;
            },
            async verDetalle(codpla, ciclo, codgrh, index_facultad, index_carrera, index_plan, index_ciclo, index_grupo)
            {
                this.index_facultad = index_facultad;
                this.index_carrera = index_carrera;
                this.index_plan = index_plan;
                this.index_ciclo = index_ciclo;
                this.index_grupo = index_grupo;
                this.selected_dho = 0;
                await this.$refs.gruposComponent.init(codpla, ciclo, codgrh);
                this.$root.startLoading();
                await this.axios.get('/MateriasAutorizadas/DetalleGrupo/' + codpla + '/' + ciclo + '/' + codgrh)
                .then(response => {
                    this.detalle_grupo = response.data;
                });
                this.$root.stopLoading();
            },
            selectedGrupo(index_facultad, index_carrera, index_plan, index_ciclo, index_grupo)
            {
                return this.index_facultad == index_facultad && this.index_carrera == index_carrera && this.index_plan == index_plan && this.index_ciclo == index_ciclo && this.index_grupo == index_grupo;
            },
            async getFacultades()
            {
                this.codcar = 0;
                this.$root.startLoading();
                await this.axios.get('/Facultades')
                .then(async response => {
                    this.facultades = response.data.filter(x=>x.fac_codigo != 4 && x.fac_codigo != 5);                             
                });
                this.$root.stopLoading();
            },
            async getCarreras()
            {                
                this.codcar = 0;
                this.horarios = [];
                this.$root.startLoading();
                await this.axios.get('/Carreras/Activas')
                .then(async response => {
                    this.carreras = response.data.filter(x=>x.car_codfac == this.codfac && x.car_virtual == 'P');
                    await this.getGruposCreados();         
                });
                this.$root.stopLoading();
            },
            getHorarios(dia, bloque)
            {
                var result = this.detalle_grupo.horarios.find(x=>x.dho_dia == dia);
                if(result == undefined)
                {
                    return [];
                }
                return result.detalle.filter(x=>x.man_codigo == bloque);
            },
            async selectMover(dia, codman)
            {
                this.selected_dia = dia;
                this.selected_codman = codman;
                this.selected_aula = 0;
                this.$root.startLoading();
                await this.axios.get('/Aulas/' + this.selected_dia + '/' + this.selected_codman + '/Disponibles/' + this.selected_dho)
                .then(response => {
                    this.aulas_disponibles = response.data;
                });                
                this.$root.stopLoading();
            },
            getDia(dia)
            {
                switch(dia)
                {
                    case 1: return 'Lun';
                    case 2: return 'Mar';
                    case 3: return 'Mie';
                    case 4: return 'Jue';
                    case 5: return 'Vie';
                    case 6: return 'Sab';
                    case 7: return 'Dom';
                }
            },
            async moverDetalle()
            {
                if(!confirm('¿Desea mover el bloque?'))
                {
                    console.log(this.selected_aula);
                    return false;
                }
                var payload = {
                    dia: this.selected_dia,
                    codman: this.selected_codman,
                    aula: this.selected_aula
                }
                await this.axios.patch('/Horarios/' + this.selected_dho + '/Mover', payload)
                .then(async () => {
                    var codpla = this.horarios[this.index_facultad].ra_car_carreras[this.index_carrera].ra_pla_planes[this.index_plan].pla_codigo;
                    var ciclo = this.horarios[this.index_facultad].ra_car_carreras[this.index_carrera].ra_pla_planes[this.index_plan].plm_ciclo[this.index_ciclo].plm_ciclo; 
                    var codgrh = this.horarios[this.index_facultad].ra_car_carreras[this.index_carrera].ra_pla_planes[this.index_plan].plm_ciclo[this.index_ciclo].grh_grupos[this.index_grupo].hor_codgrh;                
                    await this.verDetalle(codpla, ciclo, codgrh, this.index_facultad, this.index_carrera, this.index_plan, this.index_ciclo, this.index_grupo);
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
            },
            async editarHorario(codhor)
            {
                console.log(codhor);
                this.$root.startLoading();
                await this.$refs.modificarHorario.getHorario(codhor, 0, 0, 0, 0, this.codpla, this.codmat, false, 'S');
                this.$root.stopLoading();
            },
            async eliminarGrupo(codpla, ciclo, codgrh)
            {
                if(!confirm('¿Está seguro que desea eliminar este grupo?\nUna vez eliminado no podrá recuperarlo.')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Horarios/' + codpla + '/' + ciclo + '/' + codgrh)
                .then(async () => {
                    await this.getGruposCreados();
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async crearGrupo(grupos, codpla, ciclo)
            {
                var grupo = document.querySelector('#grh_' + codpla + '_' + ciclo).value;
                if(!confirm('¿Desea crear el grupo '+grupo+'?')) return false;
                var materias = [];
                for(let i = 0; i <= grupos.length - 1; i++)
                {
                    for(let j = 0; j <= grupos[i].ra_mat_materias.length - 1; j++)
                    {
                        console.log(grupos[i].ra_mat_materias[j]);
                        if(materias.findIndex(x=>x.mat_codigo == grupos[i].ra_mat_materias[j].mat_codigo) == -1)
                        {
                            materias.push({
                                mat_codigo: grupos[i].ra_mat_materias[j].mat_codigo,
                                prc_estandar: grupos[i].ra_mat_materias[j].hor_max_alumnos
                            });
                        }
                    }
                    
                }
                
                await this.axios.post('/Horarios/' + codpla + '/' + ciclo + '/' + grupo + '/Multiple', materias)
                .then(async () => {
                    await this.getGruposCreados();
                });
            },
            setPublicadoHorario(codhor, publicado)
            {
                var index_horario = this.horarios[this.index_facultad].ra_car_carreras[this.index_carrera].ra_pla_planes[this.index_plan].plm_ciclo[this.index_ciclo].grh_grupos[this.index_grupo].ra_mat_materias.findIndex(x=>x.hor_codigo == codhor);
                this.horarios[this.index_facultad].ra_car_carreras[this.index_carrera].ra_pla_planes[this.index_plan].plm_ciclo[this.index_ciclo].grh_grupos[this.index_grupo].ra_mat_materias[index_horario].hor_publicado = publicado;
            }
        }
    }
</script>