<template>
  <Header ref="Header"></Header>
  <div class="container" v-if="isLoggedIn" style="width:100%; text-align:right">
      <label class="blue-ugb white-text" style="padding:5px; border:solid 1px white"><b>Ciclo:</b> {{userData.ciclo}} | <b>Regional:</b> {{userData.regional}} | <b>Usuario:</b> {{userData.usuario}}</label>
  </div>
  <div class="container white" style="width:100%" v-bind:style="{visibility: loadingPage ? 'visible':'hidden'}">
    <div class="progress white darken-4 m0 p0">
        <div class="indeterminate blue"></div>
    </div>
  </div>
  <div class="container" style="width:100%; padding:15px; margin-bottom:15px; background-color:#fff">
    <router-view />
    <div v-if="isLoggedIn">
      <hr>
      <div class="row m0 p0">
        <div class="col m12">
          <label><b>Ciclo:</b> {{userData.ciclo}} <b>Regional:</b> {{userData.regional}} <b>Usuario:</b> {{userData.usuario}}</label>
        </div>
      </div>

      <div class="row m0 p0">
        <div class="col m2">
          <label>Regional</label>
          <select class="browser-default" v-model="codreg_actual" v-on:change="getCiclosRegional()">
            <option v-for="regional in regionales_usuario" :key="regional" :value="regional.reg_codigo">{{ regional.reg_nombre }}</option>
          </select>
        </div>
        <div class="col m2">
          <label>Ciclo</label>
          <select class="browser-default" v-model="codcil_actual">
            <option v-for="ciclo in ciclos_regional" :key="ciclo" :value="ciclo.cil_codigo">{{ciclo.ra_cic_ciclos.cic_nombre}} - {{ ciclo.cil_anio }}</option>
          </select>
        </div>
        <div class="col m2">
          <br>
          <button class="btn green darken-3 btn-small" v-on:click="ReLogin()">Cambiar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="loading" v-show="showLoader"></div>
</template>

<script>
import Header from './components/Header.vue'
import M from 'materialize-css'
export default {
  name: 'App',
  data(){
    return{
      showLoader: false,
      loadingPage: false,
      regionales_usuario: [],
      ciclos_regional: [],
      codreg_actual: 0,
      codcil_actual: 0
    }
  },
  components: {
    Header
  },
  async mounted(){
      M.AutoInit(); 
      await this.getCicloRegionalActual(); 
      await this.getRegionalesUsuario();
      await this.getCiclosRegional();
      
  },
  methods: {
    startLoading()
    {
      this.loadingPage = true;
      this.showLoader = true;
    },
    stopLoading()
    {
      this.loadingPage = false;
      this.showLoader = false;
    },
    async getCicloRegionalActual()
    {
      await this.axios.get('/Regionales/CicloRegional')
      .then(response => {
        this.codreg_actual = response.data.codreg;
        this.codcil_actual = response.data.codcil;
      });
    },
    async getRegionalesUsuario()
    {
      await this.axios.get('/Regionales/RegionalesUsuario')
      .then(response => {
        this.regionales_usuario = response.data;
        this.codreg_usuario = this.regionales_usuario[0].reg_codigo;
      });
    },
    async getCiclosRegional()
    {
      await this.axios.get('/Regionales/' + this.codreg_actual + '/Ciclos')
      .then(response => {
        this.ciclos_regional = response.data;
      });
    },
    async ReLogin()
    {
      if(this.ciclos_regional.findIndex(x=>x.cil_codigo == this.codcil_actual) == -1)
      {
        M.toast({html: 'Seleccione un ciclo.'});
        return;
      }
      var data = {
        usuario: "",
        clave: "",
        codcil: this.codcil_actual,
        codreg: this.codreg_actual
      };
      await this.axios.post('/Login/ReLogin', data)
      .then(response => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('usuario', response.data.userData.usuario);
        localStorage.setItem('regional', response.data.userData.regional);
        localStorage.setItem('codigo', response.data.userData.codigo);
        localStorage.setItem('nombre', response.data.userData.nombre);
        localStorage.setItem('codcic', response.data.userData.codcic);
        localStorage.setItem('anio', response.data.userData.anio);    
        localStorage.setItem('ciclo', response.data.userData.nombreCiclo);
        localStorage.setItem('codcil', this.codcil);
        localStorage.setItem('is_logged', 'S');
        localStorage.setItem('is_admin', response.data.administrador ? 'S':'N');          
        localStorage.setItem('is_academica', response.data.academica ? 'S':'N');          
        localStorage.setItem('is_generador', response.data.generador ? 'S':'N');          
        localStorage.setItem('procesoGraduacion', JSON.stringify(response.data.procesoGraduacion));
        localStorage.setItem('menus', JSON.stringify(response.data.menus));
        console.log(response.data);
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
        window.location = '/';
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
  computed: {
    userData: function()
    {
        return { 
          usuario: localStorage.getItem('usuario'),
          regional: localStorage.getItem('regional'),
          ciclo: localStorage.getItem('ciclo')
        };
    },
    isLoggedIn: function(){
      return localStorage.getItem('is_logged') == 'S';
    }
  }
}
</script>

<style>
  body, html
  {
    background-color:#1d1d1d
  }
</style>