<template>
  <div class="row">
    <div class="col m12 card-panel blue-ugb white-text">
        <div class="card-title">Aca puede gestionar los grupos en los que se permitira inscribir a estudiantes de otra carrera sin necesidad de autorizacion de parte de decanatura. <i class="material-icons right">info</i></div>
    </div>
    <div class="col m12 card-panel green darken-2 white-text" v-if="horarios.length == 0">
        No existen asignaciones. <i class="material-icons right">warning</i>
    </div>
  </div>
  <div class="row" v-if="horarios.length > 0">
    <div class="col m12">
        <table class="table tbl-condensed bordered striped" style="font-size:12px">
            <tbody>
                <template v-for="horario in horarios_view" v-bind:key="horario">
                <tr>
                    <td colspan="10" class="center"><span class="collection-item">                    
                    <span class="badge new teal darken-2" v-bind:data-badge-caption="('MATERIA AUTORIZADA: ' + horario.car_nombre + ' - ' + horario.pla_nombre + ' - ' + horario.mat_codigo + ' - ' + horario.mat_nombre)"></span> <a href="#!" @click="eliminar(horario.hcr_codhor, horario.hcr_codpla, horario.hcr_codmat)"><i class="material-icons right">delete</i></a></span></td>
                </tr>
                 <tr class="blue-ugb white-text">
                    <th class="center" style="width:25%">Carrera</th>
                    <th class="center" style="width:5%">Plan</th>
                    <th class="center" style="width:5%">Grupo</th>
                    <th class="center" style="width:20%">Docente</th>
                    <th class="center" style="width:25%">Materia</th>
                    <th class="center" style="width:5%">Max.</th>
                    <th class="center" style="width:5%">Fin.</th>
                    <th class="center" style="width:5%">Vrt.</th>
                    <th class="center" style="width:5%">Publ.</th>
                    <th></th>
                </tr>
                <tr class="m0 p0">
                    <td class="center">{{horario.car_nombre_horario}}</td>
                    <td class="center">{{horario.pla_nombre_horario}}</td>
                    <td class="center">{{horario.ra_hor_horarios.hor_descripcion}}</td>
                    <td class="center">{{horario.ra_hor_horarios.emp_apellidos_nombres}}</td>
                    <td class="center">{{horario.mat_codigo_horario}} - {{horario.mat_nombre_horario}}</td>
                    <td class="center">{{horario.ra_hor_horarios.hor_max_alumnos}}</td>
                    <td class="center">{{horario.ra_hor_horarios.hor_financiado}}</td>
                    <td class="center">{{horario.ra_hor_horarios.hor_virtual}}</td>
                    <td class="center"><label><input type="checkbox" class="filled-in" disabled :checked="horario.hor_publicado"><span></span></label></td>
                    <th><a href="#!" @click="editarHorario(horario.hcr_codhor)"><i class="material-icons">create</i></a></th>
                </tr>
                <tr class="m0 p0">
                    <td colspan="10">
                        <ul class="collection m0 p0">
                            <li class="collection-item p0" v-for="detalle in horario.ra_hor_horarios.ra_dho_det_horarios_gr" v-bind:key="detalle">
                                {{detalle.dh_dia_f}} | {{detalle.man_nomhor}} | {{detalle.edi_nombre}} | {{detalle.aul_nombre_corto}} | {{(detalle.dho_tipo == 'T') ? 'Teoría':'Práctica'}} | {{(detalle.dho_tipo == 'P' ? detalle.dho_capacidad:horario.ra_hor_horarios.hor_max_alumnos)}} Alumnos
                            </li>
                        </ul>
                    </td>
                </tr>                
                </template>
            </tbody>
        </table>
        <ul class="pagination">
            <li v-for="page in total_pages" v-bind:key="page" v-bind:class="{active: page == current_page}"><a @click="paginar(page)" href="#!">{{page}}</a></li>
        </ul>
    </div>
  </div>
  <div class="clearfix"></div>
  <ModificarHorario ref="modificarHorario"></ModificarHorario>
</template>

<script>
import ModificarHorario from '../components/ModificarHorarioComponent.vue'
import M from 'materialize-css'
    export default {
        name: 'Cruzadas',
        components: {
            ModificarHorario
        },
        data(){
            return {
                horarios: [],
                horarios_view: [],
                records: 5,
                total_pages: 0,
                current_page: 1,
                modales: []
            }
        },
        async mounted()
        {
            this.$root.startLoading();
            await this.axios.get('/HorarioCruzado?permitidos=true')
            .then(response => {
                this.horarios = response.data;
                this.horarios_view = response.data;
                this.paginar(1);
            });
            this.modales = M.Modal.init(document.querySelectorAll('.modal'), null);
            this.$root.stopLoading();
        },
        methods: {
            paginar(page)
            {
                this.current_page = page;
                this.total_pages = Math.ceil(this.horarios.length / this.records);
                var starting = (page - 1) * this.records;
                this.horarios_view = this.horarios.slice(starting, starting + this.records);
            },
            async editarHorario(codhor)
            {
                this.$root.startLoading();
                await this.$refs.modificarHorario.getHorario(codhor, 0, 0, 0, 0, this.codpla, this.codmat, false, 'S');
                this.$root.stopLoading();
            },
            async getHorario(codhor)
            {
                await this.axios.get('/HorarioCruzado/' + codhor)
                .then(response => {
                    this.horarios = this.horarios.filter(x=>x.hcr_codhor != codhor);
                    this.horarios = this.horarios.concat(response.data);
                    this.paginar(this.total_pages);
                });
            },
            async eliminar(codhor, codpla, codmat)
            {
                if(!confirm('¿Desea eliminar esta asignacion?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/HorarioCruzado/' + codhor + '/' + codpla + '/' + codmat)
                .then(() => {
                    var index = this.horarios.findIndex(x=>x.hcr_codhor == codhor && x.hcr_codpla == codpla && x.hcr_codmat == codmat);
                    if(index != -1)
                    {
                        this.horarios.splice(index, 1);
                        this.paginar(1);
                    }
                })
                this.$root.stopLoading();
            }
        }
    }
</script>