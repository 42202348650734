<template>
    <div class="row" v-if="(datos != null)">
        <div class="col m4">
            <div class="card-panel" style="background-color:rgb(41,51,63,1);">
                <table class="table tbl-condensed white" style="font-size:12px">
                    <caption class="white-text">EXPEDIENTE</caption>
                    <tbody>
                        <tr v-if="(datos.emp_foto != '')">
                            <th colspan="2" class="center"><img style="height:150px" class="responsive-img" v-bind:src="('data:image/png;base64, ' + datos.emp_foto)">
                                <hr>
                                {{(datos.emp_nombres_apellidos.toUpperCase())}}
                            </th>
                        </tr>
                        <tr>
                            <th style="width:25%">Estado</th>
                            <td style="width:75%">
                                <select v-model="datos.emp_estado" class="browser-default black-text" disabled>
                                    <option value="A">Activo</option>
                                    <option value="I">Inactivo</option>
                                    <option value="O">Oferente</option>
                                    <option value="J">Jubilado</option>
                                    <option value="D">Activo D</option>
                                    <option value="T">Activo T</option>
                                    <option value="E">Activo E</option>
                                    <option value="R">Retirado</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Regional</th>
                            <td>{{datos.reg_nombre}}</td>
                        </tr>
                        <tr>
                            <th>Nombre</th>
                            <td>{{datos.emp_primer_nom}} {{datos.emp_segundo_nom}}</td>
                        </tr>
                        <tr>
                            <th>Apellido</th>
                            <td>{{datos.emp_primer_ape}} {{datos.emp_segundo_ape}}</td>
                        </tr>
                        <tr v-show="((datos.emp_apellido_cas || '') != '')">
                            <th>Apellido de Casada</th>
                            <td>{{datos.emp_apellido_cas}}</td>
                        </tr>
                        <tr>
                            <th>Estado Civil</th>
                            <td>
                                <select v-model="datos.emp_est_civil" class="browser-default black-text" disabled>
                                    <option value="A">Acompañado</option>
                                    <option value="S">Soltero</option>
                                    <option value="A">Divorciado</option>
                                    <option value="C">Casado</option>
                                    <option value="V">Viudo</option>
                                    <option value="T">Tutor Virtual</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Sexo</th>
                            <td>
                                <select v-model="datos.emp_sexo" class="browser-default black-text" disabled>
                                    <option value="M">Masculino</option>
                                    <option value="F">Femenino</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Fecha de Nac.</th>
                            <td>{{datos.emp_fecha_nac}}</td>
                        </tr>
                        <tr>
                            <th>Dirección</th>
                            <td>{{datos.emp_direccion}}</td>
                        </tr>
                        <tr>
                            <th>Teléfono</th>
                            <td><a v-bind:href="'tel:'+datos.emp_telefono">{{datos.emp_telefono}}</a></td>
                        </tr>
                        <tr>
                            <th>Celular</th>
                            <td><a v-bind:href="'tel:'+datos.emp_celular">{{datos.emp_celular}}</a></td>
                        </tr>
                        <tr>
                            <th>Correo</th>
                            <td><a v-bind:href="'mailto:' + datos.emp_email">{{datos.emp_email}}</a></td>
                        </tr>
                        <tr>
                            <th>Unidad</th>
                            <td>{{datos.uni_nombre}}</td>
                        </tr>
                        <tr>
                            <th>Plaza</th>
                            <td>{{datos.plz_nombre}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col m8" v-show="!isLoading">
            <div class="card-panel" style="background-color:rgb(41,51,63,1);">
                <ListaHorarios ref="listaHorarios"></ListaHorarios>
            </div>
        </div>
    </div>
    <div class="row" v-show="(!isLoading && materias_disponibilidad.length > 0)">        
        <div class="col m8">
            <div class="card-panel" style="background-color:rgb(41,51,63,1);">
                <table class="table tbl-condensed bordered striped white" style="font-size:12px">
                    <caption class="white-text">DISPONIBILIDAD DE MATERIAS</caption>
                    <thead>
                        <tr>
                            <th>Carrera</th>
                            <th>Plan</th>
                            <th>Materia</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="materia in materias_disponibilidad" v-bind:key="materia">
                            <td>{{materia.car_nombre}}</td>
                            <td class="center">{{materia.pla_nombre}}</td>
                            <td>{{(materia.plm_alias || materia.mat_nombre)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col m4">
            <div class="card-panel" style="background-color:rgb(41,51,63,1);">
                <table class="table tbl-condensed bordered striped white" style="font-size:12px">
                    <caption class="white-text">DISPONIBILIDAD HORARIA</caption>
                    <thead>
                        <tr>
                            <th>Día</th>
                            <th>Bloque</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="horario in horarios_disponibilidad" v-bind:key="horario">
                            <td class="center">{{horario.dh_dia_f}}</td>
                            <td class="center">
                                <ul class="collection m0 p0">
                                    <li class="collection-item m0 p0" v-for="bloque in horario.bloques" v-bind:key="bloque">{{bloque.man_nomhor}}</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import ListaHorarios from '../components/ListaHorariosComponent.vue'

export default {
    name: 'Docentes',
    components: {
        ListaHorarios
    },
    data(){
        return {
            materias_disponibilidad: [],
            horarios_disponibilidad: [],
            datos: null,
            horarios: [],
            codemp: 0,
            isLoading: false
        }
    },
    async created(){
        this.$root.startLoading();
        this.isLoading = true;
        this.codemp = parseInt(this.$route.params.id);
        await this.getExpediente();
        await this.getDisponibilidadMaterias();
        await this.getDisponibilidadHoraria();
        await this.$refs.listaHorarios.getHorarios(this.codemp);
        this.isLoading = false;
        this.$root.stopLoading();
    },
    methods: {
        async getDisponibilidadMaterias()
        {
            await this.axios.get('/DisponibilidadMaterias/' + this.codemp)
            .then(response => {
                this.materias_disponibilidad = response.data;
            })
        },
        async getDisponibilidadHoraria()
        {
            await this.axios.get('/DisponibilidadHoraria/' + this.codemp)
            .then(response => {
                this.horarios_disponibilidad = response.data;
            });
        },
        async getExpediente()
        {
            await this.axios.get('/Empleados/' + this.codemp)
            .then(response => {
                this.datos = response.data;
            })
            .catch(() => {
                this.$root.stopLoading();
                this.$router.push('/');
            });
        }
    }
}
</script>