<template>
    <div class="row">
        <div class="col m3">
            <label>Edificio</label>
            <select class="browser-default" v-model="edificio" @change="getAulas()">
                <option value="0">Seleccione</option>
                <option v-for="edificio in edificios" :key="edificio" :value="edificio.edi_codigo">{{ edificio.edi_nombre }}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Aula</label>
            <select class="browser-default" v-model="aula" @change="getBloques()">
                <option value="0">Seleccione</option>
                <option v-for="aula in aulas" :key="aula" :value="aula.aul_codigo">{{ aula.aul_nombre_corto }} (CAP. {{ aula.aul_capacidad }})</option>
            </select>
        </div>
        <div class="col m2">
            <button class="btn blue-ugb" v-show="aula > 0" @click="getBloques()">Recargar</button>
        </div>
    </div>
    
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordred" style="font-size: 12px;">
                <thead>
                    <tr>
                        <th></th>
                        <th v-for="dia in bloques" :key="dia" style="width: 160px">{{dia.dh_dia_f}}</th>
                    </tr>
                </thead>
                <tbody v-if="bloques.length > 0">
                    <tr v-for="bloque in bloques[0].bloques" :key="bloque">
                        <td class="center">{{ bloque.man_hor_ini_hh }} - {{ bloque.man_hor_fin_hh }}</td>

                        <td v-for="dia in bloques" :key="dia" style="height: 150px; vertical-align: top;">
                            <ul v-for="horario in dia.bloques.filter(x=>x.man_codigo == bloque.man_codigo)" :key="horario" class="collection">
                                <li v-for="h in horario.horarios" :key="h" class="collection-item" style="height: 50px; padding: 2px; background-color: rgb(233, 240, 247);" v-show="h.dho_codigo != 0">{{ h.hor_descripcion }} | {{ h.mat_codigo }} - {{ h.mat_nombre }}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                edificios: [],
                aulas: [],
                edificio: 0,
                bloques: [],
                aula: 0
            }
        },
        async mounted()
        {
            await this.getEdificios();
        },
        methods: {
            async getBloques()
            {
                this.$root.startLoading();
                await this.axios.get('/Aulas/' + this.aula + '/Bloques')
                .then(response => {
                    this.bloques = response.data;
                    M.toast({html: 'Los bloques han sido cargados.'});
                });
                this.$root.stopLoading();
            },
            async getEdificios()
            {
                this.$root.startLoading();
                await this.axios.get('/Edificios')
                .then(response => {
                    this.edificios = response.data;
                    this.aula = 0;
                });
                this.$root.stopLoading();
            },
            async getAulas()
            {
                this.$root.startLoading();
                await this.axios.get('/Edificios/' + this.edificio + '/Aulas')
                .then(response => {
                    this.aulas = response.data;
                    this.aula = 0;
                    this.bloques = [];
                });
                this.$root.stopLoading();
            }
        }
    }
</script>