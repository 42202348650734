<template>
     <div class="row">
        <button class="btn" @click="asignarDocente()">Asignar Docentes</button>
    </div>
    <div class="row">
        <div class="col m12">
            <label>Facultad</label>
            <select class="browser-default" @change="getFacultades()" v-model="codfac">
                <option value="0">Todas</option>
                <option v-for="facultad in facultades_filter" :value="facultad.fac_codigo" :key="facultad">{{ facultad.fac_nombre }}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="card-panel">
            <button class="btn blue-ugb" @click="crearEncabezados(0, 0)">Crear Encabezados</button>
        </div>
        <div class="card-panel">
            <div class="row">
                <div class="col m2">
                    <select class="browser-default" v-model="codgrh">
                        <option value="">Seleccione</option>
                        <option v-for="grupo in grupos" :key="grupo" :value="grupo">{{ grupo }}</option>
                    </select>
                    <label><input type="checkbox" class="filled-in" v-model="todas_aulas"><span>Todos los bloques</span></label>
                </div>
                <div class="col m4">
                    <button class="btn blue-ugb" @click="getAgrupadosCicloGrupo()" v-bind:disabled="codgrh == ''">Crear Bloques</button>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                <tbody>
                    <template v-for="facultad in facultades" :key="facultad">
                        <tr class="black white-text">
                            <th colspan="8">{{facultad.fac_codigo}} - {{ facultad.fac_nombre }} <button class="btn black-text white darken-2 btn-small right" @click="crearEncabezados(facultad.fac_codigo, 0)">Crear en Facultad</button></th>
                        </tr>
                        <template v-for="carrera in facultad.ra_car_carreras" :key="carrera">
                            <tr class="grey darken-3 white-text">
                                <th colspan="8">{{carrera.car_codigo}} - {{ carrera.car_nombre }} <button class="btn green darken-2 btn-small right" @click="crearEncabezados(facultad.fac_codigo, carrera.car_codigo)">Crear en Carrera</button></th>
                            </tr>
                            <template v-for="plan in carrera.ra_pla_planes" :key="plan">
                                <tr class="grey darken-4 white-text">
                                    <th colspan="8">{{plan.pla_codigo}} - {{ plan.pla_nombre }} </th>
                                </tr>
                                <tr>
                                    <th class="center">Materia</th>
                                    <th class="center">Ciclo</th>
                                    <th class="center">Proyectados</th>
                                    <th class="center">Estándar</th>
                                    <th class="center">Inscritos</th>
                                    <th class="center">Diferencia</th>
                                    <th class="center">Grupos</th>
                                </tr>
                                <tr v-for="materia in plan.ra_mat_materias" :key="materia">
                                    <td>{{ materia.mat_codigo }} - {{ materia.mat_nombre }}</td>
                                    <td class="center">{{ materia.plm_ciclo }}</td>
                                    <td class="center">{{ materia.prc_total }}</td>
                                    <td class="center">{{ materia.prc_estandar }}</td>
                                    <td class="center">{{ materia.mai_inscritos }}</td>
                                    <td class="center">{{ materia.prc_total - materia.mai_inscritos }}</td>
                                    <td class="center">{{ materia.hor_grupos }}</td>
                                    <td><a href="#!" @click="crearHorarios(materia.mau_codigo, plan.pla_codigo, materia.mat_codigo, materia.prc_total, materia.prc_estandar, materia.plm_ciclo)"><i class="material-icons">autorenew</i></a></td>
                                </tr>
                            </template>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                facultades: [],
                facultades_filter: [],
                codfac: 0,
                grupos: [],
                codgrh: '',
                todas_aulas: false
            }
        },
        async mounted()
        {
            await this.getFacultades();
            await this.getFacultadesFilter();
        }
        ,methods: {
            async getFacultades()
            {
                this.$root.startLoading();
                await this.axios.get('/MateriasAutorizadas/Autorizadas/'+this.codfac+'/0')
                .then(response => {
                    this.facultades = response.data;
                });
                this.$root.stopLoading();
                await this.getGrupos();
            },
            async getFacultadesFilter()
            {
                await this.axios.get('/Facultades')
                .then(response => {
                    this.facultades_filter = response.data;
                });
            },
            async crearHorarios(codmau, codpla, codmat, proyeccion, estandar, plm_ciclo)
            {
                var data = {
                    codmau,
                    codmat,
                    proyeccion,
                    estandar,
                    codpla,
                    plm_ciclo
                };
                this.$root.startLoading();
                await this.axios.post('/MateriasAutorizadas/CrearGrupos', data)
                .then(async response => {
                    await this.getFacultades();
                    console.log(response.data);
                })
                .catch(() => {
                    M.toast({html: 'No se pudo generar.'});
                });
                this.$root.stopLoading();
            },
            async crearEncabezados(codfac, codcar)
            {
                this.$root.startLoading();
                await this.axios.post('/MateriasAutorizadas/CrearEncabezados/'+ (codfac || 0) +'/' + (codcar || 0))
                .then(async response => {
                    console.log(response.data);
                    await this.getFacultades();
                });
                this.$root.stopLoading();
            },
            async getAgrupadosCicloGrupo()
            {
                this.$root.startLoading();
                var payload = []
                for(let i = 2; i <= 12; i++)
                {
                    await this.axios.post('/MateriasAutorizadas/AgrupadosCicloGrupo/' + this.codfac + '/' + this.codgrh + '/' + i + '/0?all=' + this.todas_aulas, payload)
                    .then(response => {
                        console.log(response.data);
                    });
                }
                this.$root.stopLoading();
            },
            async getGrupos()
            {
                this.$root.startLoading();
                await this.axios.get('/MateriasAutorizadas/Grupos/' + this.codfac)
                .then(response => {
                    this.grupos = response.data;
                });
                this.$root.stopLoading();
            },
            async asignarDocente()
            {
                if(!confirm('¿Desea iniciar el proceso de asignación docente?')) return false;
                this.$root.startLoading();
                await this.axios.post('/MateriasProgramadas/AsignarDocente/' + this.codfac)
                .then((response) => {
                    console.log(response.data);
                });
                this.$root.stopLoading();
            }
        }
    }
</script>