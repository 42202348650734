<template>
    <div class="row">

    </div>
</template>

<script>
    export default
    {
        data(){
            return {
                horarios:[]
            }
        },
        async mounted()
        {
            await this.getChoques();
        },
        methods: {
            async getChoques()
            {
                await this.axios.get('/Horarios/Choques')
                .then(response => {
                    this.horarios = response.data;
                });
            }
        }
    }
</script>