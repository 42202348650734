<template>
    <div class="row">
        <div class="col m3">
            <ul class="collection" id="menu-tab">
                <a @click="changeTab('bloques')" href="#bloques"><li class="collection-item" v-bind:class="{'blue-ugb white-text': tab == 'bloques'}">Bloques</li></a>
                <a style="display:none" @click="changeTab('materias-aulas')" href="#materias-aulas"><li class="collection-item" v-bind:class="{'blue-ugb white-text': tab == 'materias-aulas'}">Materias/Aulas</li></a>
            </ul>
        </div>
        <div class="col m9 card-panel">
            <div id="bloques" v-show="tab == 'bloques'">
                <RestriccionBloques></RestriccionBloques>
            </div>
            <div id="materias-aulas" v-show="tab == 'materias-aulas'">MATERIAS</div>
        </div>
    </div>
</template>

<script>
import RestriccionBloques from '../components/RestriccionBloquesComponent'
    export default {
        name: 'Restricciones',
        data()
        {
            return {
                tab: 'bloques'
            }
        },
        components:{
            RestriccionBloques
        },
        methods:{
            changeTab(tab)
            {
                this.tab = tab;

            }
        }
    }
</script>