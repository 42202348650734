<template>
    <div class="row">
        <button class="btn" @click="asignarDocente()">Asignar</button>
    </div>
    <div class="row">
        <div class="col m4">
            <label>Facultad</label>
            <select class="browser-default" v-model="codfac" v-bind:disabled="carreras.length > 0">
                <option value="0">Seleccione</option>
                <option v-for="facultad in facultades" :key="facultad" :value="facultad.fac_codigo">{{facultad.fac_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Ciclo Origen</label>
            <select class="browser-default" v-model="codcil_origen" v-bind:disabled="carreras.length > 0">
                <option value="0">Seleccione</option>
                <option v-for="ciclo in ciclos" :key="ciclo" :value="ciclo.cil_codigo">{{ciclo.ra_cic_ciclos.cic_nombre}} - {{ciclo.cil_anio}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Ciclo Destino</label>
            <select class="browser-default" v-model="codcil_destino" v-bind:disabled="carreras.length > 0">
                <option value="0">Seleccione</option>
                <option v-for="ciclo in ciclos_filter" :key="ciclo" :value="ciclo.cil_codigo">{{ciclo.ra_cic_ciclos.cic_nombre}} - {{ciclo.cil_anio}}</option>
            </select>
        </div>
        <div class="col m4">
            <button class="btn blue-ugb" @click="getMaterias()" v-bind:disabled="carreras.length > 0">Cargar</button>
            <button class="btn green darken-2" @click="resetBusqueda()" v-bind:disabled="carreras.length == 0">Nuevo</button>
        </div>
    </div>

    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                <thead>
                    <tr>
                        <th>Materia</th>
                        <th>Ciclo</th>
                        <th>Inscritos</th>
                        <th>Ver Bloques</th>
                        <th></th>
                        <th>Generado</th>
                        <th>Proyección</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="carrera in carreras" :key="carrera">
                        <tr class="black white-text">
                            <th colspan="7">{{carrera.car_codigo}} - {{carrera.car_nombre}} <button class="btn red btn-small right" v-on:click="clonarCarrera(carrera.car_codigo)">Clonar</button></th>
                        </tr>
                        <template v-for="plan in carrera.ra_pla_planes" :key="plan">
                            <tr class="grey lighten-2">
                                <th colspan="7">{{plan.pla_codigo}} - {{plan.pla_nombre}}</th>
                            </tr>
                            <template v-for="materia in plan.ra_mat_materias" :key="materia">
                                <tr>
                                    <td>{{materia.mat_codigo}} - {{ materia.mat_nombre }}</td>
                                    <td class="center">{{ materia.plm_ciclo }}</td>
                                    <td class="center">{{ materia.mai_inscritos }}</td>
                                    <td class="center"><a href="#!" @click="getHorarios(carrera.car_codigo, plan.pla_codigo, materia.mat_codigo)">{{materia.hor_cantidad}}</a></td>
                                    <td class="center"><span v-if="materia.hor_cantidad > 0">{{Math.round(materia.mai_inscritos/materia.hor_cantidad)}}</span></td>
                                    <td class="center"><label><input type="checkbox" class="filled-in" @click.prevent="" :checked="materia.hag_generado"><span></span></label></td>
                                    <td class="center">{{ materia.prc_total }}</td>
                                </tr>
                            </template>
                        </template>                        
                    </template>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-detalle">
        <div class="modal-content">
            <div class="row" v-show="horarios.length == 0">
                <div class="col m2">
                    <label>Equivalencia</label>
                    <select class="browser-default">
                        <option value="">Ninguna</option>
                        <option v-for="materia in materias_equivalentes" :key="materia" :value="materia.mat_codigo">{{materia.mat_codigo}} - {{materia.mat_nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                    <thead>
                        <tr>
                            <th>Materia</th>
                            <th>Grupo</th>
                            <th>Docente</th>
                            <th>Max.</th>
                            <th>Virtual</th>
                        </tr>
                    </thead>
                    <template v-for="horario in horarios" :key="horario">
                        <tr>
                            <td>{{horario.mat_codigo}} - {{ horario.mat_nombre }}</td>
                            <td>{{ horario.hor_descripcion }}</td>
                            <td>{{ horario.emp_apellidos_nombres }}</td>
                            <td>{{ horario.hor_max_alumnos }}</td>
                            <td>{{ horario.hor_virtual }}</td>
                        </tr>
                        <tr v-for="detalle in horario.ra_dho_det_horarios_gr" :key="detalle">
                            <td colspan="5">
                                {{detalle.dh_dia_f}} | AULA {{ detalle.aul_nombre_corto }} | TIPO {{ detalle.dho_tipo }} | MAX. {{ detalle.dho_capacidad }} | HORARIO: {{ detalle.man_hor_ini }} a {{ detalle.man_hor_fin }}
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                carreras: [],
                horarios: [],
                codcil_origen: 0,
                codcil_destino: 0,
                codfac: 0,
                ciclos: [],
                facultades: [],
                materias: [],
                modal_detalle: {},
                modales: [],
                materias_equivalentes: []
            }
        },
        async mounted(){
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
            await this.getFacultades();
            await this.getCiclos();
        },
        methods: {
            async getFacultades()
            {
                this.$root.startLoading();
                await this.axios.get('/Facultades')
                .then(response => {
                    this.facultades = response.data;
                });
                this.$root.stopLoading();
            },
            async getCiclos()
            {
                this.$root.startLoading();
                await this.axios.get('/Regionales/CiclosRegional')
                .then(response => {
                    this.ciclos = response.data;
                });
                this.$root.stopLoading();
            },
            async getMaterias()
            {
                this.$root.startLoading();
                await this.axios.get('/MateriasProgramadas/' + this.codfac + '/' + this.codcil_origen + '/' + this.codcil_destino)
                .then(response => {
                    this.carreras = response.data;
                });
                this.$root.stopLoading();
            },
            resetBusqueda()
            {
                this.carreras = [];
                this.codfac = 0;
                this.codcil_origen = 0;
                this.codcil_destino = 0;
            },
            async getHorarios(codcar, codpla, codmat)
            {
                await this.getEquivalencias(this.codcil_origen, codcar, codpla, codmat);
                this.$root.startLoading();
                await this.axios.get('/MateriasProgramadas/' + this.codcil_origen + '/' + codcar + '/' + codpla + '/' + codmat)
                .then(response => {
                   this.horarios = response.data;
                   var modal = document.querySelector('#modal-detalle');
                    this.modal_detalle = M.Modal.getInstance(modal);
                    this.modal_detalle.open();
                });
                this.$root.stopLoading();
            },
            async getEquivalencias(codcil, codcar, codpla, codmat)
            {
                this.$root.startLoading();
                await this.axios.get('/MateriasProgramadas/' + codcil + '/' + codcar + '/' + codpla + '/' + codmat + '/Equivalencias')
                .then(response => {
                    this.materias_equivalentes = response.data;
                });
                this.$root.stopLoading();
            },
            async clonarCarrera(codcar)
            {
                var index_carrera = this.carreras.findIndex(x=>x.car_codigo == codcar);
                var payload = [];
                for(let i = 0; i <= this.carreras[index_carrera].ra_pla_planes.length - 1; i++)
                {
                    for(let j = 0; j <= this.carreras[index_carrera].ra_pla_planes[i].ra_mat_materias.length - 1; j++)
                    {
                        if(!this.carreras[index_carrera].ra_pla_planes[i].ra_mat_materias[j].hag_generado)
                        {
                            payload.push({
                                codcil: this.codcil_origen,
                                codcar: codcar,
                                codpla: this.carreras[index_carrera].ra_pla_planes[i].pla_codigo,
                                codmat: this.carreras[index_carrera].ra_pla_planes[i].ra_mat_materias[j].mat_codigo
                            });
                        }
                    }
                }
                if(payload.length == 0)
                {
                    M.toast({html: 'No hay materias por clonar.'});
                    return;
                }
                this.$root.startLoading();
                await this.axios.post('/MateriasProgramadas/' + this.codcil_destino + '/Clonar', payload)
                .then(async () => {
                    await this.getMaterias();
                })
                .catch(error => {
                    console.log(error.response.data);
                });
                this.$root.stopLoading();
            },
            async asignarDocente()
            {
                this.$root.startLoading();
                await this.axios.post('/MateriasProgramadas/AsignarDocente/' + this.codfac)
                .then((response) => {
                    console.log(response.data);
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            ciclos_filter(){
                if(this.codcil_origen == 0) return [];
                /*var codcic = this.ciclos.find(x=>x.cil_codigo == this.codcil_origen).cil_codcic;
                codcic = 1;*/
                return this.ciclos.filter(x=>x.cil_codigo > this.codcil_origen); //&& x.cil_codcic == codcic);
            }
        }
    }
</script>