<template>
<div class="modal blue-ugb" id="modal-crear-horario" style="width:80%; max-height:80%; border:2px solid white">
    <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
    <div class="modal-content blue-ugb">
        <div class="row">
        <div class="card-panel">
            <div class="col m5 m0 p0">
                <table class="table tbl-condensed striped bordered" style="font-size:12px">
                    <caption>PLANES ACTIVOS</caption>
                    <thead>
                        <th>Carrera</th>
                        <th>Plan</th>
                    </thead>
                    <tbody>
                        <tr v-for="plan in planes_view" v-bind:key="plan" v-bind:class="(planSeleccionado == plan.pla_codigo ? 'blue darken-3 white-text':'')">
                            <td>{{plan.ra_car_carreras.car_nombre}}</td>
                            <td class="center"><a href="#!" @click="seleccionarPlan(plan.pla_codigo)" v-bind:class="(planSeleccionado == plan.pla_codigo ? 'white-text':'')">{{plan.pla_nombre}}</a></td>
                        </tr>
                    </tbody>
                    <tfoot style="font-size:10px">
                        <tr class="m0 p0">
                            <td colspan="3" class="m0 p0">
                                <ul class="pagination m0 p0">
                                    <li v-for="i in totalPaginasPlanes" v-on:click="cambiarPaginaPlanes(i)" v-bind:key="i" v-bind:class="(i == pagina_planes ? 'active blue-ugb':'')"><a style="font-size:12px" href="#!">{{i}}</a></li>
                                </ul>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="col m5 m0 p0">
                <table v-show="materias.length > 0" class="table tbl-condensed bordered striped" style="font-size:12px">
                    <caption>MATERIAS AUTORIZADAS</caption>
                    <thead>
                        <tr>
                            <th style="width:10%">Ciclo</th>
                            <th style="width:80%">Materia</th>
                            <th style="width:10%">Grupos</th>
                        </tr>
                    </thead>   
                    <tbody>
                        <tr v-for="materia in materias_view" v-bind:key="materia" v-bind:class="(materiaSeleccionada == materia.plm_codmat ? 'blue darken-3 white-text':'')">
                            <td class="center">{{materia.plm_ciclo}}</td>
                            <td>
                                <a href="#!" @click="seleccionarMateria(materia.plm_codmat)" v-bind:class="(materiaSeleccionada == materia.plm_codmat ? 'white-text':'')">
                                    {{materia.mat_codigo}} - {{(materia.plm_alias || materia.mat_nombre)}}
                                </a>
                            </td>
                            <td class="center">{{materia.hor_cantidad}}</td>
                        </tr>
                    </tbody>
                    <tfoot style="font-size:10px">
                        <tr class="m0 p0">
                            <td colspan="3" class="m0 p0">
                                <ul class="pagination m0 p0">
                                    <li v-for="i in totalPaginasMaterias" v-on:click="cambiarPaginaMaterias(i)" v-bind:key="i" v-bind:class="(i == pagina_materias ? 'active blue-ugb':'')"><a style="font-size:12px" href="#!">{{i}}</a></li>
                                </ul>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="col m2 m0 p0" v-show="(materiaSeleccionada != '')">
                <table class="table tbl-condensed bordered striped m0 p0" style="font-size:12px">
                    <caption>GRUPOS</caption>
                    <tbody>                                        
                        <tr v-for="horario in horarios_materia" v-bind:key="horario" v-bind:class="{'blue darken-3 white-text':horario.hor_codigo == this.hor_codigo}"> 
                            <td class="center"><a v-bind:class="{'white-text':horario.hor_codigo == this.hor_codigo}" href="#!" @click="editarHorario(horario.hor_codigo)">{{horario.hor_descripcion}}</a></td>
                            <td class="center">{{horario.hor_max_alumnos}}</td>
                        </tr>
                        <tr><th>TOTAL</th><td class="center">{{horarios_materia.reduce((a, b) => a + b.hor_max_alumnos, 0)}}</td></tr>
                        <tr>
                            <th>PROYECCIÓN</th><td class="center">{{(proyeccion.prc_total || 0)}}</td>
                        </tr>
                        <tr>
                            <th>ESTÁNDAR</th><td class="center">{{(proyeccion.prc_estandar || 0)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="clearfix"></div>
            </div>
        </div>
        <div class="row" v-show="(horarios_materia.length > 0)">
        <div class="card-panel" style="position:relative">
            <a href="#!" @click="nuevoHorario" class="btn-small green darken-3" style="position:absolute; top:0px; right: 0px;">Nuevo Grupo<i class="material-icons right">add_circle</i></a>

            <div class="col m12 m0 p0">            
                <table class="tbl-condensed table striped bordered" style="font-size:12px">
                    <caption>GRUPOS</caption>
                    <thead>
                        <tr>
                            <th style="width:15%">Grupo</th>
                            <th style="width:35%">Docente</th>
                            <th style="width:10%">Max. Alu.</th>
                            <th style="width:10%">Financiado</th>
                            <th style="width:10%">Virtual</th>
                            <th style="width:10%">Publicado</th>
                            <th colspan="2" style="width:10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="horario in horarios_materia" v-bind:key="horario" v-bind:class="{'blue darken-3 white-text':horario.hor_codigo == this.hor_codigo}">
                            <td class="center">{{horario.hor_descripcion}}</td>
                            <td><a v-bind:class="(horario.hor_codigo == this.hor_codigo ? 'white-text':'')" v-bind:href="('/Docentes/' + horario.hor_codcat)" target="_blank">{{horario.emp_apellidos_nombres}}</a></td>
                            <td class="center">{{horario.hor_max_alumnos}}</td>
                            <td class="center">{{horario.hor_financiado}}</td>
                            <td class="center">{{horario.hor_virtual}}</td>
                            <td class="center"><label><input @change="cambiarEstado($event, horario.hor_codigo)" class="filled-in" type="checkbox" v-bind:checked="horario.hor_publicado"><span></span></label></td>
                            <td class="center"><a href="#!" @click="editarHorario(horario.hor_codigo)"><i class="material-icons" v-bind:class="(horario.hor_codigo == this.hor_codigo ? 'white-text':'')">create</i></a></td>
                            <td class="center"><a href="#!" @click="eliminarHorario(horario.hor_codigo)"><i class="material-icons" v-bind:class="(horario.hor_codigo == this.hor_codigo ? 'white-text':'')">delete</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="clearfix"></div>
            </div>
        </div>
        <div class="row card-panel" v-show="(materiaSeleccionada != '')">
            <input type="hidden" v-model="hor_codigo">
            <div class="col m4">
                <label>Materia</label>
                <input type="text" v-model="mat_nombre" disabled>
            </div>
            <div class="col m2">
                <label>Grupo - {{ hor_descripcion }}</label>
                <select class="browser-default" @change="getNombreGrupo()" v-model="hor_codgrh" required>
                    <option v-for="grupo in grupos_horario.filter(x=>x.grh_seccion == plm_seccion || x.grh_codigo == 'Au' || x.grh_seccion == 0)" :key="grupo" :value="grupo.grh_codigo">{{ grupo.grh_descripcion }}</option>
                </select>
                <ul>
                    <li style="font-size:12px" v-for="val in hor_descripcion_validacion" v-bind:key="val">{{val}}</li>
                </ul>
            </div>
            <div class="col m1">
                <label>Autofin.</label>
                <select v-model="hor_financiado" class="browser-default" disabled>
                    <option value="N">No</option>
                    <option value="S">Si</option>
                </select>
            </div>
            <div class="col m1">
                <label>Virtual</label>
                <select v-model="hor_virtual" class="browser-default">
                    <option value="N">No</option>
                    <option value="S">Si</option>
                </select>
            </div>
            <div class="col m3">
                <label>Docente</label> <a href="#!" @click="buscarDocente" style="float:right; font-size: 12px; text-decoration:underline">BUSCAR... <i style="font-size:12px" class="material-icons">search</i></a>
                <select v-model="hor_codcat" class="browser-default">
                    <option value="0">No Definido</option>
                    <option v-for="docente in docentes" v-bind:key="docente" v-bind:value="docente.emp_codigo">{{docente.emp_apellidos_nombres}}</option>
                </select>
            </div>
            <div class="col m1">
                <label>Cap. Max.</label>
                <input type="number" min=0 style="text-align:right" v-model="hor_max_alumnos">
                <ul>
                    <li style="font-size:12px" v-for="val in hor_max_alumnos_validacion" v-bind:key="val">{{val}}</li>
                </ul>
            </div>
            <div class="clearfix"></div>
            <div class="row card-panel blue-grey lighten-4" style="position:relative">
                <form @submit.prevent="agregarDetalleHorario">
                    <input type="hidden" v-model="detalle_index">
                    <a href="#!" @click="nuevoDetalle" class="btn-small green darken-3" style="position:absolute; top:0px; right: 0px;">Nuevo Detalle<i class="material-icons right">add_circle</i></a>
                    <div class="col m2">
                        <label>Día</label>
                        <select v-model="dho_dia" class="browser-default" @change="filtrarBloques">
                            <option value="0">Seleccione</option>
                            <option v-for="dia in dias" v-bind:value="dia.dh_codigo" v-bind:key="dia">{{dia.dh_dia_f}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Horario</label>
                        <select v-model="dho_codman" class="browser-default" :disabled="(dho_dia == 0)" @change="getSalasDisponibles">
                            <option value="0">Seleccione</option>
                            <option v-for="bloque in bloques_view" v-bind:value="bloque.man_codigo" v-bind:key="bloque">{{bloque.man_nomhor}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Edificio</label>
                        <select v-model="dho_codedi" :disabled="dho_codman == 0" class="browser-default" @change="filtrarAulas">
                            <option value="0">Seleccione</option>
                            <option v-for="edificio in edificios" v-bind:value="edificio.edi_codigo" v-bind:key="edificio">{{edificio.edi_nombre}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Aula</label>
                        <select v-model="dho_aula" class="browser-default" v-bind:disabled="(dho_codedi == 0)">
                            <option value="0">Seleccione</option>
                            <option v-for="aula in aulas" v-bind:value="aula.aul_codigo" v-bind:key="aula">{{aula.aul_nombre_corto}} (Cap. {{ aula.aul_capacidad }})</option>
                        </select>
                    </div>
                    <div class="col m1">
                        <label>Tipo</label>
                        <select v-model="dho_tipo" class="browser-default">
                            <option value="T">Teoría</option>
                            <option value="P">Práctica</option>
                        </select>
                    </div>
                    <div class="col m1" v-show="(dho_tipo == 'P')">
                        <label>Capacidad</label>
                        <input type="text" v-model="dho_capacidad">
                    </div>
                    <div class="col m1">
                        <br>
                        <label><input type="checkbox" class="filled-in" v-model="dho_virtual"><span>Virtual</span></label>
                    </div>
                    <div class="col m1">
                        <button type="submit"
                        title="Agregar bloque" 
                        class="btn blue-ugb" 
                        style="margin-top:10px" :disabled="(this.dho_dia == 0 || this.dho_codman == 0 || this.dho_aula == 0)">
                            <i class="material-icons">{{(this.detalle_index >= 0 ? 'update':'add_circle_outline')}}</i>
                        </button>
                    </div>
                </form>
            </div>
            <div class="row">
                <table class="table tbl-condensed bordered striped" v-show="(detalle_horario.length > 0)" style="font-size:12px">
                    <caption>DETALLE DE HORARIO</caption>
                    <thead>
                        <tr>
                            <th style="width:10%">Día</th>
                            <th style="width:15%">Horario</th>
                            <th style="width:20%">Edificio</th>
                            <th style="width:10%">Aula</th>
                            <th style="width:5%">Tipo</th>
                            <th style="width:10%">Grupo</th>
                            <th style="width:10%">Capacidad</th>
                            <th style="width:10%">Virtual</th>
                            <th colspan="2" style="width:10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(detalle, index) in detalle_horario" v-bind:key="detalle" v-bind:class="[{'blue darken-3 white-text':(index == detalle_index)}, {'red darken-4 white-text': (index_horario_interferencia == index)}]">
                            <td class="center">{{detalle.dh_dia_f}}</td>
                            <td class="center">{{detalle.man_nomhor}}</td>
                            <td class="center">{{detalle.edi_nombre}}</td>
                            <td class="center">{{detalle.aul_nombre_corto}}</td>
                            <td class="center">{{detalle.dho_tipo}}</td>
                            <td class="center">{{detalle.dho_gr_prac}}</td>
                            <td class="center">{{detalle.dho_capacidad}}</td>
                            <td class="center"><label><input type="checkbox" class="filled-in" @click.prevent="" v-model="detalle.dho_virtual"><span></span></label></td>
                            <td class="center"><a href="#!" @click="editarDetalle(index)" v-bind:class="((index == detalle_index || index == index_horario_interferencia) ? 'white-text':'')"><i class="material-icons">create</i></a></td>
                            <td class="center"><a href="#!" @click="eliminarDetalle(index)" v-bind:class="((index == detalle_index || index == index_horario_interferencia) ? 'white-text':'')"><i class="material-icons">delete</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col m12" v-show="(detalle_horario.length > 0)">
                     &nbsp;
                    <label v-if="validarInterferencia"><input type="checkbox" @change="cambiarValidacionInterferencia($event)" id="chk_validar_interferencia" class="filled-in" :checked="validar_interferencia"><span>Val. Interf. Prác.</span></label>
                    <button type="button" class="btn blue-ugb right" @click="agregarHorario">{{(this.hor_codigo > 0 ? 'ACTUALIZAR':'CREAR')}} <i class="material-icons right">{{(this.hor_codigo > 0 ? 'update':'save')}}</i></button>
                    <button type="button" v-show="hor_codigo > 0" class="btn green darken-2 right" @click="horarioCruzado">CRUZADA <i class="material-icons right">swap_horiz</i></button>
                    <button type="button" v-show="hor_codigo > 0" class="btn blue darken-2 right" @click="verInscritos()">INSCRITOS <i class="material-icons right">list</i></button>
                </div>
            </div>
        </div>
    </div>
</div>
    <div class="modal" id="modal-buscar-docente-crear" style="width:70%; margin-top:20px">
        <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
        <div class="modal-content">
            <BuscarDocente ref="buscarDocente"></BuscarDocente>
        </div>
    </div>

    <div class="modal blue-ugb" id="modal-horario-cruzado" style="width:75%; border:solid 2px white">
        <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
        <div class="modal-content">
            <HorarioCruzado ref="horarioCruzado" />
        </div>
    </div>

    <div class="modal blue-ugb" id="modal-inscritos">
        <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
        <div class="modal-content">
            <div class="row card-panel" style="height:400px; overflow:auto">
                <Inscritos ref="inscritos" />
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
import BuscarDocente from './BuscarDocenteComponent.vue'
import HorarioCruzado from './HorarioCruzadoComponent.vue'
import Inscritos from './InscritosComponent.vue'
    export default {
        name: 'CrearHorario',
        components: {
            BuscarDocente,
            HorarioCruzado,
            Inscritos
        },
        props: {
            dias: Array,
            bloques: Array,
            codedi_seleccionado: Number,
            codaul_seleccionado: Number,
            codman_seleccionado: Number,
            dia_seleccionado: Number
        },
        data() {
            return {
                planes: [],
                planes_view: [],
                pagina_planes: 1,
                registros: 5,
                planSeleccionado: 0,
                materiaSeleccionada: '',
                materias: [],
                materias_view: [],
                horarios: [],
                bloques_view: this.bloques,
                pagina_materias: 1,
                horarioModel: {},
                docentes: [],
                horarios_materia:[],
                mat_nombre: '',
                proyeccion: {},

                aulas: [],
                edificios: [],

                hor_codigo: 0,
                hor_descripcion: '',
                hor_descripcion_validacion: '',
                hor_codcat: 0,
                hor_max_alumnos: 0,
                hor_max_alumnos_validacion: '',
                hor_virtual: 'N',
                hor_financiado: 'N',
                hor_codpla: 0,
                hor_codmat: '',
                hor_codgrh: '',

                dho_dia: 0,
                dho_codman: 0,
                dho_aula: 0,
                dho_aula_editando: 0,
                dho_tipo: 'T',
                dho_gr_prac: 0,
                dho_capacidad: 0,
                dho_codedi: 0,
                dho_virtual: false,
                error_agregar_detalle: '',

                detalle_horario: [],

                modelErrors: [],

                detalle_index: -1,

                index_horario_interferencia: -1,
                instance_modal_busqueda_docente: null,
                instance_modal_inscritos: null,
                validar_interferencia: true,
                grupos_horario: [],
                plm_seccion: 0
            }
        },
        methods: {
            async getGruposHorario()
            {
                await this.axios.get('/GrupoHorario')
                .then(response => {
                    this.grupos_horario = response.data;
                });
            },
            async getNombreGrupo()
            {
                this.$root.startLoading();
                await this.axios.get('/GrupoHorario/NombreGrupo/' + this.hor_codpla + '/' + this.hor_codgrh)
                .then(response => {
                    this.hor_descripcion = response.data.hor_descripcion;
                    this.hor_financiado = response.data.hor_financiado;
                });
                this.$root.stopLoading();
            },
            async init()
            {
                if(this.planes.length == 0)
                {
                    await this.axios.get('/Planes/Activos/?permitidos=true')
                    .then(response => {
                        this.planes = response.data;
                        this.planes_view = response.data;
                    });
                }
                this.paginarPlanes();
                await this.getDocentes();
                await this.getGruposHorario();
            }, 
            paginarPlanes()
            {    
                this.planes_view = this.planes.slice(0, this.registros);               
            },
            cambiarPaginaPlanes(p)
            {
                if(p == this.pagina_planes)
                {
                    return;
                }
                this.materias = [];
                this.horarios_materia = [];
                this.planSeleccionado = 0;
                this.materiaSeleccionada = '';
                var inicio = (p - 1) * this.registros;
                this.pagina_planes = p;
                this.planes_view = this.planes.slice(inicio, this.registros + inicio);
            },
            paginarMaterias()
            {    
                this.materias_view = this.materias.slice(0, this.registros);               
            },
            cambiarPaginaMaterias(p)
            {
                if(p == this.pagina_materias)
                {
                    return;
                }
                this.horarios_materia = [];
                this.materiaSeleccionada = '';
                var inicio = (p - 1) * this.registros;
                this.pagina_materias = p;
                this.materias_view = this.materias.slice(inicio, this.registros + inicio);
            },
            async seleccionarPlan(id)
            {
                this.$root.startLoading();
                this.materiaSeleccionada = '';
                this.materias = [];
                this.horarios_materia = [];
                this.planSeleccionado = id;
                this.hor_codpla = id;
                this.materiaSeleccionada = '';
                await this.axios.get('/MateriasAutorizadas/' + id + '/CantidadHorarios/')
                .then(response => {
                    if(response.data.length > 0)
                    {
                        this.materias = response.data;
                    }
                });
                this.paginarMaterias();
                this.$root.stopLoading();
            },
            async seleccionarMateria(id)
            {
                this.$root.startLoading();
                this.limpiarFormularioHorario();
                this.materiaSeleccionada = '';
                this.detalle_horario = [];
                this.horarios_materia = [];
                this.materiaSeleccionada = id;
                this.hor_codmat = id;
                var materia = this.materias.find(x=>x.mat_codigo == id);
                this.mat_nombre = materia.mat_codigo + ' - ' + materia.mat_nombre;
                this.plm_seccion = materia.plm_seccion;
                await this.axios.get('/Horarios/Materia/' + this.planSeleccionado + '/' + this.materiaSeleccionada)
                .then(response => {
                    this.horarios_materia = response.data;
                });
                await this.axios.get('/Proyecciones/' + this.hor_codpla + '/' + this.hor_codmat)
                .then(response => {
                    this.proyeccion = response.data;
                });
                this.$root.stopLoading();
            },
            async getDocentes(){
                if(this.docentes.length == 0)
                {
                    await this.axios.get('/Empleados/Docentes/')
                    .then(response => {
                        this.docentes = response.data;
                    });
                }
            },
            seleccionarDocenteDisponibilidad(codemp, dia, codman)
            {
                this.dho_dia = dia;
                this.filtrarBloques();
                this.dho_codman = codman;
                this.hor_codcat = codemp;
                this.instance_modal_busqueda_docente.close();
            },
            filtrarAulas(){
                this.dho_aula = 0;
                this.aulas = this.edificios.find(x=>x.edi_codigo == this.dho_codedi).ra_aul_aulas;
            },
            filtrarBloques(){
                this.dho_codman = 0;
                this.dho_codedi = 0;
                this.dho_aula = 0;
                this.bloques_view = this.bloques.filter(x=>x.dh_codigo == this.dho_dia);
            },
            agregarDetalleHorario(){
                this.error_agregar_detalle = '';
                var existe = this.detalle_horario.find(x=>x.dho_dia == this.dho_dia && x.dho_codman == this.dho_codman && x.dho_aula == this.dho_aula)
                if(existe != undefined && this.detalle_index == -1)
                {
                    M.toast({html: 'Ya agregado un bloque en esta aula, horario y día seleccionado.'});
                    return;
                }
                if(this.dho_tipo == 'T')
                {
                    this.dho_capacidad = null;
                }
                if(this.dho_tipo == 'P' && (this.dho_capacidad || 0) == 0)
                {
                    M.toast({html: 'La capacidad del grupo práctico debe ser mayor a cero.'});
                    return;
                }
                var data = {
                    dho_dia: this.dho_dia,
                    dh_dia_f: this.dias.find(x=>x.dh_codigo == this.dho_dia).dh_dia_f,
                    dho_codman: this.dho_codman,
                    man_nomhor: this.bloques.find(x=>x.man_codigo == this.dho_codman).man_nomhor,
                    edi_codigo: this.dho_codedi,
                    edi_nombre: this.edificios.find(x=>x.edi_codigo == this.dho_codedi).edi_nombre,
                    dho_aula: this.dho_aula,
                    aul_nombre_corto: this.aulas.find(x=>x.aul_codigo == this.dho_aula).aul_nombre_corto,
                    dho_tipo: this.dho_tipo,
                    dho_capacidad: this.dho_tipo == 'T' ? this.hor_max_alumnos:this.dho_capacidad,
                    dho_gr_prac: this.dho_tipo == 'T' ? null:this.dho_gr_prac,
                    dho_virtual: this.dho_virtual
                };
                if(this.detalle_index >= 0)
                {
                    this.detalle_horario[this.detalle_index] = data;
                }
                else
                {
                    this.detalle_horario.push(data);
                }
                this.renumerarGrupos();                
                this.nuevoDetalle();
            },
            renumerarGrupos()
            {
                let n = 1;
                for(var i = 0; i <= this.detalle_horario.length - 1; i++)
                {
                    if(this.detalle_horario[i].dho_tipo == 'P')
                    {
                        this.detalle_horario[i].dho_gr_prac = n;
                        n++;
                    }
                }
            },
            async agregarHorario(){
                this.index_horario_interferencia = -1;
                if(this.hor_max_alumnos == 0)
                {
                    M.toast({html: 'La capacidad máxima debe ser mayor a cero.'})
                    return;
                }
                
                var data = {
                    hor_codigo: this.hor_codigo,
                    hor_codpla: this.hor_codpla,
                    hor_codmat: this.hor_codmat,
                    hor_descripcion: this.hor_descripcion,
                    hor_codcat: this.hor_codcat,
                    hor_max_alumnos: this.hor_max_alumnos,
                    hor_financiado: this.hor_financiado,
                    hor_virtual: this.hor_virtual,
                    hor_codgrh: this.hor_codgrh,
                    ra_dho_det_horarios_gr: this.detalle_horario
                };
                
                var practicas = this.detalle_horario.filter(x=>x.dho_tipo == 'P');
                if(practicas.length > 0)
                {
                    var total_grupos = 0;
                    for(let i = 0; i <= practicas.length - 1; i++)
                    {
                        total_grupos += parseInt(practicas[i].dho_capacidad);
                    }
                    if(total_grupos != this.hor_max_alumnos)
                    {
                        M.toast({html: 'La cantidad de estudiantes en los grupos de práctica no coincide con la capacidad máxima del grupo.'});
                        return;
                    }
                }

                this.$root.startLoading();
                if(this.detalle_horario.filter(x=>x.dho_tipo == 'P').length == 0)
                {
                    this.validar_interferencia = true;
                }
                await this.axios.post('/Horarios?validar_interferencia=' + this.validar_interferencia, data)
                .then(async response => {
                    this.hor_codigo = response.data[0].hor_codigo;
                    var horario_index = this.horarios_materia.findIndex(x=>x.hor_codigo == this.hor_codigo);

                    await this.axios.get('/Horarios/' + this.hor_codigo)
                    .then(response => {
                        if(this.horarios_materia[horario_index] == undefined)
                        {
                            this.horarios_materia.push(response.data);
                        }
                        else
                        {
                            this.horarios_materia[horario_index] = response.data;
                        }
                        var index_materia = this.materias.findIndex(x=>x.mat_codigo == this.hor_codmat);
                        this.materias[index_materia].hor_cantidad = this.horarios_materia.length;
                    });
                    this.detalle_index = -1;
                    M.toast({html: 'El horario ha sido actualizado.'});
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        M.toast({html: 'Rellene los campos correctamente.'});
                        if(data.modelErrors.find(x=>x.key == "hor_descripcion") != undefined)
                            this.hor_descripcion_validacion = data.modelErrors.find(x=>x.key == "hor_descripcion").messages;
                        if(data.modelErrors.find(x=>x.key == "hor_max_alumnos") != undefined)
                            this.hor_max_alumnos_validacion = data.modelErrors.find(x=>x.key == "hor_max_alumnos").messages;
                    }

                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                        if(data.data != undefined)
                        {
                            var index_detalle_error = this.detalle_horario
                                                          .findIndex(x=>x.dho_aula == data.data.dho_aula
                                                          && x.dho_codman == data.data.dho_codman
                                                          && x.dho_dia == data.data.dho_dia);
                            this.index_horario_interferencia = index_detalle_error;
                        }
                    }
                });
                this.$root.stopLoading();
            },
            editarHorario(codhor){
                this.detalle_horario = [];
                this.detalle_index = -1;
                var horario = this.horarios_materia.find(x=>x.hor_codigo == codhor);
                this.hor_codigo = horario.hor_codigo;
                this.hor_descripcion = horario.hor_descripcion;
                this.hor_financiado = horario.hor_financiado;
                this.hor_virtual = horario.hor_virtual;
                this.hor_codcat = horario.hor_codcat;
                this.hor_max_alumnos = horario.hor_max_alumnos;
                this.hor_codgrh = horario.hor_codgrh;
                var detalle = horario.detalle;
                for(let i = 0; i <= detalle.length - 1; i++)
                {
                    this.detalle_horario.push(
                        {
                            dho_dia: detalle[i].dh_codigo,
                            dh_dia_f: detalle[i].dh_dia_f,
                            dho_codman: detalle[i].man_codigo,
                            man_nomhor: detalle[i].man_nomhor,
                            edi_codigo: detalle[i].edi_codigo,
                            edi_nombre: detalle[i].edi_nombre,
                            dho_aula: detalle[i].aul_codigo,
                            aul_nombre_corto: detalle[i].aul_nombre_corto,
                            dho_gr_prac: detalle[i].dho_gr_prac,
                            dho_tipo: detalle[i].dho_tipo,
                            dho_capacidad: detalle[i].dho_capacidad
                        }
                    );
                }
            },
            async editarDetalle(index)
            {
                this.index_horario_interferencia = -1;
                var detalle = this.detalle_horario[index];
                this.detalle_index = index;
                this.dho_dia = detalle.dho_dia;
                this.filtrarBloques();
                this.dho_codman = detalle.dho_codman;
                this.dho_aula_editando = detalle.dho_aula;
                await this.getSalasDisponibles();
                this.dho_codedi = detalle.edi_codigo;
                this.filtrarAulas();
                this.dho_aula = detalle.dho_aula;
                this.dho_tipo = detalle.dho_tipo;
                this.dho_capacidad = detalle.dho_capacidad;
                this.dho_gr_prac = detalle.dho_gr_prac;
            },
            limpiarFormularioHorario()
            {
                this.hor_codigo = 0;
                this.hor_descripcion = '';
                this.hor_financiado = 'N';
                this.hor_virtual = 'N';
                this.hor_codcat = 0;
                this.hor_max_alumnos = 0;

                this.detalle_index = -1
                this.dho_codedi = 0;
                this.dho_dia = 0;
                this.dho_codman = 0;
                this.dho_aula = 0;
                this.dho_tipo = 'T';
                this.dho_capacidad = 0;
                this.dho_gr_prac = 0;
            },
            nuevoDetalle()
            {
                this.index_horario_interferencia = -1;
                this.detalle_index = -1
                this.dho_codedi = 0;
                this.dho_dia = 0;
                this.dho_codman = 0;
                this.dho_aula = 0;
                this.dho_aula_editando = 0;
                this.dho_tipo = 'T';
                this.dho_capacidad = 0;
                this.dho_gr_prac = 0;
            },
            nuevoHorario()
            {
                this.limpiarFormularioHorario();
                this.detalle_horario = [];
                document.querySelector('#hor_descripcion').focus();
            },
            async eliminarHorario(codhor)
            {
                if(confirm('¿Desea eliminar este horario?.\nSi existen estudiantes inscritos no podrá hacerlo.'))
                {
                    this.$root.startLoading();
                    var indice = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                    var index_materia = this.materias.findIndex(x=>x.mat_codigo == this.horarios_materia[indice].hor_codmat);
                    await this.axios.delete('/Horarios/' + codhor)
                    .then(() => {
                        this.horarios_materia.splice(indice, 1);
                        this.materias[index_materia].hor_cantidad = this.horarios_materia.length;
                        M.toast({html: 'El horario ha sido eliminado.'});
                        this.limpiarFormularioHorario();
                    })
                    .catch(error => {
                        var data = error.response.data;
                        M.toast({html: data.message});
                    });
                    this.$root.stopLoading();
                }
            },
            cambiarEstado(event, codhor)
            {
                var estado = event.target.checked;
                this.axios.patch('/Horarios/'+codhor+'/Estado/' + estado )
                .then(() => {});
            },
            eliminarDetalle(index)
            {
                this.detalle_horario.splice(index, 1);
                this.index_horario_interferencia = -1;
                this.renumerarGrupos();
            },
            async buscarDocente()
            {
                this.$root.startLoading();
                await this.$refs.buscarDocente.init(this.materiaSeleccionada, this.planSeleccionado, true, this.hor_codigo);
                var modal = document.querySelector('#modal-buscar-docente-crear');
                this.instance_modal_busqueda_docente = M.Modal.getInstance(modal);
                this.instance_modal_busqueda_docente.open();
                this.$root.stopLoading();
            },
            async getSalasDisponibles()
            {
                this.dho_aula = 0;
                this.dho_codedi = 0;
                if(this.dho_codman == 0)
                {
                    this.edificios = [];
                    this.aulas = [];
                    return;
                }
                this.$root.startLoading();
                await this.axios.get('/Aulas/' + this.dho_dia + '/' + this.dho_codman + '/' + this.dho_aula_editando + '/Disponibles')
                .then(response => {
                    this.edificios = response.data;
                });
                this.$root.stopLoading();
            },
            async horarioCruzado()
            {
                this.$root.startLoading();
                await this.$refs.horarioCruzado.init(this.hor_codigo);
                var modal = document.querySelector('#modal-horario-cruzado');
                this.instance_modal_horario_cruzado = M.Modal.getInstance(modal);
                this.instance_modal_horario_cruzado.dismissible = false;
                this.instance_modal_horario_cruzado.open();
                this.$root.stopLoading();
            },
            cambiarValidacionInterferencia(event)
            {
                this.validar_interferencia = event.target.checked;
            },
            async verInscritos()
            {
                var modal = document.querySelector('#modal-inscritos');
                this.instance_modal_inscritos= M.Modal.getInstance(modal);
                this.instance_modal_inscritos.dismissible = false;
                this.instance_modal_inscritos.open();
                await this.$refs.inscritos.init(this.hor_codigo);
            }
        },
        computed: {
            totalPaginasPlanes: function() {
                var total = this.planes.length;
                return parseInt(Math.ceil((total / this.registros)));
            },
            totalPaginasMaterias: function() {
                var total = this.materias.length;
                return parseInt(Math.ceil((total / this.registros)));
            },
            validarInterferencia: function()
            {
                var result = this.detalle_horario.filter(x=>x.dho_tipo == 'P');
                return result.length >= 2;
            }
        }        
    }
</script>