<template>
    <div class="modal blue-ugb" style="width:90%; max-height:80%; border:2px solid white" id="modal-editar-horario">
        <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
        <div class="modal-content">
            <button class="btn red m0 p0" style="display:none; height:30px; width: 30px;float:right">&times;</button>
            <div class="row m0 p0">
                <div class="card-panel">
                    
                    <div class="row">
                        <div class="col m3">
                            <ul class="collection" style="font-size:12px">
                                <li class="collection-item">EDIFICIO: <span class="badge new green darken-1" v-bind:data-badge-caption="edi_nombre"></span></li>
                                <li class="collection-item">AULA: <span class="badge new green darken-1" v-bind:data-badge-caption="aul_nombre_corto"></span></li>
                                <li class="collection-item">CAPACIDAD: <span class="badge new green darken-1" v-bind:data-badge-caption="aul_capacidad"></span></li>
                                <li class="collection-item">DÍA: <span class="badge new green darken-1" v-bind:data-badge-caption="dh_dia_f"></span></li>
                                <li class="collection-item">HORARIO: <span class="badge new green darken-1" v-bind:data-badge-caption="man_nomhor"></span></li>
                                <li class="collection-item m0 p0">
                                    <table class="table tbl-condensed bordered striped m0 p0" v-show="(codmat != '')">
                                        <tbody>                                        
                                            <tr><th colspan="2" class="center">GRUPOS</th></tr>
                                            <tr v-for="horario in horarios_materia" v-bind:key="horario"> 
                                                <td class="center"><a href="#!" @click="editarHorario(horario.hor_codigo)">{{horario.hor_descripcion}}</a></td>
                                                <td class="center">{{horario.hor_max_alumnos}}</td>
                                            </tr>
                                            <tr><th>TOTAL</th><td class="center">{{horarios_materia.reduce((a, b) => a + b.hor_max_alumnos, 0)}}</td></tr>
                                            <tr>
                                                <th>PROYECCIÓN</th><td class="center">{{(proyeccion.prc_total || 0)}}</td>
                                            </tr>
                                            <tr>
                                                <th>ESTÁNDAR</th><td class="center">{{(proyeccion.prc_estandar || 0)}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            </ul>
                        </div>

                        <div class="col m9">
                            <div class="row">
                                <div class="col m12 card-panel">
                                    <div class="col m6">
                                        <label>Facultad</label>
                                        <select class="browser-default" v-model="codfac" @change="filtrarCarreras">
                                            <option value="0">Seleccione</option>
                                            <option v-for="facultad in facultades" v-bind:key="facultad" v-bind:value="facultad.fac_codigo">{{facultad.fac_nombre}}</option>
                                        </select>
                                    </div>
                                    <div class="col m6">
                                        <label>Carrera</label>
                                        <select class="browser-default" v-model="codcar" :disabled="(codfac == 0)" @change="getPlanes">
                                            <option value="0">Seleccione</option>
                                            <option v-for="carrera in carreras_view" v-bind:key="carrera" v-bind:value="carrera.car_codigo">{{carrera.car_nombre}}</option>
                                        </select>
                                    </div>
                                    <div class="col m3">
                                        <label>Plan</label>
                                        <select class="browser-default" v-model="codpla" :disabled="(codcar == 0)" @change="getMaterias">
                                            <option value="0">Seleccione</option>
                                            <option v-for="plan in planes" v-bind:key="plan" v-bind:value="plan.pla_codigo">{{plan.pla_nombre}}</option>
                                        </select>
                                    </div>
                                    <div class="col m9">
                                        <label>Materia</label>
                                        <select class="browser-default" :disabled="(codpla == 0)" @change="getHorarios" v-model="codmat">
                                            <option value=''>Seleccione</option>
                                            <option v-for="materia in materias" v-bind:key="materia" v-bind:value="materia.mat_codigo">{{materia.mat_codigo}} - {{materia.mat_nombre}} - CICLO: {{ materia.plm_ciclo }} (GRUPOS: {{materia.hor_cantidad}}) </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="(!existe_horario_en_bloque && codmat != '')">
                                <div class="col m12 card-panel m0">
                                    <form method="post" @submit.prevent="crearHorario">
                                        <input type="hidden" v-model="hor_codigo">
                                        <div class="col m3">
                                            <label>Grupo - <a target="_blank" v-bind:href="('/Grupo/' + codpla + '/' + plm_ciclo + '/' + hor_codgrh)">{{ hor_descripcion }} </a></label>
                                            <select class="browser-default" @change="getNombreGrupo()" v-model="hor_codgrh">
                                                <option v-for="grupo in grupos_horario.filter(x=>x.grh_seccion == plm_seccion || x.grh_codigo == 'Au' || x.grh_seccion == 0)" :key="grupo" :value="grupo.grh_codigo">{{ grupo.grh_descripcion }}</option>
                                            </select>                                        
                                            <ul class="collection">
                                                <li v-for="message in hor_descripcion_validacion" v-bind:key="message">{{message}}</li>
                                            </ul>
                                        </div>
                                        <div class="col m2">
                                            <label>Autofin.</label>
                                            <select v-model="hor_financiado" class="browser-default" disabled>
                                                <option value="N">No</option>
                                                <option value="S">Si</option>
                                            </select>
                                        </div>
                                        <div class="col m2">
                                            <label>Virtual</label>
                                            <select v-model="hor_virtual" class="browser-default">
                                                <option value="N">No</option>
                                                <option value="S">Si</option>
                                            </select>
                                        </div>
                                        <div class="col m3">
                                            <label>Docente</label>
                                            <select v-model="hor_codcat" class="browser-default">
                                                <option value="0">Sin Docente</option>
                                                <option v-for="docente in docentes" v-bind:key="docente" v-bind:value="docente.emp_codigo">{{docente.emp_apellidos_nombres}} (GRUPOS: {{docente.hor_cantidad}})</option>
                                            </select>
                                            <label><input @change="getDocentes($event.target.checked)" type="checkbox" v-model="disponiblidad" class="filled-in"><span style="font-size:10px; height:5px">SOLO DISPONIBILIDAD</span></label>
                                        </div>
                                        <div class="col m2">
                                            <label>Cap. Max.</label>
                                            <input type="number" min=0 v-model="hor_max_alumnos">
                                            <ul class="collection">
                                                <li v-for="message in hor_max_alumnos_validacion" min=0 v-bind:key="message">{{message}}</li>
                                            </ul>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col m2">
                                            <label>Tipo</label>
                                            <select v-model="dho_tipo" class="browser-default">
                                                <option value="T">Teoría</option>
                                                <option value="P">Práctica</option>
                                            </select>
                                        </div>
                                        <div class="col m2" v-show="(dho_tipo == 'P')">
                                            <label>Capacidad</label>
                                            <input type="number" v-model="dho_capacidad">
                                        </div>
                                        <div class="col m2">
                                            <br>
                                            <label><input type="checkbox" v-model="dho_virtual"><span>Virtual</span></label>
                                        </div>
                                        <div class="col m3">
                                            <button type="submit" class="btn blue-ugb" style="margin-top:10px">CREAR <i class="material-icons right">save</i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>                       
                        </div>
                    </div>

                    <div class="row" v-show="(codmat != '' && horarios_materia.length > 0)">
                            <div class="col m12">
                                <table class="tbl-condensed table striped bordered" style="font-size:12px">
                                    <caption>HORARIOS PROGRAMADOS</caption>
                                    <thead>
                                        <tr>
                                            <th style="width:15%">Grupo</th>
                                            <th style="width:35%">Docente</th>
                                            <th style="width:10%">Max. Alu.</th>
                                            <th style="width:10%">Financiado</th>
                                            <th style="width:10%">Virtual</th>
                                            <th style="width:10%">Publicado</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="horario in horarios_materia" v-bind:key="horario">
                                            <tr class="grey lighten-4">
                                                <th class="center">
                                                    
                                                    <a target="_blank" v-bind:href="('/Grupo/' + horario.hor_codpla + '/' + horario.plm_ciclo + '/' + horario.hor_codgrh)">{{horario.hor_descripcion}} </a>
                                                </th>
                                                <th><a v-bind:href="('/Docentes/' + horario.hor_codcat)" target="_blank">{{horario.emp_apellidos_nombres}}</a></th>
                                                <th class="center">{{horario.hor_max_alumnos}}</th>
                                                <th class="center">{{horario.hor_financiado}}</th>
                                                <th class="center">{{horario.hor_virtual}}</th>
                                                <th class="center"><label><input @change="cambiarEstado($event, horario.hor_codigo)" class="filled-in" type="checkbox" v-bind:checked="horario.hor_publicado"><span></span></label></th>
                                                <th class="center"><a href="#!" @click.prevent="eliminarHorario(horario.hor_codigo)"><i class="material-icons">delete</i></a></th>
                                                <th class="center"><a href="#!" @click="editarHorario(horario.hor_codigo)"><i class="material-icons">edit</i></a></th>
                                            </tr>
                                            <tr v-for="dho in horario.detalle" v-bind:key="dho" v-bind:class="(dho.aul_codigo == codaul && dho.dh_codigo == dia && dho.man_codigo == codman ? 'red lighten-4':'')">
                                                <td colspan="5">
                                                    <ul class="collection m0 p0">
                                                        <li class="collection-item" >
                                                            {{dho.dh_dia_f}} | {{dho.man_nomhor}} | {{dho.edi_nombre}} | {{dho.aul_nombre_corto}} | {{(dho.dho_tipo == 'T') ? 'Teoría':'Práctica'}} {{(dho.dho_tipo == 'P' ? dho.dho_gr_prac:'')}} | {{dho.dho_capacidad}} Alumnos <span class="badge new green darken-1" data-badge-caption="" v-show="dho.dho_virtual">Virtual</span>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td class="center">
                                                    <a href="#!" title="Reemplazar" @click="reemplazarDetalle(horario.hor_codigo, dho.dho_codigo)"><i class="material-icons">update</i></a>
                                                </td>
                                                <td class="center">
                                                    <a href="#!" title="Eliminar" @click.prevent="eliminarDetalle(horario.hor_codigo, dho.dho_codigo)"><i class="material-icons">delete</i></a>
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr style="height:50px" v-show="(!existe_horario_en_bloque)">
                                                <td colspan="5" class="center lighten-5 green">
                                                    <form @submit.prevent="agregarDetalle(horario.hor_codigo)">
                                                        <div class="col m4">
                                                            <label>Tipo:</label>
                                                            <select class="browser-default" v-bind:id="('dho_tipo_' + horario.hor_codigo)" @change="mostrarDetallePractica($event, horario.hor_codigo)">
                                                                <option value="T">Teoría</option>
                                                                <option value="P">Práctica</option>
                                                            </select>
                                                        </div>
                                                        <div class="col m4" v-show="horario.dho_gr_prac_visible">
                                                            <label>Grupo:</label>
                                                            <input v-bind:id="('dho_gr_prac_' + horario.hor_codigo)" style="text-align:center" disabled type="number" v-bind:value="set_dho_gr_prac(horario.hor_codigo)">                                                    
                                                        </div> 
                                                        <div class="col m4" v-show="horario.dho_capacidad_visible">
                                                            <label>Capacidad:</label>
                                                            <input v-bind:id="('dho_capacidad_' + horario.hor_codigo)" style="text-align:center" type="number" value="0">                                                    
                                                        </div>
                                                        <div class="clearfix"></div>
                                                        <div class="col m12">
                                                            <button type="submit" class="btn green btn-small" title="Agregar bloque.">AGREGAR <i class="material-icons right">add_circle</i></button>
                                                        </div>
                                                    </form>                            
                                                </td>
                                                <td colspan="3" class="center blue-ugb white-text">{{horario.hor_codmat}} - {{horario.mat_nombre}}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    <div class="clearfix"></div>                    
                </div>
            </div>
        </div>
    </div>
    <ModificarHorario ref="modificarHorario"></ModificarHorario>
</template>

<script>
import M from 'materialize-css'
import ModificarHorario from '../components/ModificarHorarioComponent.vue';

    export default{
        name: 'AgregarHorario',
        components: {
            ModificarHorario
        },
        props: {
            codedi: Number,
            codaul: Number,
            codman: Number,
            dia: Number,
            codhor: Number,
            coddho: Number
        },
        data() {
            return {
                carreras_view: [],
                planes: [],
                materias: [],
                horarios_materia: [],
                docentes:[],
                carreras: [],
                facultades: [],
                proyeccion: {},
                codfac: 0,
                codcar: 0,
                codpla: 0,
                codmat: '',
                mat_nombre: '',
                disponiblidad: true,

                hor_codigo: this.codhor,
                hor_max_alumnos: 0,
                hor_max_alumnos_validacion: [],
                hor_descripcion: '',
                hor_descripcion_validacion: [],
                hor_financiado: 'N',
                hor_virtual: 'N',
                hor_codcat: 0,
                hor_codgrh: '',
                plm_ciclo: 0,
                plm_seccion: 0,

                dho_tipo: 'T',
                dho_gr_prac: 1,
                dho_capacidad: 0,
                dho_virtual: false,

                grupos_horario: []
            }
        },
        methods: {
            async init()
            {
                this.$root.startLoading();
                await this.getFacultades();
                this.$root.stopLoading();
                await this.getDocentes();
                await this.getGruposHorario();
            },
            async getGruposHorario()
            {
                await this.axios.get('/GrupoHorario')
                .then(response => {
                    this.grupos_horario = response.data;
                });
            },
            async getFacultades()
            {
                if(this.facultades.length == 0)
                {
                    await this.axios.get('/Facultades?permitidas=true')
                    .then(response => {
                        this.facultades = response.data;
                    });
                }
            },
            async filtrarCarreras()
            {
                this.$root.startLoading();
                if(this.carreras.length == 0)
                {
                    await this.axios.get('/Carreras/Activas/?permitidas=true')
                    .then(response => {
                        this.carreras = response.data;
                        this.carreras_view = response.data;
                    });
                }
                this.carreras_view = this.carreras.filter(x=>x.car_codfac == this.codfac);
                this.codcar = 0;
                this.codpla = 0;
                this.codmat = '';
                this.$root.stopLoading();
            },
            async getPlanes()
            {
                this.$root.startLoading();
                this.codmat = '';
                this.codpla = 0;
                await this.axios.get('/Planes/Activos/' + this.codcar)
                .then(response => {
                    this.planes = response.data;
                });
                this.$root.stopLoading();
            },
            async getMaterias()
            {
                this.$root.startLoading();
                await this.axios.get('/MateriasAutorizadas/' + this.codpla + '/CantidadHorarios/')
                .then(response => {
                    if(response.data.length > 0)
                    {
                        this.materias = response.data;
                    }
                });
                this.codmat = ''
                this.horarios_materia = [];
                this.$root.stopLoading();
            },
            async getHorarios()
            {
                if(this.codmat == '')
                {
                    return;
                }
                this.$root.startLoading();
                await this.axios.get('/Horarios/Materia/' + this.codpla + '/' + this.codmat)
                .then(response => {
                    this.horarios_materia = response.data;
                });
                await this.axios.get('/Proyecciones/' + this.codpla + '/' + this.codmat)
                .then(response => {
                    this.proyeccion = response.data;
                });
                this.mat_nombre = this.materias.find(x=>x.mat_codigo == this.codmat).mat_nombre;
                this.plm_ciclo = this.materias.find(x=>x.mat_codigo == this.codmat).plm_ciclo;
                this.plm_seccion = this.materias.find(x=>x.mat_codigo == this.codmat).plm_seccion;
                this.$root.stopLoading();
                await this.getDocentes(this.disponiblidad);
            },
            async agregarDetalle(codhor)
            {
                var tipo = document.querySelector('#dho_tipo_' + codhor).value;
                var capacidad = document.querySelector('#dho_capacidad_' + codhor).value;
                var grupo = document.querySelector('#dho_gr_prac_' + codhor).value;

                var index_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                if(this.horarios_materia[index_horario].detalle.findIndex(x=>x.aul_codigo == this.codaul && x.man_codigo == this.codman && x.dh_codigo == this.dia) != -1)
                {
                    M.toast({html: 'Ya existe un horario programado en este bloque.'});
                    return;
                }

                if(tipo == 'P' && (capacidad || 0) <= 0)
                {
                    M.toast({html: 'Debes agregar un valor mayor a cero a la capacidad del grupo de práctica.'});
                    return;
                }

                if(tipo == 'P' && (grupo || 0) == 0)
                {
                    M.toast({html: 'Agregue el número de grupo de práctica.'});
                    return;
                }

                var data = {
                    dho_aula: this.codaul,
                    dho_dia: this.dia,
                    dho_codman: this.codman,
                    dho_tipo: tipo,
                    dho_capacidad: capacidad,
                    dho_codhor: codhor,
                    dho_gr_prac: grupo,
                    dho_virtual: this.dho_virtual
                };                

                this.$root.startLoading();
                await this.axios.post('/Horarios/' + codhor + '/Detalle/', data)
                .then(async () => {
                    await this.getHorario(codhor);
                    M.toast({html: 'El bloque ha sido agregado con éxito.'});
                })
                .catch(error => {                    
                    var data = error.response.data;
                    if(error.response.status != 500)
                    {
                        M.toast({html: data.message});
                    }
                });
                
                this.$root.stopLoading();
            },
            async eliminarHorario(codhor)
            {
                if(confirm('¿Desea eliminar este horario?.\nSi existen estudiantes inscritos no podrá hacerlo.'))
                {
                    this.$root.startLoading();
                    var indice_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                    await this.axios.delete('/Horarios/' + codhor)
                    .then(() => {
                        var codmat = this.horarios_materia[indice_horario].hor_codmat;
                        var index_materia = this.materias.findIndex(x=>x.mat_codigo == codmat);
                        this.horarios_materia.splice(indice_horario, 1);
                        this.materias[index_materia].hor_cantidad = this.horarios_materia.length;
                        
                        M.toast({html: 'El horario ha sido eliminado.'});
                    })
                    .catch(error => {
                        var data = error.response.data;
                        M.toast({html: data.message});
                    });
                    this.$root.stopLoading();
                }
            },
            removerHorario(codhor)
            {
                var indice_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                if(indice_horario != -1)
                {
                    var codmat = this.horarios_materia[indice_horario].hor_codmat;
                    var index_materia = this.materias.findIndex(x=>x.mat_codigo == codmat);
                    this.horarios_materia.splice(indice_horario, 1);
                    if(index_materia != -1)
                        this.materias[index_materia].hor_cantidad = this.horarios_materia.length;
                }
            },
            async eliminarDetalle(codhor, coddho)
            {
                if(confirm('¿Desea eliminar este bloque de clases?'))
                {
                    this.$root.startLoading();
                    await this.axios.delete('/Horarios/' + codhor + '/Detalle/' + coddho)
                    .then(async () => {
                        await this.getHorario(codhor);
                        M.toast({html: 'El bloque ha sido eliminado.'});
                    }).catch(error => {
                        var data = error.response.data;
                        M.toast({html: data.message});
                    });
                    this.$root.stopLoading();
                }
            },
            async getDocentes(checked){
                this.disponiblidad = checked || this.disponiblidad;
                if(this.codmat != '')
                {
                    this.$root.startLoading();
                    this.hor_codcat = 0;
                    await this.axios.get('/Empleados/' + this.codpla + '/' + this.codmat + '/' + this.dia + '/' + this.codman + '/' + this.disponiblidad + '/' + this.hor_codigo)
                    .then(response => {
                        this.docentes = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async crearHorario()
            {
                this.hor_max_alumnos_validacion = [];
                this.hor_descripcion_validacion = [];
                if((this.hor_max_alumnos || 0) <= 0)
                {
                    M.toast({html: 'La capacidad máxima del grupo debe ser mayor a cero.'});
                    return;
                }
                var data = {
                    hor_codpla: this.codpla,
                    hor_codmat: this.codmat,
                    hor_descripcion: this.hor_descripcion,
                    hor_codcat: this.hor_codcat,
                    hor_max_alumnos: this.hor_max_alumnos,
                    hor_financiado: this.hor_financiado,
                    hor_virtual: this.hor_virtual,
                    hor_codgrh: this.hor_codgrh,
                    ra_dho_det_horarios_gr: [
                        {
                            dho_dia: this.dia,
                            dho_codman: this.codman,
                            dho_aula: this.codaul,
                            dho_tipo: this.dho_tipo,
                            dho_gr_prac: this.dho_gr_prac,
                            dho_capacidad: this.dho_capacidad,
                            dho_virtual: this.dho_virtual
                        }
                    ]
                };
                
                this.$root.startLoading();
                await this.axios.post('/Horarios', data)
                .then(async response => {
                    var codhor = response.data[0].hor_codigo;

                    var index_materia = this.materias.findIndex(x=>x.mat_codigo == this.codmat);                    

                    await this.getHorario(codhor);

                    this.materias[index_materia].hor_cantidad = this.horarios_materia.length;
                    this.hor_descripcion = '';
                    this.hor_financiado = 'N';
                    this.hor_virtual = 'N';
                    this.hor_codcat = 0;
                    this.hor_max_alumnos = 0;
                    this.dho_tipo = 'T';
                    this.dho_virtual = false;
                    M.toast({html: 'El horario ha sido creado.'});
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }

                    if(data.type == 'M')
                    {
                        if(data.modelErrors.find(x=>x.key == 'hor_max_alumnos') != undefined)
                            this.hor_max_alumnos_validacion = data.modelErrors.find(x=>x.key == 'hor_max_alumnos').messages;
                        if(data.modelErrors.find(x=>x.key == 'hor_descripcion') != undefined)
                            this.hor_descripcion_validacion = data.modelErrors.find(x=>x.key == 'hor_descripcion').messages;
                    }
                });
                this.$root.stopLoading();
            },
            mostrarDetallePractica(event, codhor)
            {
                var value = event.target.value;
                var index_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                this.horarios_materia[index_horario].dho_gr_prac_visible = value == 'P' ? true:false;
                this.horarios_materia[index_horario].dho_capacidad_visible = value == 'P' ? true:false;
                if(value == 'T')
                {
                    document.querySelector('#dho_capacidad_' + codhor).value = this.horarios_materia[index_horario].hor_max_alumnos;
                    document.querySelector('#dho_gr_prac_' + codhor).value = 1;
                }
            },
            async reemplazarDetalle(codhor, coddho)
            {                
                var index_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                if(this.horarios_materia[index_horario].detalle.findIndex(x=>x.aul_codigo == this.codaul && x.man_codigo == this.codman && x.dh_codigo == this.dia) != -1)
                {
                    M.toast({html: 'Ya existe un horario programado en este bloque.'});
                    return;
                }

                if(!confirm('¿Desea reemplazar este horario?')) return;

                var index_detalle = this.horarios_materia[index_horario].detalle.findIndex(x=>x.dho_codigo == coddho);
                var data = {
                    dho_codman: this.codman,
                    dho_aula: this.codaul,
                    dho_dia: this.dia,
                    dho_codhor: codhor,
                    dho_tipo: this.horarios_materia[index_horario].detalle[index_detalle].dho_tipo,
                    dho_capacidad: this.horarios_materia[index_horario].detalle[index_detalle].dho_capacidad,
                    dho_gr_prac: this.horarios_materia[index_horario].detalle[index_detalle].dho_gr_prac
                };

                this.$root.startLoading();
                await this.axios.patch('/Horarios/' + codhor + '/Detalle/' + coddho + '/Reemplazar/', data)
                .then(async () => {
                    /*var data_to_delete = [{
                        aul_codigo: 
                    }];
                    this.$parent.removeHorario();*/
                    await this.axios.get('/Horarios/' + codhor + '/Detalle/' + coddho)
                    .then(response => {
                        this.horarios_materia[index_horario].detalle[index_detalle] = response.data;
                    })
                })
                .catch(error => {
                    var data = error.response.data;
                    M.toast({html: data.message});
                });
                this.$root.stopLoading();
            },
            cambiarEstado(event, codhor)
            {
                var estado = event.target.checked;
                this.axios.patch('/Horarios/'+codhor+'/Estado/' + estado )
                .then(() => {});
            },
            async editarHorario(codhor, codaul, codman, codedi, dia)
            {
                this.$root.startLoading();
                await this.$refs.modificarHorario.getHorario(codhor, codaul, codman, codedi, dia);
                this.$root.stopLoading();
            },
            async getHorario(codhor)
            {
                await this.axios.get('/Horarios/' + codhor)
                .then(response => {
                    var index_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                    if(index_horario == -1)
                    {
                        this.horarios_materia.push(response.data);
                    }
                    else
                    {
                        this.horarios_materia[index_horario] = response.data;
                    }
                });
            },
            set_dho_gr_prac(codhor)
            {
                var index_horario = this.horarios_materia.findIndex(x=>x.hor_codigo == codhor);
                var n = this.horarios_materia[index_horario].detalle.filter(x=>x.dho_tipo == 'P').length + 1;
                return n;
            },
            async getNombreGrupo()
            {
                this.$root.startLoading();
                await this.axios.get('/GrupoHorario/NombreGrupo/' + this.codpla + '/' + this.hor_codgrh)
                .then(response => {
                    this.hor_descripcion = response.data.hor_descripcion;
                    this.hor_financiado = response.data.hor_financiado;
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            edi_nombre: function()
            {
                var index = this.$parent.edificios.findIndex(x=>x.edi_codigo == this.codedi);
                if(index == -1)
                {
                    return '';
                }
                else {
                    return this.$parent.edificios[index].edi_nombre;
                }
            },
            aul_nombre_corto: function()
            {
                var index = this.$parent.aulas.findIndex(x=>x.aul_codigo == this.codaul);
                if(index == -1)
                {
                    return '';
                }
                else {
                    return this.$parent.aulas[index].aul_nombre_corto;
                }
            },
            aul_capacidad: function()
            {
                var index = this.$parent.aulas.findIndex(x=>x.aul_codigo == this.codaul);
                if(index == -1)
                {
                    return 0;
                }
                else {
                    return this.$parent.aulas[index].aul_capacidad;
                }
            },
            dh_dia_f: function()
            {
                var index = this.$parent.dias.findIndex(x=>x.dh_codigo == this.dia);
                if(index == -1)
                {
                    return '';
                }
                else {
                    return this.$parent.dias[index].dh_dia_f;
                }
            },
            man_nomhor: function()
            {
                var index = this.$parent.bloques.findIndex(x=>x.man_codigo == this.codman);
                if(index == -1)
                {
                    return '';
                }
                else {
                    return this.$parent.bloques[index].man_nomhor;
                }
            },
            existe_horario_en_bloque: function()
            {
                var existe = false;
                for(let i = 0; i <= this.horarios_materia.length - 1; i++)
                {
                    if(this.horarios_materia[i].detalle.findIndex(x=>x.aul_codigo == this.codaul && x.man_codigo == this.codman && x.dh_codigo == this.dia) != -1)
                    {
                        existe = true;
                        break;
                    }
                }
                return existe;
            }
        }
    }
</script>