<template>
    <div class="row">
        <div class="col m12" style="border: dashed 1px black">
            <form @submit.prevent="guardar">
                <div class="row">
                    <div class="col m1">
                        <label>ID</label>
                        <input type="text" class="active" disabled v-model="carrera.car_codigo">                        
                    </div>
                    <div class="col m4">
                        <label>Nombre</label>
                        <input type="text" class="active" v-model="carrera.car_nombre">                        
                    </div>
                    <div class="col m1">
                        <label>Prefijo</label>
                        <input type="text" class="active" v-model="carrera.car_nombre_corto">                        
                    </div>
                    <div class="col m2">
                        <label>Tipo</label>
                        <select v-model="carrera.tipo" class="browser-default">
                            <option value="U">Pregrado</option>
                            <option value="M">Posgrado</option>
                        </select>                    
                    </div>
                    <div class="col m2">
                        <label>Modalidad</label>
                        <select v-model="carrera.car_virtual" class="browser-default">
                            <option value="P">Presencial</option>
                            <option value="V">Virtual</option>
                        </select>                    
                    </div>
                    <div class="col m2">
                        <label>Profesorado</label>
                        <select v-model="carrera.tipo_prof" class="browser-default">
                            <option value="S">Si</option>
                            <option value="N">No</option>
                        </select>                    
                    </div>
                </div>
                <div class="row">
                    <div class="col m3">
                        <button type="submit" class="btn blue-ugb">{{(editando ? 'Actualizar':'Guardar')}}</button>
                        <button v-show="editando" @click="limpiar" type="submit" class="btn orange darken-4">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <form @submit.prevent="getCarreras()">
            <div class="col m4">  
                <input type="text" v-model="search_term">
            </div>
            <div class="col m2">  
                <button type="submit" v-bind:disabled="searching" class="btn blue-ugb">Buscar</button>
            </div>
        </form>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table bordered striped tbl-condensed responsive-table" style="font-size:12px">
                <thead>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Prefijo</th>
                    <th>Tipo</th>
                    <th>Modalidad</th>
                    <th>Profesorado</th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="carrera in carreras" v-bind:key="carrera">
                        <td class="center">{{carrera.car_codigo}}</td>
                        <td><a href="#!" v-bind:title="('Ver planes de ' + carrera.car_nombre)" @click.prevent="verPlanes(carrera.car_codigo)">{{carrera.car_nombre}}</a></td>
                        <td>{{carrera.car_nombre_corto}}</td>
                        <td class="center">{{carrera.tipo}}</td>
                        <td class="center">{{carrera.car_virtual}}</td>
                        <td class="center">{{carrera.tipo_prof}}</td>
                        <td class="center"><a title="Editar carrera" href="#!" @click.prevent="editar(carrera.car_codigo)"><i class="material-icons">create</i></a></td>
                        <td class="center"><a title="Eliminar carrera" href="#!" @click.prevent="eliminar(carrera.car_codigo)"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <ul class="pagination">
                    <li v-for="p in total_pages" v-bind:class="(p == current_page ? 'active green':'')" v-bind:key="p"><a href="#!" @click.prevent="getCarreras(p)">{{p}}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <PlanesComponent ref="planesComponent"></PlanesComponent>
</template>

<script>
import PlanesComponent from '@/components/PlanesComponent.vue';

    export default{
        name: 'Carreras',
        components:{
            PlanesComponent
        },
        data(){
            return {
                carreras: [],
                carrera: {},
                editando: false,
                total_pages: 0,
                current_page: 1,
                search_term: '',
                searching: false
            }
        },
        created()
        {
            this.getCarreras();
        },
        methods:{
            editar(id)
            {
                this.carrera = this.carreras.find(x=>x.car_codigo == id);
                this.editando = true;
                window.scrollTo(0, 0);
            },
            limpiar()
            {
                this.carrera = {};
                this.editando = false;
            },
            verPlanes(codcar)
            {
                this.$refs.planesComponent.getPlanes(codcar);
            },
            getCarreras(p)
            {
                this.searching = true;
                this.$root.startLoading();
                this.axios.get('/Carreras?p=' + (p || 1) + '&search_term=' + this.search_term)
                .then(response => {
                    this.carreras = response.data.records;
                    this.total_pages = response.data.total_pages;
                    this.current_page = response.data.current_page;
                    this.searching = false;
                })
                .catch(() => {
                    //var data = error.response.data;
                })
                .then(() => {
                    this.$root.stopLoading();
                });
            }
        }
    }
</script>
