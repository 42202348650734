<template>
    <div class="row" v-if="display_data">
        <div class="col m1">
            <label>Grupo</label>
            <p>{{ grupo.hor_codgrh }}</p>
        </div>
        <div class="col m3">
            <label>Facultad</label>
            <p>{{ grupo.fac_nombre }}</p>
        </div>
        <div class="col m4">
            <label>Carrera</label>
            <p>{{ grupo.car_nombre }}</p>
        </div>
        <div class="col m1">
            <label>Plan</label>
            <p>{{ grupo.pla_nombre }}</p>
        </div>
        <div class="col m1">
            <label>Ciclo Plan</label>
            <p>{{ grupo.plm_ciclo }}</p>
        </div>        
    </div>

    <div class="row">
        <div class="col m3">
            <label>Grupo</label>
            <select class="browser-default" v-model="codgrh_nuevo">
                <option v-for="grupo in grupos_horario" :key="grupo.grh_codigo" :value="grupo.grh_codigo">{{ grupo.grh_descripcion }}</option>
            </select>
            <button class="btn blue-ugb btn-small" @click="cambiarGrupo()">Cambiar Grupo</button>
        </div>
    </div>

    <div class="row" v-show="detalle_grupo.sin_detalle != undefined && detalle_grupo.sin_detalle.length > 0">
        <div class="col m12">
            <ul class="collection m0 p0" style="font-size: 12px">
                <li class="collection-item red lighten-3">SIN DETALLE DE HORARIO</li>
                <li class="collection-item" v-for="materia in detalle_grupo.sin_detalle" :key="materia">
                    <div>
                        {{ materia.mat_codigo }} - {{ materia.mat_nombre }}
                        <a href="#!" @click="editarHorario(materia.hor_codigo)" class="secondary-content"><i class="material-icons">edit</i></a>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="row" v-show="detalle_grupo.sin_detalle != undefined && detalle_grupo.sin_encabezado.length > 0">
        <div class="col m12">
            <ul class="collection m0 p0" style="font-size: 12px">
                <li class="collection-item red lighten-3 p3" @click="crearEncabezados()">MATERIAS DEL CICLO SIN GRUPO <a href="#!" class="btn blue-ugb btn-small right">Crear Grupos</a></li>
                <li class="collection-item p3" v-for="materia in detalle_grupo.sin_encabezado" :key="materia">{{ materia.mat_codigo }} - {{ materia.mat_nombre }} (Estándar: {{ materia.prc_estandar }})</li>
            </ul>
        </div>
    </div>

    <div class="row" v-show="detalle_grupo.con_detalle != undefined && detalle_grupo.con_detalle.length > 0">
        <div class="col m12">
            <ul class="collection m0 p0" style="font-size: 12px">
                <li class="collection-item green darken-2 white-text p3">MATERIAS CON HORARIO <label class="secondary-content"><input type="checkbox" @click="publicarGrupo($event.target.checked)" class="filled-in"><span></span></label></li>
                <li class="collection-item p3" v-for="materia in detalle_grupo.con_detalle" :key="materia">
                    <a href="#!" @click="eliminarGrupo(materia.hor_codigo)"><i class="material-icons">delete</i></a>
                    {{ materia.mat_codigo }} - {{ materia.mat_nombre }}
                    <label class="secondary-content"><input type="checkbox" :checked="materia.hor_publicado" @click="publicarHorario(materia.hor_codigo, $event.target.checked)" class="filled-in"><span></span></label>                    
                </li>
            </ul>
        </div>
    </div>

    <div class="row" style="display: none;">
        <div class="col m12">
            <button class="btn blue-ugb btn-small">Publicar</button>
            <button class="btn red darken-3 btn-small">Ocultar</button>
        </div>
    </div>

    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px; width: 100%;">
                <caption v-if="isGenerador || isAdmin">
                    <label class="left"><input type="checkbox" v-model="todas_aulas" class="filled-in"><span>Todos los bloques</span></label>
                    <button class="btn red darken-2 btn-small right" @click="regenerarModal()">Regenerar Personalizado</button>
                    <button class="btn green darken-2 btn-small right" @click="regenerar()">Regenerar Rápido</button>                    
                </caption>
                <thead>
                    <tr>
                        <th style="width: 5%">Bloque</th>
                        <th style="width: 13%">Lunes</th>
                        <th style="width: 13%">Martes</th>
                        <th style="width: 13%">Miércoles</th>
                        <th style="width: 13%">Jueves</th>
                        <th style="width: 13%">Viernes</th>
                        <th style="width: 13%">Sábado</th>
                        <th style="width: 13%">Domingo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="bloque in detalle_grupo.bloques" :key="bloque">
                        <td class="center" style="height: 100px">{{ bloque.man_hor_ini }} <br> {{ bloque.man_hor_fin }}</td>
                        <td v-for="i in 7" :key="i" class="center" style="vertical-align: top">
                            <template v-if="permitirBloque(i, bloque.man_codigo)">                
                                <div v-if="selected_dho > 0" style="vertical-align: middle;">
                                        <a href="#!" class="btn green darken-2 btn-small" @click="selectMover(i, bloque.man_codigo)" v-show="selected_dia == 0 && selected_codman == 0" style="width: 100%; font-size:10px; padding: 0px; margin: 0px">{{ getDia(i) }}: {{ bloque.man_hor_ini }}-{{ bloque.man_hor_fin }}</a>
                                        <br>
                                        <div v-if="selected_dia == i && selected_codman == bloque.man_codigo">
                                        <select class="browser-default" v-model="selected_aula">
                                            <option value="0">Seleccione</option>
                                            <option v-for="aula in aulas_disponibles" :key="aula" :value="aula.aul_codigo">{{ aula.edi_nombre }} - {{ aula.aul_nombre_corto }} (Cap. {{ aula.aul_capacidad }})</option>
                                        </select>
                                        <br>
                                        <button class="btn blue-ugb btn-small" style="width: 100%" @click="moverDetalle()" :disabled="selected_aula == 0">Mover</button>
                                    </div>
                                </div>

                                <ul style="padding: 0px; margin:0px">
                                    <li v-for="horario in getHorarios(i, bloque.man_codigo)" :key="horario" v-bind:class="{'red lighten-4': horario.dho_codigo == selected_dho}" style="padding: 2px">
                                        <div style="width: 100%; background-color:#e9f0f7; border-radius: 2px; font-size: 10px;">
                                            <span>{{ horario.mat_codigo }} - {{ horario.mat_nombre }}</span>
                                        </div>
                                        
                                        <div>
                                            <span class="badge new" v-bind:style="{'background-color': colores_materia[horario.mat_codigo]}" data-badge-caption="" style="width: 100%; border-radius: 0px;">{{ horario.aul_nombre_corto }}</span>
                                                {{ horario.dho_tipo == 'T' ? 'Teoría':'Prác. Grp. ' + (horario.dho_gr_prac || '') }}  <br> {{ horario.aul_lab == 'S' ? 'Laboratorio':'' }}
                                        </div>
                                        <a href="#!" v-show="this.selected_dho == 0 || this.selected_dho != horario.dho_codigo" v-on:click="selectDetalle(horario.dho_codigo)"><i class="material-icons">swap_vertical_circle</i></a>
                                        <a href="#!" v-show="this.selected_dho == horario.dho_codigo" @click="selected_dho = 0" class="red-text"><i class="material-icons">cancel</i></a>
                                    </li>
                                </ul>
                            </template>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <ModificarHorario ref="modificarHorario"></ModificarHorario>

    <div class="modal" id="modal-seleccionar-dias-bloques">
        <div class="modal-content">
            <div class="row">
                <span class="badge new" data-badge-caption="">Seleccione al menos {{ materias.length * 2 }} bloques.</span>
                <span v-show="(materias.length * 2) > dias_horas.length" class="badge new" data-badge-caption="">Bloques restantes {{ (materias.length * 2) - dias_horas.length }} bloques.</span>
            </div>
            <div class="row">
                <table class="table tbl-bordered tbl-condensed" style="font-size: 12px;">
                    <thead>
                        <tr>
                            <th style="width: 5%">Bloque</th>
                            <th style="width: 13%">Lunes</th>
                            <th style="width: 13%">Martes</th>
                            <th style="width: 13%">Miércoles</th>
                            <th style="width: 13%">Jueves</th>
                            <th style="width: 13%">Viernes</th>
                            <th style="width: 13%">Sábado</th>
                            <th style="width: 13%">Domingo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="bloque in detalle_grupo.bloques" :key="bloque">
                            <td class="center" style="height: 20px">{{ bloque.man_hor_ini }} <br> {{ bloque.man_hor_fin }}</td>
                            <td v-for="i in 7" :key="i" class="center" style="vertical-align: top; cursor:pointer" @click="seleccionarBloque(i, bloque.man_codigo)" v-bind:class="{red: dias_horas.find(x=>x.dia == i && x.codman == bloque.man_codigo) != undefined}">
                                <div v-if="!permitirBloque(i, bloque.man_codigo)">
                                    NO
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <button class="btn blue-ugb" @click="regenerar()">REGENERAR</button>
            </div>
        </div>
    </div>
</template>

<script>
    import ModificarHorario from '../components/ModificarHorarioComponent'
    import M from 'materialize-css'
    export default {
        components: {
            ModificarHorario
        },
        data()
        {
            return {
                detalle_grupo: [],
                selected_dho: 0,
                selected_dia: 0,
                selected_codman: 0,
                selected_aula: 0,
                aulas_disponibles: [],
                codpla: 0,
                ciclo: 0,
                codgrh: '',
                codgrh_nuevo: '',
                display_data: false,
                grupo: {},
                modales:[],
                grupos_horario: [],
                materias: [],
                colors: ['#16A085', '#8E44AD', '#2C3E50', '#F39C12', '#C0392B'],
                colores_materia: [],
                bloques_regional: [],
                modal_dia_horas: {},
                dias_horas: [],
                todas_aulas: false
            }
        },
        async created()
        {
            this.codpla = isNaN(this.$route.params.codpla) ? undefined:parseInt(this.$route.params.codpla);
            this.ciclo = isNaN(this.$route.params.ciclo) ? undefined:parseInt(this.$route.params.ciclo);
            this.codgrh = this.$route.params.codgrh;
            this.codgrh_nuevo = this.codgrh;
            if(this.codpla != undefined)
            {
                await this.init(this.codpla, this.ciclo, this.codgrh, true);
            }
        },
        async mounted()
        {
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
            await this.getGruposHorario();
        },
        methods: {
            async init(codpla, ciclo, codgrh, display_data)
            {
                this.display_data = display_data || false;
                this.selected_dho = 0;
                this.codpla = codpla;
                this.ciclo = ciclo;
                this.codgrh = codgrh;
                this.codgrh_nuevo = codgrh;
                this.$root.startLoading();
                await this.axios.get('/MateriasAutorizadas/DetalleGrupo/' + codpla + '/' + ciclo + '/' + codgrh)
                .then(response => {
                    this.detalle_grupo = response.data;
                    this.grupo = response.data.grupo || {};
                    this.materias = response.data.materias;
                    this.bloques_regional = response.data.bloques_regional;
                    this.colores_materia = [];
                    for(let i = 0; i <= this.materias.length - 1; i++)
                    {
                        this.colores_materia[this.materias[i].mat_codigo] = this.colors[i];
                    }
                    console.log(this.colores_materia);
                });
                this.$root.stopLoading();
            },
            selectDetalle(coddho)
            {
                this.selected_codman = 0;
                this.selected_aula = 0;
                this.selected_dia = 0;
                this.selected_dho = coddho;
            },
            async selectMover(dia, codman)
            {
                this.selected_dia = dia;
                this.selected_codman = codman;
                this.selected_aula = 0;
                this.$root.startLoading();
                await this.axios.get('/Aulas/' + this.selected_dia + '/' + this.selected_codman + '/Disponibles/' + this.selected_dho)
                .then(response => {
                    this.aulas_disponibles = response.data;
                });
                this.$root.stopLoading();
            },
            getHorarios(dia, bloque)
            {
                var result = this.detalle_grupo.horarios.find(x=>x.dho_dia == dia);
                if(result == undefined)
                {
                    return [];
                }
                return result.detalle.filter(x=>x.man_codigo == bloque);
            },
            getDia(dia)
            {
                switch(dia)
                {
                    case 1: return 'Lun';
                    case 2: return 'Mar';
                    case 3: return 'Mie';
                    case 4: return 'Jue';
                    case 5: return 'Vie';
                    case 6: return 'Sab';
                    case 7: return 'Dom';
                }
            },
            async moverDetalle()
            {
                if(!confirm('¿Desea mover el bloque?'))
                {
                    console.log(this.selected_aula);
                    return false;
                }
                var payload = {
                    dia: this.selected_dia,
                    codman: this.selected_codman,
                    aula: this.selected_aula
                }
                this.$root.startLoading();
                await this.axios.patch('/Horarios/' + this.selected_dho + '/Mover', payload)
                .then(async () => {
                    this.init(this.codpla, this.ciclo, this.codgrh);
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async editarHorario(codhor)
            {
                this.$root.startLoading();
                await this.$refs.modificarHorario.getHorario(codhor, 0, 0, 0, 0, this.codpla, this.codmat, false, 'S');
                this.$root.stopLoading();
            },
            async getGruposHorario()
            {
                this.$root.startLoading();
                await this.axios.get('/GrupoHorario')
                .then(response => {
                    this.grupos_horario = response.data.filter(x=>x.grh_codigo != 'Au');
                });
                this.$root.stopLoading();
            },
            async cambiarGrupo()
            {
                if(!confirm('¿Desea cambiar el identificador de grupo?\nSi ya existe este grupo para el mismo ciclo de estudio no podrá ser cambiado.')) return false;
                this.$root.startLoading();
                await this.axios.patch('/Horarios/' + this.codpla + '/' + this.ciclo + '/' + this.codgrh + '/CambiarGrupo/' + this.codgrh_nuevo)
                .then(async () => {
                    await this.regenerar();
                    await this.init(this.codpla, this.ciclo, this.codgrh_nuevo, false);
                    //this.$router.push('/Grupo/' + this.codpla + '/' + this.ciclo + '/' + this.codgrh_nuevo);
                    
                    M.toast({html: 'El identificador de grupo ha sido cambiado.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                    this.codgrh_nuevo = this.codgrh;
                });
                this.$root.stopLoading();
            },
            async regenerar()
            {   
                this.$root.startLoading();
                await this.axios.post('/MateriasAutorizadas/AgrupadosCicloGrupo/0/' + this.codgrh + '/0/'+ this.codpla +'?all=' + this.todas_aulas + '&regenerate=' + true, this.dias_horas)
                .then(async () => {
                    await this.init(this.codpla, this.ciclo, this.codgrh_nuevo, false);
                });
                this.$root.stopLoading();
            },
            regenerarModal()
            {
                this.dias_horas = [];
                this.modal_dia_horas = M.Modal.getInstance(document.querySelector('#modal-seleccionar-dias-bloques'));
                this.modal_dia_horas.open();
            },
            permitirBloque(dia, codman)
            {
                return this.bloques_regional.find(x=>x.dh_codigo == dia && x.man_codigo == codman) != undefined;
            },
            seleccionarBloque(dia, codman)
            {
                var index = this.dias_horas.findIndex(x=>x.dia == dia && x.codman == codman);
                if(index != -1)
                {
                    this.dias_horas.splice(index, 1);
                }
                else
                {
                    this.dias_horas.push({
                        dia: dia,
                        codman: codman
                    });
                }
            },
            async crearEncabezados()
            {
                this.$root.startLoading();
                await this.axios.post('/Horarios/' + this.codpla + '/' + this.ciclo + '/' + this.codgrh + '/Multiple', this.detalle_grupo.sin_encabezado)
                .then(async () => {
                    await this.init(this.codpla, this.ciclo, this.codgrh_nuevo, false);
                });
                this.$root.stopLoading();
            },
            async publicarHorario(codhor, publicado)
            {
                this.$root.startLoading();
                await this.axios.patch('/Horarios/' + codhor + '/Estado/' + publicado)
                .then(() => {
                    if(this.$parent.setPublicadoHorario != undefined)
                        this.$parent.setPublicadoHorario(codhor, publicado);
                });
                this.$root.stopLoading();
            },
            async publicarGrupo(publicado)
            {
                for(let i = 0; i <= this.detalle_grupo.con_detalle.length - 1; i++)
                {
                    await this.publicarHorario(this.detalle_grupo.con_detalle[i].hor_codigo, publicado)
                    .then(() => {
                        this.detalle_grupo.con_detalle[i].hor_publicado = publicado;
                    });
                }
            },
            async eliminarGrupo(codhor)
            {
                if(!confirm('¿Desea eliminar el horario programado para esta materia?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Horarios/' + codhor)
                .then(async () => {
                    await this.init(this.codpla, this.ciclo, this.codgrh_nuevo, false);
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            isGenerador: function()
            {
                return localStorage.getItem('is_generador') == 'S';
            },
            isAdmin: function()
            {
                return localStorage.getItem('is_admin') == 'S';
            }
        }
    }
</script>