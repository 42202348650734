<template>
  <div style="min-height:400px">
    <div class="row center" style="padding-top:50px">
      <img src="@/assets/logologin.png">
      <div class="clearfix"></div>
      <span style="font-size:30px; padding:5px; border-radius:5px">Universidad Gerardo Barrios</span>
      <br>
      <label>Líderes en Gestión del Conocimiento</label>
    </div>
  </div>
</template>

<script>
  export default {
    async mounted()
    {
      await this.axios.head('/Login/Status/')
      .then(() => {});
      this.$root.stopLoading();
    }
  }
</script>