import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'materialize-css/dist/css/materialize.css'
import 'materialize-css/dist/js/materialize.js'
import './assets/css/style.css'
import M from 'materialize-css'
import validation from './plugins/validations'

//axios.defaults.baseURL = 'https://apiplanner.ugb.edu.sv/';
//axios.defaults.baseURL = 'https://localhost:5001/';

axios.defaults.baseURL = process.env.VUE_APP_ENV_VARIABLE

var token = localStorage.getItem('token');
if(token)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

axios.interceptors.response.use(function (response) {
    return response
    }, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        localStorage.removeItem('regional');
        localStorage.removeItem('codigo');
        localStorage.removeItem('nombre');
        localStorage.removeItem('codcic');
        localStorage.removeItem('anio');    
        localStorage.removeItem('ciclo');
        localStorage.removeItem('ciclo');
        localStorage.removeItem('is_logged'); 
        localStorage.removeItem('is_admin');   
        localStorage.removeItem('is_academica');   
        router.push('/Login')
    }
    if(error.response.status === 403)
    {
        router.push('/');
    }
    if(error.response.status === 500)
    {
        M.toast({html: 'Ha ocurrido un error de conexión al servidor.'});
    }
    return Promise.reject(error)
})

createApp(App).use(router).use(validation).use(VueAxios, axios).mount('#app')