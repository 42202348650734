<template>
    <div>
        <div class="row">
            <div class="col m6 card-panel">
                <div class="row">
                    <div class="col m10">
                        <label>Carrera</label>
                        <select class="browser-default" v-model="proceso.pgr_codcar">
                            <option value="0">Seleccione</option>
                            <option v-for="carrera in carreras" :key="carrera" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
                        </select>
                        <small>{{$validation(validations_proceso, "pgr_codcar")}}</small>
                    </div>
                </div>
                <div class="row">
                    <div class="col m5">
                        <label>Tipo</label>
                        <select class="browser-default" v-model="proceso.pgr_codtpg" @change="setTipoProceso()">
                            <option value="">Seleccione</option>
                            <option v-for="tipo in tipos_proceso" :key="tipo.tpg_codigo" :value="tipo.tpg_codigo">{{tipo.tpg_descripcion}}</option>
                        </select>
                        <small>{{$validation(validations_proceso, "pgr_codtpg")}}</small>
                    </div>
                    <div class="col m7" v-show="proceso.pgr_codtpg == 'P'">
                        <label>Descripción</label>
                        <input type="text" v-model="proceso.pgr_descripcion" />
                        <small>{{$validation(validations_proceso, "pgr_descripcion")}}</small>
                    </div>                   
                </div>
                <div class="row">
                    <div class=" col m4">
                        <label>Matrícula Base</label>
                        <input type="text" v-model="proceso.pgr_matricula" />
                        <small>{{$validation(validations_proceso, "pgr_matricula")}}</small>
                    </div>
                    <div class=" col m4">
                        <label>Cuota Base</label>
                        <input type="text" v-model="proceso.pgr_cuota" />
                        <small>{{$validation(validations_proceso, "pgr_cuota")}}</small>
                    </div>
                    <div class=" col m4">
                        <label>Cant. Cuotas Base</label>
                        <input type="number" min="1" max="12" v-model="proceso.pgr_cant_cuotas" />
                        <small>{{$validation(validations_proceso, "pgr_cant_cuotas")}}</small>
                    </div>
                </div>
                <div class="row">
                    <div class="col m12">
                        <button class="btn blue-ugb" @click="setProceso()">{{proceso.pgr_codigo == 0 ? 'Guardar':'Actualizar'}}</button>
                        <button class="btn green darken-2" v-show="proceso.pgr_codigo > 0" @click="resetProceso()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>      
       
        <div class="row">
            
            <div class="col m12 card-panel">
                <div class="row">
                    <div class="col m3">
                        <label>Carrera</label>
                        <select v-model="codcar" class="browser-default">
                            <option value="0">Todas</option>
                            <option v-for="carrera in carreras_autorizadas" :key="carrera.car_codigo" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Tipo</label>
                        <select class="browser-default" v-model="codtpg">
                            <option value="0">Todos</option>
                            <option v-for="tipo in tipos_procesos" :key="tipo" :value="tipo.tpg_codigo">{{tipo.tpg_descripcion}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Estado</label>
                        <select class="browser-default" v-model="autorizado">
                            <option value="-1">Todos</option>
                            <option value="0">Sin Autorizar</option>
                            <option value="1">Autorizados</option>
                        </select>
                    </div>
                    <div class="col m3">
                        <label>Buscar</label>
                        <input type="text" v-model="search">
                    </div>
                </div>
                <table class="table tbl-condensed bordered tbl-bordered" style="font-size:12px">
                    <thead>
                        <tr>
                            <th>Fecha<br/>Creación</th>
                            <th>Carrera</th>
                            <th>Tipo</th>
                            <th>Descripción</th>
                            <th>Matrícula Base</th>
                            <th>Cuota Base</th>
                            <th>Cant. Cuotas</th>
                            <th>Módulos</th>
                            <th>Autorizar<br/>{{getCiclo()}}</th>
                            <th colspan="2"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(_proceso, index) in _procesos" :key="_proceso" v-bind:class="{'grey lighten-2': index == index_proceso}">
                            <td>{{_proceso.pgr_fecha}}</td>
                            <td>{{_proceso.car_nombre}}</td>
                            <td>{{_proceso.tpg_descripcion}}</td>
                            <td>{{_proceso.pgr_descripcion}}</td>
                            <td style="text-align:right">${{_proceso.pgr_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td style="text-align:right">${{_proceso.pgr_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td class="center">{{_proceso.pgr_cant_cuotas}}</td>
                            <td class="center" style="position:relative">
                                <div v-show="_proceso.pgr_codtpg == 'P'">
                                    {{_proceso.pgr_mod_modulos.length}}
                                    <a href="#!" @click="modalModulos(index)" style="position:absolute; top: 0px; right: 0px"><i class="material-icons">add_circle</i></a>
                                </div>
                            </td>
                            <td class="center">
                                <div v-show="_proceso.pgr_mod_modulos.length > 0 && _proceso.pgr_codtpg == 'P'">
                                    <button v-show="!isProcesoAutorizado(_proceso.pgr_codigo)" @click="autorizarModal(index)" class="btn orange darken-4"><i class="material-icons">assignment</i></button>
                                    <button v-show="isProcesoAutorizado(_proceso.pgr_codigo)" @click="autorizarModal(index)" class="btn green darken-1"><i class="material-icons">check_circle</i></button>
                                </div>
                                <div v-show="_proceso.pgr_codtpg != 'P'">
                                    <button type="button" @click="autorizarModal(index)" v-show="!isProcesoAutorizado(_proceso.pgr_codigo)" class="btn orange darken-4"><i class="material-icons">add_circle</i></button>
                                    <button type="button" @click="autorizarModal(index)" v-show="isProcesoAutorizado(_proceso.pgr_codigo)" class="btn green darken-1"><i class="material-icons">check_circle</i></button>
                                </div>
                            </td>
                            <td class="center"><a href="#!" @click="editarProceso(index)"><i class="material-icons">edit</i></a></td>
                            <td class="center"><a href="#!" @click="eliminarProceso(_proceso.pgr_codigo)"><i class="material-icons">delete</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal" id="modal-modulos-proceso">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content">
            <div class="row">
                <div class="col m9">
                    <label>Carrera</label>
                    <p>{{proceso.car_nombre}}</p>
                </div>
                <div class="col m3">
                    <label>Tipo</label>
                    <p>{{proceso.tpg_descripcion}}</p>
                </div>
                <div class="col m6">
                    <label>Descripción</label>
                    <p>{{proceso.pgr_descripcion}}</p>
                </div>
                <div class="col m2">
                    <label>Matrícula</label>
                    <p>${{proceso.pgr_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</p>                    
                </div>
                <div class="col m2">
                    <label>Cuota</label>
                    <p>${{proceso.pgr_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</p>
                </div>
                <div class="col m2">
                    <label>Cant. Cuotas</label>
                    <p>{{proceso.pgr_cant_cuotas}}</p>                    
                </div>
            </div>
            <div class="row card-panel" style="padding:10px">
                <form @submit.prevent="setModulo()">
                    <div class="row">
                        <div class="col m12">
                            <label>Nombre del Módulo</label>
                            <input type="text" v-model="modulo.mod_nombre" />
                            <small>{{$validation(validations_modulo, 'mod_nombre')}}</small>
                        </div>                    
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb" type="submit">{{modulo.mod_codigo == 0 ? 'Guardar':'Actualizar'}}</button>
                            <button class="btn green darken-1" @click="resetModulo()" type="button">Cancelar</button>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th colspan="2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(modulo, index) in proceso.pgr_mod_modulos" :key="modulo" v-bind:class="{'grey lighten-2': index == index_modulo}">
                                <td style="width: 80%">{{modulo.mod_nombre}}</td>
                                <td style="width: 10%" class="center"><a href="#!" @click="editarModulo(index)"><i class="material-icons">edit</i></a></td>
                                <td style="width: 10%" class="center"><a href="#!" @click="eliminarModulo(modulo.mod_codigo)"><i class="material-icons">delete</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="modal-autorizacion-proceso" style="width: 85%">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content">
                <div class="row m0 p0">
                <div class="row">
                    <div class="row m0 p0">
                        <div class="col m3">
                            <label>Estado</label>
                            <p v-if="isProcesoAutorizado(this.proceso.pgr_codigo)"><span data-badge-caption="" class="left new badge green darken-3">AUTORIZADO</span></p>
                            <p v-if="!isProcesoAutorizado(this.proceso.pgr_codigo)"><span data-badge-caption="" class="left new badge orange darken-4">SIN AUTORIZAR</span></p>
                        </div>
                        <div class="col m6">
                            <label>Carrera</label>
                            <p>{{proceso.car_nombre}}</p>
                        </div>
                        <div class="col m3">
                            <label>Tipo</label>
                            <p>{{proceso.tpg_descripcion}}</p>
                        </div>
                    </div>
                    <div class="row m0 p0">
                        <div class="col m6">
                            <div class="col m12">
                                <label>Descripción</label>
                                <p>{{proceso.pgr_descripcion}}</p>
                            </div>
                            <div class="col m12">
                                <div class="row m0 p0">
                                    <button type="button" class="btn blue-ugb" @click="setAutorizado()" v-show="!isProcesoAutorizado(this.proceso.pgr_codigo)">AUTORIZAR EN {{getCiclo()}}*</button>
                                    <button type="button" class="btn red darken-4" @click="setDesautorizado()" v-show="isProcesoAutorizado(this.proceso.pgr_codigo)">DESAUTORIZAR EN {{getCiclo()}}</button>
                                </div>
                                <small v-show="!isProcesoAutorizado(this.proceso.pgr_codigo)">*Una vez autorizado no podrá realizar cambios en el proceso.</small>
                            </div>         
                        </div>
                        <div class="col m6 grey lighten-3" style="padding:20px; border: dashed 2px black">
                            <div class="row m0 p0">
                                <div class="col m4">
                                    <label>Matrícula</label>
                                    <input type="text" v-model="proceso_autorizado.aut_matricula" />
                                </div>
                                <div class="col m4">
                                    <label>Cuota</label>
                                    <input type="text" v-model="proceso_autorizado.aut_cuota" />
                                </div>
                                <div class="col m4">
                                    <label>Cant. Cuotas</label>
                                    <input type="number" min="1" max="12" v-model="proceso_autorizado.aut_cant_cuotas" />
                                </div>
                            </div>
                            <div class="row m0 p0">
                                <div class="col m12">
                                    <button type="button" class="btn blue-ugb" @click="setCosto()" v-show="isProcesoAutorizado(this.proceso.pgr_codigo)">ACTUALIZAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m0 p0" v-if="proceso.pgr_codtpg == 'P' && isProcesoAutorizado(this.proceso.pgr_codigo)">
                    <div class="col m6" style="border: solid 1px black; padding: 10px">
                        <div class="row">
                            <div class="col m12">
                                <input type="text" placeholder="Buscar..." v-model="buscar_modulo_registrado" />
                            </div>
                        </div>
                        <div style="height: 200px; overflow:auto">
                            <table class="table tbl-condensed tbl-bordered" style="font-size:12px; width: 99%">
                                <caption>MÓDULOS REGISTRADOS</caption>
                                <thead>
                                    <tr>
                                        <th>Módulo</th>
                                        <th class="center">Autorizar</th>
                                    </tr>
                                </thead>                           
                                <tbody>
                                    <tr v-for="(modulo, index) in _modulos_registrados" :key="modulo" v-bind:class="{'grey lighten-2': index == index_modulo}">
                                        <td style="width: 80%">{{modulo.mod_nombre}}</td>
                                        <td class="center"><label><input :checked="isModuloAutorizado(modulo.mod_codigo)" @click="autorizarModulo($event, modulo.mod_codigo)" type="checkbox" class="filled-in" /><span></span></label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col m6">
                        <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                            <caption>MÓDULOS AUTORIZADOS</caption>
                            <thead>
                                <tr>
                                    <th>Módulo</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(modulo, index) in proceso_autorizado.pgr_moa_modulos_autorizados" :key="modulo">
                                    <td><b>Mód. {{index+1}}:</b> {{modulo.pgr_mod_modulos.mod_nombre}}</td>
                                    <td class="center">
                                        <a href="#!" @click="moveOrden(index, 'D')"><i class="material-icons">arrow_drop_up</i></a>
                                        <a href="#!" @click="moveOrden(index, 'U')"><i class="material-icons">arrow_drop_down</i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                carreras: [],
                tipos_proceso: [],
                procesos: [],
                procesos_autorizados: [],
                proceso_autorizado: {},
                proceso: {},
                modulo: {},
                validations_proceso: [],
                validations_modulo: [],
                index_proceso: -1,
                index_modulo: -1,
                modales: [],
                modal_modulos: {},
                carreras_autorizadas: [],
                codcar: 0,
                tipos_procesos: [],
                codtpg: 0,
                autorizado: -1,
                buscar_modulo_registrado: '',
                search: ''
            }
        },
        created()
        {
            this.proceso.pgr_codtpg = '';
            this.proceso.pgr_codcar = 0;
            this.proceso.pgr_codigo = 0;
            this.proceso.pgr_cuota = 0;
            this.proceso.pgr_matricula = 0;
            this.modulo.mod_codigo = 0;
        },
        async mounted(){
           
            await this.getTiposProceso();
            await this.getCarreras();
            await this.getProcesos();
            await this.getProcesosAutorizados();
            await this.getCarrerasAutorizadas();
            await this.getTiposProcesos();
            var elems = document.querySelectorAll('.modal');
            var options = {
                onCloseStart: (modal) => {
                    if(modal.id == 'modal-modulos-proceso'){
                        this.resetProceso();
                    }
                },
                dismissible: false
            };
            this.modales = M.Modal.init(elems, options);
        },
        methods: {
            async getCarreras()
            {
                this.$root.startLoading();
                await this.axios.get('/Carreras/ProcesoGraduacion')
                .then(response => {
                    this.carreras = response.data;
                });
                this.$root.stopLoading();
            },
            async getTiposProceso()
            {
                this.$root.startLoading();
                await this.axios.get('/TipoProcesoGraduacion')
                .then(response => {
                    this.tipos_proceso = response.data.filter(x=>x.tpg_estado);
                });
                this.$root.stopLoading();
            },
            async getProcesos()
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacion')
                .then(response => {
                    this.procesos = response.data;
                });
                this.$root.stopLoading();
            },
            async getProceso(id)
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacion/' + id)
                .then(response => {
                    this.proceso = response.data;
                });
                this.$root.stopLoading();
            },
            async eliminarProceso(id)
            {
                if(!confirm('¿Desea eliminar este proceso?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/ProcesosGraduacion/' + id)
                .then(() => {
                    var index = this.procesos.findIndex(x=>x.pgr_codigo == id);
                    if(index != -1)
                    {
                        this.procesos.splice(index, 1);
                    }
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async setProceso()
            {
                this.validations_proceso = [];
                if(this.proceso.pgr_codtpg != 'P')
                {
                    var index_carrera = this.carreras.findIndex(x=>x.car_codigo == this.proceso.pgr_codcar);
                    if(index_carrera == -1)
                    {
                        M.toast({html: 'Seleccione una carrera.'});
                        return;
                    }
                    var index_tipo = this.tipos_proceso.findIndex(x=>x.tpg_codigo == this.proceso.pgr_codtpg);
                    if(index_tipo == -1)
                    {
                        M.toast({html: 'Seleccione una tipo.'});
                        return;
                    }
                    this.proceso.pgr_descripcion = this.carreras[index_carrera].car_nombre_corto + ' - ' + this.tipos_proceso[index_tipo].tpg_descripcion;
                }
                var payload = {
                    pgr_codigo: this.proceso.pgr_codigo,
                    pgr_descripcion: this.proceso.pgr_descripcion,
                    pgr_codtpg: this.proceso.pgr_codtpg,
                    pgr_codcar: this.proceso.pgr_codcar,
                    pgr_matricula: this.proceso.pgr_matricula,
                    pgr_cuota: this.proceso.pgr_cuota,
                    pgr_cant_cuotas: this.proceso.pgr_cant_cuotas,
                    pgr_usuario: this.proceso.pgr_usuario,
                    pgr_fecha: '2022-01-01-01'
                };
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacion', payload)
                .then(async response => {
                    this.$root.stopLoading();
                    await this.getProceso(response.data.pgr_codigo);
                    await this.getCarrerasAutorizadas();
                    var index_proceso = this.procesos.findIndex(x=>x.pgr_codigo == response.data.pgr_codigo);
                    if(index_proceso == -1)
                    {
                        this.procesos.unshift(this.proceso);
                    }
                    else
                    {
                        this.procesos[index_proceso] = this.proceso;
                    }
                    this.resetProceso();
                    M.toast({html: 'Los cambios han sido aplicados.'});
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations_proceso = error.response.data.modelErrors;
                        M.toast({html: 'Llene todos los campos.'});
                    }
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                    this.$root.stopLoading();
                });
            },
            resetProceso()
            {
                this.proceso = {};
                this.proceso.pgr_codtpg = '';
                this.proceso.pgr_codcar = 0;
                this.proceso.pgr_codigo = 0;
                this.index_proceso = -1;
                this.proceso.pgr_cuota = 0;
                this.proceso.pgr_matricula = 0;
            }, 
            async editarProceso(index)
            {
                this.index_proceso = index;
                this.proceso = this._procesos[index];
            },
            modalModulos(index)
            {
                this.proceso = this._procesos[index];
                this.modal_modulos = M.Modal.getInstance(document.querySelector('#modal-modulos-proceso'));
                this.modal_modulos.open();
            }, 
            async getModulos(id)
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacion/' + id + '/Modulos')
                .then(response => {
                    this.proceso.pgr_mod_modulos = response.data;
                });
                this.$root.stopLoading();
            },
            async setModulo()
            {
                this.modulo.mod_nombre = this.modulo.mod_nombre.trim();
                this.modulo.mod_codpgr = this.proceso.pgr_codigo;
                var payload = {
                                    "mod_codigo": this.modulo.mod_codigo,
                                    "mod_codpgr": this.modulo.mod_codpgr,
                                    "mod_nombre": this.modulo.mod_nombre,
                                    "mod_fecha": "2022-09-23T14:24:12.023",
                                };
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionModulos/', payload)
                .then(response => {
                    this.modulo = response.data;
                    var index_modulo = this.proceso.pgr_mod_modulos.findIndex(x=>x.mod_codigo == this.modulo.mod_codigo);
                    if(index_modulo == -1)
                    {
                        this.proceso.pgr_mod_modulos.push(this.modulo);
                    }
                    else
                    {
                        this.proceso.pgr_mod_modulos[index_modulo] = this.modulo;
                    }
                    this.resetModulo();
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations_modulo = error.response.data.modelErrors;
                    }
                });
                this.$root.stopLoading();
            },
            resetModulo()
            {
                this.modulo = {};
                this.modulo.mod_codigo = 0;
                this.modulo.mod_nombre = '';
                this.index_modulo = -1;
            },
            async eliminarModulo(id)
            {
                this.$root.startLoading();
                await this.axios.delete('/ProcesosGraduacionModulos/' + id)
                .then(() => {
                    var index_modulo = this.proceso.pgr_mod_modulos.findIndex(x=>x.mod_codigo == id);
                    if(index_modulo != -1)
                    {
                        this.proceso.pgr_mod_modulos.splice(index_modulo, 1);
                    }
                    this.resetModulo();
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            editarModulo(index)
            {
                this.index_modulo = index;
                this.modulo = this.proceso.pgr_mod_modulos[index];
            },
            setTipoProceso()
            {
                if(this.proceso.pgr_codtpg == 'P') this.proceso.pgr_descripcion = '';
            },
            async getProcesosAutorizados()
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionAutorizacion')
                .then(response => {
                    this.procesos_autorizados = response.data;
                });
                this.$root.stopLoading();
            },
            isProcesoAutorizado(id)
            {
                return this.procesos_autorizados.find(x=>x.aut_codpgr == id) != undefined;
            },
            autorizarModal(index)
            {
                this.proceso = this._procesos[index];
                this.proceso_autorizado = this.procesos_autorizados.find(x=>x.aut_codpgr == this.proceso.pgr_codigo);
                if(this.proceso_autorizado == undefined)
                {
                    this.proceso_autorizado = {};
                    this.proceso_autorizado.pgr_moa_modulos_autorizados = [];
                }
                this.proceso_autorizado.aut_matricula = this.isProcesoAutorizado(this.proceso.pgr_codigo) ? this.proceso_autorizado.aut_matricula:this.proceso.pgr_matricula;
                this.proceso_autorizado.aut_cuota = this.isProcesoAutorizado(this.proceso.pgr_codigo) ? this.proceso_autorizado.aut_cuota:this.proceso.pgr_cuota;
                this.proceso_autorizado.aut_cant_cuotas = this.isProcesoAutorizado(this.proceso.pgr_codigo) ? this.proceso_autorizado.aut_cant_cuotas:this.proceso.pgr_cant_cuotas;
                this.modal_modulos = M.Modal.getInstance(document.querySelector('#modal-autorizacion-proceso'));
                this.modal_modulos.open();
            },
            isModuloAutorizado(id)
            {
                if(this.proceso_autorizado == undefined)
                {
                    this.proceso_autorizado = {};
                    this.proceso_autorizado.pgr_moa_modulos_autorizados = [];
                }
                if(this.proceso_autorizado.pgr_moa_modulos_autorizados == undefined)
                {
                    this.proceso_autorizado.pgr_moa_modulos_autorizados = [];
                }
                return this.proceso_autorizado.pgr_moa_modulos_autorizados.find(x=>x.moa_codmod == id) != undefined;
            },
            async autorizarModulo(event, codmod)
            {
                if(event.target.checked)
                {
                    var modulo = {
                        moa_codigo: 0,
                        moa_codaut: this.proceso_autorizado.aut_codigo,
                        moa_codmod: codmod,
                        moa_orden: this.proceso_autorizado.pgr_moa_modulos_autorizados.length + 1
                    };
                    this.$root.startLoading();
                    await this.axios.post('/ProcesosGraduacionAutorizacionModulo', modulo)
                    .then(async response => {
                        await this.axios.get('/ProcesosGraduacionAutorizacionModulo/' + response.data.moa_codigo)
                        .then(response2 => {
                            this.proceso_autorizado.pgr_moa_modulos_autorizados.push(response2.data);
                        })                        
                    });
                    this.$root.stopLoading();
                }
                else
                {
                    this.$root.startLoading();
                    await this.axios.delete('/ProcesosGraduacionAutorizacionModulo/' + this.proceso_autorizado.aut_codigo + '/' + codmod)
                    .then(() => {
                        var index_modulo_autorizado = this.proceso_autorizado.pgr_moa_modulos_autorizados.findIndex(x=>x.moa_codmod == codmod && x.moa_codaut == this.proceso_autorizado.aut_codigo);
                        this.proceso_autorizado.pgr_moa_modulos_autorizados.splice(index_modulo_autorizado, 1);
                    })
                    .catch(error => {
                        M.toast({html: error.response.data.message});
                        event.target.checked = true;
                    });
                    this.$root.stopLoading();
                }
                await this.setOrden();
            },
            async setOrden()
            {
                var payload = [];
                for(let i = 0; i <= this.proceso_autorizado.pgr_moa_modulos_autorizados.length - 1; i++)
                {
                    this.proceso_autorizado.pgr_moa_modulos_autorizados[i].moa_orden = i + 1;
                    payload.push({
                        moa_codaut: this.proceso_autorizado.pgr_moa_modulos_autorizados[i].moa_codaut,
                        moa_codigo: this.proceso_autorizado.pgr_moa_modulos_autorizados[i].moa_codigo,
                        moa_codmod: this.proceso_autorizado.pgr_moa_modulos_autorizados[i].moa_codmod,
                        moa_fecha: this.proceso_autorizado.pgr_moa_modulos_autorizados[i].moa_fecha,
                        moa_orden: this.proceso_autorizado.pgr_moa_modulos_autorizados[i].moa_orden
                    });
                }
                this.$root.startLoading();
                await this.axios.patch('/ProcesosGraduacionAutorizacionModulo/' + this.proceso_autorizado.aut_codigo + '/Orden', payload)
                .then(() => {

                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async moveOrden(index, where)
            {
                if(this.proceso_autorizado.pgr_moa_modulos_autorizados.length == index + 1 && where == 'U') return;
                if(where == 'D' && index == 0) return;

                var record = this.proceso_autorizado.pgr_moa_modulos_autorizados[index];
                var replace = this.proceso_autorizado.pgr_moa_modulos_autorizados[index + (where == 'U' ? 1:(-1))];

                this.proceso_autorizado.pgr_moa_modulos_autorizados[index] = replace;
                this.proceso_autorizado.pgr_moa_modulos_autorizados[index + (where == 'U' ? 1:(-1))] = record;
                await this.setOrden();
            },
            async autorizarModalSinModulos(index)
            {
                this.proceso = this.procesos[index];
                this.proceso_autorizado = this.procesos_autorizados.find(x=>x.aut_codpgr == this.proceso.pgr_codigo);
                if(this.proceso_autorizado == undefined)
                {
                    this.proceso_autorizado = {};
                    this.proceso_autorizado.pgr_moa_modulos_autorizados = [];
                }
                this.modal_modulos = M.Modal.getInstance(document.querySelector('#modal-autorizacion-proceso'));
                this.modal_modulos.open();
            },
            async setAutorizado()
            {
                if(!confirm('¿Desea autorizar este proceso de graduación?')) return false;
                var autorizacion = {
                    aut_codigo: this.proceso_autorizado.aut_codigo,
                    aut_codreg: 0,
                    aut_codcil: 0,
                    aut_codpgr: this.proceso.pgr_codigo,
                    aut_matricula: this.proceso_autorizado.aut_matricula,
                    aut_cuota: this.proceso_autorizado.aut_cuota,
                    aut_cant_cuotas: this.proceso_autorizado.aut_cant_cuotas
                };
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionAutorizacion', autorizacion)
                .then(response => {
                    this.proceso_autorizado = response.data;
                    this.procesos_autorizados.push(response.data);
                });
                this.$root.stopLoading();
            },
            async setDesautorizado()
            {
                if(!confirm('¿Desea desautorizar este proceso de graduación?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/ProcesosGraduacionAutorizacion/' + this.proceso_autorizado.aut_codigo)
                .then(() => {
                    var index_autorizado = this.procesos_autorizados.findIndex(x=>x.aut_codigo == this.proceso_autorizado.aut_codigo);
                    this.proceso_autorizado.aut_codigo = 0
                    this.procesos_autorizados.splice(index_autorizado, 1);
                })
                .catch(error => {
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async setCosto()
            {
                var payload = {
                    aut_codigo: this.proceso_autorizado.aut_codigo,
                    aut_matricula: this.proceso_autorizado.aut_matricula,
                    aut_cuota: this.proceso_autorizado.aut_cuota,
                    aut_cant_cuotas: this.proceso_autorizado.aut_cant_cuotas
                };
                this.$root.startLoading();
                await this.axios.patch('/ProcesosGraduacionAutorizacion/' + this.proceso_autorizado.aut_codigo + '/Costo', payload)
                .then(() => {
                    M.toast({html: 'El costo ha sido actualizado.'});
                });
                this.$root.stopLoading();
            },
            getCiclo()
            {
                return localStorage.getItem('ciclo');
            },
            async getCarrerasAutorizadas()
            {
                await this.axios.get('/ProcesosGraduacion/Carreras')
                .then(response => {
                    this.carreras_autorizadas = response.data;
                });
            },
            async getTiposProcesos()
            {
                await this.axios.get('/TipoProcesoGraduacion')
                .then(response => {
                    this.tipos_procesos = response.data.filter(x=>x.tpg_estado);
                });
            }
        },
        computed: {
            _procesos() {
            return this.procesos
                        .filter(x=>x.car_codigo == (this.codcar == 0 ? x.car_codigo:this.codcar)
                                && x.tpg_codigo == (this.codtpg == 0 ? x.tpg_codigo:this.codtpg)
                                && (this.autorizado == -1 || (this.isProcesoAutorizado(x.pgr_codigo) == this.autorizado))
                                &&
                                    (
                                        x.car_nombre.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
                                        x.pgr_descripcion.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
                                        x.tpg_descripcion.toLowerCase().indexOf(this.search.toLowerCase()) != -1
                                    )
                                );
            },
            _modulos_registrados()
            {
                return this.proceso.pgr_mod_modulos.filter(x=>x.mod_nombre.toLowerCase().indexOf(this.buscar_modulo_registrado.toLowerCase()) != -1);
            }
        }
    }
</script>