<template>
    <div class="row">
        <div class="col m4">
            <label>Usuario</label>
            <select class="browser-default" v-model="codemp">
                <option value="0">Seleccione</option>
                <option v-for="usuario in _usuarios" :key="usuario" :value="usuario.emp_codigo">{{usuario.emp_apellidos_nombres}}</option>
            </select>
        </div>
        <div class="col m1">
            <br>
            <button class="btn btn-small blue-ugb" @click="setAutorizado()">Agregar</button>
        </div>
    </div>
    <div class="row">
        <div class="col m5">
            <table class="table tbl-condensed tbl-bordered striped" style="font-size: 12px">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="autorizado in autorizados" :key="autorizado">
                        <td>{{autorizado.emp_codigo}}</td>
                        <td>{{autorizado.emp_apellidos_nombres}}</td>
                        <td class="center"><a href="#!" @click="getAccesos(autorizado.emp_codigo)"><i class="material-icons">list</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-accesos-usuario">
        <div class="modal-content">
            <div class="row">
                <div class="col m3">
                    <label>Código</label>
                    <p>{{autorizado.emp_codigo}}</p>
                </div>
                <div class="col m6">
                    <label>Nombre</label>
                    <p>{{autorizado.emp_apellidos_nombres}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
                        <thead>
                            <tr>
                                <th>Opción</th>
                                <th>Activo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="opcion in opciones" :key="opcion">
                                <td>{{opcion.mpg_descripcion}}</td>
                                <td class="center"><label><input type="checkbox" @click="setAccesoOpcion(opcion.mpg_codigo, $event.target.checked)" class="filled-in" :checked="accesos.findIndex(x=>x.apg_codmpg == opcion.mpg_codigo && x.apg_activo) != -1" /><span></span></label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
export default {
    data(){
        return {
            opciones: [],
            usuarios: [],
            accesos: [],
            autorizados: [],
            codemp: 0,
            autorizado: {},
            modales: [],
            modal_opciones: {}
        }
    },
    async mounted()
    {
        await this.getUsuarios();
        await this.getAutorizados();
        await this.getOpciones();
        var elems = document.querySelectorAll('.modal');
        this.modales = M.Modal.init(elems, null);
    },
    methods: {
        async getUsuarios()
        {
            await this.axios.get('/Usuarios')
            .then(response => {
                this.usuarios = response.data;
            });
        },
        async getOpciones()
        {
            await this.axios.get('/ProcesosGraduacionOpciones')
            .then(response => {
                this.opciones = response.data;
            });
        },
        async getAccesos(codemp)
        {
            this.autorizado = this.usuarios.find(x=>x.emp_codigo == codemp);
            await this.axios.get('/Usuarios/' + codemp + '/AccesosProcesoGraduacion')
            .then(response => {
                this.accesos = response.data;
            });
            this.modal_opciones = M.Modal.getInstance(document.querySelector('#modal-accesos-usuario'));
            this.modal_opciones.open();
        },
        async getAutorizados()
        {
            await this.axios.get('/Usuarios/AutorizadosProcesoGraduacion')
            .then(response => {
                this.autorizados = response.data;
            });
        },
        setAutorizado()
        {
            this.autorizado = this.usuarios.find(x=>x.emp_codigo == this.codemp);
            this.autorizados.push({
               emp_codigo: this.autorizado.emp_codigo,
               emp_apellidos_nombres: this.autorizado.emp_apellidos_nombres 
            });
        },
        async setAccesoOpcion(codmpg, estado)
        {
            await this.axios.post('/ProcesosGraduacionOpciones/' + codmpg + '/' + this.autorizado.emp_codigo + '/' + estado)
            .then(async () => {
                await this.getAccesos(this.autorizado.emp_codigo);
            });
        }
    },
    computed: {
        _usuarios()
        {
            return this.usuarios.filter(x=>this.autorizados.findIndex(y=>y.emp_codigo == x.emp_codigo) == -1);
        }
    }
}
</script>
