<template>
    <div class="row">
        <div class="col m3">
            <label>Usuario</label>
            <select class="browser-default" v-model="codusr" @change="getOpciones()">
                <option value="0">Seleccione</option>
                <option v-for="usuario in usuarios" :key="usuario" :value="usuario.usr_codigo">{{ usuario.usr_usuario }}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Tipo</label>
            <select class="browser-default" v-model="tipo_filtro" @change="getOpciones()">
                <option value="0">Todos</option>
                <option value="1">Asignados</option>
                <option value="2">Sin Asignar</option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="col m12">
            <ul class="browser-default">
                <template v-for="opcion in opciones" :key="opcion">
                    <li><label><input type="checkbox" class="filled-in" :checked="opcion.opm_asignado" /><span>{{ opcion.padre_opm_nombre }}</span></label>
                        <ul class="browser-default">
                            <template v-for="hijo in opcion.hijos" :key="hijo">
                                <li>
                                    <label><input type="checkbox" :checked="hijo.opm_asignado" class="filled-in" /><span>{{ hijo.hijo_opm_nombre }}</span></label>
                                    <ul class="browser-default">
                                        <li v-for="menu in hijo.menus" :key="menu"><label><input type="checkbox" class="filled-in" :checked="menu.opm_asignado" @click="setPermiso(menu.menu_opm_codigo, $event.target.checked)" /><span>{{ menu.menu_opm_nombre }}</span></label></li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
    export default 
    {
        data(){
            return {
                opciones: [],
                roles_acceso: [],
                usuario: '',
                tipo_filtro: 0,
                codusr: 0,
                usuarios: []
            }
        },
        async mounted()
        {
            await this.getUsuarios();
        },
        methods: {
            async getOpciones()
            {
                await this.axios.get('/AccesosUOnline/' + this.codusr + '/opciones/' + this.tipo_filtro)
                .then(response => {
                    this.opciones = response.data;
                });
            },
            async getUsuarios()
            {
                await this.axios.get('/AccesosUOnline/Usuarios')
                .then(response => {
                    this.usuarios = response.data;
                });
            },
            async setPermiso(codopm, checked)
            {
                console.log(codopm);
                console.log(checked);
                const payload = {
                    opm_codigo: codopm,
                    opm_checked: checked
                };
                await this.axios.post('/AccesosUOnline/' + this.codusr + '/Permiso', payload)
                .then(async () => {
                    await this.getOpciones();
                });
            }
        }
    }
</script>