<template>
<div class="modal blue-ugb" id="modal-modificar-horario" style="width:85%; max-height:80%; border:2px solid white">
    <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
    <div class="modal-content">
    <div class="row card-panel">
            <input type="hidden" v-model="hor_codigo">
            <div class="col m4">
                <label>Materia</label> <a href="#!" v-if="allow_change_subject" @click="buscarMateria" style="float:right; font-size: 12px; text-decoration:underline">BUSCAR... <i style="font-size:12px" class="material-icons">search</i></a>
                <input type="text" v-model="mat_nombre" disabled>
                <label class="helper-text" data-error="wrong" data-success="right">{{car_nombre}} - {{pla_nombre}}</label>
            </div>
            <div class="col m2">
                <label>Grupo - <a target="_blank" v-bind:href="('/Grupo/' + hor_codpla + '/' + plm_ciclo + '/' + hor_codgrh)">{{ hor_descripcion }} </a></label>
                <select class="browser-default" @change="getNombreGrupo()" v-model="hor_codgrh" required>
                    <option v-for="grupo in grupos_horario.filter(x=>x.grh_seccion == plm_seccion || x.grh_codigo == 'Au' || x.grh_seccion == 0)" :key="grupo" :value="grupo.grh_codigo">{{ grupo.grh_descripcion }}</option>
                </select>
                <ul>
                    <li style="font-size:12px" v-for="val in hor_descripcion_validacion" v-bind:key="val">{{val}}</li>
                </ul>
            </div>
            <div class="col m1">
                <label>Autofin.</label>
                <select v-model="hor_financiado" class="browser-default" disabled>
                    <option value="N">No</option>
                    <option value="S">Si</option>
                </select>
            </div>
            <div class="col m1">
                <label>Virtual</label>
                <select v-model="hor_virtual" class="browser-default">
                    <option value="N">No</option>
                    <option value="S">Si</option>
                </select>
            </div>
            <div class="col m3">
                <label>Docente</label> <a href="#!" @click="buscarDocente" style="float:right; font-size: 12px; text-decoration:underline">BUSCAR... <i style="font-size:12px" class="material-icons">search</i></a>
                <select v-model="hor_codcat" class="browser-default" @change="cambiarDocente($event)">
                    <option :value="0">No Definido</option>
                    <option v-for="docente in docentes" v-bind:key="docente" v-bind:value="docente.emp_codigo">{{docente.emp_apellidos_nombres}}</option>
                </select>
            </div>
            <div class="col m1">
                <label>Cap. Max.</label>
                <input type="number" min=0 style="text-align:right" v-model="hor_max_alumnos">
                <ul>
                    <li style="font-size:12px" v-for="val in hor_max_alumnos_validacion" v-bind:key="val">{{val}}</li>
                </ul>
            </div>
            <div class="clearfix"></div>
            <div class="row card-panel blue-grey lighten-4" style="position:relative">
                <form @submit.prevent="agregarDetalleHorario">
                    <input type="hidden" v-model="detalle_index">
                    <a href="#!" @click="nuevoDetalle" class="btn-small green darken-3" style="position:absolute; top:0px; right: 0px;">Nuevo Detalle<i class="material-icons right">add_circle</i></a>
                    <div class="col m2">
                        <label>Día</label>
                        <select v-model="dho_dia" class="browser-default" @change="filtrarBloques">
                            <option value="0">Seleccione</option>
                            <option v-for="dia in dias" v-bind:value="dia.dh_codigo" v-bind:key="dia">{{dia.dh_dia_f}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Horario</label>
                        <select v-model="dho_codman" class="browser-default" :disabled="(dho_dia == 0)" @change="getSalasDisponibles">
                            <option value="0">Seleccione</option>
                            <option v-for="bloque in bloques_view" v-bind:value="bloque.man_codigo" v-bind:key="bloque">{{bloque.man_nomhor}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Edificio</label>
                        <select v-model="dho_codedi" class="browser-default" :disabled="dho_codman == 0" @change="filtrarAulas">
                            <option value="0">Seleccione</option>
                            <option v-for="edificio in edificios" v-bind:value="edificio.edi_codigo" v-bind:key="edificio">{{edificio.edi_nombre}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Aula</label>
                        <select v-model="dho_aula" class="browser-default" v-bind:disabled="(dho_codedi == 0)">
                            <option value="0">Seleccione</option>
                            <option v-for="aula in aulas" v-bind:value="aula.aul_codigo" v-bind:key="aula">{{aula.aul_nombre_corto}} (Cap. {{ aula.aul_capacidad }})</option>
                        </select>
                    </div>
                    <div class="col m1">
                        <label>Tipo</label>
                        <select v-model="dho_tipo" class="browser-default">
                            <option value="T">Teoría</option>
                            <option value="P">Práctica</option>
                        </select>
                    </div>
                    <div class="col m1" v-show="(dho_tipo == 'P')">
                        <label>Capacidad</label>
                        <input type="text" v-model="dho_capacidad">
                    </div>
                    <div class="col m1">
                        <br>
                        <label><input type="checkbox" class="filled-in" v-model="dho_virtual"><span>Virtual</span></label>
                    </div>
                    <div class="col m1">
                        <button type="submit"
                        title="Agregar bloque" 
                        class="btn blue-ugb" 
                        style="margin-top:10px" :disabled="(this.dho_dia == 0 || this.dho_codman == 0 || this.dho_aula == 0)">
                            <i class="material-icons">{{(this.detalle_index >= 0 ? 'update':'add_circle_outline')}}</i>
                        </button>
                    </div>
                </form>
            </div>
            <div class="row">
                <table class="table tbl-condensed bordered striped" v-show="(detalle_horario.length > 0)" style="font-size:12px">
                    <caption>DETALLE DE HORARIO</caption>
                    <thead>
                        <tr>
                            <th style="width:10%">Día</th>
                            <th style="width:15%">Horario</th>
                            <th style="width:20%">Edificio</th>
                            <th style="width:10%">Aula</th>
                            <th style="width:5%">Tipo</th>
                            <th style="width:10%">Grupo</th>
                            <th style="width:10%">Capacidad</th>
                            <th style="width:10%">Virtual</th>
                            <th colspan="2" style="width:10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(detalle, index) in detalle_horario" v-bind:key="detalle" v-bind:class="[{'blue darken-3 white-text':(index == detalle_index)}, {'red darken-4 white-text': (index_horario_interferencia == index)}]">
                            <td class="center">{{detalle.dh_dia_f}}</td>
                            <td class="center">{{detalle.man_nomhor}}</td>
                            <td class="center">{{detalle.edi_nombre}}</td>
                            <td class="center">{{detalle.aul_nombre_corto}}</td>
                            <td class="center">{{detalle.dho_tipo}}</td>
                            <td class="center">{{detalle.dho_gr_prac}}</td>
                            <td class="center">{{detalle.dho_capacidad}}</td>
                            <td class="center"><label><input type="checkbox" class="filled-in" @click.prevent="" v-model="detalle.dho_virtual"><span></span></label></td>
                            <td class="center"><a href="#!" @click="editarDetalle(index)" v-bind:class="((index == detalle_index || index == index_horario_interferencia) ? 'white-text':'')"><i class="material-icons">create</i></a></td>
                            <td class="center"><a href="#!" @click="eliminarDetalle(index)" v-bind:class="((index == detalle_index || index == index_horario_interferencia) ? 'white-text':'')"><i class="material-icons">delete</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col m12" v-show="(detalle_horario.length > 0)">
                    <label><input type="checkbox" id="chk_hor_publicado" class="filled-in" @change="cambiarEstado($event)" :checked="hor_publicado"><span>Publicado</span></label>
                    &nbsp;
                    <label v-if="validarInterferencia"><input type="checkbox" @change="cambiarValidacionInterferencia($event)" id="chk_validar_interferencia" class="filled-in" :checked="validar_interferencia"><span>Val. Interf. Prác.</span></label>
                    <button type="button" class="btn red darken-2 right" @click="eliminarHorario">ELIMINAR <i class="material-icons right">delete</i></button>
                    <button type="button" class="btn blue-ugb right" @click="agregarHorario">{{(this.hor_codigo > 0 ? 'ACTUALIZAR':'CREAR')}} <i class="material-icons right">{{(this.hor_codigo > 0 ? 'update':'save')}}</i></button>
                    <button type="button" class="btn green darken-2 right" @click="horarioCruzado">CRUZADA <i class="material-icons right">swap_horiz</i></button>
                    <button type="button" v-show="hor_codigo > 0" class="btn blue darken-2 right" @click="verInscritos()">INSCRITOS <i class="material-icons right">list</i></button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="modal-buscar-docente-modificar" style="width:75%; margin-top:20px">
    <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
    <div class="modal-content">
        <BuscarDocente ref="buscarDocente"></BuscarDocente>
    </div>
</div>

<div class="modal" id="modal-buscar-materia-modificar" style="width:75%; margin-top:20px">
    <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
    <div class="modal-content">
        <BuscarMateria v-bind:emp_apellidos_nombres="emp_apellidos_nombres" v-bind:codmat_seleccionado="hor_codmat" v-bind:mat_nombre="mat_nombre" v-bind:codcat_seleccionado="hor_codcat" v-bind:codpla_seleccionado="hor_codpla" ref="buscarMateria"></BuscarMateria>
    </div>
</div>

<div class="modal blue-ugb" id="modal-horario-cruzado-modificar-horario" style="width:75%; border:solid 2px white">
    <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
    <div class="modal-content">
        <HorarioCruzado ref="horarioCruzado" />
    </div>
</div>

<div class="modal blue-ugb" id="modal-inscritos-modificar">
    <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
    <div class="modal-content">
        <div class="row card-panel" style="height:400px; overflow:auto">
            <Inscritos ref="inscritos" />
        </div>
    </div>
</div>

</template>

<script>
import M from 'materialize-css'
import BuscarDocente from './BuscarDocenteComponent.vue'
import BuscarMateria from './BuscarMateriaComponent.vue'
import HorarioCruzado from './HorarioCruzadoComponent.vue'
import Inscritos from './InscritosComponent.vue'
export default {
    name: 'ModificarHorario',
    components:{
        BuscarDocente,
        BuscarMateria,
        HorarioCruzado,
        Inscritos
    },
    data() {
        return {
            detalle_horario: [],
            hor_codigo: 0,
            hor_codpla: 0,
            hor_codmat: '',
            hor_descripcion: '',
            hor_descripcion_validacion: [],
            hor_codcat: 0,
            hor_max_alumnos: 0,
            hor_max_alumnos_validacion: [],
            hor_financiado: 'N',
            hor_virtual: 'N',
            hor_publicado: true,
            hor_codgrh: [],
            mat_nombre: '',
            pla_nombre: '',
            car_nombre: '',
            fac_nombre: '',
            emp_apellidos_nombres: '',
            plm_ciclo: 0,
            plm_seccion: 0,

            dho_dia: 0,
            dho_codman: 0,
            dho_aula: 0,
            dho_aula_editando: 0,
            dho_codedi: 0,
            dho_tipo: 'T',
            dho_gr_prac: 1,
            dho_capacidad: 0,
            dho_virtual: false,

            aulas: [],
            bloques: [],
            bloques_view: [],
            edificios: [],
            docentes: [],
            dias: [],
            planes: [],
            materias: [],

            detalle_index: -1,
            index_horario_interferencia: -1,
            instance_modal_busqueda_docente: null,
            instance_modal_busqueda_materia: null,
            instance_modal_horario_cruzado: null,
            instance_modal_inscritos: null,
            modal_instance: null,

            allow_change_subject: true,
            validar_interferencia: true,

            grupos_horario: []
        }
    },
    async mounted()
    {
        await this.getGruposHorario();
    },
    methods: {
        verDetalleHorario()
        {
            console.log(this.detalle_horario)
        },
        async getNombreGrupo()
        {
            this.$root.startLoading();
            await this.axios.get('/GrupoHorario/NombreGrupo/' + this.hor_codpla + '/' + this.hor_codgrh)
            .then(response => {
                this.hor_descripcion = response.data.hor_descripcion;
                this.hor_financiado = response.data.hor_financiado;
            });
            this.$root.stopLoading();
        },
        async getGruposHorario()
        {
            await this.axios.get('/GrupoHorario')
            .then(response => {
                this.grupos_horario = response.data;
            });
        },
        async getHorario(codhor, codaul, codman, codedi, dia, codpla = 0, codmat = '', allow_change_subject = true, virtual = 'N')
        {
            var modal = document.querySelector('#modal-modificar-horario');
            this.modal_instance = M.Modal.getInstance(modal);
            this.allow_change_subject = allow_change_subject;
            this.hor_codigo = codhor;
            this.mat_nombre = '';
            this.hor_descripcion = '';
            this.hor_codcat = 0;
            this.hor_max_alumnos = 0;
            this.hor_codgrh = '';
            this.pla_nombre = '';
            this.car_nombre = '';
            this.fac_nombre = '';
            this.dho_dia = 0;
            this.dho_codman = 0;
            this.dho_codedi = 0;
            this.dho_aula = 0;
            this.dho_tipo = 'T';
            this.dho_capacidad = 0;
            this.hor_publicado = true;
            this.detalle_horario = [];
            this.validar_interferencia = true;
            if(codhor > 0)
            {
                var existe = true;
                await this.axios.get('/Horarios/' + codhor)
                .then(response => {
                    var horario = response.data;
                    this.hor_codpla = horario.hor_codpla;
                    this.hor_codmat = horario.hor_codmat;
                    this.hor_descripcion = horario.hor_descripcion;
                    this.hor_codcat = horario.hor_codcat;
                    this.hor_max_alumnos = horario.hor_max_alumnos;
                    this.hor_financiado = horario.hor_financiado;
                    this.hor_virtual = horario.hor_virtual;
                    this.hor_publicado = horario.hor_publicado;
                    this.hor_codgrh = horario.hor_codgrh;
                    this.detalle_horario = horario.detalle;
                    this.mat_nombre = horario.hor_codmat + ' - ' + horario.mat_nombre;
                    this.emp_apellidos_nombres = horario.emp_apellidos_nombres;
                    this.plm_ciclo = response.data.plm_ciclo;
                    this.plm_seccion = response.data.plm_seccion;
                    document.querySelector('#chk_hor_publicado').checked = horario.hor_publicado;                
                })
                .catch(error => {
                    if(error.response.status == 404)
                    {
                        M.toast({html: 'El horario ya no existe.'});
                        var data_horario = [{
                            aul_codigo: codaul,
                            man_codigo: codman,
                            dh_codigo: dia,
                            edi_codigo: codedi
                        }];
                        if(this.$parent.$parent.removeHorario != undefined)
                            this.$parent.$parent.removeHorario(data_horario);
                        existe = false;
                    }
                });
            }
            else
            {
                this.hor_codpla = codpla;
                this.hor_codmat = codmat;
                this.hor_virtual = virtual;
            }
            
            if(existe || (!existe && codhor == 0))
            {
                if(this.dias.length == 0)
                {
                    await this.axios.get('/Dias')
                    .then(response => {
                        this.dias = response.data;
                    });
                }

                if(this.bloques.length == 0)
                {
                    await this.axios.get('/Bloques')
                    .then(response => {
                        this.bloques = response.data;
                        this.bloques_view = response.data;
                    });
                }

                if(this.docentes.length == 0)
                {
                    await this.axios.get('/Empleados/Docentes/')
                    .then(response => {
                        this.docentes = response.data;
                    });
                }
                
                if(this.hor_codpla > 0)
                {
                    await this.axios.get('/Planes/' + this.hor_codpla)
                    .then(response => {
                        this.car_nombre = response.data.ra_car_carreras.car_nombre;
                        this.pla_nombre = response.data.pla_nombre;
                    });
                }
                if(this.allow_change_subject == false)
                {
                   await this.axios.get('/Planes/' + this.hor_codpla + '/Materias/' + this.hor_codmat)
                   .then(response => {
                       this.mat_nombre = this.hor_codmat + ' - ' + (response.data.plm_alias || response.data.ra_mat_materias.mat_nombre);
                   });
                }
                this.modal_instance.open();
            }
        },
        async editarDetalle(index)
        {
            this.index_horario_interferencia = -1;
            var detalle = this.detalle_horario[index];
            this.detalle_index = index;
            this.dho_dia = detalle.dho_dia;
            this.filtrarBloques();
            this.dho_codman = detalle.dho_codman; 
            this.dho_aula_editando = detalle.dho_aula;
            await this.getSalasDisponibles();
            this.dho_codedi = detalle.edi_codigo;
            this.filtrarAulas();
            this.dho_aula = detalle.dho_aula;            
            this.dho_tipo = detalle.dho_tipo;
            this.dho_capacidad = detalle.dho_capacidad;
            this.dho_gr_prac = detalle.dho_gr_prac;
            this.dho_virtual = detalle.dho_virtual;
        },
        filtrarAulas(){
            this.dho_aula = 0;
            this.aulas = this.dho_codedi == 0 ? [] : this.edificios.find(x=>x.edi_codigo == this.dho_codedi).ra_aul_aulas;
        },
        filtrarBloques(){
            this.dho_codman = 0;
            this.dho_codedi = 0;
            this.dho_aula = 0;
            this.bloques_view = this.bloques.filter(x=>x.dh_codigo == this.dho_dia);
        },
        eliminarDetalle(index)
        {
            this.detalle_horario.splice(index, 1);
            this.index_horario_interferencia = -1;
            this.renumerarGrupos();
        },
        async agregarHorario(){
            this.index_horario_interferencia = -1;
            if(this.hor_max_alumnos == 0)
            {
                M.toast({html: 'La capacidad máxima debe ser mayor a cero.'})
                return;
            }
            
            var data = {
                hor_codigo: this.hor_codigo,
                hor_codpla: this.hor_codpla,
                hor_codmat: this.hor_codmat,
                hor_descripcion: this.hor_descripcion,
                hor_codcat: this.hor_codcat,
                hor_max_alumnos: this.hor_max_alumnos,
                hor_financiado: this.hor_financiado,
                hor_virtual: this.hor_virtual,
                hor_codgrh: this.hor_codgrh,
                ra_dho_det_horarios_gr: this.detalle_horario
            };
            
            var practicas = this.detalle_horario.filter(x=>x.dho_tipo == 'P');
            if(practicas.length > 0)
            {
                var total_grupos = 0;
                for(let i = 0; i <= practicas.length - 1; i++)
                {
                    total_grupos += parseInt(practicas[i].dho_capacidad);
                }
                if(total_grupos != this.hor_max_alumnos)
                {
                    M.toast({html: 'La cantidad de estudiantes en los grupos de práctica no coincide con la capacidad máxima del grupo.'});
                    return;
                }
            }

            this.$root.startLoading();
            if(this.detalle_horario.filter(x=>x.dho_tipo == 'P').length == 0)
            {
                this.validar_interferencia = true;
            }
            await this.axios.post('/Horarios/?validar_interferencia=' + this.validar_interferencia, data)
            .then(async response => {
                this.detalle_index = -1;
                if(this.hor_codigo == 0)
                {
                    this.hor_codigo = response.data[0].hor_codigo;                    
                }
                await this.getHorario(this.hor_codigo);
                M.toast({html: 'El horario ha sido ' + (response.status == 201 ? 'creado':'actualizado') + '.'});
                if(this.$parent.getHorario != undefined)
                {
                    await this.$parent.getHorario(this.hor_codigo);
                }
            })
            .catch(error => {
                var data = error.response.data;
                if(data.type == 'M')
                {
                    M.toast({html: 'Rellene los campos correctamente.'});
                    if(data.modelErrors.find(x=>x.key == "hor_descripcion") != undefined)
                        this.hor_descripcion_validacion = data.modelErrors.find(x=>x.key == "hor_descripcion").messages;
                    if(data.modelErrors.find(x=>x.key == "hor_max_alumnos") != undefined)
                        this.hor_max_alumnos_validacion = data.modelErrors.find(x=>x.key == "hor_max_alumnos").messages;
                }

                if(data.type == 'C')
                {
                    M.toast({html: data.message});
                    if(data.data != undefined)
                    {
                        var index_detalle_error = this.detalle_horario
                                                        .findIndex(x=>x.dho_aula == data.data.dho_aula
                                                        && x.dho_codman == data.data.dho_codman
                                                        && x.dho_dia == data.data.dho_dia);
                        this.index_horario_interferencia = index_detalle_error;
                    }
                }
            });
            this.$root.stopLoading();
        },
        agregarDetalleHorario(){
            this.error_agregar_detalle = '';
            var existe = this.detalle_horario.find(x=>x.dho_dia == this.dho_dia && x.dho_codman == this.dho_codman && x.dho_aula == this.dho_aula)
            if(existe != undefined && this.detalle_index == -1)
            {
                M.toast({html: 'Ya agregado un bloque en esta aula, horario y día seleccionado.'});
                return;
            }
            if(this.dho_tipo == 'T')
            {
                this.dho_capacidad = null;
            }
            if(this.dho_tipo == 'P' && (this.dho_capacidad || 0) == 0)
            {
                M.toast({html: 'La capacidad del grupo práctico debe ser mayor a cero.'});
                return;
            }
            var data = {
                dho_dia: this.dho_dia,
                dh_dia_f: this.dias.find(x=>x.dh_codigo == this.dho_dia).dh_dia_f,
                dho_codman: this.dho_codman,
                man_nomhor: this.bloques.find(x=>x.man_codigo == this.dho_codman).man_nomhor,
                edi_codigo: this.dho_codedi,
                edi_nombre: this.edificios.find(x=>x.edi_codigo == this.dho_codedi).edi_nombre,
                dho_aula: this.dho_aula,
                aul_nombre_corto: this.aulas.find(x=>x.aul_codigo == this.dho_aula).aul_nombre_corto,
                dho_tipo: this.dho_tipo,
                dho_capacidad: this.dho_tipo == 'T' ? this.hor_max_alumnos:this.dho_capacidad,
                dho_gr_prac: this.dho_tipo == 'T' ? null:this.dho_gr_prac,
                dho_virtual: this.dho_virtual
            };
            if(this.detalle_index >= 0)
            {
                this.detalle_horario[this.detalle_index] = data;
            }
            else
            {
                this.detalle_horario.push(data);
            }
            this.renumerarGrupos();
            this.nuevoDetalle();
        },
        renumerarGrupos()
        {
            let n = 1;
            for(var i = 0; i <= this.detalle_horario.length - 1; i++)
            {
                if(this.detalle_horario[i].dho_tipo == 'P')
                {
                    this.detalle_horario[i].dho_gr_prac = n;
                    n++;
                }
            }
        },
        nuevoDetalle()
        {
            this.index_horario_interferencia = -1;
            this.detalle_index = -1
            this.dho_codedi = 0;
            this.dho_dia = 0;
            this.dho_codman = 0;
            this.dho_aula = 0;
            this.dho_aula_editando = 0;
            this.dho_tipo = 'T';
            this.dho_capacidad = 0;
            this.dho_gr_prac = 0;
            this.dho_virtual = false;
        },
        async eliminarHorario()
        {
            if(confirm('¿Desea eliminar este horario?.\nSi existen estudiantes inscritos no podrá hacerlo.'))
            {
                this.$root.startLoading();
                await this.axios.delete('/Horarios/' + this.hor_codigo)
                .then(() => {
                    if(this.$parent.removerHorario != undefined)
                    {
                        this.$parent.removerHorario(this.hor_codigo);
                    }
                    M.toast({html: 'El horario ha sido eliminado.'});
                })
                .catch(error => {
                    var data = error.response.data;
                    M.toast({html: data.message});
                });
                this.modal_instance.close();
                this.$root.stopLoading();
            }
        },
        async buscarDocente()
        {
            this.$root.startLoading();
            await this.$refs.buscarDocente.init(this.hor_codmat, this.hor_codpla, false, this.hor_codigo);
            var modal = document.querySelector('#modal-buscar-docente-modificar');
            this.instance_modal_busqueda_docente = M.Modal.getInstance(modal);
            this.instance_modal_busqueda_docente.open();
            this.$root.stopLoading();
        },
        seleccionarDocenteDisponibilidad(codemp, dia, codman)
        {
            this.hor_codcat = codemp;
            this.dho_dia = dia;
            this.dho_codman = codman;
            this.instance_modal_busqueda_docente.close();
        },
        async buscarMateria()
        {
            this.$root.startLoading();
            await this.$refs.buscarMateria.init();
            var modal = document.querySelector('#modal-buscar-materia-modificar');
            this.instance_modal_busqueda_materia = M.Modal.getInstance(modal);
            this.instance_modal_busqueda_materia.open();
            this.$root.stopLoading();
        },
        seleccionarMateria(codpla, codmat, mat_nombre, car_nombre, pla_nombre)
        {
            this.hor_codpla = codpla;
            this.hor_codmat = codmat;
            this.mat_nombre = codmat + ' - ' + mat_nombre;
            this.car_nombre = car_nombre;
            this.pla_nombre = pla_nombre;
            this.instance_modal_busqueda_materia.close();
        },
        cambiarDocente(event)
        {
            var index = event.target.selectedIndex;
            this.emp_apellidos_nombres = event.target.options[index].text;
        },
        cambiarEstado(event)
        {
            var estado = event.target.checked;
            this.axios.patch('/Horarios/'+this.hor_codigo+'/Estado/' + estado )
            .then(() => {});
        },
        async getSalasDisponibles()
        {
            this.dho_codedi = 0;
            this.dho_aula = 0;
            if(this.dho_codman == 0)
            {
                this.edificios = [];
                this.aulas = [];
                return;
            }
            this.$root.startLoading();
            await this.axios.get('/Aulas/' + this.dho_dia + '/' + this.dho_codman + '/' + this.dho_aula_editando + '/Disponibles')
            .then(response => {
                this.edificios = response.data;
            });
            this.$root.stopLoading();
        },
        async horarioCruzado()
        {
            this.$root.startLoading();
            await this.$refs.horarioCruzado.init(this.hor_codigo);
            var modal = document.querySelector('#modal-horario-cruzado-modificar-horario');
            this.instance_modal_horario_cruzado = M.Modal.getInstance(modal);
            this.instance_modal_horario_cruzado.dismissible = false;
            this.instance_modal_horario_cruzado.open();
            this.$root.stopLoading();
        },
        cambiarValidacionInterferencia(event)
        {
            this.validar_interferencia = event.target.checked;
        },
        async verInscritos()
        {
            var modal = document.querySelector('#modal-inscritos-modificar');
            this.instance_modal_inscritos= M.Modal.getInstance(modal);
            this.instance_modal_inscritos.dismissible = false;
            this.instance_modal_inscritos.open();
            await this.$refs.inscritos.init(this.hor_codigo);
        }
    },
    computed: {
        validarInterferencia: function()
        {
            var result = this.detalle_horario.filter(x=>x.dho_tipo == 'P');
            return result.length >= 2;
        }
    }
}
</script>