<template>
    <div class="row m0 p0">
        <div class="col m3">
            <label>Facultad</label>
            <select class="browser-default" v-model="codfac" @change="setCodcar()">
                <option value="0">Todas</option>
                <option v-for="facultad in facultades" :key="facultad" :value="facultad.fac_codigo">{{ facultad.fac_nombre }}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Carrera</label>
            <select class="browser-default" v-model="codcar">
                <option value="0">Todas</option>
                <option v-for="carrera in carreras_filter" :key="carrera" :value="carrera.car_codigo">{{ carrera.car_nombre }}</option>
            </select>
        </div>
        <div class="col m4">
            <label>Buscar Materia</label>
            <input type="text" v-model="search">
        </div>
        <div class="col m2">
            <label>Fecha de Inicio</label>
            <select class="browser-default" v-model="fecha_desde_filtro" @change="getHorarios()">
                <option value="">Todas</option>
                <option v-for="fecha in fechas_iniciales" :key="fecha" :value="toDMY(fecha)">{{ toDMY(fecha) }}</option>
            </select>
        </div>
    </div>
    <div class="row m0 p0">
        <div class="col m12">
            <blockquote>
                Este apartado es para definir la fecha límite de ingreso de notas para los grupos de carreras virtuales. Las ponderaciones por materia se crearán automaticamente cuando defina la fecha límite.
            </blockquote>
        </div>
    </div>
    <div class="row card-panel" v-show="total_seleccionados > 0" style="border: solid 1px black">
        <div class="col m12">
            <div class="col m1">
                <span class="badge new left m0 p0" data-badge-caption="" style="width: 100%">{{ total_seleccionados }} SELEC.</span><br/>
                <a href="#!" class="white-text" @click="desmarcar()"><span  style="width: 100%" class="badge new left red darken-3 m0 p0" data-badge-caption=""><i class="material-icons">close</i></span></a>
            </div>
            <div class="col m2">
                <label>Repo Hasta</label>
                <datepicker v-model="hor_fecha_limite_notas" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" />
            </div>
            <div class="col m1">
                <label></label>
                <button type="button" class="btn blue-ugb" @click="modalSetVirtualesProgramacion()" title="Enviar"><i class="material-icons">send</i></button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                <tbody>           
                    <template v-for="(carrera, index_carrera) in carreras" :key="carrera">
                        <tr  v-show="mostrar(carrera.car_codfac, carrera.car_codigo)">
                            <th colspan="13" class="center">{{ carrera.car_nombre.toUpperCase() }}</th>
                        </tr>
                        <template v-for="(plan, index_plan) in carrera.ra_pla_planes" :key="plan">
                            <tr  v-show="mostrar(carrera.car_codfac, carrera.car_codigo)">
                                <th colspan="13" class="center">{{ plan.pla_nombre.toUpperCase() }}</th>
                            </tr>

                            <template v-for="(ciclo, index_ciclo) in plan.plm_ciclos" :key="ciclo">
                                <tr v-show="mostrar(carrera.car_codfac, carrera.car_codigo)">
                                    <th colspan="13" class="center">CICLO {{ ciclo.plm_ciclo }}</th>
                                </tr>
                                <tr v-show="mostrar(carrera.car_codfac, carrera.car_codigo)" class="black white-text" style="font-size: 10px">
                                    <th style="width: 3%"></th>
                                    <th class="center" style="width: 5%">GRUPO</th>
                                    <th class="center" style="width: 28%;">MATERIA</th>
                                    <th class="center" style="width: 18%">DOCENTE</th>
                                    <th class="center" style="width: 3%">MAX.<br>ALU.</th>
                                    <th class="center" style="width: 4%">ACTIVO</th>
                                    <th class="center" style="width: 7%">DESDE</th>
                                    <th class="center" style="width: 7%">HASTA</th>
                                    <th class="center" style="width: 7%">REPO DESDE</th>
                                    <th class="center" style="width: 7%">REPO HASTA</th>
                                    <th class="center" style="width: 7%">LÍMITE NOTAS</th>
                                    <th style="width: 4%"></th>
                                </tr>
                                <template v-for="(horario, index_horario) in ciclo.horarios" :key="horario">
                                    <tr v-show="mostrar(carrera.car_codfac, carrera.car_codigo, horario.mat_nombre)">
                                        <td><label class="m0 p0"><input type="checkbox" class="filled-in" v-model="horario.hor_seleccionado"><span class="m0 p0"></span></label></td>
                                        <td class="center">{{ horario.hor_descripcion }}</td>
                                        <td>{{ horario.mat_codigo }} - {{ horario.mat_nombre }}</td>
                                        <td>{{ horario.emp_apellidos_nombres }}</td>
                                        <td class="center">{{ horario.hor_max_alumnos }}</td>
                                        <td class="center">
                                            <label><input type="checkbox" class="filled-in" :checked="horario.hor_publicado" @click.prevent=""><span></span></label>
                                        </td>
                                        <td class="center">
                                            <datepicker class="black-text" v-model="horario.hor_desde" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" disabled />
                                        </td>
                                        <td class="center">
                                            <datepicker class="black-text" v-model="horario.hor_hasta" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" disabled />
                                        </td>
                                        <td class="center">
                                            <datepicker class="black-text" v-model="horario.hor_repo_desde" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" disabled/>
                                        </td>
                                        <td class="center">
                                            <datepicker class="black-text" v-model="horario.hor_repo_hasta" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" disabled/>
                                        </td>
                                        <td v-bind:class="{'red lighten-4': !datesAreEqual(horario.hor_fecha_limite_notas, horario.hor_fecha_limite_notas_temp)}">
                                            <datepicker class="black-text" v-model="horario.hor_fecha_limite_notas" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" clearable/>
                                        </td>
                                        <td class="center"><a href="#!" @click="setFechaNota(index_carrera, index_plan, index_ciclo, index_horario, horario.hor_codigo)"><i class="material-icons">save</i></a></td>                                      
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </template>
                </tbody>  
            </table>
        </div>
    </div>
    
    <div id="modal-edicion-fechas" class="modal">
        <div class="modal-content">
            <div class="row">
                <div class="col m12">
                    <div class="col m3">
                        <label>Fecha Límite</label>
                        <datepicker v-model="hor_fecha_limite_notas" inputFormat="dd/MM/yyyy" class="black-text" :typeable="true" vertical="true" disabled />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                        <thead>
                            <tr>
                                <th>Grupo</th>
                                <th>Carrera</th>
                                <th>Materia</th>
                                <th>Docente</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="materia in horSeleccionados" :key="materia">
                                <td>{{ materia.hor_descripcion }}</td>
                                <td>{{ materia.car_nombre }}</td>
                                <td>{{ materia.mat_codigo }} - {{ materia.mat_nombre }}</td>
                                <td>{{ materia.emp_apellidos_nombres }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <a href="#!" class="btn blue-ugb" @click="setVirtualesProgramacionNotas()">APLICAR FECHAS</a>
            <a href="#!" class="modal-close red darken-3 btn">CANCELAR</a>
        </div>
    </div>

</template>

<script>
    import Datepicker from 'vue3-datepicker'
    import M from 'materialize-css'
    export default {
        components: {
            Datepicker
        },
        data(){
            return {
                carreras: [],
                facultades: [],
                codfac: 0,
                codcar: 0,
                hor_fecha_limite_notas: null,
                search: '',
                modales: [],
                fecha_desde_filtro: '',
                fechas_iniciales: []
            }
        },
        async mounted()
        {
            this.modales = M.Modal.init(document.querySelectorAll('.modal'), null);
            await this.getHorarios();
        },
        methods: {
            async getHorarios()
            {
                this.$root.startLoading();
                await this.axios.get('/Horarios/Virtuales/0/0?fecha=' + this.fecha_desde_filtro)
                .then(response => {
                    this.carreras = response.data.group;
                    this.fechas_iniciales = response.data.fechas;
                    for(let i = 0; i <= this.fechas_iniciales.length - 1; i++)
                    {
                        this.fechas_iniciales[i] = new Date(this.fechas_iniciales[i]);
                    }
                    if(this.facultades.length == 0)
                    {
                        this.facultades = response.data.facultades;
                    }
                    for(let i = 0; i <= this.carreras.length - 1; i++)
                    {
                        for(let j = 0; j <= this.carreras[i].ra_pla_planes.length - 1; j++)
                        {
                            for(let k = 0; k <= this.carreras[i].ra_pla_planes[j].plm_ciclos.length - 1; k++)
                            {
                                this.carreras[i].ra_pla_planes[j].plm_ciclos[k].horarios.forEach(x=>{
                                    x.hor_desde = x.hor_desde == null ? null:new Date(x.hor_desde);
                                    x.hor_hasta = x.hor_hasta == null ? null: new Date(x.hor_hasta);
                                    x.hor_desde_temp = x.hor_desde_temp == null ? null: new Date(x.hor_desde_temp);
                                    x.hor_hasta_temp = x.hor_hasta_temp == null ? null:  new Date(x.hor_hasta_temp);

                                    x.hor_repo_desde = x.hor_repo_desde == null ? null:new Date(x.hor_repo_desde);
                                    x.hor_repo_hasta = x.hor_repo_hasta == null ? null: new Date(x.hor_repo_hasta);
                                    x.hor_repo_desde_temp = x.hor_repo_desde_temp == null ? null: new Date(x.hor_repo_desde_temp);
                                    x.hor_repo_hasta_temp = x.hor_repo_hasta_temp == null ? null:  new Date(x.hor_repo_hasta_temp);

                                    x.hor_fecha_limite_notas = x.hor_fecha_limite_notas == null ? null:  new Date(x.hor_fecha_limite_notas);
                                    x.hor_fecha_limite_notas_temp = x.hor_fecha_limite_notas_temp == null ? null:  new Date(x.hor_fecha_limite_notas_temp);
                                });
                            }
                        }
                    }
                });
                this.$root.stopLoading();
            },
            async setFechaNota(index_carrera, index_plan, index_ciclo, index_horario, codhor)
            {
                var hor_fecha_limite_notas = this.carreras[index_carrera].ra_pla_planes[index_plan].plm_ciclos[index_ciclo].horarios[index_horario].hor_fecha_limite_notas;              
                var data = {
                    hor_fecha_limite_notas: hor_fecha_limite_notas,
                    hor_codigo: codhor,
                };
                this.$root.startLoading();
                await this.axios.post('/Horarios/Virtuales/' + codhor + '/FechaNotas', data)
                .then(() => {
                    this.carreras[index_carrera].ra_pla_planes[index_plan].plm_ciclos[index_ciclo].horarios[index_horario].hor_fecha_limite_notas_temp = hor_fecha_limite_notas;
                    M.toast({html: 'La fecha de ingreso de notas ha sido actualizada.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            datesAreEqual(date, date_compare)
            {
                if(date == null && date_compare == null) return true;
                if(date == null && date_compare != null) return false;
                if(date != null && date_compare == null) return false;
                if(date != null && date_compare != null)
                {
                    return date.getTime() == date_compare.getTime();
                }
                return false;
            },
            async setVirtualesProgramacionNotas()
            {
                if(this.horSeleccionados.length == 0) return false;
                if(!confirm('¿Desea actualizar los '+this.horSeleccionados.length+' registros seleccionados?')) return false;
                var payload = {
                    hor_fecha_limite_notas: this.hor_fecha_limite_notas,
                    horarios: this.horSeleccionados.map(x=>{
                        return x.hor_codigo
                    })
                };
                this.$root.startLoading();
                await this.axios.post('/Horarios/Virtuales/ProgramacionNotas', payload)
                .then(() => {
                    this.carreras.forEach(carrera => {
                    carrera.ra_pla_planes.forEach(plan => {
                        plan.plm_ciclos.forEach(ciclo => {
                                ciclo.horarios.forEach(x=>{
                                    if(this.horSeleccionados.findIndex(y=>y.hor_codigo == x.hor_codigo) != -1)
                                    {
                                        x.hor_fecha_limite_notas = this.hor_fecha_limite_notas;
                                        x.hor_fecha_limite_notas_temp = this.hor_fecha_limite_notas;
                                    }
                                });
                            })
                        })
                    });
                    M.toast({html: 'Las fechas han sido actualizadas.'});
                    var modal = M.Modal.getInstance(document.querySelector('#modal-edicion-fechas'));
                    modal.close();
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            modalSetVirtualesProgramacion()
            {
                if(this.hor_fecha_limite_notas == null)
                {
                    M.toast({html: 'Seleccione la fecha.'});
                    return;
                }
                var modal = M.Modal.getInstance(document.querySelector('#modal-edicion-fechas'));
                modal.open();
            },
            desmarcar()
            {
                this.carreras.forEach(carrera => {
                    carrera.ra_pla_planes.forEach(plan => {
                        plan.plm_ciclos.forEach(ciclo => {
                            ciclo.horarios.forEach(x=>{
                                x.hor_seleccionado = false;
                            });
                        })
                    })
                });
            },
            mostrar(codfac, codcar, nombre)
            {
                nombre = (nombre || '').toLowerCase();
                if(this.codfac == 0 && this.codcar == 0)
                {
                    if(nombre == '')
                        return true;

                    return nombre.indexOf(this.search.toLowerCase()) != -1;
                }
                if(codfac == this.codfac && this.codcar == (this.codcar == 0 ? this.codcar:codcar))
                {
                    if(nombre == '')
                        return true;

                    return nombre.indexOf(this.search.toLowerCase()) != -1;
                }
            },
            setCodcar()
            {
                this.codcar = 0;
            },
            toDMY(today)
            {
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const formattedToday = dd + '/' + mm + '/' + yyyy;

                return formattedToday;
            },
            datesAreEqualNull(date, date_compare)
            {
                date = date == '' ? null:date;
                if(date == null && date_compare == null) return true;
                if(date == null && date_compare != null) return true;
                if(date != null && date_compare == null) return false;
                if(date != null && date_compare != null)
                {
                    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    date_compare = new Date(date_compare.getFullYear(), date_compare.getMonth(), date_compare.getDate());
                    return date.getTime() == date_compare.getTime();
                }
                return false;
            }
        },
        computed: {
            carreras_filter()
            {
                if(this.codfac == 0) return [];
                return this.facultades.find(x=>x.fac_codigo == this.codfac).ra_car_carreras;
            },
            total_seleccionados()
            {
                var total = 0;
                this.carreras.forEach(carrera => {
                    carrera.ra_pla_planes.forEach(plan => {
                        plan.plm_ciclos.forEach(ciclo => {
                            total += ciclo.horarios.filter(x=>x.hor_seleccionado).length;
                        })
                    })
                });
                return total;
            },
            horSeleccionados()
            {
                var horarios = [];
                this.carreras.forEach(carrera => {
                    carrera.ra_pla_planes.forEach(plan => {
                        plan.plm_ciclos.forEach(ciclo => {
                            //horarios = horarios.concat(ciclo.horarios.filter(x=>x.hor_seleccionado));
                            horarios = horarios.concat(ciclo.horarios.filter(x=>x.hor_seleccionado).map(x=>{
                                return {
                                    hor_codigo: x.hor_codigo,
                                    mat_codigo: x.mat_codigo,
                                    mat_nombre: x.mat_nombre,
                                    emp_apellidos_nombres: x.emp_apellidos_nombres,
                                    hor_descripcion: x.hor_descripcion,
                                    car_nombre: carrera.car_nombre,
                                    pla_nombre: plan.pla_nombre
                                }
                            }));
                        })
                    })
                });
                return horarios;
            }
        }
    }
</script>