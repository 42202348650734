<template>
    <div class="row">
        <div class="col m12 card-panel blue-ugb white-text m0 p0">
            <div class="col m5">
                <span class="collection-item"><b>DOCENTE:</b> {{(emp_apellidos_nombres || 'SIN DOCENTE SELECCIONADO')}}</span>
            </div>
            <div class="col m7">
                <span class="collection-item"><b>MATERIA ACTUAL:</b> {{mat_nombre}}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <ul class="tabs m0 p0" id="tabs-busqueda-materias">
            <li class="tab col s3" id="tab-materias"><a @click="changeTab(0)" v-bind:class="{'blue-grey darken-4 white-text': active_tab == 0}" class="black-text" href="#!">Todas Las Materias</a></li>
            <li class="tab col s3" id="tab-disponibilidad" v-show="(codcat_seleccionado > 0)"><a v-bind:class="{'blue-grey darken-4 white-text': active_tab == 1}" @click="changeTab(1)" class="black-text" href="#!">Disponibilidad</a></li>
        </ul>
    </div>
    <div class="m0 p0" v-show="(active_tab == 0)">
        <div class="row">
            <div class="col m12 card-panel m0 blue-ugb white-text">
                <div class="col m4">
                    <label class="white-text">Facultad</label>
                    <select v-model="codfac" @change="getCarreras" class="browser-default">
                        <option value="0">Seleccione</option>
                        <option v-for="facultad in facultades" v-bind:key="facultad" v-bind:value="facultad.fac_codigo">{{facultad.fac_nombre}}</option>
                    </select>
                </div>
                <div class="col m5">
                    <label class="white-text">Carrera</label>
                    <select v-model="codcar" :disabled="(codfac == 0)" @change="getPlanes" class="browser-default">
                        <option value="0">Seleccione</option>
                        <option v-for="carrera in carreras_view" v-bind:key="carrera" v-bind:value="carrera.car_codigo">{{carrera.car_nombre}}</option>
                    </select>
                </div>
                <div class="col m3">
                    <label class="white-text">Plan</label>
                    <select v-model="codpla" :disabled="(codcar == 0)" @change="getMaterias" class="browser-default">
                        <option value="0">Seleccione</option>
                        <option v-for="plan in planes" v-bind:key="plan" v-bind:value="plan.pla_codigo">{{plan.pla_nombre}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <!--<div style="height:200px; overflow:auto">-->
                <table class="table tbl-condensed bordered striped" style="font-size:12px" v-show="(materias.length > 0)">
                    <caption>MATERIAS AUTORIZADAS</caption>
                    <thead>
                        <tr>
                            <th style="width:10%">Ciclo</th>
                            <th style="width:70%">Materia</th>
                            <th style="width:10%">Grupos</th>
                            <th style="width:10%">Seleccionar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="materia in materias" v-bind:key="materia">
                            <td class="center">{{materia.plm_ciclo}}</td>
                            <td>{{materia.mat_codigo}} - {{materia.mat_nombre}}</td>
                            <td class="center">{{materia.hor_cantidad}}</td>
                            <td class="center"><a href="#!" @click="seleccionarMateria(materia.mat_codigo, materia.mat_nombre)" title="Seleccionar"><i class="material-icons">check_circle</i></a></td>
                        </tr>
                    </tbody>
                </table>
            <!--</div>-->
        </div>
    </div>
    <div id="disponibilidad" v-show="(active_tab == 1)">
        <div class="row">
            <table class="table tbl-condensed bordered striped" style="font-size:12px" v-show="(materias_disponibilidad.length > 0)">
                <caption>DISPONIBILIDAD DE MATERIAS</caption>
                <thead>
                    <tr>
                        <th style="width:40%">Carrera</th>
                        <th style="width:10%">Ciclo</th>
                        <th style="width:40%">Materia</th>
                        <th style="width:10%">Seleccionar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="materia in materias_disponibilidad" v-bind:key="materia">
                        <td>{{materia.car_nombre}}</td>
                        <td class="center">{{materia.plm_ciclo}}</td>
                        <td>{{materia.mat_codigo}} - {{materia.mat_nombre}}</td>
                        <td class="center"><a href="#!" @click="seleccionarMateriaDisponibilidad(materia.pla_codigo, materia.mat_codigo, materia.mat_nombre, materia.car_nombre, materia.pla_nombre)" title="Seleccionar"><i class="material-icons">check_circle</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
//import M from 'materialize-css'
    export default {
        name: 'BuscarMateria',
        props: {
            codmat_seleccionado: String,
            codpla_seleccionado: Number,
            codcat_seleccionado: Number,
            emp_apellidos_nombres: String,
            mat_nombre: String
        },
        data(){
            return {
                facultades: [],
                codfac: 0,
                carreras: [],
                carreras_view: [],
                codcar: 0,
                planes: [],
                codpla: 0,
                materias: [],
                materias_disponibilidad: [],
                disponibilidad: false,
                tab_instances: null,
                tab_instance: null,
                active_tab: 0
            }
        },
        mounted(){
            //this.tab_instances = M.Tabs.init(document.querySelectorAll('.tabs'), null);
            //this.tab_instance = M.Tabs.getInstance(document.querySelector('#tabs-busqueda-materias'));
        },
        methods: {
            async getFacultades()
            {
                if(this.facultades.length == 0)
                {
                    await this.axios.get('/Facultades/?permitidas=true')
                    .then(response => {
                        this.facultades = response.data;
                    });
                }
            },
            async getCarreras()
            {
                this.$root.startLoading();
                this.codcar = 0;
                this.materias = [];
                this.codpla = 0;
                if(this.carreras.length == 0)
                {
                    await this.axios.get('/Carreras/Activas?permitidas=true')
                    .then(response => {
                        this.carreras = response.data;
                    });
                }                
                this.carreras_view = this.carreras.filter(x=>x.car_codfac == this.codfac);
                this.$root.stopLoading();
            },
            async getPlanes()
            {
                this.$root.startLoading();
                this.materias = [];
                this.codpla = 0;
                await this.axios.get('/Planes/Activos/' + this.codcar)
                .then(response => {
                    this.planes = response.data;
                });
                this.$root.stopLoading();
            },
            async getMaterias()
            {
                this.$root.startLoading();
                this.materias = [];
                await this.axios.get('/MateriasAutorizadas/'+this.codpla+'/CantidadHorarios/')
                .then(response => {
                    this.materias = response.data;
                });
                this.$root.stopLoading();
            },
            async getMateriasDisponibilidad()
            {
                if(this.codcat_seleccionado > 0)
                {
                    await this.axios.get('/DisponibilidadMaterias/' + this.codcat_seleccionado + '?permitidas=true')
                    .then(response => {
                        this.materias_disponibilidad = response.data;
                    });
                }
            },
            async init()
            {          
                this.active_tab = 0;
                await this.getFacultades();
                await this.getMateriasDisponibilidad();
            },
            seleccionarMateria(codmat, mat_nombre)
            {
                var plan = this.planes.find(x=>x.pla_codigo == this.codpla);
                this.$parent.seleccionarMateria(this.codpla, codmat, mat_nombre, plan.ra_car_carreras.car_nombre, plan.pla_nombre);
            },
            seleccionarMateriaDisponibilidad(codpla, codmat, mat_nombre, car_nombre, pla_nombre)
            {
                this.$parent.seleccionarMateria(codpla, codmat, mat_nombre, car_nombre, pla_nombre);
            },
            changeTab(tab)
            {
                this.active_tab = tab;
            }
        }
    }
</script>
