<template>
    <div class="row" v-show="!loading">
        <div class="col m12 card-panel blue-ugb white-text">
            <form @submit.prevent="agregarRegistro">
                <div class="col m3">
                    <label class="white-text">Edificio</label>
                    <select class="browser-default" v-model="codedi" @change="getAulas">
                        <option value="0">Seleccione</option>
                        <option v-for="edificio in edificios" v-bind:value="edificio.edi_codigo" v-bind:key="edificio">{{edificio.edi_nombre}}</option>
                    </select>
                </div>
                <div class="col m2">
                    <label class="white-text">Aula</label>
                    <select class="browser-default" v-model="codaul" :disabled="codedi == 0">
                        <option value="0">Seleccione</option>
                        <option v-for="aula in aulas_view" v-bind:value="aula.aul_codigo" v-bind:key="aula">{{aula.aul_nombre_corto}}</option>
                    </select>
                </div>
                <div class="col m2">
                    <label class="white-text">Dia</label>
                    <select class="browser-default" v-model="dia" :disabled="codaul == 0" @change="getBloques">
                        <option value="0">Seleccione</option>
                        <option v-for="dia in dias" v-bind:value="dia.dh_codigo" v-bind:key="dia">{{dia.dh_dia_f}}</option>
                    </select>
                </div>
                <div class="col m3">
                    <label class="white-text">Bloque</label>
                    <select class="browser-default" v-model="codman" :disabled="dia == 0">
                        <option value="0">Seleccione</option>
                        <option v-for="bloque in bloques_view" v-bind:value="bloque.man_codigo" v-bind:key="bloque">{{bloque.man_nomhor}}</option>
                    </select>
                </div>
                <div class="col m2">
                    <button type="submit" class="btn green darken-2 btn-small" :disabled="(codaul == 0 || dia == 0 || codman == 0)" style="margin-top:22px">AGREGAR <i class="material-icons right">add_circle</i></button>
                </div>
            </form>
        </div>
    </div>
    
    <div class="row" v-show="!loading">
        <div class="col m12">
            <table class="table tbl-condensed striped bordered" style="font-size:12px" v-show="restricciones.length > 0">
                <caption>BLOQUES RESERVADOS</caption>
                <thead>
                    <tr>
                        <th>Edificio</th>
                        <th>Aula</th>
                        <th>Dia</th>
                        <th>Bloque</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="restriccion in restricciones_view" v-bind:key="restriccion">
                        <td class="center">{{restriccion.edi_nombre}}</td>
                        <td class="center">{{restriccion.aul_nombre_corto}}</td>
                        <td class="center">{{restriccion.dh_dia_f}}</td>
                        <td class="center">{{restriccion.man_nomhor}}</td>
                        <td class="center"><a @click="eliminarBloque(restriccion.aul_codigo, restriccion.man_codigo, restriccion.dh_codigo)" href="#!"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
            </table>
            <ul class="pagination">
                <li v-for="n in total_pages" v-bind:class="{active: n == current_page}" v-bind:key="n"><a @click="changePage(n)"  href="#!">{{n}}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        name: 'RestriccionBloques',
        data() {
            return {
                edificios: [],
                aulas: [],
                aulas_view: [],
                dias: [],
                bloques: [],
                bloques_view: [],
                codedi: 0,
                codaul: 0,
                dia: 0,
                codman: 0,
                restricciones: [],
                restricciones_view: [],
                loading: false,
                
                total_records: 10,
                current_page: 1,
                total_pages: 0
            }
        },
        async mounted()
        {
            this.$root.startLoading();
            this.loading = true;
            await this.axios.get('/Edificios')
            .then(response => {
                this.edificios = response.data;
            });
            await this.axios.get('/Aulas')
            .then(response => {
                this.aulas = response.data;
                this.aulas_view = response.data;
            });
            await this.axios.get('/Dias')
            .then(response => {
                this.dias = response.data;
            });
            await this.axios.get('/Bloques')
            .then(response => {
                this.bloques = response.data;
                this.bloques_view = response.data;
            });
            await this.axios.get('/RestriccionAula')
            .then(response => {
                this.restricciones = response.data;
                this.restricciones_view = response.data;
                this.paginar();
            });
            this.loading = false;
            this.$root.stopLoading();
        },
        methods: {
            getBloques()
            {
                this.codman = 0;
                this.bloques_view = this.bloques.filter(x=>x.dh_codigo == this.dia);
            },
            getAulas()
            {
                this.codaul = 0;
                this.aulas_view = this.aulas.filter(x=>x.aul_codedi == this.codedi);
            },
            async agregarRegistro()
            {
                var data = {
                    rba_codaul: this.codaul,
                    rba_codman: this.codman,
                    rba_dia: this.dia
                };
                this.$root.startLoading();
                await this.axios.post('/RestriccionAula', data)
                .then(async () => {
                    await this.axios.get('/RestriccionAula/' + this.codaul + '/' + this.dia + '/' + this.codman)
                    .then(response => {
                        this.restricciones.push(response.data);
                        this.paginar();
                    });
                })
                .catch(error => {
                    var data = error.response.data;
                    M.toast({html: data.message});
                });
                this.$root.stopLoading();
            },
            async eliminarBloque(codaul, codman, dia)
            {
                this.$root.startLoading();
                await this.axios.delete('/RestriccionAula/' + codaul + '/' + dia + '/' + codman)
                .then(() => {
                    var index = this.restricciones.findIndex(x=>x.aul_codigo == codaul && x.man_codigo == codman && x.dh_codigo == dia);
                    this.restricciones.splice(index, 1);
                    this.paginar();
                });
                this.$root.stopLoading();
            },
            paginar()
            {
                var total = this.restricciones.length;
                this.total_pages = Math.ceil(total / this.total_records);
                this.restricciones_view = this.restricciones.slice(0, this.total_records);
            },
            changePage(n)
            {
                var inicio = (n - 1) * this.total_records;
                this.restricciones_view = this.restricciones.slice(inicio, this.total_records + inicio);
                this.current_page = n;
            }
        }
    }
</script>