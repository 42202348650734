<template>
    <div class="row">
        <div class="col m12 m0 p0" v-if="inscritos != null">
            <div class="col m2 p0 m0">
                <label>Grupo</label>
                <p>{{inscritos.hor_descripcion}}</p>
            </div>
            <div class="col m6 p0 m0">
                <label>Materia</label>
                <p>{{inscritos.mat_codigo}} - {{inscritos.mat_nombre}}</p>
            </div>
            <div class="col m4 p0 m0">
                <label>Docente</label>
                <p>{{inscritos.emp_apellidos_nombres}}</p>
            </div>
        </div>
        <div class="col m12 m0 p0">
            <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px" v-if="inscritos != null">
                <thead style="visibility:hidden">
                    <tr>
                        <th style="width:15%">Carnet</th>
                        <th style="width:60%">Nombre</th>
                        <th style="width:15%">Fecha</th>
                        <th style="width:5%"></th>
                        <th style="width:5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-show="inscritos.inscritos.length > 0">
                        <th colspan="5" class="center">INSCRITOS ({{inscritos.inscritos.length}})</th>
                    </tr>
                    <tr v-for="inscrito in inscritos.inscritos" :key="inscrito.per_codigo">
                        <td class="center">{{inscrito.per_carnet}}</td>
                        <td>{{inscrito.per_apellidos_nombres}}</td>
                        <td class="center">{{inscrito.mai_fecha}}</td>
                        <td class="center"><a href="#!" @click="retirar(inscrito.mai_codigo)" title="Retirar"><i class="material-icons">do_not_disturb</i></a></td>
                        <td class="center"><a href="#!" @click="eliminar(inscrito.mai_codigo)"  title="Eliminar"><i class="material-icons">delete</i></a></td>
                    </tr>
                    <tr v-show="inscritos.retirados.length > 0">
                        <th colspan="5" class="center">RETIRADOS ({{inscritos.retirados.length}})</th>
                    </tr>
                    <tr v-for="retirado in inscritos.retirados" :key="retirado.per_codigo" v-show="inscritos.retirados.length > 0">
                        <td class="center">{{retirado.per_carnet}}</td>
                        <td>{{retirado.per_apellidos_nombres}}</td>
                        <td class="center">{{retirado.mai_fecha}}</td>
                        <td class="center"><a href="#!" @click="inscribir(retirado.mai_codigo)" title="Retirar"><i class="material-icons">add_circle_outline</i></a></td>
                        <td class="center"><a href="#!" @click="eliminar(retirado.mai_codigo)" title="Eliminar"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import M  from 'materialize-css'
    export default {
        data(){
            return {
                inscritos: null,
                codhor: 0
            }
        },
        methods: {
            async init(codhor)
            {
                /* this.inscritos.inscritos = [];
                this.inscritos.retirados = []; */
                this.codhor = codhor;
                await this.axios.get('/Horarios/' + codhor + '/Inscritos')
                .then(response => {
                    this.inscritos = response.data;
                })
                .catch(error => {
                    M.toast({html: error.response.data});
                });
            },
            async eliminar(codmai)
            {
                if(!confirm('¿Desea eliminar esta inscripción?\nUna vez eliminada no podrá recuperarla.')) return false;

                this.$root.startLoading();
                await this.axios.delete('/MateriaInscritas/' + codmai)
                .then(() => {
                    var index = this.inscritos.inscritos.findIndex(x=>x.mai_codigo == codmai);
                    if(index != -1)
                    {
                        this.inscritos.inscritos.splice(index, 1);
                        return
                    }
                    index = this.inscritos.retirados.findIndex(x=>x.mai_codigo == codmai);
                    if(index != -1)
                    {
                        this.inscritos.retirados.splice(index, 1);
                        return;
                    }
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async inscribir(codmai)
            {
                if(!confirm('¿Desea reactivar esta materia?')) return false;
                this.$root.startLoading();
                await this.axios.patch('/MateriasInscritas/' + codmai + '/Inscribir')
                .then(async () => {
                    await this.init(this.codhor);
                });
                this.$root.stopLoading();
            },
            async retirar(codmai)
            {
                if(!confirm('¿Desea retirar esta materia?')) return false;
                this.$root.startLoading();
                await this.axios.patch('/MateriasInscritas/' + codmai + '/Retirar')
                .then(async () => {
                    await this.init(this.codhor);
                });
                this.$root.stopLoading();
            }
        }
    }
</script>