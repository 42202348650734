<template>
    <div>
        {{ edificios }}
    </div>
</template>

<script>
    export default {
        data(){
            return {
                edificios: []
            }
        },
        async mounted()
        {
            await this.getEdificios();
        },
        methods: {
            async getEdificios()
            {
                await this.axios.get('/Bloques/Libres')
                .then(response => {
                    this.edificios = response.data;
                });
            }
        }
    }
</script>