<template>
    <div class="row">
        <div class="col m12">
            <div class="col m4">
                <label>Facultad</label>
                <select class="browser-default" v-model="codfac" @change="getEstudiantes()">
                    <option value="0">Seleccione</option>
                    <option v-for="facultad in facultades" :key="facultad" :value="facultad.fac_codigo">{{ facultad.fac_nombre }}</option>
                </select>
            </div>
            <div class="col m4">
                <label>Carrera</label> {{ codfac }}
                <select class="browser-default" @change="getEstudiantes()" v-model="codcar">
                    <option value="0">Todas</option>
                    <option v-for="carrera in carreras.filter(x=>x.car_codfac == (codfac == 0 ? x.car_codfac:codfac))" :key="carrera" :value="carrera.car_codigo">{{ carrera.car_nombre }}</option>
                </select>
            </div>
            <div class="col m2">
                <label>Ciclo</label>
                <select class="browser-default" v-model="codcil">
                    <option value="0">Seleccione</option>
                    <option v-for="ciclo in ciclos" :key="ciclo" :value="ciclo.cil_codigo">{{ ciclo.ra_cic_ciclos.cic_nombre }} - {{ ciclo.cil_anio }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <button type="button" class="btn blue-ugb" @click="egresarIndividual(0)">Egresar</button>
            <button type="button" v-show="egresados.length > 0" class="btn green darken-2">Ver Egresados</button>
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                <caption>
                    {{ estudiantes.length }} ESTUDIANTES A PROCESAR
                    <br>
                    {{ estudiantes.filter(x=>x.per_estado != null).length }} de {{ estudiantes.length }}
                    <br>
                    {{ estudiantes.filter(x=>x.per_estado == 'Egresado').length }} EGRESADOS
                </caption>
                <thead>
                    <th>#</th>
                    <th>Codigo</th>
                    <th>Plan</th>
                    <th>Carnet</th>
                    <th>Estudiante</th>
                    <th>Estado</th>
                    <th>Estado Pensum</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="(estudiante, index) in estudiantes" :key="estudiante">
                        <td class="center">{{ index + 1 }}</td>
                        <td>{{ estudiante.per_codigo }}</td>
                        <td>{{ estudiante.pla_nombre }}</td>
                        <td>{{ estudiante.per_carnet }}</td>
                        <td>{{ estudiante.per_apellidos_nombres }}</td>
                        <td>{{ estudiante.per_estado }}</td>
                        <td>{{ estudiante.per_cursadas }} / {{ estudiante.per_total }}</td>
                        <td>{{  }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import M  from 'materialize-css'
    export default {
        data(){
            return {
                facultades: [],
                carreras: [],
                estudiantes: [],
                ciclos: [],
                codfac: 0,
                codcar: 0,
                codcil: 0,
                egresados: []
            }
        },
        async mounted()
        {
            await this.getFacultades();
            await this.getCarreras();
            await this.getCiclos();
        },
        methods: {
            async getFacultades()
            {
                this.$root.startLoading();
                await this.axios.get('/Facultades')
                .then(response => {
                    this.facultades = response.data;
                });
                this.$root.stopLoading();
            },
            async getCarreras()
            {
                this.$root.startLoading();
                await this.axios.get('/Egresar/Carreras')
                .then(response => {
                    this.carreras = response.data;
                });
                this.$root.stopLoading();
            },
            async getEstudiantes()
            {
                this.$root.startLoading();
                await this.axios.get('/Egresar/' + this.codfac + '/' + this.codcar + '/Estudiantes')
                .then(response => {
                    this.estudiantes = response.data;
                });
                this.$root.stopLoading();
            },
            async egresar(codper)
            {
                this.$root.startLoading();
                await this.axios.post('/Egresar/'+this.codcil+'/Egresar/' + this.codfac + '/' + this.codcar + '/' + codper)
                .then(response => {
                    this.egresados = response.data;
                    this.estudiantes.forEach(x=>{
                        x.per_estado = this.egresados.findIndex(y=>y.per_codigo == x.per_codigo) != -1
                    });
                    M.toast({html: 'Se egresaron '+  this.egresados.length + ' estudiantes.'});
                });
                this.$root.stopLoading();
            },
            async egresarIndividual()
            {
                this.$root.startLoading();
                for(let i = 0; i <= this.estudiantes.length - 1; i++)
                {                    
                    this.estudiantes[i].per_estado = 'Espere...';
                    await this.axios.post('/Egresar/'+this.codcil+'/Egresar/' + this.codfac + '/' + this.codcar + '/' + this.estudiantes[i].per_codigo)
                    .then(response => {
                        if(response.data.length > 0)
                        {
                            this.estudiantes[i].per_estado = 'Egresado';
                        }
                        else
                        {
                            this.estudiantes[i].per_estado = 'No Cumple'
                        }
                    });                    
                }
                this.$root.stopLoading();
            },
            async getCiclos()
            {
                this.$root.startLoading();
                await this.axios.get('/Regionales/CiclosRegional')
                .then(response => {
                    this.ciclos = response.data.filter(x=>x.cil_codcic == 1 || x.cil_codcic == 2);
                });
                this.$root.stopLoading();
            }
        }
    }
</script>