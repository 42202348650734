<template>
    <div class="row">
        <div class="col m4 card-panel blue-ugb">
            <div class="row">
                <div class="col m12">
                    <label class="white-text">Facultad</label>
                    <select class="browser-default" v-model="codfac" @change="getCarreras">
                        <option value="0">Todas</option>
                        <option v-for="facultad in facultades" v-bind:key="facultad" v-bind:value="facultad.fac_codigo">{{facultad.fac_nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <label class="white-text">Carreras</label>
                    <select class="browser-default" v-model="codcar" @change="getPlanes">
                        <option value="0">Todas</option>
                        <option v-for="carrera in carreras_view" v-bind:key="carrera" v-bind:value="carrera.car_codigo">{{carrera.car_nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <label class="white-text">Plan</label>
                    <select class="browser-default" v-model="codpla">
                        <option value="0">Todos</option>
                        <option v-for="plan in planes_view" v-bind:key="plan" v-bind:value="plan.pla_codigo">{{plan.pla_nombre}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col m8">
            <em>*El estándar solo permite múltiplos de 5.</em>
            <table class="table tbl-condensed bordered striped" style="font-size:12px" v-show="proyecciones.length > 0">
                <template v-for="(proyeccion) in proyecciones_view" v-bind:key="proyeccion">
                    <thead>
                        <tr>
                            <td colspan="6" class="center blue-ugb white-text" style="height:50px">{{proyeccion.fac_nombre}} - {{proyeccion.car_nombre}} - {{proyeccion.pla_nombre}}</td>
                        </tr>
                        <tr>
                            <th style="width:10%">Ciclo</th>
                            <th style="width:70%">Materia</th>
                            <!--<th style="width:10%">Ajuste--</th>-->
                            <th style="width:10%">Proyección</th>
                            <th style="width:10%">Estándar*</th>
                            <!--<th style="width:10%">Ajuste++</th>-->
                        </tr>
                    </thead>
                    <tbody>                        
                        <tr v-for="materia in proyeccion.prc_proyeccion" v-bind:key="materia">
                            <td class="center">{{materia.plm_ciclo}}</td>
                            <td>{{materia.mat_codigo}} - {{materia.mat_nombre}}</td>
                            <!--<td class="center red lighten-3">{{roundDownToFiveZero(materia.prc_total)}}</td>-->
                            <td class="center light-blue lighten-4">                                
                                <input type="number" min=0 :disabled="((saving || materia.plm_ciclo > 1) && !isAdmin)" @change="guardarProyeccion(proyeccion.fac_codigo, materia.mat_codigo, proyeccion.pla_codigo)" class="browser-default" style="text-align:center; width:100%" v-model="materia.prc_total">
                            </td>
                            <td class="center"><input type="number" max="100" step="5" :disabled="saving" @change="guardarProyeccion(proyeccion.fac_codigo, materia.mat_codigo, proyeccion.pla_codigo)" class="browser-default" style="text-align:center; width:100%" v-model="materia.prc_estandar"></td>
                            <!--<td class="center green lighten-3">{{roundUpToFiveZero(materia.prc_total)}}</td>-->
                        </tr>                    
                    </tbody>
                </template>
            
            </table>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        name: 'Proyeccion',
        data() {
            return {
                facultades: [],
                codfac: 0,
                carreras: [],
                carreras_view: [],
                codcar: 0,
                planes: [],
                planes_view: [],
                codpla: 0,
                materias: [],
                proyecciones: [],
                proyecciones_view: [],
                saving: false
            }
        },
        async created()
        {
            M.AutoInit();
            this.$root.startLoading();
            await this.axios.get('/Proyecciones/')
            .then(response => {
                this.proyecciones = response.data;
                this.proyecciones_view = response.data;
            });
            await this.getFacultades();
            await this.getCarreras();
            this.$root.stopLoading();
        },
        methods:
        {
            async getFacultades()
            {
                if(this.facultades == 0)
                {
                    await this.axios.get('/Facultades?permitidas=true')
                    .then(response => {
                        this.facultades = response.data;
                    });
                }
                this.filtrarProyecciones();
            },
            async getCarreras()
            {
                this.codpla = 0;
                this.codcar = 0;
                if(this.carreras.length == 0)
                {
                    await this.axios.get('/Carreras/Activas?permitidas=true')
                    .then(response => {
                        this.carreras = response.data;
                    });
                }
                this.carreras_view = this.codfac == 0 ? this.carreras : this.carreras.filter(x=>x.car_codfac == this.codfac);
                this.filtrarProyecciones();
            },
            async getPlanes()
            {
                this.codpla = 0;
                if(this.planes.length == 0)
                {
                    await this.axios.get('/Planes/Activos/?permitidos=true')
                    .then(response => {
                        this.planes = response.data;
                    });
                }
                this.planes_view = this.planes.filter(x=>x.pla_codcar == this.codcar);
                this.filtrarProyecciones();
            },
            filtrarProyecciones()
            {
                this.proyecciones_view = this.proyecciones
                                             .filter(x=>x.car_codigo == (this.codcar == 0 ? x.car_codigo:this.codcar)
                                                    && x.fac_codigo == (this.codfac == 0 ? x.fac_codigo:this.codfac)
                                                    && x.pla_codigo == (this.codpla == 0 ? x.pla_codigo:this.copla));
            },
            roundUpToFiveZero(n)
            {
                var len = n.toString().length;
                var numero = parseInt(n.toString().substring(len, len - 1));
                if(numero < 5 && numero > 0)
                {
                    return parseInt(n) + parseInt(5 - numero);
                }
                else if(numero > 5)
                {
                    return parseInt(n) + parseInt(10 - numero);
                }
                else
                {
                    return parseInt(n);
                }
            },
            roundDownToFiveZero(n)
            {
                var len = n.toString().length;
                var numero = parseInt(n.toString().substring(len, len - 1));
                //29 -> 9
                //9 - 5 = 4
                //29 - 4 = 25
                //return numero;
                if(numero > 5)
                {
                    return parseInt(n) - (parseInt(numero) - 5);
                }
                else if(numero < 5 && numero > 0)
                {
                    return parseInt(n) - parseInt(numero);
                }
                else
                {
                    return n;
                }
            },
            async guardarProyeccion(codfac, codmat, codpla)
            {                
                this.saving = true;
                var prc_estandar = this.proyecciones.find(x=>x.fac_codigo == codfac && x.pla_codigo == codpla).prc_proyeccion.find(x=>x.mat_codigo == codmat).prc_estandar;
                var index_codfac = this.proyecciones.findIndex(x=>x.fac_codigo == codfac && x.pla_codigo == codpla);
                var index_codmat = this.proyecciones[index_codfac].prc_proyeccion.findIndex(x=>x.mat_codigo == codmat);
                
                prc_estandar = isNaN(parseInt(prc_estandar)) ? 0:parseInt(prc_estandar);
                var len = prc_estandar.toString().length;
                var numero = parseInt(prc_estandar.toString().substring(len, len - 1));
                if(numero > 0 && numero <= 4)
                {
                    prc_estandar = parseInt(prc_estandar) - (parseInt(numero) - 5);
                }
                else if(numero > 5 && numero <= 9)
                {
                    prc_estandar = parseInt(prc_estandar) - parseInt(numero) + 5;
                }
                this.proyecciones[index_codfac].prc_proyeccion[index_codmat].prc_estandar = prc_estandar;
                var payload = {
                    prc_codmat: codmat,
                    prc_codpla: codpla,
                    prc_total: this.proyecciones.find(x=>x.fac_codigo == codfac && x.pla_codigo == codpla).prc_proyeccion.find(x=>x.mat_codigo == codmat).prc_total,
                    prc_estandar: prc_estandar
                };
                await this.axios.patch('/Proyecciones/', payload)
                .then(() => {

                });
                this.saving = false;
            }
        },
        computed: {
            isGenerador: function()
            {
                return localStorage.getItem('is_generador') == 'S';
            },
            isAdmin: function()
            {
                return localStorage.getItem('is_admin') == 'S';
            }
        }
    }
</script>