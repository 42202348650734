<template>
    <div class="row">
        <div class="col m4">
            <label>Buscar</label>
            <input type="text" v-model="search_term" />
        </div>
        <div class="col m2">
            <label>Facultad</label>
            <select class="browser-default" v-model="codfac">
                <option value="0">Todas</option>
                <option v-for="facultad in _facultades" :key="facultad" :value="facultad.fac_codigo">{{facultad.fac_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Carrera</label>
            <select class="browser-default" v-model="codcar">
                <option value="0">Todas</option>
                <option v-for="carrera in _carreras" :key="carrera" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
            </select>
        </div>
    </div>
    <div class="row" style="height: 400px; overflow-x:auto">
        <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px; width: 99%">
            <thead>
                <tr>
                    <th>Carnet</th>
                    <th>Nombre</th>
                    <th>Facultad</th>
                    <th>Carrera</th>
                    <th>Plan</th>
                    <th>Detalles</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(estudiante) in _estudiantes" :key="estudiante">
                    <td class="center">{{estudiante.per_carnet}}</td>
                    <td>{{estudiante.per_apellidos_nombres}}</td>
                    <td>{{estudiante.fac_nombre}}</td>
                    <td>{{estudiante.car_nombre}}</td>
                    <td>{{estudiante.pla_nombre}}</td>
                    <td class="center"><a href="#!" @click="modalEstudiante(estudiante.per_codigo)"><i class="material-icons">list</i></a></td>
                </tr>
            </tbody>
        </table>
    </div>

    <!--Inicio modal detalle-->
    <div class="modal" id="modal-detalle-estudiante" style="width: 90%">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content">
            <div class="row" v-if="index_estudiante >= 0">
                <div class="col m2">
                    <label>Carnet</label>
                    <p>{{estudiantes[index_estudiante].per_carnet}}</p>
                </div>
                <div class="col m9">
                    <label>Estudiante</label>
                    <p class="m0 p0">{{estudiantes[index_estudiante].per_apellidos_nombres}}</p>
                    <small class="m0 p0">{{estudiantes[index_estudiante].pla_nombre}} - {{estudiantes[index_estudiante].car_nombre}}</small>
                </div>
            </div>
            <div class="row m0 p0">
                <button class="btn orange darken-4 btn-small right" @click="getGruposAutorizados()">Cambiar a otro proceso o grupo <i class="material-icons right">compare_arrows</i></button>
                <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                    <thead>
                        <tr>
                            <th>Ciclo</th>
                            <th>Grupo</th>
                            <th>Descripción</th>
                            <th>Tipo</th>
                            <th>Autofinanciado</th>
                            <th>Fecha Inscripción</th>
                            <th>Activo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(inscripcion, index) in inscripciones" :key="inscripcion" :class="{'grey lighten-2': (index == index_grupo)}">
                            <td class="center">{{inscripcion.cic_nombre}} - {{inscripcion.cil_anio}}</td>
                            <td><a href="#!" @click="getDetallesGrupo(index, inscripcion.grh_codigo, inscripcion.ipg_codigo)">{{inscripcion.grh_descripcion}}</a></td>
                            <td>{{inscripcion.pgr_descripcion}}</td>
                            <td class="center">{{inscripcion.tpg_descripcion}}</td>
                            <td class="center"><label><input type="checkbox" class="filled-in" @click.prevent="" :checked="inscripcion.grh_autofinanciado" /><span></span></label></td>
                            <td class="center">{{inscripcion.ipg_fecha}}</td>
                            <td class="center"><label><input type="checkbox" class="filled-in" @click.prevent="" :checked="inscripcion.ipg_estado" /><span></span></label></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row m0 p0" v-if="index_grupo >= 0">
                <div class="col m8">
                    <div class="row">
                        <div class="col m12"></div>
                    </div>
                    <div class="row">
                        <table class="table tbl-condensed tbl-bordered" style="font-size:12px" v-show="inscripciones[index_grupo].tpg_codigo == 'P'">
                            <caption>MÓDULOS INSCRITOS</caption>
                            <thead>
                                <tr>
                                    <th style="width: 65%">Módulo</th>
                                    <th style="width: 20%">Periodo</th>
                                    <th style="width: 5%">Fecha Ins.</th>
                                    <th style="width: 5%">Activo</th>
                                    <th style="width: 5%"></th>
                                </tr>
                            </thead>
                            <thead>
                                <tr v-for="modulo in modulos_inscritos" :key="modulo">
                                    <td>
                                        Mod. {{modulo.moa_orden}} - {{modulo.mod_nombre}}
                                        <br /><small>{{modulo.emp_apellidos_nombres}} | {{modulo.dh_dia_f}} | {{modulo.aul_nombre_corto}} | {{modulo.man_nomhor}}</small>
                                    </td>
                                    <td class="center"><span class="badge new green darken-2" data-badge-caption="">{{modulo.dhg_fecha_inicio}} - {{modulo.dhg_fecha_fin}}</span></td>
                                    <td>{{modulo.idg_fecha}}</td>
                                    <td class="center"><label><input @click="setEstadoInscripcion($event, modulo.idg_codigo)" type="checkbox" class="filled-in" :checked="modulo.idg_estado" /><span></span></label></td>
                                    <td class="center" @click="eliminarInscripcion(modulo.idg_codigo)"><a href="#!"><i class="material-icons">delete</i></a></td>
                                </tr>
                            </thead>
                            
                            <tfoot>
                                <tr>
                                    <th>
                                        <select class="browser-default" v-model="codmoa">
                                            <option value="0">Seleccione</option>
                                            <option v-for="bloque in _bloques" :key="bloque" :value="bloque.dhg_codmoa">Mod. {{bloque.moa_orden}} - {{bloque.mod_nombre}}</option>
                                        </select>
                                    </th>
                                    <th colspan="3" class="center"><button class="blue-ugb btn btn-small" :disabled="_bloques.length == 0 || codmoa == 0" @click="inscribirModulo()">INSCRIBIR <i class="material-icons right">add_circle</i></button> </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="col m4">
                    <div class="row">
                        <div class="col m12"></div>
                    </div>
                    <div class="row" style="padding-left:10px">
                        <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                            <caption>
                                <span class="badge new red darken-2" v-show="!fechas_creadas" data-badge-caption="" style="width: 100%">SE ESTÁN TOMANDO LAS FECHAS GLOBALES DE UONLINE</span>
                                <span class="badge new green darken-2" v-show="fechas_creadas" data-badge-caption="" style="width: 100%">LAS FECHAS HAN SIDO DEFINIDAS PARA ESTE GRUPO</span>
                            </caption>
                            <thead>
                                <tr>
                                    <th style="width: 55%">Arancel</th>
                                    <th style="width: 20%">Monto</th>
                                    <th style="width: 20%">Fecha</th>
                                    <th style="width: 5%">Mora</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="fecha in fechas" :key="fecha">
                                    <td>{{fecha.tmo_descripcion}}</td>
                                    <td style="text-align:right">${{fecha.fpg_monto.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                    <td class="center">{{fecha.fpg_fecha_limite_string}}</td>
                                    <td class="center"><label><input type="checkbox" class="filled-in" :checked="fecha.fpg_mora" @click.prevent="" /><span></span></label></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--fin modal detalle-->

    <!--Inicio modal cambio de grupo-->
    <div class="modal" id="modal-cambio-grupo" style="width: 75%">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <br/>
        <br/>
        <div class="modal-content">
            <div class="row m0 p0">
                <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                    <caption style="font-size: 16px">Procesos de graduación autorizados para la carrera del estudiante.</caption>
                    <thead>
                        <tr class="black white-text">
                            <th>ESTADO</th>
                            <th>CARRERA</th>
                            <th>TIPO</th>
                            <th>DESCRIPCION</th>
                            <th>CAPACIDAD</th>
                            <th>INSCRITOS</th>
                            <th>MATRICULA</th>
                            <th>CUOTA</th>
                            <th>CANT. CUOTAS</th>
                            <th>SELECCIONAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="grupo in grupos_autorizados" :key="grupo">
                            <td><span class="badge new" data-badge-caption="ACTIVO" v-show="isActiveGrupo(grupo.grh_codigo)"></span></td>
                            <td>{{grupo.grh_descripcion}}</td>
                            <td class="center">{{grupo.tpg_descripcion}}</td>
                            <td>{{grupo.pgr_descripcion}}</td>
                            <td class="center">{{grupo.grh_capacidad}}</td>
                            <td class="center">{{grupo.grh_inscritos}}</td>
                            <td style="text-align:right">${{grupo.grh_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td style="text-align:right">${{grupo.grh_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td class="center">{{grupo.grh_cant_cuotas}}</td>
                            <td class="center"><label><input type="radio" class="with-gap" name="grh_codigo" v-model="grupo_seleccionado" :value="grupo.grh_codigo" :checked="isActiveGrupo(grupo.grh_codigo)" /><span></span>
                            </label></td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn btn-small right" v-show="!isActiveGrupo(grupo_seleccionado)" @click="setInscripcion()">Cambiar de Grupo <i class="material-icons right">compare_arrows</i></button>
            </div>
        </div>
    </div>
    <!--fin modal cambio de grupo-->
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                estudiantes: [],
                search_term: '',
                codcar: 0,
                codfac: 0,
                inscripciones: [],
                index_estudiante: -1,
                modales: [],
                modal_estudiante: {},
                modal_cambio_grupo: {},
                bloques: [],
                modulos_inscritos: [],
                index_grupo: -1,
                codmoa: 0,
                fechas: [],
                grupos_autorizados: [],
                grupo_seleccionado: 0,
                fechas_creadas: true
            }
        },
        async mounted()
        {
            await this.getEstudiantes();
            var elems = document.querySelectorAll(".modal");
            this.modales = M.Modal.init(elems, {
                        dismissible: false
                    });        
        },
        methods: {
            isActiveGrupo(codgrh)
            {
                var result = this.inscripciones.find(x=>x.ipg_estado);
                if(result == undefined) return false;
                return result.grh_codigo == codgrh;
            },
            async getEstudiantes()
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacion/Estudiantes')
                .then(response => {
                    this.estudiantes = response.data;
                });
                this.$root.stopLoading();
            },
            async modalEstudiante(codper)
            {
                this.index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == codper);
                this.index_grupo = -1;
                await this.getInscripciones();
                this.modal_estudiante = M.Modal.getInstance(document.querySelector("#modal-detalle-estudiante"));
                this.modal_estudiante.open();
            },
            async getInscripciones()
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/' + this.estudiantes[this.index_estudiante].per_codigo + '/InscripcionProcesoGraduacion')
                .then(response => {
                    this.inscripciones = response.data;
                });
                this.$root.stopLoading();
            },
            async getDetallesGrupo(index, codgrh, codipg)
            {
                this.index_grupo = index;
                await this.getModulosInscritos(codipg);
                await this.getBloques(codgrh);
                await this.getFechas(codgrh);
            },
            async getModulosInscritos(codipg)
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/' + this.estudiantes[this.index_estudiante].per_codigo + '/InscripcionProcesoGraduacion/' + codipg + '/Modulos')
                .then(response => {
                    this.modulos_inscritos = response.data;
                });
                this.$root.stopLoading();
            },
            async getBloques(codgrh)
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionGrupoHorario/' + codgrh + '/Bloques')
                .then(response => {
                    this.bloques = response.data;
                });
                this.$root.stopLoading();
            },
            async inscribirModulo()
            {                
                var bloque = this.bloques.find(x=>x.dhg_codmoa == this.codmoa);
                /*if(this._bloques.findIndex(x=>x.dhg_codigo == bloque.dhg_codigo) >= 1)
                {
                    M.toast({html: 'Debes inscribir primero el módulo anterior.'});
                    return false;
                }*/
                var payload = {
                    idg_codigo: 0,
                    idg_codgrh: this.inscripciones[this.index_grupo].grh_codigo,
                    idg_coddhg: bloque.dhg_codigo,
                    idg_codipg: this.inscripciones[this.index_grupo].ipg_codigo,
                    idg_codmoa: bloque.dhg_codmoa,
                    idg_estado: true
                };
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionInscripcionModulo', payload)
                .then(async () => {
                    this.codmoa = 0;
                    this.$root.stopLoading();
                    await this.getModulosInscritos(this.inscripciones[this.index_grupo].ipg_codigo);
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                    this.$root.stopLoading();
                });
            },
            async eliminarInscripcion(codidg)
            {
                if(!confirm('¿Desea eliminar este módulo inscrito?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/ProcesosGraduacionInscripcionModulo/' + codidg)
                .then(async () => {
                    var index = this.modulos_inscritos.findIndex(x=>x.idg_codigo == codidg);
                    this.modulos_inscritos.splice(index, 1);
                });
                this.$root.stopLoading();
            },
            async getFechas(codgrh)
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionGrupo/' + codgrh + '/Fechas')
                .then(response => {
                    this.fechas = response.data;
                    this.fechas_creadas = response.status == 200;
                });
                this.$root.stopLoading();
            },
            async setEstadoInscripcion(event, codidg)
            {
                if(!event.target.checked && !confirm('¿Desea desactivar esta inscripción de módulo?'))
                {
                    event.target.checked = true;
                    return false;
                }
                this.$root.startLoading();
                await this.axios.patch('/ProcesosGraduacionInscripcionModulo/' + codidg + '/Estado?status=' + event.target.checked)
                .then(() => {
                    
                });
                this.$root.stopLoading();
            },
            async setInscripcion()
            {
                if(!confirm('¿Desea cambiar al estudiante de grupo?')) return false;
                var payload = {
                    ipg_codigo: 0,
                    ipg_codgrh: this.grupo_seleccionado,
                    ipg_codper: this.estudiantes[this.index_estudiante].per_codigo,
                    ipg_codpla: this.estudiantes[this.index_estudiante].pla_codigo
                };
                this.$root.startLoading();
                await this.axios.put('/ProcesosGraduacionInscripcion', payload)
                .then(async () => {
                    this.inscripciones.forEach(x=>x.ipg_estado = false);
                    var index = this.inscripciones.findIndex(x=>x.grh_codigo == this.grupo_seleccionado);
                    if(index != -1)
                    {
                        this.inscripciones[index].ipg_estado = true;
                    }
                    await this.getInscripciones();
                    await this.getGruposAutorizados();
                    M.toast({html: 'Se ha cambiado al estudiante de grupo.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async getGruposAutorizados()
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionGrupo/'+this.estudiantes[this.index_estudiante].car_codigo+'/GruposCarrera')
                .then(response => {
                    this.grupos_autorizados = response.data;
                    var result = this.inscripciones.find(x=>x.ipg_estado);
                    if(result != undefined)
                    {
                        this.grupo_seleccionado = result.grh_codigo;
                    }
                });
                this.$root.stopLoading();
                this.modal_cambio_grupo = M.Modal.getInstance(document.querySelector("#modal-cambio-grupo"));
                this.modal_cambio_grupo.open();
            }
        },
        computed: {
            _estudiantes(){
                return this.estudiantes.filter(x=>
                (   x.per_carnet.toLowerCase().indexOf(this.search_term.toLowerCase()) != -1 ||
                    x.per_apellidos_nombres.toLowerCase().indexOf(this.search_term.toLowerCase()) != -1 ||
                    x.fac_nombre.toLowerCase().indexOf(this.search_term.toLowerCase()) != -1 ||
                    x.car_nombre.toLowerCase().indexOf(this.search_term.toLowerCase()) != -1
                ) && x.fac_codigo == (this.codfac == 0 ? x.fac_codigo:this.codfac) && x.car_codigo == (this.codcar == 0 ? x.car_codigo:this.codcar));
            },
            _carreras(){
                var carreras = [];
                for(let i = 0; i <= this.estudiantes.length - 1; i++)
                {
                    if(carreras.findIndex(x=>x.car_codigo == this.estudiantes[i].car_codigo) == -1)
                    {
                        if(this.codfac == 0 || this.codfac == this.estudiantes[i].fac_codigo)
                        {
                            carreras.push({
                                car_codigo: this.estudiantes[i].car_codigo,
                                car_nombre: this.estudiantes[i].car_nombre,
                                fac_codigo: this.estudiantes[i].fac_codigo
                            });
                        }
                    }
                }
                return carreras;
            },
            _facultades(){
                var facultades = [];
                for(let i = 0; i <= this.estudiantes.length - 1; i++)
                {
                    if(facultades.findIndex(x=>x.fac_codigo == this.estudiantes[i].fac_codigo) == -1)
                    {
                        facultades.push({
                            fac_codigo: this.estudiantes[i].fac_codigo,
                            fac_nombre: this.estudiantes[i].fac_nombre
                        });
                    }
                }
                return facultades;
            },
            _bloques()
            {
                return this.bloques.filter(x=>this.modulos_inscritos.findIndex(y=>y.idg_codmoa == x.dhg_codmoa) == -1);
            }
        }
    }
</script>