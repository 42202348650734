<template>
   <div id="modal-crear-horario" class="modal">
    <a href="#!" class="right modal-close btn red darken-4 white-text modal-close-btn">&times;</a>
    <div class="modal-content">
        <span class="collection-item blue-ugb white-text" style="padding:2px">{{carrera.car_nombre}}</span>
        <div class="row" style="margin-top:10px; font-size:12px">
            <div class="col m3">
                <label>Nombre Corto:</label>
                <p>{{carrera.car_nombre_corto}}</p>
            </div>
            <div class="col m3">
                <label>Virtual:</label>
                <p>{{carrera.car_virtual}}</p>
            </div>
            <div class="col m3">
                <label>Tipo:</label>
                <p>{{carrera.tipo}}</p>
            </div>
            <div class="col m3">
                <label>Profesorado:</label>
                <p>{{carrera.tipo_prof}}</p>
            </div>
        </div>
        <div class="row">
            <table class="table responsive-table tbl-condensed striped bordered" style="font-size:12px">
                <thead>
                    <th>Tipo</th>
                    <th>Nombre</th>
                    <th>Acuerdo</th>
                    <th>Desde</th>
                    <th>Hasta</th>                    
                    <th>Vigente</th>
                    <th>Estado</th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="plan in carrera.planes" v-bind:key="plan.pla_codigo">
                        <td class="center">{{plan.pla_tipo}}</td>
                        <td>{{plan.pla_nombre}}</td>
                        <td>{{plan.pla_acuerdo}}</td>
                        <td class="center">{{plan.pla_desde}}</td>
                        <td class="center">{{plan.pla_hasta}}</td>
                        <td class="center">{{plan.pla_vigente}}</td>
                        <td class="center">{{plan.pla_estado}}</td>
                        <td class="center"><a href="#!" @click.prevent="editar(plan.pla_codigo)"><i class="material-icons">create</i></a></td>
                        <td class="center"><a href="#!" @click.prevent="eliminar(plan.pla_codigo)"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
      <a href="#!" class="modal-close btn waves-effect red waves-red darken-4">CERRAR <i class="material-icons right">close</i></a>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        mounted()
        {
            var elems = document.querySelectorAll('.modal');
            M.Modal.init(elems, null);
        },
        data()
        {
            return {
                carrera: []
            }
        },
        methods: {
            getPlanes(codcar)
            {     
                this.$parent.$parent.showLoader = true;
                this.$parent.$parent.loadingPage = true;
                this.$root.startLoading();
                this.axios.get('/Carreras/'+codcar+'/Planes/')
                .then(response => {
                    this.$parent.$parent.$emit('stopLoading');
                    this.carrera = response.data;
                    var instance = M.Modal.getInstance(document.querySelector('#modal1'));
                    instance.options.dismissible = false;
                    instance.open();                    
                })
                .catch(() => {
                    //var data = error.response.data;
                })
                .then(() => {
                    this.$root.stopLoading();
                });
            }
        }
    }
</script>

<style scoped>
    .modal
    {
        width: 80%
    }
</style>