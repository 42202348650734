<template>
    <div class="row m0 p0" v-show="usuarios.length > 0">
        <div class="col m4">
            <div class="card-panel blue-ugb">
                <div class="row grey lighten-5" style="padding: 10px; border-radius: 2px; margin: 0px">
                    <div class="col m5">
                        <label>Tipo</label>
                        <select class="browser-default" v-model="usp_tipo_filtro">
                            <option value="">Todos</option>
                            <option value="X">Administrador</option>
                            <option value="A">Académica</option>
                            <option value="V">Vice-Decanatura</option>
                            <option value="D">Decanatura</option>
                            <option value="C">Coordinación</option>
                            <option value="T">Tutor</option>
                        </select>
                    </div>
                    <div class="col m7">
                        <label>Buscar</label>
                        <input type="text" v-model="search_empleado">
                    </div>
                </div>
                <div class="row m0 p0"><br>
                    <a href="#!" class="right white-text" @click="buscarUsuario(1)" title="Agregar usuario"><i class="material-icons">add_circle</i></a>
                </div>
                <div class="row m0 p0">
                    <div style="height: 400px; overflow: auto;">
                        <table class="table tbl-condensed striped white" style="font-size:12px">
                            <thead>
                                <th style="width:70%">Usuario</th>
                                <th style="width:8%">Acti.</th>
                                <th style="width:8%">Admin.</th>
                                <th style="width:8%">Acad.</th>
                                <th style="width:6%"></th>
                            </thead>
                            <tbody>
                                <template v-for="usuario in usuarios.filter(x=>x.emp_apellidos_nombres.toLowerCase().indexOf(search_empleado.toLowerCase()) != -1)" v-bind:key="usuario">
                                    <tr v-bind:class="{'blue-ugb white-text':(codemp == usuario.emp_codigo)}" v-show="usuario.usp_tipo == (usp_tipo_filtro == '' ? usuario.usp_tipo:usp_tipo_filtro)">
                                        <td><a v-bind:class="{'white-text':(codemp == usuario.emp_codigo)}" @click="getUsuario(usuario.emp_codigo)" href="#!">{{usuario.emp_codigo}} - {{usuario.emp_apellidos_nombres}}</a>
                                            <br><small>{{usuario.uni_nombre}} | {{ usp_tipo(usuario.usp_tipo) }}</small></td>
                                        <td class="center"><label><input @change="cambiarEstado($event, usuario.emp_codigo, usuario.usp_estado)" type="checkbox" class="filled-in" :checked="usuario.usp_estado"><span></span></label></td>
                                        <td class="center"><label><input @change="cambiarSuperUsuario($event, usuario.emp_codigo, usuario.usp_superusuario)" type="checkbox" class="filled-in" :checked="usuario.usp_superusuario"><span></span></label></td>
                                        <td class="center"><label><input @change="cambiarAcademica($event, usuario.emp_codigo, usuario.usp_academica)" type="checkbox" class="filled-in" :checked="usuario.usp_academica"><span></span></label></td>
                                        <td class="center"><a href="#!" @click="eliminarUsuario(usuario.emp_codigo)"><i class="material-icons">delete</i></a></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col m8">
            <div class="card-panel" v-if="codemp > 0">
                <div class="row">
                    <div class="col m6">
                       <ul class="collection" style="font-size:12px">
                            <li class="collection-item">
                                Regional: <span class="badge new blue-ugb" v-bind:data-badge-caption="usuario.reg_nombre"></span>
                            </li>
                            <li class="collection-item">
                                Nombre: <span class="badge new blue-ugb" v-bind:data-badge-caption="usuario.emp_apellidos_nombres"></span>
                            </li>
                       </ul>
                    </div>
                    <div class="col m6">
                        <ul class="collection" style="font-size:12px">
                            <li class="collection-item">
                                Plaza: <span class="badge new blue-ugb" v-bind:data-badge-caption="usuario.plz_nombre"></span>
                            </li>
                            <li class="collection-item">
                                Unidad: <span class="badge new blue-ugb" v-bind:data-badge-caption="usuario.uni_nombre"></span>
                            </li>
                       </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col m12 center">
                        <h5>Accesos</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col m3">
                        <div class="row">
                            <table class="table tbl-condensed striped" style="font-size:12px">
                                <thead>
                                    <tr>
                                        <th colspan="2">Regional</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="regional in usuario.ra_reg_regionales" v-bind:key="regional">
                                        <td class="center">{{regional.reg_nombre}}</td>
                                        <td class="center"><a @click="eliminarRegional(regional.reg_codigo)" href="#!"><i class="material-icons">delete</i></a></td>
                                    </tr>
                                    <tr v-show="regionales_pendientes.length > 0">
                                        <td>
                                            <select class="browser-default" v-model="codreg">
                                                <option value="0">Seleccione</option>
                                                <option v-for="regional in regionales_pendientes" v-bind:key="regional" v-bind:value="regional.reg_codigo">{{regional.reg_nombre}}</option>
                                            </select>
                                        </td>
                                        <td class="center"><a href="#!" @click="setRegional"><i class="material-icons">add_circle</i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="row" style="height: 400px; overflow: auto;">
                            <table class="table tbl-condensed striped" style="font-size: 12px">
                                <thead>
                                    <tr>
                                        <th>Opción</th>
                                        <th>Activo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td><label><input type="checkbox" @click="setAccesoOpcionTodos($event.target.checked)" class="filled-in"><span></span></label></td>
                                    </tr>
                                    <tr v-for="opcion in opciones" :key="opcion">
                                        <td>{{ opcion.mop_descripcion }}</td>
                                        <td class="center"><label><input type="checkbox" @click="setAccesoOpcion(opcion.mop_codigo, $event.target.checked)" class="filled-in" :checked="opcion.ump_activo" v-model="opcion.ump_activo"><span></span></label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col m9" style="height: 500px; overflow: auto;">
                        <table class="table tbl-condensed striped" style="font-size:12px">
                            <thead>
                                <tr>
                                    <th style="width:40%">Facultad</th>
                                    <th style="width:40%">Carrera</th>
                                    <th style="width:10%">Editable</th>
                                    <th style="width:10%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="carrera in usuario.ra_car_carreras" v-bind:key="carrera">
                                    <td>{{carrera.fac_nombre}}</td>
                                    <td>{{carrera.car_nombre}}</td>
                                    <th class="center"><label><input @click="setEditable(carrera.car_codigo, usuario.emp_codigo, $event.target.checked)" type="checkbox" class="filled-in" :checked="carrera.uac_editable"><span></span></label></th>
                                    <td class="center"><a @click="eliminarCarrera(carrera.car_codigo)" href="#!"><i class="material-icons">delete</i></a></td>
                                </tr>
                                <tr v-show="carreras_pendientes.length > 0"><td colspan="2"></td><td class="center"><a @click="setTodasCarreras" href="#!" title="Asignar todas"><i class="material-icons">library_add</i></a></td></tr>
                                <tr v-show="carreras_pendientes.length > 0">
                                    <td colspan="2">
                                        <select class="browser-default" v-model="codcar">
                                            <option value="0">Seleccione</option>
                                            <option v-for="carrera in carreras_pendientes" v-bind:key="carrera" v-bind:value="carrera.car_codigo">{{carrera.fac_nombre}} - {{carrera.car_nombre}}</option>
                                        </select>
                                    </td>
                                    <td class="center"><a href="#!" @click="setCarrera"><i class="material-icons">add_circle</i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal blue-ugb" id="modal-busqueda-usuario" style="width:80%">
        <div class="modal-content">
            <div class="row m0 p0">
                <div class="col m12 card-panel">
                    <form method="get" @submit.prevent="buscarUsuario(1)">
                        <div class="col m8">
                            <label>Buscar</label>
                            <input type="text" v-model="busqueda">
                        </div>
                        <div class="col m4">
                            <button type="submit" class="btn"><i class="material-icons">search</i></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row m0 p0">
                <table class="table tbl-condensed striped bordered white" style="font-size:12px">
                    <thead>
                        <th style="width:5%">Código</th>
                        <th style="width:25%">Nombre</th>
                        <th style="width:10%">Regional</th>
                        <th style="width:25%">Plaza</th>
                        <th style="width:25%">Unidad</th>
                        <th style="width:5%">Estado</th>
                        <th style="width:5%"></th>
                    </thead>
                    <tbody>
                        <tr v-for="usuario in usuarios_pendientes" v-bind:key="usuario">
                            <td class="center">{{usuario.emp_codigo}}</td>
                            <td>{{usuario.emp_apellidos_nombres}}</td>
                            <td class="center">{{usuario.reg_nombre}}</td>
                            <td>{{usuario.plz_nombre}}</td>
                            <td>{{usuario.uni_nombre}}</td>
                            <td class="center">{{usuario.emp_estado}}</td>
                            <td class="center"><a href="#!" @click="agregarUsuario(usuario.emp_codigo)"><i class="material-icons">add_circle</i></a></td>
                        </tr>
                    </tbody>
                </table>
                <div class="row m0 p0">
                    <div class="col m12 white">
                        <ul class="pagination">
                            <li v-if="current_page > 1"><a @click="buscarUsuario(1)" href="#!"><i class="material-icons">first_page</i></a></li>
                            <li v-if="current_page > 1"><a @click="buscarUsuario(current_page - 1)" href="#!"><i class="material-icons">chevron_left</i></a></li>
                            <template v-for="page in total_pages" v-bind:key="page">
                                <li v-if="page > pagina_inicial && page < pagina_final" v-bind:class="{active: page == current_page}"><a @click="buscarUsuario(page)" href="#!">{{page}}</a></li>
                            </template>
                            <li v-if="current_page < total_pages"><a @click="buscarUsuario(current_page + 1)" href="#!"><i class="material-icons">chevron_right</i></a></li>
                            <li v-if="current_page < total_pages"><a @click="buscarUsuario(total_pages)" href="#!"><i class="material-icons">last_page</i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default
    {
        name: 'Administracion',
        data() {
            return {
                usuarios: [],
                empleados: [],
                codemp: 0,
                codreg: 0,
                codcar: 0,
                usuario: {},
                regionales_pendientes: [],
                carreras_pendientes: [],
                total_pages: 0,
                current_page: 1,
                pagina_inicial: 0,
                pagina_final: 0,
                usuarios_pendientes: [],
                modales: [],
                busqueda: '',
                opciones: [],
                usp_tipo_filtro: '',
                search_empleado: ''
            }
        },
        async created()
        {
            if(localStorage.getItem('is_admin') == 'N')
            {
                this.$router.push('/');
                return;
            }
            this.$root.startLoading();
            await this.axios.get('/Usuarios')
            .then(response => {
                this.usuarios = response.data;
            });
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
            this.$root.stopLoading();
        },
        async mounted()
        {

        },
        methods:{
            async getUsuario(codemp)
            {
                this.$root.startLoading();
                this.codemp = codemp;
                this.codreg = 0;
                await this.axios.get('/Usuarios/' + this.codemp)
                .then(response => {
                    this.usuario = response.data;
                });
                await this.axios.get('/Usuarios/' + this.codemp + '/Regional/Pendientes/')
                .then(response => {
                    this.regionales_pendientes = response.data;
                });
                await this.axios.get('/Usuarios/' + this.codemp + '/Carrera/Pendientes/')
                .then(response => {
                    this.carreras_pendientes = response.data;
                });
                await this.getOpcionesMenu(codemp);
                this.$root.stopLoading();
            },
            async cambiarEstado(event, codemp, estado)
            {
                if(confirm('¿Desea '+(estado ? 'inactivar':'activar')+' este usuario?'))
                {
                    var checked = event.target.checked;
                    await this.axios.patch('/Usuarios/' + codemp + '/Estado/' + checked)
                    .then(() => {});
                }
                else
                {
                    event.target.checked = estado;
                }
            },
            async cambiarSuperUsuario(event, codemp, estado)
            {
                if(confirm('¿Desea '+(estado ? 'quitar':'asignar')+' el acceso de administrador a este usuario?'))
                {
                    var checked = event.target.checked;
                    await this.axios.patch('/Usuarios/' + codemp + '/SuperUsuario/' + checked)
                    .then(() => {});
                }
                else
                {
                    event.target.checked = estado;
                }
            },
            async cambiarAcademica(event, codemp, estado)
            {
                if(confirm('¿Desea '+(estado ? 'quitar':'asignar')+' el rol de academica a este usuario?'))
                {
                    var checked = event.target.checked;
                    await this.axios.patch('/Usuarios/' + codemp + '/Academica/' + checked)
                    .then(() => {});
                }
                else
                {
                    event.target.checked = estado;
                }
            },
            async eliminarUsuario(codemp)
            {
                if(!confirm('¿Desea eliminar este usuario?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Usuarios/' + codemp)
                .then(() => {
                    var index = this.usuarios.findIndex(x=>x.emp_codigo == codemp);
                    this.usuarios.splice(index, 1);
                    if(this.codemp == codemp)
                    {
                        this.usuario = {};
                        this.codemp = 0;
                    }
                    M.toast({html: 'El acceso ha sido eliminado.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async eliminarRegional(codreg)
            {
                if(confirm('¿Desea eliminar el acceso a esta regional?'))
                {
                    var index_regional = this.usuario.ra_reg_regionales.findIndex(x=>x.reg_codigo == codreg);
                    var regional = this.usuario.ra_reg_regionales[index_regional];
                    this.$root.startLoading();
                    await this.axios.delete('/Usuarios/' + this.codemp + '/Regional/' + codreg)
                    .then(() => {
                        this.usuario.ra_reg_regionales.splice(index_regional, 1);
                        this.regionales_pendientes.push(regional);
                    });
                    this.$root.stopLoading();
                    this.codreg = 0;
                }
            },
            async setRegional()
            {
                if(this.codreg == 0) return;
                if(confirm('¿Desea asignar acceso a esta regional?'))
                {
                    var data = {
                        rup_codreg: this.codreg,
                        rup_codemp: this.codemp
                    };
                    var index_regional = this.regionales_pendientes.findIndex(x=>x.reg_codigo == this.codreg);
                    var regional = this.regionales_pendientes[index_regional];
                    this.$root.startLoading();
                    await this.axios.post('/Usuarios/' + this.codemp + '/Regional/', data)
                    .then(() => {
                        this.usuario.ra_reg_regionales.push(regional);
                        this.regionales_pendientes.splice(index_regional, 1);
                    });
                    this.$root.stopLoading();
                    this.codreg = 0;
                }
            },
            async eliminarCarrera(codcar)
            {
                var index_carrera = this.usuario.ra_car_carreras.findIndex(x=>x.car_codigo == codcar);
                var carrera = this.usuario.ra_car_carreras[index_carrera];
                this.$root.startLoading();
                await this.axios.delete('/Usuarios/' + this.codemp + '/Carrera/' + codcar)
                .then(() => {
                    this.usuario.ra_car_carreras.splice(index_carrera, 1);
                    this.usuario.ra_car_carreras = this.usuario.ra_car_carreras.sort(x=>x.car_nombre);
                    this.carreras_pendientes.push(carrera);
                    this.carreras_pendientes = this.carreras_pendientes.sort(x=>x.car_nombre);
                });
                this.$root.stopLoading();
                this.codcar = 0;
            },
            async setCarrera()
            {
                if(this.codcar == 0) return;
                var data = {
                    uac_codcar: this.codcar,
                    uac_codemp: this.codemp,
                    uac_editable: true
                };
                var index_carrera = this.carreras_pendientes.findIndex(x=>x.car_codigo == this.codcar);
                var carrera = this.carreras_pendientes[index_carrera];
                this.$root.startLoading();
                await this.axios.post('/Usuarios/' + this.codemp + '/Carrera/', data)
                .then(() => {
                    this.usuario.ra_car_carreras.push(carrera);
                    this.usuario.ra_car_carreras = this.usuario.ra_car_carreras.sort(x=>x.car_nombre);
                    this.carreras_pendientes.splice(index_carrera, 1);
                    this.carreras_pendientes = this.carreras_pendientes.sort(x=>x.car_nombre);
                });
                this.$root.stopLoading();
                this.codcar = this.carreras_pendientes.length > 0 ? this.carreras_pendientes[index_carrera].car_codigo:0;
            },
            async setTodasCarreras()
            {
                if(!confirm('¿Desea asignar todas las carreras?')) return false;
                var data = [];
                for(let i = 0; i <= this.carreras_pendientes.length - 1; i++)
                {
                    data.push({
                        uac_codcar: this.carreras_pendientes[i].car_codigo,
                        uac_codemp: this.codemp,
                        uac_editable: true
                    });
                }
                this.$root.startLoading();
                await this.axios.post('/Usuarios/' + this.codemp + '/Carrera/Todas/', data)
                .then(() => {
                    this.usuario.ra_car_carreras = this.usuario.ra_car_carreras.concat(this.carreras_pendientes);
                    this.carreras_pendientes = [];
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();                
            },
            async buscarUsuario(p)
            {
                this.$root.startLoading();
                await this.axios.get('/Empleados/SinAcceso/?page=' + (p || 1) + '&b=' + this.busqueda)
                .then(response => {
                    this.usuarios_pendientes = response.data.records;
                    this.total_pages = response.data.total_pages;
                    this.current_page = response.data.current_page;
                    this.pagina_inicial = this.current_page - 5;
                    this.pagina_final = this.current_page + 5;
                });
                var instance = M.Modal.getInstance(document.querySelector('#modal-busqueda-usuario'));
                instance.open();
                this.$root.stopLoading();
            },
            async agregarUsuario(codemp)
            {
                var data = {
                    usp_codemp: codemp,
                    usp_estado: true,
                    usp_superusuario: false
                };
                this.$root.startLoading();
                await this.axios.post('/Usuarios', data)
                .then(async () => {
                    this.axios.get('/Usuarios/' + codemp)
                    .then(response => {
                        this.usuarios.push(response.data);
                    });
                    var index = this.usuarios_pendientes.findIndex(x=>x.emp_codigo == codemp);
                    this.usuarios_pendientes.splice(index, 1);
                    M.toast({html: 'El usuario ha sido agregado.'});
                });
                this.$root.stopLoading();
            },
            async setEditable(codcar, codemp, editable)
            {
                await this.axios.patch('/Usuarios/Acceso/' + codemp + '/' + codcar + '/Editable?editable=' + editable)
                .then(() => {

                });
            },
            async getOpcionesMenu(codemp)
            {
                this.$root.startLoading();
                await this.axios.get('/OpcionesMenu/' + codemp)
                .then(response => {
                    this.opciones = response.data;
                });
                this.$root.stopLoading();
            },
            async setAccesoOpcion(codmop, activo)
            {
                this.$root.startLoading();
                await this.axios.post('/OpcionesMenu/' + this.codemp + '/'+  codmop + '?status=' + activo)
                .then(() => {

                });
                this.$root.stopLoading();
            },
            async setAccesoOpcionTodos(activo)
            {
                for(let i = 0; this.opciones.length - 1; i++)
                {
                    await this.setAccesoOpcion(this.opciones[i].mop_codigo, activo)
                    .then(() => {
                        this.opciones[i].ump_activo = activo;
                    });
                }
            },
            usp_tipo(tipo)
            {
                switch(tipo)
                {
                    case 'X':
                        return 'Administrador';
                    case 'A':
                        return 'Académica';
                    case 'V':
                        return 'Vice-Decanatura';
                    case 'D':
                        return 'Decanatura';
                    case 'C':
                        return 'Coordinación'
                    case 'T':
                        return 'Tutor'
                    default: ''
                }
            }
        },
    }
</script>