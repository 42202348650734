<template>
    <div class="row">
        <div class="col m3">
            <label>Carrera</label>
            <select v-model="codcar" class="browser-default">
                <option value="0">Todas</option>
                <option v-for="carrera in carreras" :key="carrera.car_codigo" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Tipo</label>
            <select class="browser-default" v-model="codtpg">
                <option value="0">Todos</option>
                <option v-for="tipo in tipos_procesos" :key="tipo" :value="tipo.tpg_codigo">{{tipo.tpg_descripcion}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Grupos</label>
            <select class="browser-default" v-model="grupo_filter">
                <option value="-1">Todos</option>
                <option value="0">Sin Grupos</option>
                <option value="1">Con Grupos</option>
            </select>
        </div>
        <div class="col m3">
            <label>Buscar</label>
            <input type="text" v-model="search">
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px;">
                <caption>PROCESOS AUTORIZADOS</caption>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Carrera</th>
                        <th>Tipo</th>
                        <th>Descripción</th>
                        <th>Matrícula</th>
                        <th>Cuota</th>
                        <th>Cant. Cuotas</th>
                        <th>Módulos</th>
                        <th>Grupos</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(proceso, index) in _procesos_autorizados" :key="proceso">
                        <td class="center">{{proceso.aut_fecha}}</td>
                        <td>{{proceso.car_nombre}}</td>
                        <td>{{proceso.tpg_descripcion}}</td>
                        <td>{{proceso.pgr_descripcion}}</td>
                        <td style="text-align:right">${{proceso.aut_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                        <td style="text-align:right">${{proceso.aut_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                        <td class="center">{{proceso.aut_cant_cuotas}}</td>
                        <td class="center">                           
                            <div v-show="proceso.pgr_moa_modulos_autorizados.length > 0">
                                <a chref="#!" @click="modalModulos(index)" style="cursor:pointer"><span data-badge-caption="" class="badge new blue">{{proceso.pgr_moa_modulos_autorizados.length}}</span></a>
                            </div>
                        </td>
                        <td class="center" style="position:relative">
                            <a href="#!" @click="modalGrupos(index)"><span data-badge-caption class="badge new green darken-1">{{proceso.pgr_grh_grupo_horario.length}}</span></a>                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-modulos-autorizados">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content">
            <div class="row m0 p0" v-if="index_proceso_autorizado >= 0 && _procesos_autorizados[index_proceso_autorizado] != undefined">
                <div class="row m0 p0">
                    <div class="col m12">
                        <label>Carrera</label>
                        <p>{{_procesos_autorizados[index_proceso_autorizado].car_nombre}}</p>
                    </div>
                    <div class="col m4">
                        <label>Tipo</label>
                        <p>{{_procesos_autorizados[index_proceso_autorizado].tpg_descripcion}}</p>
                    </div>
                    <div class="col m8">
                        <label>Descripción</label>
                        <p>{{_procesos_autorizados[index_proceso_autorizado].pgr_descripcion}}</p>
                    </div>
                </div>
                <div class="row">
                    <table class="table tbl-condensed tbl-bordered" v-if="_procesos_autorizados.length > 0">
                        <thead>
                            <th>Módulos</th>
                        </thead>
                        <tbody>
                            <tr v-for="modulo in _procesos_autorizados[index_proceso_autorizado].pgr_moa_modulos_autorizados" :key="modulo">
                                <td>Mod. {{modulo.moa_orden}} - {{modulo.pgr_mod_modulos.mod_nombre}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!--MODAL CREACION DE GRUPO-->
    <div class="modal" id="modal-proceso-grupos" style="width: 95%;">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content">
            <div class="row m0 p0" v-if="index_proceso_autorizado >= 0 && _procesos_autorizados[index_proceso_autorizado]!= undefined">
                <div class="row m0 p0">
                    <div class="col m12">
                        <label>Carrera</label>
                        <p>{{_procesos_autorizados[index_proceso_autorizado].car_nombre}}</p>
                    </div>
                    <div class="col m2">
                        <label>Tipo</label>
                        <p>{{_procesos_autorizados[index_proceso_autorizado].tpg_descripcion}}</p>
                    </div>
                    <div class="col m6">
                        <label>Descripción</label>
                        <p>{{_procesos_autorizados[index_proceso_autorizado].pgr_descripcion}}</p>
                    </div>
                    <div class="col m1">
                        <label>Matrícula</label>
                        <p>${{_procesos_autorizados[index_proceso_autorizado].aut_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</p>
                    </div>
                    <div class="col m1">
                        <label>Cuota</label>
                        <p>${{_procesos_autorizados[index_proceso_autorizado].aut_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</p>
                    </div>
                    <div class="col m2">
                        <label>Cant. Cuotas</label>
                        <p>{{_procesos_autorizados[index_proceso_autorizado].aut_cant_cuotas}}</p>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <br/>
            <div class="row m0 p0" v-if="index_proceso_autorizado >= 0 && _procesos_autorizados[index_proceso_autorizado]!= undefined">
                <fieldset>
                    <form @submit.prevent="setGrupo()">
                        <legend><b>CREAR GRUPO</b></legend>
                        <div class="col m1">
                            <label>Descripción</label>
                            <input type="text" v-model="grupo.grh_descripcion" />
                            <small class="sm-validation">{{$validation(validations_grupo, 'grh_descripcion')}}</small>
                        </div>
                        <div class="col m1">
                            <label>Capacidad</label>
                            <input type="number" min="0" max="100" v-model="grupo.grh_capacidad" />
                            <small class="sm-validation">{{$validation(validations_grupo, 'grh_capacidad')}}</small>
                        </div>
                        <div v-if="index_proceso_autorizado >= 0">
                            <div class="col m2" v-if="_procesos_autorizados[index_proceso_autorizado].tpg_codigo != 'P'">
                            <label>Docente</label>
                            <select class="browser-default" v-model="grupo.grh_codemp">
                                <option value="">Ninguno</option>
                                <option v-for="docente in docentes" :key="docente" :value="docente.emp_codigo">{{ docente.emp_apellidos_nombres }}</option>
                            </select>
                            <small class="sm-validation">{{$validation(validations_grupo, 'grh_codemp')}}</small>
                            </div>
                        </div>
                        <div class="col m2">
                            <label><br /></label>
                            <label><input type="checkbox" class="filled-in" v-model="grupo.grh_autofinanciado" /><span>Autofinanciado</span></label>
                        </div>
                        <div class="col m4">
                            <label>Comentarios</label>
                            <textarea class="materialize-textarea" style="border: solid 1px black; font-size:12px; margin:0px;" rows="5" v-model="grupo.grh_comentarios"></textarea>
                        </div>
                        <div class="col m1">
                            <button class="btn blue-ugb" :disabled="index_grupo_horario >= 0" style="margin-top:15px" type="submit"><i class="material-icons">{{grupo.grh_codigo == 0 ? 'save':'update'}}</i></button>
                        </div>
                        <div class="col m1" v-show="index_grupo >= 0">
                            <button class="btn red darken-2" type="button" style="margin-top:15px" @click="resetGrupo()"><i class="material-icons">close</i></button>
                        </div>
                    </form>
                </fieldset>
            </div>
            <div class="row m0 p0" v-if="index_proceso_autorizado >= 0 && _procesos_autorizados[index_proceso_autorizado] != undefined">
                <table class="table tbl-condensed tbl-bordered" style="font-size: 12px; margin-top:10px">
                    <thead>
                        <tr>
                            <th style="width:5%">#</th>
                            <th style="width:10%">Descripción</th>
                            <th style="width:10%" v-if="index_proceso_autorizado >= 0 && procesos_autorizados[index_proceso_autorizado].tpg_codigo != 'P'">Docente</th>
                            <th style="width:5%">Capacidad</th>
                            <th style="width:5%">Matrícula</th>
                            <th style="width:5%">Cuota</th>
                            <th style="width:8%">Cant. Cuotas</th>
                            <th style="width:5%">Mód. Prog.</th>
                            <th style="width:5%">Autofin.</th>
                            <th style="width:5%"></th>
                            <th style="width:5%"></th>
                            <th style="width:5%">Activo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(grupo, index) in _procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario" :key="grupo" :class="{'grey lighten-2':(index == index_grupo), 'green lighten-4':(index == index_grupo_horario)}">
                            <td class="center">{{index+1}}</td>
                            <td><a href="#" @click="getDetalleHorario(index, grupo.grh_codigo)">{{grupo.grh_descripcion}}</a></td>
                            <td v-if="index_proceso_autorizado >= 0 && procesos_autorizados[index_proceso_autorizado].tpg_codigo != 'P'">{{(grupo.pla_emp_empleado == null ? 'SIN DOCENTE ASIGNADO':grupo.pla_emp_empleado.emp_apellidos_nombres)}}</td>
                            <td class="center">{{grupo.grh_capacidad}}</td>

                            <td style="text-align: right">${{grupo.grh_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td style="text-align: right">${{grupo.grh_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td class="center" style="position:relative">
                                {{grupo.grh_cant_cuotas}}
                                <a href="#!" style="position:absolute; top:0px; right: 0px" @click="modalModificarCosto(index, grupo.grh_codigo)"><i class="material-icons green-text" style="font-size:20px">swap_vertical_circle</i></a>
                            </td>
                            
                            <td class="center">{{grupo.pgr_dhg_detalle_horario_grupo.length}} de {{procesos_autorizados[index_proceso_autorizado].pgr_moa_modulos_autorizados.length}}</td>
                            <td class="center"><label><input type="checkbox" @click.prevent="" class="filled-in" :checked="grupo.grh_autofinanciado" /><span></span></label></td>
                            <td class="center"><a href="#!" @click="editarGrupo(index)"><i class="material-icons">edit</i></a></td>
                            <td class="center"><a v-show="grupo.pgr_dhg_detalle_horario_grupo.length == 0" href="#!" @click="eliminarGrupo(index)"><i class="material-icons">delete</i></a></td>
                            <td class="center"><label><input type="checkbox" @click="setEstadoGrupo(grupo.grh_codigo, $event)" class="filled-in" :checked="grupo.grh_activo" /><span></span></label></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="index_grupo_horario >= 0 && (procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo_horario].grh_comentarios || '') != ''">
                <br>
                <div class="row m0 p0">
                    <div class="col m12">
                        <blockquote>
                            <b>Comentarios:</b> {{procesos_autorizados[index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo_horario].grh_comentarios}}
                        </blockquote>
                    </div>
                </div>
            </div>

            <br/>
            <div class="row m0 p0" style="margin-top:10px; border: dashed 1px black; padding:10px; padding-bottom:50px" v-if="index_grupo_horario >= 0 && procesos_autorizados[index_proceso_autorizado].tpg_codigo == 'P'">
                <table class="table tbl-condensed tbl-bordered tbl-bordered" style="font-size:12px; margin:10px; width: 98%">
                    <caption>HORARIOS <a href="#!" class="right btn red darken-2 btn-small" @click="index_grupo_horario = -1">CANCELAR EDICIÓN <i class="material-icons right">cancel</i></a></caption>
                    <thead>
                        <tr>
                            <th style="width:31%">Módulo</th>
                            <th style="width:15%">Docente</th>
                            <th style="width:5%">Dia</th>
                            <th style="width:5%">Aula</th>
                            <th style="width:10%">Horario</th>
                            <th style="width:6%">Inicio</th>
                            <th style="width:6%">Fin</th>
                            <th style="width:5%">Límite Notas</th>
                            <th colspan="2" style="width: 6%"></th>
                            <th style="width: 11%">Arancel Req.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(detalle, index) in detalle_grupo" :key="detalle">
                            
                            <tr :class="{'orange lighten-4': detalle.dhg_editable}">
                                <td>Mod. {{detalle.moa_orden}} - {{detalle.mod_nombre}}</td>
                                <td>
                                    <span v-if="!detalle.dhg_editable">{{detalle.emp_apellidos_nombres}}</span>
                                    <select v-if="detalle.dhg_editable"  class="browser-default" v-model="detalle.dhg_codemp">
                                        <option value="0">Ninguno</option>
                                        <option v-for="docente in docentes" :key="docente" :value="docente.emp_codigo">{{docente.emp_apellidos_nombres}}</option>
                                    </select>
                                </td>
                                <td class="center">
                                    <span v-if="!detalle.dhg_editable">{{detalle.dh_dia_f}}</span>
                                    <select v-if="detalle.dhg_editable" class="browser-default" v-model="detalle.dhg_dia">
                                        <option v-for="dia in dias" :key="dia" :value="dia.dh_codigo">{{dia.dh_dia_f}}</option>
                                    </select>
                                </td>
                                <td class="center">
                                    <span v-if="!detalle.dhg_editable">{{detalle.aul_nombre_corto}}</span>
                                    <select v-if="detalle.dhg_editable" class="browser-default" v-model="detalle.dhg_aula">
                                        <option v-for="aula in aulas" :value="aula.aul_codigo" :key="aula">{{aula.aul_nombre_corto}}</option>
                                    </select>
                                </td>
                                <td>
                                    <span v-if="!detalle.dhg_editable">{{detalle.man_nomhor}}</span>
                                    <select v-if="detalle.dhg_editable" class="browser-default" v-model="detalle.dhg_codman">
                                        <option v-for="horario in horarios_dia_detalle(detalle.dhg_dia)" :value="horario.man_codigo" :key="horario">{{horario.man_nomhor}}</option>
                                    </select>
                                </td>
                                <td class="center">
                                    <span v-if="!detalle.dhg_editable">{{detalle.dhg_fecha_inicio}}</span>
                                    <datepicker v-if="detalle.dhg_editable" v-model="detalle.dhg_fecha_inicio" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" inlineWithInput />
                                </td>
                                <td class="center">
                                    <span v-if="!detalle.dhg_editable">{{detalle.dhg_fecha_fin}}</span>
                                    <datepicker v-if="detalle.dhg_editable" v-model="detalle.dhg_fecha_fin" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" inlineWithInput />
                                </td>
                                <td class="center">
                                    <span v-if="!detalle.dhg_editable">{{detalle.dhg_fecha_limite}}</span>
                                    <datepicker v-if="detalle.dhg_editable" v-model="detalle.dhg_fecha_limite" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" inlineWithInput />
                                </td>
                                <td>
                                    <a href="#!" v-if="!detalle.dhg_editable" @click="editarDetalleGrupo(index)" title="Editar"><i class="material-icons">edit</i></a>
                                    <a href="#!" v-if="detalle.dhg_editable" @click="setDetalleGrupoEdicion(index)" title="Guardar"><i class="material-icons">save</i></a>
                                </td>
                                <td>
                                    <a href="#!" v-if="(detalle_grupo.length == index + 1) && !detalle.dhg_editable" @click="eliminarDetalleGrupo(detalle.dhg_codigo)" title="Eliminar"><i class="material-icons">delete</i></a>
                                    <a href="#!" v-if="detalle.dhg_editable" @click="cancelEditDetalleGrupo(index)" title="Cancelar edición"><i class="material-icons">cancel</i></a>
                                </td>
                                <td>
                                    <select v-model="detalle.dhg_orden" class="browser-default" v-on:change="setPagoRequerido(detalle.dhg_codigo)">
                                        <option value="null">Seleccione</option>
                                        <option v-for="fecha in fechas_pago" :key="fecha" :value="fecha.fpg_orden">{{fecha.tmo_descripcion}} ({{fecha.fpg_fecha_limite_string}})</option>
                                    </select>
                                </td>
                            </tr>
                        </template>

                        <tr style="padding:20px; min-height: 50px; font-size:12px" v-show="modulos_autorizados.length > 0">
                            <td style="height: 30px">
                                <select class="browser-default" v-model="detalle.dhg_codmoa">
                                    <option value="0" disabled>Seleccione</option>
                                    <template v-for="(modulo, index) in modulos_autorizados" :key="modulo">
                                        <option :value="modulo.moa_codigo" :disabled="index != 0">Mod. {{modulo.moa_orden}} - {{modulo.pgr_mod_modulos.mod_nombre}}</option>
                                    </template>
                                </select>                                
                            </td>
                            <td>
                                <select class="browser-default" v-model="detalle.dhg_codemp">
                                    <option value="0">Ninguno</option>
                                    <option v-for="docente in docentes" :key="docente" :value="docente.emp_codigo">{{docente.emp_apellidos_nombres}}</option>
                                </select>
                            </td>
                            <td>
                                <select class="browser-default" v-model="detalle.dhg_dia" @change="detalle.dhg_codman = 0">
                                    <option v-for="dia in dias" :key="dia" :value="dia.dh_codigo">{{dia.dh_dia_f}}</option>
                                </select>                                
                            </td>
                            <td>
                                <select class="browser-default" v-model="detalle.dhg_aula">
                                    <option value="0">Seleccione</option>
                                    <option v-for="aula in aulas" :key="aula" :value="aula.aul_codigo">{{aula.ra_edi_edificios.edi_nombre}} - {{aula.aul_nombre_corto}}</option>
                                </select>                                
                            </td>
                            <td>
                                <select class="browser-default" v-model="detalle.dhg_codman">
                                    <option value="0">Seleccione</option>
                                    <option v-for="horario in horarios_dia" :key="horario" :value="horario.man_codigo">{{horario.man_nomhor}}</option>
                                </select>
                                
                            </td>
                            <td>
                                <datepicker v-model="detalle.dhg_fecha_inicio" inputFormat="dd/MM/yyyy" vertical="true" :typeable="true" inlineWithInput />
                            </td>
                            <td>
                                <datepicker v-model="detalle.dhg_fecha_fin" inputFormat="dd/MM/yyyy" vertical="true" :typeable="true" inlineWithInput />
                            </td>
                            <td colspan="2" class="center">
                                <a href="#!" @click="setDetalleGrupo()"><i class="material-icons">add_circle</i></a>
                            </td>
                        </tr>
                        <tr style="min-height:0px; font-size:14px">
                            <td><small class="sm-validation">{{$validation(validations_detalle_grupo, 'dhg_codmoa')}}</small></td>
                            <td></td>
                            <td><small class="sm-validation">{{$validation(validations_detalle_grupo, 'dhg_dia')}}</small></td>
                            <td><small class="sm-validation">{{$validation(validations_detalle_grupo, 'dhg_aula')}}</small></td>
                            <td><small class="sm-validation">{{$validation(validations_detalle_grupo, 'dhg_codman')}}</small></td>
                            <td><small class="sm-validation">{{$validation(validations_detalle_grupo, 'dhg_fecha_inicio')}}</small></td>
                            <td><small class="sm-validation">{{$validation(validations_detalle_grupo, 'dhg_fecha_fin')}}</small></td>
                            <td colspan="2"></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!--FIN MODAL CREACION DE GRUPO-->

    <!--MODAL MODIFICAR COSTOS-->
    <div class="modal" id="modal-modificar-costo-grupo">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content">
            <fieldset>
                <legend>ACTUALIZAR COSTO</legend>            
                    <form @submit.prevent="setCosto()">
                        <div class="row">
                            <div class="col m3">
                                <label>Grupo</label>
                                <p>{{costo_nuevo.grh_descripcion}}</p>
                            </div>
                            <div class="col m2">
                                <label>Matrícula</label>
                                <input type="text" v-model="costo_nuevo.grh_matricula" />
                            </div>
                            <div class="col m2">
                                <label>Cuota</label>
                                <input type="text" v-model="costo_nuevo.grh_cuota" />
                            </div>
                            <div class="col m2">
                                <label>Cant. Cuotas</label>
                                <input type="number" v-model="costo_nuevo.grh_cant_cuotas" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col m12">
                                <button class="btn blue-ugb" type="submit">Actualizar</button>
                                <button class="btn red darken-2 modal-close" type="button">Cancelar</button>
                            </div>
                        </div>
                    </form>
            </fieldset>
        </div>
    </div>
    <!--FIN MODAL MODIFICAR COSTOS-->
    <div id="modal-choques-proceso" class="modal" style="width: 80%">
        <div class="modal-content">
            <div class="row m0 p0">
                <div class="col m12">
                    <blockquote>Se encontraron interferencias con los siguientes bloques.</blockquote>
                </div>
            </div>
            <div class="row m0 p0">
                <div class="col m12">
                    <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                        <thead>
                            <tr>
                                <th style="width: 8%">Grupo</th>
                                <th style="width: 20%">Proceso</th>
                                <th>Módulo</th>
                                <th style="width: 5%">Día</th>
                                <th style="width: 8%">Aula</th>
                                <th style="width: 8%">Hora</th>
                                <th style="width: 15%">Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="choque in choques_proceso" :key="choque">
                                <td class="center">{{ choque.grh_descripcion }}</td>
                                <td>{{ choque.pgr_descripcion }}</td>
                                <td>{{ choque.moa_orden }} - {{ choque.mod_nombre }}</td>
                                <td class="center">{{ choque.dh_dia_f }}</td>
                                <td class="center">{{ choque.aul_nombre_corto }}</td>
                                <td class="center">{{ choque.man_hor_ini }} - {{ choque.man_hor_fin }}</td>
                                <td class="center">{{ choque.dhg_fecha_inicio }} al {{ choque.dhg_fecha_fin }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close btn blue-ugb">Aceptar</a>
        </div>
    </div>
        
</template>

<script>
import Datepicker from 'vue3-datepicker'
import M from 'materialize-css'
    export default {
    components: {
        Datepicker
    },
    data() {
        return {
            procesos_autorizados: [],
            grupos: [],
            grupo: {},
            detalle_grupo: [],
            detalle: {},
            index_proceso_autorizado: -1,
            index_grupo: -1,
            index_grupo_horario: -1,
            modales: [],
            modal_modulos: {},
            modal_grupos: {},
            modal_costo_nuevo: {},
            docentes: [],
            validations_grupo: [],
            dias: [],
            aulas: [],
            horarios: [],
            validations_detalle_grupo: [],
            costo_nuevo: {},
            carreras: [],
            codcar: 0,
            tipos_procesos: [],
            codtpg: 0,
            grupo_filter: -1,
            fechas_pago: [],
            search: '',
            choques_proceso: []
        };
    },
    created()
    {
        this.grupo.grh_codigo = 0;
        this.grupo.grh_codemp = '';
        this.grupo.grh_codigo = 0;
        this.grupo.grh_codaut = 0;
        this.resetDetalleGrupo();        
    },
    async mounted() {
        await this.getProcesosAutorizados();
        var elems = document.querySelectorAll(".modal");
        this.modales = M.Modal.init(elems, {
                        dismissible: false
                    });        
        await this.getDocentes();
        await this.getDias();
        await this.getHorarios();
        await this.getAulas();
        await this.getCarreras();
        await this.getTiposProcesos();
    },
    methods: {
        resetDetalleGrupo()
        {
            this.detalle.dhg_codemp = 0;
            this.detalle.dhg_codgrh = 0;
            this.detalle.dhg_dia = 1;
            this.detalle.dhg_codman = 0;
            this.detalle.dhg_aula = 0;
            this.detalle.dhg_codmoa = 0;
            this.detalle.dhg_fecha_inicio = new Date();
            var result = new Date();
            result.setDate(result.getDate() + 30)
            this.detalle.dhg_fecha_fin = result;
        },
        async getProcesosAutorizados() {
            this.$root.startLoading();
            await this.axios.get("/ProcesosGraduacionAutorizacion")
                .then(response => {
                this.procesos_autorizados = response.data;
            });
            this.$root.stopLoading();
        },
        modalModulos(index) {
            this.index_proceso_autorizado = index;
            this.modal_modulos = M.Modal.getInstance(document.querySelector("#modal-modulos-autorizados"));
            this.modal_modulos.open();
        },
        modalGrupos(index) {
            this.validations_detalle_grupo = [];
            this.validations_grupo = [];
            this.index_proceso_autorizado = index;
            this.index_grupo_horario = -1;
            this.modal_grupos = M.Modal.getInstance(document.querySelector("#modal-proceso-grupos"));
            this.modal_grupos.open();
        },
        async getDocentes()
        {
            this.$root.startLoading();
            await this.axios.get('/Empleados/Docentes')
            .then(response => {
                this.docentes = response.data;
            });
            this.$root.stopLoading();
        },
        async setGrupo()
        {
           this.grupo.grh_codaut = this.procesos_autorizados[this.index_proceso_autorizado].aut_codigo;
           this.grupo.grh_matricula = this.procesos_autorizados[this.index_proceso_autorizado].aut_matricula;
           this.grupo.grh_cuota = this.procesos_autorizados[this.index_proceso_autorizado].aut_cuota;
           this.grupo.grh_cant_cuotas = this.procesos_autorizados[this.index_proceso_autorizado].aut_cant_cuotas;
           this.validations_grupo = [];
           this.$root.startLoading();
           await this.axios.post('/ProcesosGraduacionGrupo', this.grupo)
           .then(response => {
                var index_grupo = this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario.findIndex(x=>x.grh_codigo == response.data.grh_codigo);
                if(index_grupo == -1)
                {
                    this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario.push(response.data);
                }
                else
                {
                    this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo] = response.data;
                }
                this.resetGrupo();
           })
           .catch(error => {
                if(error.response.data.type == 'M')
                {
                    this.validations_grupo = error.response.data.modelErrors;
                    M.toast({html: 'Complete todos los campos.'});
                }
                if(error.response.data.type == 'C')
                {
                    M.toast({html: error.response.data.message});
                }
           });
           this.$root.stopLoading();
        },
        editarGrupo(index)
        {
            this.index_grupo = index;
            this.index_grupo_horario = -1;
            this.grupo = this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index];
            this.grupo.grh_codemp = this.grupo.grh_codemp || '';
            console.log(this.grupo.grh_codemp);
        },
        async eliminarGrupo(index)
        {
            if(!confirm('¿Desea eliminar este grupo?')) return false;
            this.$root.startLoading();
            await this.axios.delete('/ProcesosGraduacionGrupo/' + this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index].grh_codigo)
            .then(() => {
                this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario.splice(index, 1);
                if(index == this.index_grupo)
                {
                    this.resetGrupo();
                }
            })
            .catch(error => {
                if(error.response.data.type == 'C')
                {
                    M.toast({html: error.response.data.message});
                }
            });
            this.$root.stopLoading();
        },
        resetGrupo()
        {
            this.index_grupo = -1;
            this.grupo = {};
            this.grupo.grh_codemp = '';
            this.grupo.grh_codigo = 0;
            this.grupo.grh_descripcion = '';
        },
        async getDetalleHorario(index, codgrh)
        {
            this.index_grupo_horario = index;
            this.resetGrupo();
            this.$root.startLoading();
            await this.axios.get('/ProcesosGraduacionGrupoHorario/' + codgrh + '/Bloques')
            .then(response => {
                this.detalle_grupo = response.data;
                if(this.modulos_autorizados.length > 0)
                {
                    this.detalle.dhg_codmoa = this.modulos_autorizados[0].moa_codigo;                    
                }
                if(this.detalle_grupo.length > 0)
                {
                    this.detalle.dhg_fecha_inicio = this.toDateFormat(this.detalle_grupo[this.detalle_grupo.length - 1].dhg_fecha_fin, 1);
                    this.detalle.dhg_fecha_fin = this.toDateFormat(this.detalle_grupo[this.detalle_grupo.length - 1].dhg_fecha_fin, 30);
                }
                else
                {
                    this.detalle.dhg_fecha_inicio = new Date();
                    var resultDate = new Date();
                    resultDate.setDate(resultDate.getDate() + 30);
                    this.detalle.dhg_fecha_fin = resultDate;
                }
            });
            this.$root.stopLoading();
            await this.getFechasPago(codgrh);
        },
        toDateFormat(date, plus)
        {
            var day = parseInt(date.substring(0, 2));
            var month = parseInt(date.substring(3,5)) - 1;
            var year = parseInt(date.substring(6,10));
            var result = new Date(year, month, day);
            result.setDate(result.getDate() + (plus || 0))
            return result;
        },
        addDays()
        {

        },
        async getDias()
        {
            this.$root.startLoading();
            await this.axios.get('/Dias')
            .then(response => {
                this.dias = response.data;
            });
            this.$root.stopLoading();
        },
        async getAulas()
        {
            this.$root.startLoading();
            await this.axios.get('/Aulas')
            .then(response => {
                this.aulas = response.data;
            });
            this.$root.stopLoading();
        },
        async getHorarios()
        {
            this.$root.startLoading();
            await this.axios.get('/Horarios/BloquesPre')
            .then(response => {
                this.horarios = response.data;
            });
            this.$root.stopLoading();
        },
        async setDetalleGrupo()
        {
            this.validations_detalle_grupo = [];
            this.choques_proceso = [];
            this.detalle.dhg_codgrh = this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[this.index_grupo_horario].grh_codigo;
            var detalle = {
                dhg_codgrh: this.detalle.dhg_codgrh,
                dhg_dia: this.detalle.dhg_dia,
                dhg_codman: this.detalle.dhg_codman,
                dhg_aula: this.detalle.dhg_aula,
                dhg_codmoa: this.detalle.dhg_codmoa,
                dhg_fecha_inicio: this.detalle.dhg_fecha_inicio,
                dhg_fecha_fin: this.detalle.dhg_fecha_fin,
                dhg_codemp: this.detalle.dhg_codemp
            };
            this.$root.startLoading();
            await this.axios.post('/ProcesosGraduacionGrupoHorario', detalle)
            .then(async () => {
                await this.getDetalleHorario(this.index_grupo_horario, this.detalle.dhg_codgrh);
                this.resetDetalleGrupo();                
                if(this.modulos_autorizados.length > 0)
                {
                    this.detalle.dhg_codmoa = this.modulos_autorizados[0].moa_codigo;                    
                }
                
                this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[this.index_grupo_horario].pgr_dhg_detalle_horario_grupo = this.detalle_grupo;
            })
            .catch(error => {
                if(error.response.data.type == 'M')
                {
                    this.validations_detalle_grupo = error.response.data.modelErrors;
                    M.toast({html: 'Complete todos los campos.'});
                }
                if(error.response.data.type == 'C')
                {
                    M.toast({html: error.response.data.message});
                    if(error.response.data.data != null)
                    {
                        console.log(error.response.data.data);
                        this.choques_proceso = error.response.data.data;
                        var modal_choque = M.Modal.getInstance(document.querySelector('#modal-choques-proceso'));
                        modal_choque.open();
                    }
                }
           });
           this.$root.stopLoading();
        },
        async setDetalleGrupoEdicion(index)
        {
            this.$root.startLoading();
            await this.axios.post('/ProcesosGraduacionGrupoHorario', this.detalle_grupo[index])
            .then(async () => {
                this.detalle_grupo[index].dhg_fecha_fin = this.detalle_grupo[index].dhg_fecha_fin_back;
                this.detalle_grupo[index].dhg_fecha_inicio = this.detalle_grupo[index].dhg_fecha_inicio_back;
                this.detalle_grupo[index].dhg_editable = false;
                M.toast({html: 'El cambio ha sido guardado.'});
                this.$root.stopLoading();
                await this.getDetalleHorario(this.index_grupo_horario, this.detalle_grupo[index].dhg_codgrh);
            })
            .catch(error => {
                if(error.response.data.type == 'M')
                {
                    this.validations_detalle_grupo = error.response.data.modelErrors;
                    M.toast({html: 'Complete todos los campos.'});
                }
                if(error.response.data.type == 'C')
                {
                    M.toast({html: error.response.data.message});
                    if(error.response.data.data != null)
                    {
                        this.choques_proceso = error.response.data.data;
                        var modal_choque = M.Modal.getInstance(document.querySelector('#modal-choques-proceso'));
                        modal_choque.open();
                    }
                }
                this.$root.stopLoading();
           });
        },
        async eliminarDetalleGrupo(coddhg)
        {
            if(confirm('¿Desea eliminar este horario?'))
            {
                this.$root.startLoading();
                await this.axios.delete('/ProcesosGraduacionGrupoHorario/' + coddhg)
                .then(async () => {
                    await this.getDetalleHorario(this.index_grupo_horario, this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[this.index_grupo_horario].grh_codigo);
                    this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[this.index_grupo_horario].pgr_dhg_detalle_horario_grupo = this.detalle_grupo;
                });
                this.$root.stopLoading();
            }
        },
        editarDetalleGrupo(index)
        {
            this.detalle_grupo[index].dhg_fecha_fin_back = this.detalle_grupo[index].dhg_fecha_fin;
            this.detalle_grupo[index].dhg_fecha_fin = new Date(this.detalle_grupo[index].dhg_fecha_fin.split('/').reverse().join('-') + 'T00:00:00');
            this.detalle_grupo[index].dhg_fecha_inicio_back = this.detalle_grupo[index].dhg_fecha_inicio;
            this.detalle_grupo[index].dhg_fecha_inicio = new Date(this.detalle_grupo[index].dhg_fecha_inicio.split('/').reverse().join('-') + 'T00:00:00');
            this.detalle_grupo[index].dhg_fecha_limite_back = this.detalle_grupo[index].dhg_fecha_limite;
            this.detalle_grupo[index].dhg_fecha_limite = new Date(this.detalle_grupo[index].dhg_fecha_limite.split('/').reverse().join('-') + 'T00:00:00');
            this.detalle_grupo[index].dhg_editable = true;
        },
        cancelEditDetalleGrupo(index)
        {
            this.detalle_grupo[index].dhg_fecha_fin = this.detalle_grupo[index].dhg_fecha_fin_back;
            this.detalle_grupo[index].dhg_fecha_inicio = this.detalle_grupo[index].dhg_fecha_inicio_back;
            this.detalle_grupo[index].dhg_editable = false;
        },
        horarios_dia_detalle(dia)
        {
            return this.horarios.filter(x=>x.dh_codigo == dia);
        },
        modalModificarCosto(index, codgrh)
        {
            this.costo_nuevo = {
                grh_codigo: codgrh,
                grh_descripcion: this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index].grh_descripcion,
                grh_matricula: this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index].grh_matricula,
                grh_cuota: this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index].grh_cuota,
                grh_cant_cuotas: this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index].grh_cant_cuotas
            };
            this.modal_costo_nuevo = M.Modal.getInstance(document.querySelector("#modal-modificar-costo-grupo"));
            this.modal_costo_nuevo.open();
        },
        async setCosto()
        {
            this.$root.startLoading();
            await this.axios.patch('/ProcesosGraduacionGrupo/' + this.costo_nuevo.grh_codigo + '/Costo', this.costo_nuevo)
            .then(() => {
                M.toast({html: 'El costo ha sido actualizado.'});
                var index_grupo = this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario.findIndex(x=>x.grh_codigo == this.costo_nuevo.grh_codigo);
                this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_matricula = parseFloat(this.costo_nuevo.grh_matricula);
                this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_cuota = parseFloat(this.costo_nuevo.grh_cuota);
                this.procesos_autorizados[this.index_proceso_autorizado].pgr_grh_grupo_horario[index_grupo].grh_cant_cuotas = this.costo_nuevo.grh_cant_cuotas;
            })
            .catch(error => {
                M.toast({html: error.response.data.message});
            });
            this.$root.stopLoading();
        },
        async getCarreras()
        {
            this.$root.startLoading();
            await this.axios.get('/ProcesosGraduacionAutorizacion/Carreras')
            .then(response => {
                this.carreras = response.data;
            });
            this.$root.stopLoading();
        },
        async getTiposProcesos()
        {
            this.$root.startLoading();
            await this.axios.get('/TipoProcesoGraduacion')
            .then(response => {
                this.tipos_procesos = response.data.filter(x=>x.tpg_estado);
            });
            this.$root.stopLoading();
        },
        async getFechasPago(codgrh)
        {
            this.$root.startLoading();
            await this.axios.get('/ProcesosGraduacionGrupo/' + codgrh + '/Fechas')
            .then(response => {
                this.fechas_pago = response.data;
            });
            this.$root.stopLoading();
        },
        async setPagoRequerido(coddhg)
        {
            var index_detalle = this.detalle_grupo.findIndex(x=>x.dhg_codigo == coddhg);
            var fpg_orden = this.detalle_grupo[index_detalle].dhg_orden;
            var index_fecha = this.fechas_pago.findIndex(x=>x.fpg_orden == fpg_orden);
            var payload = {
                dhg_codigo: this.detalle_grupo[index_detalle].dhg_codigo,
                dhg_orden: index_fecha == -1 ? null:this.detalle_grupo[index_detalle].dhg_orden,
                dhg_codtmo: index_fecha == -1 ? null:this.fechas_pago[index_fecha].fpg_orden
            };
            await this.axios.post('/ProcesosGraduacionGrupoHorario/' + coddhg + '/ArancelRequerido', payload)
            .then(() => {

            });
        },
        async setEstadoGrupo(codgrh, event)
        {
            await this.axios.patch('/ProcesosGraduacionGrupo/' + codgrh + '/Estado/' + event.target.checked)
            .then(() => {
                M.toast({html: 'El grupo ha sido ' + (event.target.checked ? 'activado':'inactivado') + '.'});
            });
        }
    },
    computed: {
        horarios_dia()
        {
            return this.horarios.filter(x=>x.dh_codigo == this.detalle.dhg_dia);
        },
        modulos_autorizados()
        {
            if(this.index_proceso_autorizado == -1) return [];
            return this.procesos_autorizados[this.index_proceso_autorizado].pgr_moa_modulos_autorizados.filter(x=>this.detalle_grupo.find(y=>y.dhg_codmoa == x.moa_codigo) == undefined);
        },
        _procesos_autorizados() {
            return this.procesos_autorizados
                        .filter(x=>x.car_codigo == (this.codcar == 0 ? x.car_codigo:this.codcar)
                                && x.tpg_codigo == (this.codtpg == 0 ? x.tpg_codigo:this.codtpg)
                                && (this.grupo_filter == -1 || (this.grupo_filter == 0 && x.pgr_grh_grupo_horario.length == 0) || (this.grupo_filter == 1 && x.pgr_grh_grupo_horario.length > 0))
                                &&
                                    (
                                        x.car_nombre.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
                                        x.tpg_descripcion.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
                                        x.pgr_descripcion.toLowerCase().indexOf(this.search.toLowerCase()) != -1
                                    )
                                );
        }
    }
}
</script>

<style scoped>
    #modal-proceso-grupos
    {
        overflow-y: scroll !important;
        overflow-x: visible;
        min-height: 600px !important;
        top: 10px !important
    }
    .vdp-datepicker__calendar {
        position: fixed !important;
        z-index: 10000
    }

    p
    {
        padding: 0px;
        margin: 0px
    }
    </style>