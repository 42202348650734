<template>
    <div class="row">
        <div class="col m12">
            <h5>Horarios sin docente</h5>
        </div>
    </div>
    <div class="row">
        <div class="col m4">
            <label>Facultad</label>
            <select class="browser-default" v-model="codfac" @change="codcar = 0">
                <option value="0">Todas</option>
                <option v-for="facultad in facultades_filter" :key="facultad" :value="facultad.fac_codigo">{{ facultad.fac_nombre }}</option>
            </select>
        </div>
        <div class="col m4">
            <label>Carrera</label>
            <select class="browser-default" v-model="codcar">
                <option value="0">Todas</option>
                <option v-for="carrera in carreras_filter" :key="carrera" :value="carrera.car_codigo">{{ carrera.car_nombre }}</option>
            </select>
        </div>
        <div class="col m2">
            <button class="btn blue-ugb" @click="getFacultades()">Recargar <i class="material-icons right">refresh</i></button>
        </div>
    </div>
    <div class="row">
        <div class="col m12" style="height: 400px; overflow: auto">
            <table class="table tbl-condensed tbl-bordered striped" style="font-size: 12px">
                <tbody>
                    <template v-for="facultad in sin_horarios" :key="facultad">
                        <tr class="black white-text"  v-show="facultad.ra_car_carreras.length > 0">
                            <th colspan="3">{{ facultad.fac_nombre }}</th>
                        </tr>
                        <template v-for="carrera in facultad.ra_car_carreras" :key="carrera">
                            <tr class="grey darken-2 white-text" v-show="carrera.ra_pla_planes.length > 0">
                                <th colspan="3">{{carrera.car_nombre}}</th>
                            </tr>

                            <template v-for="plan in carrera.ra_pla_planes" :key="plan">
                                <tr v-show="plan.ra_mat_materias.length > 0">
                                    <th colspan="3">{{ plan.pla_nombre }}</th>
                                </tr>
                                <tr>
                                    <th class="center">Materia</th>
                                    <th class="center">Ciclo</th>
                                    <th></th>
                                </tr>
                                <template v-for="materia in plan.ra_mat_materias" :key="materia">
                                    <tr>
                                        <td style="width: 80%"><span class="badge new" data-badge-caption="">{{materia.hor_descripcion}}</span> {{ materia.mat_codigo }} - {{ materia.mat_nombre }}</td>
                                        <td style="width: 10%" class="center">{{ materia.plm_ciclo }}</td>
                                        <td style="width: 10%" class="center">
                                            <a href="#!" class="" @click="editarHorario(materia.hor_codigo, plan.pla_codigo, materia.mat_codigo)" ><i class="material-icons">edit</i></a>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
    <ModificarHorario ref="modificarHorario"></ModificarHorario>
</template>

<script>
import ModificarHorario from '../components/ModificarHorarioComponent'
import M from 'materialize-css'
    export default {
        components: {
            ModificarHorario
        },
        data(){
            return {
                facultades: [],
                codfac: 0,
                codcar: 0,
                codmat: '',
                codpla: 0,
                modales: []
            }
        },
        async mounted()
        {
            await this.init();
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
        },
        methods: {
            async editarHorario(codhor, codpla, codmat)
            {
                this.codmat = codmat;
                this.codpla = codpla;
                this.$root.startLoading();
                await this.$refs.modificarHorario.getHorario(codhor, 0, 0, 0, 0, codpla, codmat, false, 'N');
                this.$root.stopLoading();
            },
            async init()
            {
                await this.getFacultades();
            },
            async getFacultades()
            {
                this.$root.startLoading();
                await this.axios.get('/MateriasProgramadas/SinDocente/' + this.codfac + '/' + this.codcar)
                .then(response => {
                    this.facultades = response.data;
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            facultades_filter() {
                var facultades = [];
                for(let i = 0; i <= this.facultades.length - 1; i++)
                {
                    facultades.push({
                        fac_codigo: this.facultades[i].fac_codigo,
                        fac_nombre: this.facultades[i].fac_nombre,
                    })
                }
                return facultades;
            },
            carreras_filter(){
                var carreras = [];
                for(let i = 0; i <= this.facultades.length - 1; i++)
                {
                    for(let j = 0; j <= this.facultades[i].ra_car_carreras.length - 1; j++)
                    {
                        if(carreras.findIndex(x=>x.car_codigo == this.facultades[i].ra_car_carreras[j].car_codigo) == -1)
                        {
                            if(this.codfac == 0 || this.codfac == this.facultades[i].fac_codigo)
                            {
                                carreras.push({
                                    car_codigo: this.facultades[i].ra_car_carreras[j].car_codigo,
                                    car_nombre: this.facultades[i].ra_car_carreras[j].car_nombre,
                                });
                            }
                        }
                    }
                }
                return carreras;
            },
            sin_horarios()
            {
                if(this.codfac == 0 && this.codcar == 0) return this.facultades;
                var facultades = [];
                for(let i = 0; i <= this.facultades.length - 1; i++)
                {
                    var facultad = [];
                    if(this.facultades[i].fac_codigo == this.codfac || this.codfac == 0)
                    {
                        var carreras = [];
                        for(let j = 0; j <= this.facultades[i].ra_car_carreras.length - 1; j++)
                        {
                            if(this.facultades[i].ra_car_carreras[j].car_codigo == this.codcar || this.codcar == 0)
                            {
                                carreras.push(this.facultades[i].ra_car_carreras[j]);
                            }
                            facultad = {
                                fac_codigo: this.facultades[i].fac_codigo,
                                fac_nombre: this.facultades[i].fac_nombre,
                                ra_car_carreras: carreras
                            };
                            
                        }
                        facultades.push(facultad);
                    }
                }
                return facultades;
            }
        }
    }
</script>