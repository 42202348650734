<template>
  <div class="row">
    <div class="row">
        <div class="col m3">
            <button class="btn blue-ugb" @click="notificarAcademica()">Notificar Carga Académica <i class="material-icons right">email</i></button>
        </div>
        <div class="col m3">
            <a class="btn green darken-2" href="/HorariosAulaVirtualComponent" target="_blank">Aula Virtual <i class="material-icons right">computer</i></a>
        </div>

        <a href="#!" @click="crear" title="Agregar horario" class="right"><i class="material-icons" style="font-size:35px">add_circle_outline</i></a>
    </div>
    <div class="row card-panel blue-ugb">
        <div class="col m2">
            <label class="white-text">Edificio</label>
            <select v-model="codedi" class="browser-default" v-on:change="filtrar">
                <option value="0">Todos</option>
                <option v-for="edificio in edificios" v-bind:key="edificio" v-bind:value="edificio.edi_codigo">{{edificio.edi_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label class="white-text">Aula</label>
            <select v-model="codaul" class="browser-default" v-on:change="filtrar">
                <option value="0">Todas</option>
                <option v-for="aula in aulas_view" v-bind:key="aula" v-bind:value="aula.aul_codigo">{{aula.aul_nombre_corto}}</option>
            </select>
        </div>
        <div class="col m2">
            <label class="white-text">Facultad</label>
            <select v-model="codfac" class="browser-default" v-on:change="filtrar">
                <option value="0">Todas</option>
                <option v-for="facultad in facultades" v-bind:key="facultad" v-bind:value="facultad.fac_codigo">{{facultad.fac_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label class="white-text">Carrera</label>
            <select v-model="codcar" class="browser-default" v-on:change="filtrar(); getNiveles()">
                <option value="0">Todas</option>
                <option v-for="carrera in carreras_view" v-bind:key="carrera" v-bind:value="carrera.car_codigo">{{carrera.car_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label class="white-text">Nivel</label>
            <select v-model="plm_ciclo" class="browser-default" v-on:change="filtrar">
                <option value="-1">Todos</option>
                <option v-for="nivel in niveles" v-bind:key="nivel" v-bind:value="nivel">Ciclo {{nivel}}</option>
            </select>
        </div>
        <div class="col m2">
            <label class="white-text">Opción de filas</label>
            <select class="browser-default" v-model="opcionFila" @change="filtrar">
                <option value="0">Mostrar todas</option>
                <option value="1">Ocultar vacías</option>
                <option value="2">Mostrar vacías</option>
            </select>
        </div>
    </div>
    <label class="right"><input type="checkbox" class="filled-in" @change="ocultarOcupados($event)"><span>OCULTAR NO DISPONIBLES/EDITABLES</span></label>

    <div class="row">
        <div class="col m12 m0 p0" style="width:100%; overflow:auto;">
        <table class="table tbl-condensed bordered" style="font-size:12px" id="tbl-grid-horarios">
            <thead>
                <tr>
                    <th>Edificio</th>
                    <th>Aula</th>
                    <th>Lunes</th>
                    <th>Martes</th>
                    <th>Miercoles</th>
                    <th>Jueves</th>
                    <th>Viernes</th>
                    <th>Sábado</th>
                    <th>Domingo</th>
                </tr>
            </thead>
            <tbody>
            <template v-for="(edificio, key_edificio) in bloques_salas_view" v-bind:key="edificio">
                <template v-for="(sala, key_sala) in edificio.salas" v-bind:key="sala">
                    <tr v-for="(horario, key_horario) in sala.horarios" v-bind:key="horario">
                        <td class="black white-text" v-if="key_sala == 0 && key_horario == 0" v-bind:rowspan="edificiosRowspan(key_edificio)" style="vertical-align: text-top">{{edificio.edi_nombre}}</td>
                        <td class="grey darken-4 white-text" v-if="key_horario == 0" v-bind:rowspan="sala.horarios.length" style="vertical-align: text-top;">
                            <span>Sala: {{sala.aul_nombre_corto}} <br>Capacidad: {{sala.aul_capacidad}} <br>Laboratorio: {{sala.aul_lab}}<br>Tipo: {{sala.tau_tiposala}}</span></td>
                        
                        <td v-bind:class="{'td-horario-selected': bloqueSeleccionado(edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 1)}" class="center td-horario" @click.ctrl="seleccionarHorario(horario.lunesHorario, edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 1, horario.lunes != null, horario.lunesHorario == null ? true:horario.lunesHorario.editable)">
                            <div v-show="!ocultarOcupada(horario, 1)" class="hor-detail" v-if="(horario.lunesHorario != null)">
                                <span class="badge new descripcion-grupo" v-bind:class="{'red darken-4': !horario.lunesHorario.hor_publicado}" v-bind:data-badge-caption="(horario.lunesHorario.car_nombre_corto + ' - ' + horario.lunesHorario.pla_nombre)">{{horario.lunesHorario.hor_descripcion}}</span>
                                <p>{{horario.lunesHorario.mat_codigo}} - {{horario.lunesHorario.mat_nombre}}</p>
                                <p>{{horario.lunesHorario.emp_nombres_apellidos}}</p>
                                <span class="badge new detalle-grupo" data-badge-caption="">{{horario.lunesHorario.dho_tipo_descripcion}} Max: {{horario.lunesHorario.dho_capacidad}} <span v-show="horario.lunesHorario.dho_virtual">| Virtual</span></span>
                            </div>
                            <label class="lbl-horario" v-show="(horario.lunes != undefined)">{{horario.lunes}}</label>
                            <i class="material-icons icono-cruzada" title="Permite inscripcion cruzada." v-if="permiteCruzada(horario, 1)">swap_horiz</i>
                            <a href="#!" v-if="(horario.lunes != null && horario.lunesHorario == null && !carreraFiltrada)" @click.prevent="agregar(0, 0, horario.man_codigo, edificio.edi_codigo, sala.aul_codigo, 1)"><i class="material-icons bottom-right-absolute">add_circle_outline</i></a>
                            <a href="#!" v-if="(horario.lunes != null && horario.lunesHorario != null && horario.lunesHorario.editable)" @click.prevent="editar(horario.lunesHorario.hor_codigo, sala.aul_codigo, horario.man_codigo, edificio.edi_codigo, 1)"><i class="material-icons bottom-right-absolute">create</i></a>
                        </td>

                        <td v-bind:class="{'td-horario-selected': bloqueSeleccionado(edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 2)}" class="center td-horario" @click.ctrl="seleccionarHorario(horario.martesHorario, edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 2, horario.martes != null, horario.martesHorario == null ? true:horario.martesHorario.editable)">
                            <div v-show="!ocultarOcupada(horario, 2)" class="hor-detail" v-if="(horario.martesHorario != null)">
                                <span class="badge new descripcion-grupo" v-bind:class="{'red darken-4': !horario.martesHorario.hor_publicado}" v-bind:data-badge-caption="(horario.martesHorario.car_nombre_corto + ' - ' + horario.martesHorario.pla_nombre)">{{horario.martesHorario.hor_descripcion}}</span>
                                <p>{{horario.martesHorario.mat_codigo}} - {{horario.martesHorario.mat_nombre}}</p>
                                <p>{{horario.martesHorario.emp_nombres_apellidos}}</p>
                                <span class="badge new detalle-grupo" data-badge-caption="">{{horario.martesHorario.dho_tipo_descripcion}} Max: {{horario.martesHorario.dho_capacidad}} <span v-show="horario.martesHorario.dho_virtual">| Virtual</span></span>
                            </div>
                            <label class="lbl-horario" v-show="(horario.martes != null)">{{horario.martes}}</label>
                            <i class="material-icons icono-cruzada" title="Permite inscripcion cruzada." v-if="permiteCruzada(horario, 2)">swap_horiz</i>
                            <a href="#!" v-if="(horario.martes != null && horario.martesHorario == null && !carreraFiltrada)" @click.prevent="agregar(0, 0, horario.man_codigo, edificio.edi_codigo, sala.aul_codigo, 2)"><i class="material-icons bottom-right-absolute">add_circle_outline</i></a>
                            <a href="#!" v-if="(horario.martes != null && horario.martesHorario != null && horario.martesHorario.editable)" @click.prevent="editar(horario.martesHorario.hor_codigo, sala.aul_codigo, horario.man_codigo, edificio.edi_codigo, 2)"><i class="material-icons bottom-right-absolute">create</i></a>
                        </td>

                        <td v-bind:class="{'td-horario-selected': bloqueSeleccionado(edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 3)}" class="center td-horario" @click.ctrl="seleccionarHorario(horario.miercolesHorario, edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 3, horario.miercoles != null, horario.miercolesHorario == null ? true:horario.miercolesHorario.editable)">
                            <div v-show="!ocultarOcupada(horario, 3)" class="hor-detail" v-if="(horario.miercolesHorario != null)">
                                <span class="badge new descripcion-grupo" v-bind:class="{'red darken-4': !horario.miercolesHorario.hor_publicado}" v-bind:data-badge-caption="(horario.miercolesHorario.car_nombre_corto + ' - ' + horario.miercolesHorario.pla_nombre)">{{horario.miercolesHorario.hor_descripcion}}</span>
                                <p>{{horario.miercolesHorario.mat_codigo}} - {{horario.miercolesHorario.mat_nombre}}</p>
                                <p>{{horario.miercolesHorario.emp_nombres_apellidos}}</p>
                                <span class="badge new detalle-grupo" data-badge-caption="">{{horario.miercolesHorario.dho_tipo_descripcion}} Max: {{horario.miercolesHorario.dho_capacidad}} <span v-show="horario.miercolesHorario.dho_virtual">| Virtual</span></span>
                            </div>
                            <label class="lbl-horario" v-show="(horario.miercoles != null)">{{horario.miercoles}}</label>
                            <i class="material-icons icono-cruzada" title="Permite inscripcion cruzada." v-if="permiteCruzada(horario, 3)">swap_horiz</i>
                            <a href="#!" v-if="(horario.miercoles != null && horario.miercolesHorario == null && !carreraFiltrada)" @click.prevent="agregar(0, 0, horario.man_codigo, edificio.edi_codigo, sala.aul_codigo, 3)"><i class="material-icons bottom-right-absolute">add_circle_outline</i></a>
                            <a href="#!" v-if="(horario.miercoles != null && horario.miercolesHorario != null && horario.miercolesHorario.editable)" @click.prevent="editar(horario.miercolesHorario.hor_codigo, sala.aul_codigo, horario.man_codigo, edificio.edi_codigo, 3)"><i class="material-icons bottom-right-absolute">create</i></a>
                        </td>

                        <td v-bind:class="{'td-horario-selected': bloqueSeleccionado(edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 4)}" class="center td-horario" @click.ctrl="seleccionarHorario(horario.juevesHorario, edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 4, horario.jueves != null, horario.juevesHorario == null ? true:horario.juevesHorario.editable)">
                            <div v-show="!ocultarOcupada(horario, 4)" class="hor-detail" v-if="(horario.juevesHorario != null)">
                                <span class="badge new descripcion-grupo" v-bind:class="{'red darken-4': !horario.juevesHorario.hor_publicado}" v-bind:data-badge-caption="(horario.juevesHorario.car_nombre_corto + ' - ' + horario.juevesHorario.pla_nombre)">{{horario.juevesHorario.hor_descripcion}}</span>
                                <p>{{horario.juevesHorario.mat_codigo}} - {{horario.juevesHorario.mat_nombre}}</p>
                                <p>{{horario.juevesHorario.emp_nombres_apellidos}}</p>
                                <span class="badge new detalle-grupo" data-badge-caption="">{{horario.juevesHorario.dho_tipo_descripcion}} Max: {{horario.juevesHorario.dho_capacidad}} <span v-show="horario.juevesHorario.dho_virtual">| Virtual</span></span>
                            </div>
                            <label class="lbl-horario" v-show="(horario.jueves != null)">{{horario.jueves}}</label>
                            <i class="material-icons icono-cruzada" title="Permite inscripcion cruzada." v-if="permiteCruzada(horario, 4)">swap_horiz</i>
                            <a href="#!" v-if="(horario.jueves != null && horario.juevesHorario == null && !carreraFiltrada)" @click.prevent="agregar(0, 0, horario.man_codigo, edificio.edi_codigo, sala.aul_codigo, 4)"><i class="material-icons bottom-right-absolute">add_circle_outline</i></a>
                            <a href="#!" v-if="(horario.jueves != null && horario.juevesHorario != null && horario.juevesHorario.editable)" @click.prevent="editar(horario.juevesHorario.hor_codigo, sala.aul_codigo, horario.man_codigo, edificio.edi_codigo, 4)"><i class="material-icons bottom-right-absolute">create</i></a>
                        </td>

                        <td v-bind:class="{'td-horario-selected': bloqueSeleccionado(edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 5)}" class="center td-horario" @click.ctrl="seleccionarHorario(horario.viernesHorario, edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 5, horario.viernes != null, horario.viernesHorario == null ? true:horario.viernesHorario.editable)">
                            <div v-show="!ocultarOcupada(horario, 5)" class="hor-detail" v-if="(horario.viernesHorario != null)">
                                <span class="badge new descripcion-grupo" v-bind:class="{'red darken-4': !horario.viernesHorario.hor_publicado}" v-bind:data-badge-caption="(horario.viernesHorario.car_nombre_corto + ' - ' + horario.viernesHorario.pla_nombre)">{{horario.viernesHorario.hor_descripcion}}</span>
                                <p>{{horario.viernesHorario.mat_codigo}} - {{horario.viernesHorario.mat_nombre}}</p>
                                <p>{{horario.viernesHorario.emp_nombres_apellidos}}</p>
                                <span class="badge new detalle-grupo" data-badge-caption="">{{horario.viernesHorario.dho_tipo_descripcion}} Max: {{horario.viernesHorario.dho_capacidad}} <span v-show="horario.viernesHorario.dho_virtual">| Virtual</span></span>
                            </div>
                            <label class="lbl-horario" v-show="(horario.viernes != null)">{{horario.viernes}}</label>
                            <i class="material-icons icono-cruzada" title="Permite inscripcion cruzada." v-if="permiteCruzada(horario, 5)">swap_horiz</i>
                            <a href="#!" v-if="(horario.viernes != null && horario.viernesHorario == null && !carreraFiltrada)" @click.prevent="agregar(0, 0, horario.man_codigo, edificio.edi_codigo, sala.aul_codigo, 5)"><i class="material-icons bottom-right-absolute">add_circle_outline</i></a>
                            <a href="#!" v-if="(horario.viernes != null && horario.viernesHorario != null && horario.viernesHorario.editable)" @click.prevent="editar(horario.viernesHorario.hor_codigo, sala.aul_codigo, horario.man_codigo, edificio.edi_codigo, 5)"><i class="material-icons bottom-right-absolute">create</i></a>
                        </td>

                        <td v-bind:class="{'td-horario-selected': bloqueSeleccionado(edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 6)}" class="center td-horario" @click.ctrl="seleccionarHorario(horario.sabadoHorario, edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 6, horario.sabado != null, horario.sabadoHorario == null ? true:horario.sabadoHorario.editable)">
                            <div v-show="!ocultarOcupada(horario, 6)" class="hor-detail" v-if="(horario.sabadoHorario != null)">
                                <span class="badge new descripcion-grupo" v-bind:class="{'red darken-4': !horario.sabadoHorario.hor_publicado}" v-bind:data-badge-caption="(horario.sabadoHorario.car_nombre_corto + ' - ' + horario.sabadoHorario.pla_nombre)">{{horario.sabadoHorario.hor_descripcion}}</span>
                                <p>{{horario.sabadoHorario.mat_codigo}} - {{horario.sabadoHorario.mat_nombre}}</p>
                                <p>{{horario.sabadoHorario.emp_nombres_apellidos}}</p>
                                <span class="badge new detalle-grupo" data-badge-caption="">{{horario.sabadoHorario.dho_tipo_descripcion}} Max: {{horario.sabadoHorario.dho_capacidad}} <span v-show="horario.sabadoHorario.dho_virtual">| Virtual</span></span>
                            </div>
                            <label class="lbl-horario" v-show="(horario.sabado != undefined)">{{horario.sabado}}</label>
                            <i class="material-icons icono-cruzada" title="Permite inscripcion cruzada." v-if="permiteCruzada(horario, 6)">swap_horiz</i>
                            <a href="#!" v-if="(horario.sabado != null && horario.sabadoHorario == null && !carreraFiltrada)" @click.prevent="agregar(0, 0, horario.man_codigo, edificio.edi_codigo, sala.aul_codigo, 6)"><i class="material-icons bottom-right-absolute">add_circle_outline</i></a>
                            <a href="#!" v-if="(horario.sabado != null && horario.sabadoHorario != null && horario.sabadoHorario.editable)" @click.prevent="editar(horario.sabadoHorario.hor_codigo, sala.aul_codigo, horario.man_codigo, edificio.edi_codigo, 6)"><i class="material-icons bottom-right-absolute">create</i></a>
                        </td>

                        <td v-bind:class="{'td-horario-selected': bloqueSeleccionado(edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 7)}" class="center td-horario" @click.ctrl="seleccionarHorario(horario.domingoHorario, edificio.edi_codigo, sala.aul_codigo, horario.man_codigo, 7, horario.domingo != null,  horario.domingoHorario == null ? true:horario.domingoHorario.editable)">
                            <div v-show="!ocultarOcupada(horario, 7)" class="hor-detail" v-if="(horario.domingoHorario != null)">
                                <span class="badge new descripcion-grupo" v-bind:class="{'red darken-4': !horario.domingoHorario.hor_publicado}" v-bind:data-badge-caption="(horario.domingoHorario.car_nombre_corto + ' - ' + horario.domingoHorario.pla_nombre)">{{horario.domingoHorario.hor_descripcion}}</span>
                                <p>{{horario.domingoHorario.mat_codigo}} - {{horario.domingoHorario.mat_nombre}}</p>
                                <p>{{horario.domingoHorario.emp_nombres_apellidos}}</p>
                                <span class="badge new detalle-grupo" data-badge-caption="">{{horario.domingoHorario.dho_tipo_descripcion}} Max: {{horario.domingoHorario.dho_capacidad}} <span v-show="horario.domingoHorario.dho_virtual">| Virtual</span></span>
                            </div>
                            <label class="lbl-horario" v-show="(horario.domingo != null)">{{horario.domingo}}</label>
                            <i class="material-icons icono-cruzada" title="Permite inscripcion cruzada." v-if="permiteCruzada(horario, 7)">swap_horiz</i>
                            <a href="#!" v-if="(horario.domingo != null && horario.domingoHorario == null && !carreraFiltrada)" @click.prevent="agregar(0, 0, horario.man_codigo, edificio.edi_codigo, sala.aul_codigo, 7)"><i class="material-icons bottom-right-absolute">add_circle_outline</i></a>
                            <a href="#!" v-if="(horario.domingo != null && horario.domingoHorario != null && horario.domingoHorario.editable)" @click.prevent="editar(horario.domingoHorario.hor_codigo, sala.aul_codigo, horario.man_codigo, edificio.edi_codigo, 7)"><i class="material-icons bottom-right-absolute">create</i></a>
                        </td>

                    </tr>
                </template>
            </template>
            </tbody>
        </table>
        </div>
    </div>
    <div class="selected-schedules" v-show="(horarios_seleccionados.length > 0)">
        <ul class="collection m0 p0">
            <li class="collection-item blue-ugb white-text" v-for="(horario, index) in horarios_seleccionados" v-bind:key="index">
                <a href="#!" class="right white-text" @click="(horarios_seleccionados.splice(index, 1))"><i class="material-icons">close</i></a>
                <span class="title">Bloque {{(index + 1)}}: {{horario.edi_nombre}} | {{horario.aul_nombre}} | {{horario.dh_dia_f}} | {{horario.man_nomhor}}</span>
                <div class="white black-text" v-if="horario.horario != null" style="border-radius:2px; padding:2px; font-size:10px">
                    <div class="row m0 p0">
                        <div class="col m6 m0 p0">
                            <b>Grupo:</b> {{horario.horario.hor_descripcion}} {{horario.horario.car_nombre_corto}} - {{horario.horario.pla_nombre}}
                            <br>
                            <b>Materia:</b> {{horario.horario.mat_codigo}} - {{horario.horario.mat_nombre}}
                            <br>
                            <b>Docente:</b> {{horario.horario.emp_nombres_apellidos}}                                                                                
                        </div>
                        <div class="col m5 m0 p0">
                            <b>N° de Grupo:</b> {{horario.horario.dho_gr_prac}}
                            <br>
                            <b>Tipo:</b> {{horario.horario.dho_tipo_descripcion}}
                            <br>
                            <b>Capacidad:</b> {{horario.horario.dho_capacidad}}
                        </div>
                     </div>
                </div>
                <div class="white black-text" v-if="horario.horario == null" style="border-radius:2px; padding:2px; font-size:10px">
                    SIN HORARIO PROGRAMADO
                </div>
            </li>
            <li class="collection-item" v-show="(horarios_seleccionados.length == 2)">
                <div class="row m0 p0">
                    <div class="col m6 m0 p0">
                        <a href="#!" @click="(horarios_seleccionados = [])" title="Quitar todo.">
                            LIMPIAR
                            <i class="material-icons left">clear_all</i>
                        </a>
                    </div>
                    <div class="col m6 m0 p0">
                        <a href="#!" @click="intercambiarHorarios" title="Intercambiar horarios.">
                            INTERCAMBIAR
                            <i class="material-icons left">repeat</i>
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
  </div>

<CrearHorario ref="crearHorario"
              v-bind:codedi_seleccionado="codedi_seleccionado"
              v-bind:codman_seleccionado="codman_seleccionado"
              v-bind:codaul_seleccionado="codaul_seleccionado"
              v-bind:dia_seleccionado="dia_seleccionado"
              v-bind:dias="dias"
              v-bind:bloques="bloques"></CrearHorario>

<AgregarHorario ref="agregarHorario"
              v-bind:codhor="codhor_seleccionado"
              v-bind:coddho="coddho_seleccionado"
              v-bind:codedi="codedi_seleccionado"
              v-bind:codman="codman_seleccionado"
              v-bind:codaul="codaul_seleccionado"
              v-bind:dia="dia_seleccionado">
</AgregarHorario>

<!-- <div class="modal" id="modal-horarios-virtual" style="width: 80%">
    <div class="modal-content">
        <HorariosAulaVirtual ref="horariosAulaVirtual" />
    </div>
</div> -->
</template>

<script>
import * as signalR from '@microsoft/signalr'
import M from 'materialize-css'
import CrearHorario from '../components/CrearHorarioComponent.vue'
import AgregarHorario from '../components/AgregarHorarioComponent.vue'
//import HorariosAulaVirtual from '../components/HorariosAulaVirtualComponent'
    export default
    {
        components: {
            CrearHorario,
            AgregarHorario,
            //HorariosAulaVirtual
        },
        data() {
            return {
                codcar: 0,
                codfac: 0,
                carreras: [],
                carreras_view: [],
                facultades: [],
                edificios: [],
                planes: [],
                codedi: 0,
                codaul: 0,
                non_empty: false,
                opcionFila: 0,
                aulas: [],
                aulas_view: [],
                materias: [],
                dias: [],
                bloques: [],
                bloques_salas: [],
                bloques_salas_view: [],
                horarios: {},
                mensajes: [],
                codedi_seleccionado: 0,
                codaul_seleccionado: 0,
                codman_seleccionado: 0,
                dia_seleccionado: 0,
                codhor_seleccionado: 0,
                coddho_seleccionado: 0,
                modales: [],
                horarios_seleccionados: [],
                carreras_autorizadas: [],
                ocultar_ocupados: false,
                niveles: [],
                plm_ciclo: -1
            }
        },
        async created(){
            this.$root.startLoading();
            await this.axios.get('/Carreras/Activas/')
            .then(response => {
                this.carreras = response.data;
                this.carreras_view = response.data;
            });
            await this.axios.get('/Carreras/Activas/?permitidas=true')
            .then(response => {
                this.carreras_autorizadas = response.data;
            });
            await this.axios.get('/Facultades')
            .then(response => {
                this.facultades = response.data;
            });
            await this.axios.get('/Dias')
            .then(response => {
                this.dias = response.data;
            });
            await this.axios.get('/Bloques')
            .then(response => {
                this.bloques = response.data;
            });
            await this.axios.get('/Edificios')
            .then(response => {
                this.edificios = response.data;
            });
            await this.axios.get('/Aulas')
            .then(response => {
                this.aulas = response.data;
                this.aulas_view = response.data;
            });
            await this.axios.get('/Horarios/BloquesSalas/')
            .then(response => {
                this.bloques_salas = response.data;
                this.bloques_salas_view = response.data;
            });
            this.$root.stopLoading();
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
        },
        mounted()
        {
            this.connection = new signalR.HubConnectionBuilder()
                                         .withUrl('https://apiplanner.ugb.edu.sv/updaterHub')
                                         //.withUrl('https:localhost:5001/updaterHub')
                                         .withAutomaticReconnect()
                                         .build();
            this.connection.start();
            var thisVue = this;
            thisVue.connection.on('ReceiveUpdate', (data) => {
                var json = JSON.parse(data);
                if(json[0].cil_codigo == localStorage.getItem('codcil'))
                {
                    this.pushHorario(json);
                }
            });

            thisVue.connection.on('ReceiveDelete', (data) => {
                var json = JSON.parse(data);
                //if(json[0].cil_codigo == localStorage.getItem('codcil'))
                //{ 
                    this.removeHorario(json);
                //}
            });
        },
        methods: {
            async filtrar()
            {
                if(this.codedi > 0) {
                    this.aulas_view = this.aulas.filter(x=>x.aul_codedi == this.codedi);
                }
                else {
                    this.aulas_view = this.aulas;
                }

                if(this.codfac > 0)
                {
                    this.carreras_view = this.carreras.filter(x=>x.car_codfac == this.codfac);
                }
                else
                {
                    this.carreras_view = this.carreras;
                }
                this.$root.startLoading();
                await this.axios.get('/Horarios/BloquesSalas/?facultad=' + this.codfac + '&carrera=' + this.codcar + '&edificio=' + this.codedi + '&aula=' + this.codaul + '&row_option=' + this.opcionFila + '&nivel=' + this.plm_ciclo)
                .then(response => {
                    this.bloques_salas = response.data;
                    this.bloques_salas_view = response.data;
                });
                this.$root.stopLoading();
            },
            async crear()
            {
                this.$root.startLoading();
                if(this.planes.length == 0)
                {
                    await this.axios.get('/Planes/Activos/')
                    .then(response => {
                        this.planes = response.data;
                    });
                }
                var modal = document.querySelector('#modal-crear-horario')
                var instance = M.Modal.getInstance(modal);
                instance.open();
                await this.$refs.crearHorario.init();
                //this.$refs.crearHorario.paginarPlanes();
                //await this.$refs.crearHorario.getDocentes();
                this.$root.stopLoading();
            },
            removeHorario(horario)
            {
                for(let i = 0; i <= horario.length - 1; i++)
                {
                    var codedi = horario[i].edi_codigo;
                    var codaul = horario[i].aul_codigo;
                    var codman = horario[i].man_codigo;
                    var dia = horario[i].dh_codigo;
                    var coddho = horario[i].dho_codigo;
                    var edificio_index = this.bloques_salas.findIndex(x=>x.edi_codigo == codedi);
                    if(edificio_index == -1) continue;
                    var sala_index = this.bloques_salas[edificio_index].salas.findIndex(x=>x.aul_codigo == codaul);
                    if(sala_index == -1) continue;
                    var horario_index = this.bloques_salas[edificio_index].salas[sala_index].horarios.findIndex(x=>x.man_codigo == codman);
                    if(horario_index == -1) continue;
                    
                    //En caso eliminen un horario que había seleccionado para intercambiar.
                    var index_horario_seleccionado = this.horarios_seleccionados
                                                         .findIndex(x=>x.edi_codigo == codedi && x.aul_codigo == codaul && x.dh_codigo == dia);
                    if(index_horario_seleccionado != -1)
                    {
                        M.toast({html: 'Se detectó un cambio en uno de los bloques seleccionados.'});
                        this.horarios_seleccionados.splice(index_horario_seleccionado, 1);
                    }

                    switch(dia)
                    {   
                        case 1:
                            if(this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].lunesHorario.dho_codigo == coddho)
                                this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].lunesHorario = null;
                                break;
                        case 2:
                            if(this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].martesHorario.dho_codigo == coddho)
                                this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].martesHorario = null;
                                break;
                        case 3:
                            if(this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].miercolesHorario.dho_codigo == coddho)
                                this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].miercolesHorario = null;
                                break;
                        case 4:
                            if(this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].juevesHorario.dho_codigo == coddho)
                                this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].juevesHorario = null;
                                break;
                        case 5:
                            if(this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].viernesHorario.dho_codigo == coddho)
                                this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].viernesHorario = null;
                                break;
                        case 6:
                            if(this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].sabadoHorario.dho_codigo == coddho)
                                this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].sabadoHorario = null;
                                break;
                        case 7:
                            if(this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].domingoHorario.dho_codigo == coddho)
                                this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].domingoHorario = null;
                                break;
                    }
                }
            },
            pushHorario(horario)
            {
                for(let i = 0; i <= horario.length - 1; i++)
                {
                    var codedi = horario[i].edi_codigo;
                    var codaul = horario[i].aul_codigo;
                    var codman = horario[i].man_codigo;
                    var dia = horario[i].dh_codigo;
                    var edificio_index = this.bloques_salas.findIndex(x=>x.edi_codigo == codedi);
                    if(edificio_index == -1) continue;
                    var sala_index = this.bloques_salas[edificio_index].salas.findIndex(x=>x.aul_codigo == codaul);
                    if(sala_index == -1) continue;
                    var horario_index = this.bloques_salas[edificio_index].salas[sala_index].horarios.findIndex(x=>x.man_codigo == codman);
                    if(horario_index == -1) continue;
                    if(this.carreras_autorizadas.findIndex(x=>x.car_codigo == horario[i].car_codigo) != -1)
                    {
                        horario[i].editable = true;
                    }
                    //En caso creen/actualicen un horario que había seleccionado para intercambiar.
                    var index_horario_seleccionado = this.horarios_seleccionados
                                                         .findIndex(x=>x.edi_codigo == codedi && x.aul_codigo == codaul && x.dh_codigo == dia);
                    if(index_horario_seleccionado != -1)
                    {
                        M.toast({html: 'Se detectó un cambio en uno de los bloques seleccionados.'});
                        this.horarios_seleccionados.splice(index_horario_seleccionado, 1);
                    }

                    switch(dia)
                    {   
                        case 1:
                            this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].lunesHorario = horario[i];
                            break;
                        case 2:
                            this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].martesHorario = horario[i];
                            break;
                        case 3:
                            this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].miercolesHorario = horario[i];
                            break;
                        case 4:
                            this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].juevesHorario = horario[i];
                            break;
                        case 5:
                            this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].viernesHorario = horario[i];
                            break;
                        case 6:
                            this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].sabadoHorario = horario[i];
                            break;
                        case 7:
                            this.bloques_salas[edificio_index].salas[sala_index].horarios[horario_index].domingoHorario = horario[i];
                            break;
                    }
                }
                
            },
            async editar(codhor, codaul, codman, codedi, dia)
            {
                this.$root.startLoading();
                await this.$refs.agregarHorario.editarHorario(codhor, codaul, codman, codedi, dia);
                this.$root.stopLoading();
            },
            async agregar(codhor, coddho, codman, codedi, codaul, dia)
            {
                this.$root.startLoading();
                var existe_horario = false;
                await this.axios.get('/Horarios/' + codaul + '/' + dia + '/' + codman + '/Verificar/')
                .then(response => {
                    if(response.status == 200)
                    {
                        M.toast({html: 'Se ha creado un nuevo horario en el bloque seleccionado.'});
                        this.pushHorario(response.data);                        
                        existe_horario = true;
                    }
                });
                this.$root.stopLoading();
                if(existe_horario) return;
                
                this.codhor_seleccionado = codhor;
                this.coddho_seleccionado = coddho;
                this.codman_seleccionado = codman;
                this.codedi_seleccionado = codedi;
                this.codaul_seleccionado = codaul;
                this.dia_seleccionado = dia;
                var modal = document.querySelector('#modal-editar-horario')
                var instance = M.Modal.getInstance(modal);
                instance.open();
                await this.$refs.agregarHorario.init();
            },
            edificiosRowspan(index_edificio)
            {
                var salas = this.bloques_salas[index_edificio].salas;
                var rowspan = 0;
                for(let i = 0; i <= salas.length - 1; i++)
                {
                    rowspan += salas[i].horarios.length;
                }
                return rowspan;
            },
            salasRowspan(index_edificio)
            {
                var salas = this.bloques_salas[index_edificio].salas;
                var rowspan = 0;
                for(let i = 0; i <= salas.length - 1; i++)
                {
                    rowspan += salas[i].horarios.length;
                }
                return rowspan;
            },
            seleccionarHorario(horario, codedi, codaul, codman, dia, selectable, allowed)
            {
                if(!selectable || !allowed)
                {
                    return;
                }
                
                /*if(this.carreraFiltrada)
                {
                    M.toast({html: 'No puede seleccionar horarios si ha aplicado filtro por facultad o carrera.'});
                    return;
                }*/
                if(horario == null && allowed && this.carreraFiltrada)
                {
                    M.toast({html: 'No puede seleccionar un bloque vacío si ha aplicado filtro por facultad o carrera.'});
                    return;
                }

                //deseleccionar bloque
                var index_existente = this.horarios_seleccionados.findIndex(x=>x.man_codigo == codman && x.edi_codigo == codedi && x.aul_codigo == codaul && x.dh_codigo == dia);
                if(index_existente != -1)
                {
                    this.horarios_seleccionados.splice(index_existente, 1);
                    return;
                }

                //límite de bloques
                if(this.horarios_seleccionados.length >= 2)
                {
                    return;
                }
                
               if(this.horarios_seleccionados.findIndex(x=>x.horario != null) == -1 && horario == null)
               {
                   M.toast({html: 'Seleccione al menos un bloque con una materia programada.'});
                   return;
               }

               var index_edificio = this.bloques_salas.findIndex(x=>x.edi_codigo == codedi);
               var index_sala = this.bloques_salas[index_edificio].salas.findIndex(x=>x.aul_codigo == codaul);
               var index_bloque = this.bloques_salas[index_edificio].salas[index_sala].horarios.findIndex(x=>x.man_codigo == codman);
               var man_nomhor = '';
               var horarios = this.bloques_salas[index_edificio].salas[index_sala].horarios.find(x=>x.man_codigo == codman);
               switch(dia)
               {
                   case 1:
                       man_nomhor = horarios.lunes;
                       break;
                    case 2:
                       man_nomhor = horarios.martes;
                       break;
                    case 3:
                       man_nomhor = horarios.miercoles;
                       break;
                    case 4:
                       man_nomhor = horarios.jueves;
                       break;
                    case 5:
                       man_nomhor = horarios.viernes;
                       break;
                    case 6:
                       man_nomhor = horarios.sabado;
                       break;
                    case 7:
                       man_nomhor = horarios.domingo;
                       break;
               }

               var detalle = {
                   index_edificio: index_edificio,
                   edi_codigo: codedi,
                   edi_nombre: this.bloques_salas[index_edificio].edi_nombre,
                   index_sala: index_sala,
                   aul_codigo: codaul,
                   aul_nombre: this.bloques_salas[index_edificio].salas[index_sala].aul_nombre_corto,
                   index_bloque: index_bloque,
                   man_codigo: codman,
                   man_nomhor: man_nomhor,
                   dh_codigo: dia,
                   dh_dia_f: this.dias.find(x=>x.dh_codigo == dia).dh_dia_f,
                   horario: horario
               };

               //ubicación estricta de bloque con horario en indice cero
               if(horario == null)
                this.horarios_seleccionados.push(detalle);
               else
                this.horarios_seleccionados.unshift(detalle);
            },
            bloqueSeleccionado(codedi, codaul, codman, dia)
            {
                return this.horarios_seleccionados.findIndex(x=>x.edi_codigo == codedi && x.aul_codigo == codaul && x.man_codigo == codman && x.dh_codigo == dia) != -1;
            },
            async intercambiarHorarios()
            {
                if(confirm('¿Desea intercambiar estos horarios?'))
                {
                    var horarios_temp = this.horarios_seleccionados;
                    this.horarios_seleccionados = [];
                    this.$root.startLoading();
                    await this.axios.post('/Horarios/Intercambiar/', horarios_temp)
                    .then(() => {
                        M.toast({html: 'Los horarios han sido actualizados.'});
                        this.horarios_seleccionados = [];
                    })
                    .catch(error => {
                        this.horarios_seleccionados = horarios_temp;
                        var data = error.response.data;
                        M.toast({html: data.message});
                    });
                    this.$root.stopLoading();
                }
            },
            ocultarOcupada(horario, dia)
            {
                if(horario == null)
                {
                    return false;
                }
                var bloque = null;
                switch(dia)
                {   
                    case 1:
                        bloque = horario.lunesHorario;
                        break;
                    case 2:
                        bloque = horario.martesHorario;
                        break;
                    case 3:
                        bloque = horario.miercolesHorario;
                        break;
                    case 4:
                        bloque = horario.juevesHorario;
                        break;
                    case 5:
                        bloque = horario.viernesHorario;
                        break;
                    case 6:
                        bloque = horario.sabadoHorario;
                        break;
                    case 7:
                        bloque = horario.domingoHorario;
                        break;
                }

                if(bloque == null)
                {
                    return false;
                }

                if(!bloque.editable && this.ocultar_ocupados)
                {
                    return true;
                }
                
                return false;
            },
            ocultarOcupados(event)
            {
                this.ocultar_ocupados = event.target.checked;
            },
            async getNiveles()
            {
                await this.axios.get('/Carreras/' + this.codcar + '/Planes/Ciclos')
                .then(response => {
                    this.niveles = response.data;
                });
            },
            permiteCruzada(horario, dia)
            {
                var permite = false;
                switch(dia)
                {
                    case 1:
                    permite = horario.lunes != null && horario.lunesHorario != null && horario.lunesHorario.cruzada;
                    break;
                    case 2:
                    permite = horario.martes != null && horario.martesHorario != null && horario.martesHorario.cruzada;
                    break;
                    case 3:
                    permite = horario.miercoles != null && horario.miercolesHorario != null && horario.miercolesHorario.cruzada;
                    break;
                    case 4:
                    permite = horario.jueves != null && horario.juevesHorario != null && horario.juevesHorario.cruzada;
                    break;
                    case 5:
                    permite = horario.viernes != null && horario.viernesHorario != null && horario.viernesHorario.cruzada;
                    break;
                    case 6:
                    permite = horario.sabado != null && horario.sabadoHorario != null && horario.sabadoHorario.cruzada;
                    break;
                    case 7:
                    permite = horario.domingo != null && horario.domingoHorario != null && horario.domingoHorario.cruzada;
                    break;
                }
                return permite;
            },
            async notificarAcademica()
            {
                if(!confirm('¿Desea notificar a Desarrollo Humano la carga académica?')) return false;
                this.$root.startLoading();
                await this.axios.post('/NotificacionesCorreo/CargaAcademica')
                .then(() => {
                    M.toast({html: "La notificación ha sido enviada."});
                });
                this.$root.stopLoading();
            },
            async modalHorarioVirtual()
            {
                console.log(3);
                var modal = document.querySelector('#modal-horarios-virtual');
                var modal_horario_virtual = M.Modal.getInstance(modal);
                modal_horario_virtual.open();
            }
        },
        computed:{
            carreraFiltrada: function()
            {
                return this.codfac > 0 || this.codcar > 0;
            }
        }
    }
</script>

<style scoped>

    .td-horario
    {
        position:relative;
        border:solid 1px white;
        margin:10px;
        width:150px;
        height:175px;
        background-color:#212121;
        color:white;
        cursor:pointer
    }

    .td-horario-selected
    {
        background-color:#424242   
    }

    .td-horario .badge.descripcion-grupo
    {
        background-color:#43a047;
        color:white;
        border-radius: 10px;
    }

    .td-horario .badge.detalle-grupo
    {
        position:absolute;
        bottom:0px;
        left:0px;
        border-radius:0px;
        background-color:#0288d1;
    }

    .td-horario .material-icons
    {
        
        color:#fafafa;

    }

    .lbl-horario
    {
        position:absolute !important;
        top:0px !important;
        right:0px !important;
        font-size:10px !important;
        z-index:4;
        padding:2px;
        color:#f1f1f1 !important;
    }

    .hor-detail
    {
        width:100% !important;
        text-align:left;
        padding:5px;
        font-size:10px;
        height:140px
    }

    .selected-schedules
    {
        position:fixed;
        bottom: 0px;
        right:0px;
        margin:0px;
        z-index: 10;
        padding:0px;
        max-width:500px;
        font-size:12px
    }

    .bottom-right-absolute
    {
        position:absolute; bottom: 0px; right: 0px
    }

    .icono-cruzada
    {
        color:orange !important; position:absolute; top:0px; left:0px; z-index:10
    }
</style>