export default {
    install: (app) =>
    {
        app.config.globalProperties.$validation = function(validations, field)
        {
            if(validations == undefined) return '';
            var validation = validations.find(x=>x.key == field);
            if(validation == undefined) return '';
            return validation.messages[0];
        }
    }
}