<template>
    <div class="row">
        <div class="col m2">
            <label>Jornada</label>
            <select class="browser-default" v-model="jdh_descripcion">
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="A3">A3</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="B3">B3</option>
                <option value="C1">C1</option>
                <option value="C2">C2</option>
            </select>
        </div>
        <div class="col m2">
            <label>Dia</label>
            <select class="browser-default" v-model="dia">
                <option value="1">Lunes</option>
                <option value="2">Martes</option>
                <option value="3">Miércoles</option>
                <option value="4">Jueves</option>
                <option value="5">Viernes</option>
                <option value="6">Sábado</option>
                <option value="7">Domingo</option>
            </select>
        </div>
        <div class="col m3">
            <label>Bloque</label>
            <select class="browser-default" v-model="codman">
                <template v-for="bloque in bloques_filter" :key="bloque">
                    <option v-bind:value="bloque.man_codigo">{{ bloque.man_nomhor }} </option>
                </template>
            </select>
        </div>
        <div class="col m2">
            <button class="btn blue-ugb" @click="agregar()">Agregar</button>
        </div>
    </div>

    <div class="row">
        <div class="col m6">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px;">
                <thead>
                    <tr>
                        <th>Jornada</th>
                        <th>Dia</th>
                        <th>Bloque</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="bloque in dias_bloques" :key="bloque">
                        <td>{{ bloque.jdh_descripcion }}</td>
                        <td>{{ bloque.dh_dia_f }}</td>
                        <td>{{ bloque.man_nomhor }}</td>
                        <td><a href="#!" @click="eliminarBloque(bloque.jdh_dia, bloque.jdh_codman, bloque.jdh_descripcion)"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                dias_bloques: [],
                bloques: [],
                jdh_descripcion: '',
                dia: 0,
                codman: 0,
                dias: []
            }
        },
        async mounted(){
            await this.getBloques();
            await this.getDiasBloques();
        },
        methods: {
            async getBloques()
            {
                this.$root.startLoading();
                await this.axios.get('/Horarios/Bloques')
                .then(response => {
                    this.bloques = response.data;
                });
                this.$root.stopLoading();
            },
            async getDias()
            {
                this.$root.startLoading();
                await this.axios.get('/Dias')
                .then(response => {
                    this.dias = response.data;
                });
                this.$root.stopLoading();
            },
            async agregar(){
                var payload = {
                    jdh_descripcion: this.jdh_descripcion,
                    jdh_dia: this.dia,
                    jdh_codman: this.codman,
                    jdh_codreg: 0
                };
                this.$root.startLoading();
                await this.axios.post('/JornadaDiaHora', payload)
                .then(async() => {
                    await this.getDiasBloques();
                });
                this.$root.stopLoading();
            },
            async getDiasBloques()
            {
                this.$root.startLoading();
                await this.axios.get('/JornadaDiaHora')
                .then(response => {
                    this.dias_bloques = response.data;
                });
                this.$root.stopLoading();
            },
            async eliminarBloque(dia, codman, descripcion)
            {
                if(!confirm('¿Desea eliminar este bloque?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/JornadaDiaHora/' + dia + '/' + codman + '/' + descripcion)
                .then(() => {

                });
                this.$root.stopLoading();
            }
        },
        computed: {
            bloques_filter()
            {
                var bloques = [];
                for(let i = 1; i <= this.bloques.length - 1; i++)
                {
                    //console.log(this.dias_bloques.filter(x=>x.jdh_dia == this.bloques[i].dh_codigo && x.jdh_codman == this.bloques[i].man_codigo));
                    if(this.bloques[i].dh_codigo == this.dia)
                    {
                        bloques.push(this.bloques[i])
                    }
                }
                return bloques;
            }
        }
    }
</script>