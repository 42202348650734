<template>
  <div class="row m0 p0">
    <div class="col m12 card-panel">
        <table class="table tbl-condensed bordered striped white" style="font-size:12px;">
            <caption>DETALLE DEL GRUPO</caption>
            <thead>
                <tr>
                    <th style="width:10%">Plan</th>
                    <th style="width:10%">Grupo</th>
                    <th style="width:25%">Docente</th>
                    <th style="width:30%">Materia</th>
                    <th style="width:10%">Max. Alu.</th>
                    <th style="width:5%">Finan.</th>
                    <th style="width:5%">Virt.</th>
                    <th style="width:5%">Publi.</th>
                </tr>
            </thead>
            <tbody>
                <tr class="m0 p0">
                    <td class="center">{{car_nombre_corto}} - {{pla_nombre}}</td>
                    <td class="center">{{hor_descripcion}}</td>
                    <td>{{emp_apellidos_nombres}}</td>
                    <td>{{(plm_alias || mat_nombre)}}</td>
                    <td class="center">{{hor_max_alumnos}}</td>
                    <td class="center">{{hor_financiado}}</td>
                    <td class="virtual">{{hor_virtual}}</td>
                    <td class="center"><label><input type="checkbox" class="filled-in" disabled v-bind:checked="hor_publicado"><span></span></label></td>
                </tr>
                <tr v-for="detalle in detalle_horario" v-bind:key="detalle">
                    <td colspan="7" class="m0 p0">
                        {{detalle.dh_dia_f}} | {{detalle.man_nomhor}} | {{detalle.edi_nombre}} | {{detalle.aul_nombre_corto}} | {{(detalle.dho_tipo == 'T') ? 'Teoría':'Práctica'}} | {{(detalle.dho_tipo == 'P' ? detalle.dho_capacidad:hor_max_alumnos)}} Alumnos
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
  <div class="row m0 p0">
    <div class="col m12 card-panel">
        <div class="col m3">
            <label>Facultad</label>
            <select v-model="codfac" class="browser-default" @change="getCarreras">
                <option value="0">Seleccione</option>
                <option v-for="facultad in facultades" v-bind:key="facultad.fac_codigo" v-bind:value="facultad.fac_codigo">{{facultad.fac_nombre}}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Carrera</label>
            <select v-model="codcar" class="browser-default" :disabled="codfac == 0" @change="getPlanes">
                <option value="0">Seleccione</option>
                <option v-for="carrera in carreras_view" v-bind:key="carrera.car_codigo" v-bind:value="carrera.car_codigo">{{carrera.car_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Plan</label>
            <select v-model="codpla" class="browser-default" :disabled="codcar == 0" @change="getMaterias">
                <option value="0">Seleccione</option>
                <option v-for="plan in planes" v-bind:key="plan.pla_codigo" v-bind:value="plan.pla_codigo">{{plan.pla_nombre}}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Materia</label>
            <select class="browser-default" :disabled="(codpla == 0)" v-model="codmat">
                <option value=''>Seleccione</option>
                <option v-for="materia in materias" v-bind:key="materia" v-bind:value="materia.mat_codigo">{{materia.mat_codigo}} - {{materia.mat_nombre}} (GRUPOS: {{materia.hor_cantidad}})</option>
            </select>
        </div>
        <div class="col m1">
            <button @click="setHorarioCruzado" class="btn blue-ugb" :disabled="codmat == ''" style="margin-top:10px"><i class="material-icons">add_circle_outline</i></button>
        </div>        
    </div>
  </div>
  <div class="row m0 p0" v-show="horarios_cruzados.length > 0">
    <div class="col m12 card-panel">
        <table class="table tbl-condensed" style="font-size:12px">
            <caption>PLAN/MATERIA QUE PODRAN INSCRIBIR EN ESTE GRUPO</caption>
            <caption class="red darken-4 white-text">Importante: El estudiante podra inscribir en este grupo sin necesidad de aprobacion de parte de las decanaturas.</caption>
            <thead>
                <tr>
                    <th style="width:40%">Carrera</th>
                    <th style="width:10%">Plan</th>
                    <th style="width:40%">Materia</th>
                    <th style="width:5%"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="horario in horarios_cruzados" v-bind:key="horario">
                    <td>{{horario.car_nombre}}</td>
                    <td class="center">{{horario.pla_nombre}}</td>
                    <td>{{horario.mat_codigo}} - {{horario.mat_nombre}}</td>
                    <td class="center"><a @click="eliminarHorarioCruzado(horario.hcr_codhor, horario.hcr_codpla, horario.hcr_codmat)" href="#!"><i class="material-icons">delete</i></a></td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
    export default
    {
        name: 'HorarioCruzado',
        data(){
            return {
                detalle_horario: [],
                hor_codigo: 0,
                hor_codpla: 0,
                hor_codmat: '',
                hor_descripcion: '',
                hor_descripcion_validacion: [],
                hor_codcat: 0,
                hor_max_alumnos: 0,
                hor_max_alumnos_validacion: [],
                hor_financiado: 'N',
                hor_virtual: 'N',
                hor_publicado: true,
                mat_nombre: '',
                pla_nombre: '',
                car_nombre: '',
                fac_nombre: '',
                emp_apellidos_nombres: '',
                car_nombre_corto: '',
                plm_alias: '',

                horarios_cruzados: [],

                facultades: [],
                codfac: 0,
                carreras: [],
                codcar: 0,
                carreras_view: [],
                planes: [],
                codpla: 0,
                materias: [],
                codmat: ''
            }
        },
        methods: {
            async init(codhor)
            {
                this.hor_codigo = codhor;
                await this.axios.get('/Horarios/' + this.hor_codigo)
                .then(response => {
                    var horario = response.data;
                    this.hor_codpla = horario.hor_codpla;
                    this.hor_codmat = horario.hor_codmat;
                    this.hor_descripcion = horario.hor_descripcion;
                    this.hor_codcat = horario.hor_codcat;
                    this.hor_max_alumnos = horario.hor_max_alumnos;
                    this.hor_financiado = horario.hor_financiado;
                    this.hor_virtual = horario.hor_virtual;
                    this.hor_publicado = horario.hor_publicado;
                    this.detalle_horario = horario.detalle;
                    this.mat_nombre = horario.hor_codmat + ' - ' + horario.mat_nombre;
                    this.emp_apellidos_nombres = horario.emp_apellidos_nombres;
                    this.car_nombre_corto = horario.car_nombre_corto;
                    this.pla_nombre = horario.pla_nombre;
                });

                await this.axios.get('/HorarioCruzado/' + codhor)
                .then(response => {
                    this.horarios_cruzados = response.data;
                });

                await this.axios.get('/Facultades?permitidas=true')
                .then(response => {
                    this.facultades = response.data;
                });
            },
            async getCarreras()
            {
                this.$root.startLoading();
                if(this.carreras.length == 0)
                {
                    await this.axios.get('/Carreras/Activas/?permitidas=true')
                    .then(response => {
                        this.carreras = response.data;
                        this.carreras_view = response.data;
                    });
                }
                this.carreras_view = this.carreras.filter(x=>x.car_codfac == this.codfac);
                this.codcar = 0;
                this.codpla = 0;
                this.codmat = '';
                this.$root.stopLoading();
            },
            async getPlanes()
            {
                this.$root.startLoading();
                this.codmat = '';
                this.codpla = 0;
                await this.axios.get('/Planes/Activos/' + this.codcar)
                .then(response => {
                    this.planes = response.data.filter(x=>x.pla_codigo != this.hor_codpla);
                });
                this.$root.stopLoading();
            },
            async getMaterias()
            {
                this.$root.startLoading();
                await this.axios.get('/MateriasAutorizadas/' + this.codpla + '/CantidadHorarios/')
                .then(response => {
                    if(response.data.length > 0)
                    {
                        this.materias = response.data;
                    }
                });
                this.codmat = ''
                this.$root.stopLoading();
            },
            async setHorarioCruzado()
            {
                var data = {
                    hcr_codpla: this.codpla,
                    hcr_codmat: this.codmat
                };

                this.$root.startLoading();
                await this.axios.post('/HorarioCruzado/' + this.hor_codigo, data)
                .then(async () => {
                    await this.axios.get('/HorarioCruzado/' + this.hor_codigo + '/' + this.codpla + '/' + this.codmat)
                    .then(response => {
                        this.horarios_cruzados.push(response.data);
                    });
                })
                .catch(error => {
                    var data = error.response.data;
                    M.toast({html: data.message});
                });
                this.$root.stopLoading();
            },
            async eliminarHorarioCruzado(codhor, codpla, codmat)
            {
                if(!confirm('¿Desea eliminar esta asignacion?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/HorarioCruzado/' + codhor + '/' + codpla + '/' + codmat)
                .then(() => {
                    var index = this.horarios_cruzados.findIndex(x=>x.hcr_codhor == codhor && x.hcr_codpla == codpla && x.hcr_codmat == codmat);
                    if(index != -1)
                    {
                        this.horarios_cruzados.splice(index, 1);
                    }
                })
                .catch(error => {
                    var data = error.response.data;
                    if(error.response.status == 404)
                    {
                        M.toast({html: 'Asignacion no encontrada.'});
                    }
                    if(error.response.status == 400)
                        M.toast({html: data.message});
                });
                this.$root.stopLoading();
            }
        }
    }
</script>