<template>
    <div></div>
</template>
<script>
    export default 
    {
        mounted() {
            localStorage.removeItem('token');
            localStorage.removeItem('usuario');
            localStorage.removeItem('regional');
            localStorage.removeItem('codigo');
            localStorage.removeItem('nombre');
            localStorage.removeItem('codcic');
            localStorage.removeItem('anio');    
            localStorage.removeItem('ciclo');
            localStorage.removeItem('ciclo');
            localStorage.removeItem('is_logged');  
            window.location = '/Login';
        }
    }
</script>