<template>
    <div class="row">
        <div class="col m3">
            <label>Carrera</label>
            <select v-model="codcar" class="browser-default">
                <option value="0">Todas</option>
                <option v-for="carrera in carreras" :key="carrera.car_codigo" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Tipo</label>
            <select class="browser-default" v-model="codtpg">
                <option value="0">Todos</option>
                <option v-for="tipo in tipos_procesos" :key="tipo" :value="tipo.tpg_codigo">{{tipo.tpg_descripcion}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Inscripción</label>
            <select class="browser-default" v-model="inscritos_filter">
                <option value="-1">Todos</option>
                <option value="0">Sin Inscritos</option>
                <option value="1">Con Inscritos</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                <caption>PROCESOS AUTORIZADOS</caption>
                <tbody>
                    <template v-for="(proceso, _index_proceso) in _procesos_autorizados" :key="proceso">
                        <tr class="black white-text">
                            <th>Fecha</th>
                            <th>Carrera</th>
                            <th>Tipo</th>
                            <th>Descripción</th>
                            <th>Matrícula</th>
                            <th>Cuota</th>
                            <th>Cant. Cuotas</th>
                            <th>Módulos</th>
                            <th>Grupos</th>
                        </tr>
                        <tr>
                            <td class="center">{{proceso.aut_fecha}}</td>
                            <td>{{proceso.car_nombre}}</td>
                            <td>{{proceso.tpg_descripcion}}</td>
                            <td>{{proceso.pgr_descripcion}}</td>
                            <td style="text-align:right">${{proceso.aut_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td style="text-align:right">${{proceso.aut_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td class="center">{{proceso.aut_cant_cuotas}}</td>
                            <td class="center">                           
                                <div v-show="proceso.pgr_moa_modulos_autorizados.length > 0">
                                    <span data-badge-caption="" class="badge new blue">{{proceso.pgr_moa_modulos_autorizados.length}}</span>
                                </div>
                            </td>
                            <td class="center" style="position:relative">
                                <span data-badge-caption class="badge new green darken-1">{{proceso.pgr_grh_grupo_horario.length}}</span>
                            </td>
                        </tr>
                        <tr v-show="proceso.pgr_grh_grupo_horario.length > 0">
                            <th colspan="9" class="center">GRUPOS</th>
                        </tr>
                        <template v-for="(detalle, _index_detalle) in proceso.pgr_grh_grupo_horario" :key="detalle">
                            <tr v-show="(inscritos_filter == -1 || (inscritos_filter == 0 && detalle.pgr_ipg_inscripcion_proceso_graduacion.filter(x=>x.ipg_estado).length == 0) || (inscritos_filter == 1 && detalle.pgr_ipg_inscripcion_proceso_graduacion.filter(x=>x.ipg_estado).length > 0))">
                                <td colspan="9" class="white">
                                    <ul class="collection m0 p0">
                                        <li class="collection-item m0 p0">
                                            <span class="" data-badge-caption="">
                                            <span data-badge-caption="" class="badge new green darken-2">{{detalle.grh_descripcion}}</span>
                                            | <span data-badge-caption="" class="badge new grey darken-2">                                          
                                            <b>MATRÍCULA:</b> ${{detalle.grh_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}} 
                                            | <b>CUOTA:</b> ${{detalle.grh_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}} 
                                            | <b>CUOTAS:</b> {{detalle.grh_cant_cuotas}}
                                            | <b>CAPACIDAD:</b> {{detalle.grh_capacidad}}
                                            | <b>INSCRITOS:</b> {{detalle.pgr_ipg_inscripcion_proceso_graduacion.filter(x=>x.ipg_estado).length}}
                                            </span>                                            
                                            <a style="display:none" href="#!" class="right" @click="modalInscripcion(detalle.grh_codigo, proceso.car_codigo, _index_proceso, _index_detalle)"><i class="material-icons">person</i></a>
                                            <a href="#!" class="right" @click="modalInscripcion(detalle.grh_codigo, proceso.car_codigo, proceso.aut_codigo, detalle.grh_codigo)"><i class="material-icons">person</i></a>
                                            </span>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-inscripcion-proceso" style="width:85%; overflow-y: hidden; border: solid 1px white">
        <button class="btn modal-close-btn red darken-2 modal-close">&times;</button>
        <div class="modal-content m0 p0" style="overflow: hidden">
            <div class="row card-panel">
                <table class="table tbl-condensed tbl-bordered" style="font-size:12px" v-if="index_proceso >= 0">
                    <thead>
                        <tr class="black white-text">
                            <th>CARRERA</th>
                            <th>TIPO</th>
                            <th>DESCRIPCION</th>
                            <th>CAPACIDAD</th>
                            <th>INSCRITOS</th>
                            <th>MATRICULA</th>
                            <th>CUOTA</th>
                            <th>CANT. CUOTAS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{this.procesos_autorizados[index_proceso].pgr_grh_grupo_horario[index_detalle].grh_descripcion}} - {{procesos_autorizados[index_proceso].car_nombre}}</td>
                            <td class="center">{{procesos_autorizados[index_proceso].tpg_descripcion}}</td>
                            <td>{{procesos_autorizados[index_proceso].pgr_descripcion}}</td>
                            <td class="center">{{this.procesos_autorizados[index_proceso].pgr_grh_grupo_horario[index_detalle].grh_capacidad}}</td>
                            <td class="center">{{this.procesos_autorizados[index_proceso].pgr_grh_grupo_horario[index_detalle].pgr_ipg_inscripcion_proceso_graduacion.filter(x=>x.ipg_estado).length}}</td>
                            <td style="text-align:right">${{this.procesos_autorizados[index_proceso].pgr_grh_grupo_horario[index_detalle].grh_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td style="text-align:right">${{this.procesos_autorizados[index_proceso].pgr_grh_grupo_horario[index_detalle].grh_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            <td class="center">{{this.procesos_autorizados[index_proceso].pgr_grh_grupo_horario[index_detalle].grh_cant_cuotas}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row card-panel" v-if="_habilitar_cupos">
                <div class="col m12">
                    <div class="row m0 p0">
                        <span>
                            <label><input class="filled-in" type="checkbox" v-model="aumentar_cupo" /><span>El grupo se encuentra lleno, si desea incrementar el cupo automaticamente al inscribir un estudiante, marque esta opción.</span></label>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row card-panel m0">
                <div class="col m6 m0">
                    <fieldset>
                        <legend><span class="badge new blue-ugb" data-badge-caption="">Estudiantes</span></legend>
                        <div class="row m0 p0">
                            <div class="row m0 p0">
                            <div class="col m12">
                                <input type="text" v-model="search_estudiante" placeholder="Buscar..."/>
                            </div>
                            </div>
                            <br/>
                            
                            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                                <thead>
                                    <tr>
                                        <th style="width: 20%">Carnet</th>
                                        <th style="width: 65%">Nombre</th>
                                        <th style="width: 15%">Inscribir</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div style="height: 225px; overflow: auto">                        
                            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                                <tbody>
                                    <tr v-for="estudiante in _estudiantes" :key="estudiante">
                                        <td style="width: 21%">{{estudiante.per_carnet}}</td>
                                        <td style="width: 67%">{{estudiante.per_apellidos_nombres}}</td>
                                        <td class="center" style="width: 20%"><a href="#!" @click="setInscripcion(estudiante.per_codigo, estudiante.alc_codpla)"><i class="material-icons">add_circle</i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>
                <div class="col m6 m0">
                    <fieldset>
                        <legend><span class="badge new green darken-2" data-badge-caption="">Estudiantes inscritos</span></legend>
                        <div class="row m0 p0">
                            <div class="col m12">
                                <input type="text" v-model="search_inscrito" placeholder="Buscar..."/>
                            </div>
                        </div>
                        <br/>
                        <table class="table tbl-condensed tbl-bordered" style="font-size:12px" v-show="inscritos.length > 0">
                            <thead>
                                <tr>
                                    <th style="width: 18%">Carnet</th>
                                    <th style="width: 45%">Nombre</th>
                                    <th style="width: 15%">Fecha Ins.</th>
                                    <th style="width: 15%">Activo</th>
                                    <th></th>
                                </tr>
                            </thead>
                        </table>
                        <div style="height: 225px; overflow: auto">
                            <table class="table tbl-condensed tbl-bordered" style="font-size:12px" v-show="inscritos.length > 0">                           
                                <tbody>
                                    <tr v-for="inscrito in _inscritos" :key="inscrito">
                                        <td style="width: 18%">{{inscrito.per_carnet}}</td>
                                        <td style="width: 52%">{{inscrito.per_apellidos_nombres}}</td>
                                        <td style="width: 16%">{{inscrito.ipg_fecha}}</td>
                                        <td class="center" style="width: 15%"><label><input type="checkbox" v-model="inscrito.ipg_estado" @click="setEstado($event, inscrito.ipg_codigo)" class="filled-in" v-bind:checked="inscrito.ipg_estado" /><span></span></label></td>
                                        <td class="center"><a href="#!" @click="eliminarInscripcion(inscrito.ipg_codigo)"><i class="material-icons">delete</i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                procesos_autorizados: [],
                index_proceso: -1,
                index_detalle: -1,
                inscritos: [],
                estudiantes: [],
                modales: [],
                modal_inscripcion: {},
                search_estudiante: '',
                search_inscrito: '',
                carreras: [],
                codcar: 0,
                tipos_procesos: [],
                codtpg: 0,
                inscritos_filter: -1,
                aumentar_cupo: false
            }
        },
        async mounted()
        {
            await this.getProcesosAutorizados();
            var elems = document.querySelectorAll(".modal");
            this.modales = M.Modal.init(elems, {
                dismissible: false
            });
            await this.getCarreras();
            await this.getTiposProcesos();
        },
        methods: {
            async getProcesosAutorizados()
            {
                this.$root.startLoading();
                await this.axios.get("/ProcesosGraduacionAutorizacion")
                    .then(response => {
                    this.procesos_autorizados = response.data.filter(x=>x.pgr_grh_grupo_horario.length > 0);
                });
                this.$root.stopLoading();
            },
            async getInscritos(codgrh)
            {
                await this.axios.get('/ProcesosGraduacionGrupo/' + codgrh + '/Inscritos')
                .then(response => {
                    this.inscritos = response.data;
                });
            },
            async getEstudiantes(codcar)
            {
                this.$root.startLoading();
                await this.axios.get('/Carreras/' + codcar + '/Estudiantes')
                .then(response => {
                    this.estudiantes = response.data.estudiantes;
                    this.estudiantes_view = response.data.estudiantes;
                });
                this.$root.stopLoading();
            },
            //async modalInscripcion(codgrh, codcar, index_proceso, index_detalle)
            async modalInscripcion(codgrh, codcar, aut_codigo, grh_codigo)
            {
                this.index_proceso = this.procesos_autorizados.findIndex(x=>x.aut_codigo == aut_codigo);
                this.index_detalle = this.procesos_autorizados[this.index_proceso].pgr_grh_grupo_horario.findIndex(x=>x.grh_codigo == grh_codigo);
                await this.getInscritos(codgrh);
                await this.getEstudiantes(codcar);
                this.modal_inscripcion = M.Modal.getInstance(document.querySelector("#modal-inscripcion-proceso"));
                this.modal_inscripcion.open();
            },
            async setInscripcion(codper, codpla)
            {
                var payload = {
                    ipg_codigo: 0,
                    ipg_codgrh: this.procesos_autorizados[this.index_proceso].pgr_grh_grupo_horario[this.index_detalle].grh_codigo,
                    ipg_codper: codper,
                    ipg_codpla: codpla,
                    ipg_estado: true
                };
                var index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == codper);
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionInscripcion?forzar=' + this.aumentar_cupo, payload)
                .then(response => {
                    this.inscritos.push({
                        per_codigo: this.estudiantes[index_estudiante].per_codigo,
                        per_carnet: this.estudiantes[index_estudiante].per_carnet,
                        per_apellidos_nombres: this.estudiantes[index_estudiante].per_apellidos_nombres,
                        ipg_codigo: response.data.ipg_codigo,
                        ipg_fecha: response.data.ipg_fecha,
                        ipg_estado: response.data.ipg_estado,
                    });                    
                    if(this.aumentar_cupo)
                    {   
                        M.toast({html: 'Se ha habilitado el cupo automaticamente.'});
                        this.procesos_autorizados[this.index_proceso].pgr_grh_grupo_horario[this.index_detalle].grh_capacidad++;
                    }
                    this.aumentar_cupo = false;
                    this.updateInscritos();
                })
                .catch(error => {
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async eliminarInscripcion(codipg)
            {
                if(!confirm('¿Desea eliminar esta inscripcion?'))
                {
                    return;
                }
                this.$root.startLoading();
                await this.axios.delete('/ProcesosGraduacionInscripcion/' + codipg)
                .then(() => {
                    var index = this.inscritos.findIndex(x=>x.ipg_codigo == codipg);
                    this.inscritos.splice(index, 1);
                    this.updateInscritos();
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async setEstado(event, codipg)
            {
                this.$root.startLoading();
                await this.axios.patch('/ProcesosGraduacionInscripcion/' + codipg + '/Estado?estado=' + event.target.checked)
                .then(() => {
                    this.updateInscritos();
                })
                .catch(error => {
                    if(error.response.data.type == 'C')
                    {
                        event.target.checked = false;
                        M.toast({html: error.response.data.message});
                    }
                });
                this.$root.stopLoading();
            },
            updateInscritos()
            {
                this.procesos_autorizados[this.index_proceso].pgr_grh_grupo_horario[this.index_detalle].pgr_ipg_inscripcion_proceso_graduacion = this.inscritos.filter(x=>x.ipg_estado);
            },
            async getCarreras()
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionAutorizacion/Carreras')
                .then(response => {
                    this.carreras = response.data;
                });
                this.$root.stopLoading();
            },
            async getTiposProcesos()
            {
                this.$root.startLoading();
                await this.axios.get('/TipoProcesoGraduacion')
                .then(response => {
                    this.tipos_procesos = response.data.filter(x=>x.tpg_estado);
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            _estudiantes()
            {
                if(this.estudiantes.length == 0) return [];
                return this.estudiantes.filter(x=>this.inscritos.findIndex(y=>y.per_codigo == x.per_codigo) == -1 &&
                    (x.per_apellidos_nombres.toLowerCase().indexOf(this.search_estudiante.toLowerCase()) != -1 || x.per_carnet.toLowerCase().indexOf(this.search_estudiante.toLowerCase()) != -1)
                );
            },
            _inscritos()
            {
                if(this.inscritos.length == 0) return [];
                return this.inscritos.filter(x=>x.per_apellidos_nombres.toLowerCase().indexOf(this.search_inscrito.toLowerCase()) != -1 || x.per_carnet.toLowerCase().indexOf(this.search_inscrito.toLowerCase()) != -1);
            },
            _procesos_autorizados() {
                return this.procesos_autorizados
                            .filter(x=>x.car_codigo == (this.codcar == 0 ? x.car_codigo:this.codcar)
                                    && x.tpg_codigo == (this.codtpg == 0 ? x.tpg_codigo:this.codtpg));
            },
            _habilitar_cupos()
            {
                if(this.index_proceso == -1 || this.index_detalle == -1) return 0;
                return this.procesos_autorizados[this.index_proceso].pgr_grh_grupo_horario[this.index_detalle].grh_capacidad <= this.inscritos.filter(x=>x.ipg_estado).length;
            }
        }
    }
</script>