<template>
    <div class="row">
        <div class="col m3">
            <label>Carrera</label>
            <select class="browser-default" v-model="codcar">
                <option value="0">Todas</option>
                <option v-for="carrera in _carreras" :key="carrera" :value="carrera.car_codigo">{{carrera.car_nombre}}</option>
            </select>
        </div>
        <div class="col m2">
            <label>Tipo</label>
            <select class="browser-default" v-model="codtpg">
                <option value="">Todos</option>
                <option v-for="tipo in _tipo_proceso" :value="tipo.tpg_codigo" :key="tipo">{{ tipo.tpg_descripcion }}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Buscar</label>
            <input type="text" v-model="search">
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered striped" style="font-size: 12px">
                <thead>
                    <tr>
                        <th>Grupo</th>
                        <th>Descripción</th>
                        <th>Docente</th>
                        <th>Tipo</th>
                        <th>Autofinanciado</th>
                        <th>Carrera</th>
                        <th>Capacidad</th>
                        <th>Activo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="grupo in _grupos" :key="grupo">
                        <td class="center">{{grupo.grh_descripcion}}</td>
                        <td>{{grupo.pgr_descripcion}}</td>
                        <td>{{ grupo.emp_apellidos_nombres }}</td>
                        <td class="center">{{grupo.tpg_descripcion}}</td>
                        <td class="center"><label><input type="checkbox" class="filled-in" :checked="grupo.grh_autofinanciado" @click.prevent="" /><span></span></label></td>
                        <td class="center">{{grupo.car_nombre}}</td>
                        <td class="center">{{grupo.grh_inscritos}} de {{grupo.grh_capacidad}}</td>
                        <td class="center"><label><input type="checkbox" class="filled-in" :checked="grupo.grh_activo" @click.prevent="" /><span></span></label></td>
                        <th class="center"><a href="#!" @click="getEstudiantes(grupo.grh_codigo)"><i class="material-icons">list</i></a></th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-estudiantes" style="font-size: 12px; width: 85%">
        <div class="modal-content">
            <div class="row">                
                <div class="col m2">
                    <label>Grupo</label>
                    <p>{{grupo.grh_descripcion}}</p>
                </div>                
                <div class="col m2">
                    <label>Descripción</label>
                    <p>{{grupo.pgr_descripcion}}</p>
                </div>
                <div class="col m2">
                    <label>Docente</label>
                    <p>{{grupo.emp_apellidos_nombres}}</p>
                </div>
                <div class="col m2">
                    <label>Tipo</label>
                    <p>{{grupo.tpg_descripcion}}</p>
                </div>
                <div class="col m1">
                    <label>Autofin.</label>
                    <p>{{grupo.grh_autofinanciado ? 'Si':'No'}}</p>
                </div>
                <div class="col m1">
                    <label>Capacidad</label>
                    <p>{{grupo.grh_capacidad}}</p>
                </div>
                <div class="col m1">
                    <label>Inscritos</label>
                    <p>{{grupo.grh_inscritos}}</p>
                </div>                
            </div>
            <div class="row">
                <div class="col m12">
                    <table class="table tbl-condensed tbl-bordered striped white">
                        <thead>
                            <tr>
                                <th style="width: 10%">Carnet</th>
                                <th>Nombre</th>
                                <th v-for="ponderacion in ponderaciones" :key="ponderacion" style="width: 75px; vertical-align: top">
                                    {{ponderacion.pon_ponderacion}}<span v-show="ponderacion.pon_porcentaje > 0"><br>({{ponderacion.pon_porcentaje}}%)</span>
                                    <br />
                                    {{getModuloNombre(ponderacion.npg_orden)}}
                                </th>
                                <!-- <th style="width: 5%">Nota Final</th> -->
                                <th style="width: 5%"></th>
                                <th style="width: 5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="estudiante in estudiantes" :key="estudiante">
                                <td>{{estudiante.per_carnet}}</td>
                                <td>{{estudiante.per_apellidos_nombres}}</td>
                                <td class="center" v-for="ponderacion in ponderaciones" :key="ponderacion">
                                    <input type="text" v-bind:title="(!moduloInscrito(estudiante.con_modulos, estudiante.per_codigo, ponderacion.pon_codigo) ? 'El módulo no está inscrito.':'')" :disabled="!moduloInscrito(estudiante.con_modulos, estudiante.per_codigo, ponderacion.pon_codigo)" v-bind:id="('txt_' + estudiante.per_codigo + '_' + ponderacion.pon_codigo)" @change="setNota(estudiante.per_codigo, ponderacion.pon_codigo)" v-bind:class="{'red lighten-4': notaCambiada(estudiante.per_codigo, ponderacion.pon_codigo)}" class="browser-default" style="width: 50px; text-align: right; line-height: 2em; border: 1px solid #d1d1d1;" v-bind:value="getNota(estudiante.per_codigo, ponderacion.pon_codigo)" />
                                </td>
                                <!-- <td class="center blue white-text">{{estudiante.npg_nota_final}}</td> -->
                                <td class="center"><a tabindex="-1" href="#!" @click="guardarNota(estudiante.ipg_codigo, estudiante.per_codigo)"><i class="material-icons">save</i></a></td>
                                <td class="center"><a tabindex="-1" href="#!" v-show="notaCambiadaEstudiante(estudiante.per_codigo)" @click="deshacerNota(estudiante.per_codigo)"><i class="material-icons">undo</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <button class="btn blue-ugb" @click="guardarNotasGrupo()">Guardar Todo <i class="material-icons right">save</i></button>
                    <button class="btn red darken-2 modal-close">Cerrar <i class="material-icons right">close</i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default 
    {
        data(){
            return {
                grupos: [],
                estudiantes: [],
                modulos: [],
                ponderaciones: [],
                modals: [],
                modal_estudiantes: {},
                grupo: {},

                codcar: 0,
                codtpg: '',
                search: ''
            }
        },
        async mounted()
        {
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
            await this.getGrupos();
        },
        methods: {
            async getGrupos()
            {
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionGrupo')
                .then(response => {
                    this.grupos = response.data;
                });
                this.$root.stopLoading();
            },
            async getEstudiantes(codgrh)
            {
                this.grupo = this.grupos.find(x=>x.grh_codigo == codgrh);
                this.$root.startLoading();
                await this.axios.get('/ProcesosGraduacionGrupo/' + codgrh + '/Estudiantes')
                .then(response => {
                    this.estudiantes = response.data;
                    this.ponderaciones = [];
                    if(this.estudiantes.length > 0)
                    {
                        for(let i = 0; i <= this.estudiantes[0].notas.length - 1; i++)
                        {
                            this.ponderaciones.push({
                                pon_codigo: this.estudiantes[0].notas[i].pon_codigo,
                                pon_ponderacion: this.estudiantes[0].notas[i].pon_ponderacion,
                                pon_porcentaje: this.estudiantes[0].notas[i].pon_porcentaje,
                                npg_orden: this.estudiantes[0].notas[i].npg_orden
                            });
                        }
                    }
                    this.modal_estudiantes = M.Modal.getInstance(document.querySelector('#modal-estudiantes'));
                    this.modal_estudiantes.open();                    
                });
                this.$root.stopLoading();
                await this.getModulos(codgrh);
            },
            getNota(codper, codpon)
            {
                var index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == codper);
                if(index_estudiante == -1) return 0;
                var index_nota = this.estudiantes[index_estudiante].notas.findIndex(x=>x.pon_codigo == codpon);
                if(index_nota == -1) return 0;
                return this.estudiantes[index_estudiante].notas[index_nota].npg_nota;
            },
            getNotaTemp(codper, codpon)
            {
                var index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == codper);
                if(index_estudiante == -1) return 0;
                var index_nota = this.estudiantes[index_estudiante].notas.findIndex(x=>x.pon_codigo == codpon);
                if(index_nota == -1) return 0;
                return this.estudiantes[index_estudiante].notas[index_nota].npg_nota_temp;
            },
            setNota(codper, codpon)
            {
                var index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == codper);
                var index_nota = this.estudiantes[index_estudiante].notas.findIndex(x=>x.pon_codigo == codpon);
                let nota = document.querySelector('#txt_' + codper + '_' + codpon).value;
                if(isNaN(nota) || nota < 0 || nota > 10 || (nota || '') == '')
                {
                    document.querySelector('#txt_' + codper + '_' + codpon).value = this.getNotaTemp(codper, codpon);
                    M.toast({html: 'La nota debe ser entre 0 a 10.'});
                    return;
                }
                this.estudiantes[index_estudiante].notas[index_nota].npg_nota = nota;
            },
            setNotaTemp(codper, codpon)
            {
                var index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == codper);
                var index_nota = this.estudiantes[index_estudiante].notas.findIndex(x=>x.pon_codigo == codpon);
                let nota = document.querySelector('#txt_' + codper + '_' + codpon).value;
                this.estudiantes[index_estudiante].notas[index_nota].npg_nota_temp = nota;
            },
            deshacerNota(codper)
            {
                var changed = false;
                for(let i = 0; i <= this.ponderaciones.length - 1; i++)
                {
                    if(this.getNota(codper, this.ponderaciones[i].pon_codigo) != this.getNotaTemp(codper, this.ponderaciones[i].pon_codigo))
                    {
                        document.querySelector('#txt_' + codper + '_' + this.ponderaciones[i].pon_codigo).value = this.getNotaTemp(codper, this.ponderaciones[i].pon_codigo);
                        this.setNota(codper, this.ponderaciones[i].pon_codigo);
                        changed = true;
                    }
                }
                if(changed)
                    M.toast({html: 'Se han reestablecido las notas.'});
            },
            notaCambiada(codper, codpon)
            {
                return this.getNota(codper, codpon) != this.getNotaTemp(codper, codpon);
            },
            notaCambiadaEstudiante(codper)
            {
                var changed = false;
                for(let i = 0; i <= this.ponderaciones.length - 1; i++)
                {
                    if (this.getNota(codper, this.ponderaciones[i].pon_codigo) != this.getNotaTemp(codper, this.ponderaciones[i].pon_codigo))
                    {
                        changed = true;
                        break;
                    }
                }
                return changed;
            },
            async guardarNota(codipg, codper)
            {
                var notas = [];
                var index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == codper);
                
                for(let i = 0; i <= this.ponderaciones.length - 1; i++)
                {
                    var index_nota = this.estudiantes[index_estudiante].notas.findIndex(x=>x.pon_codigo == this.ponderaciones[i].pon_codigo);
                    let nota = document.querySelector('#txt_' + codper + '_' + this.ponderaciones[i].pon_codigo).value;
                    notas.push({
                        npg_nota: nota,
                        npg_codpon: this.ponderaciones[i].pon_codigo,
                        idg_codigo: this.estudiantes[index_estudiante].notas[index_nota].pgr_mod_modulos == null ? 0:this.estudiantes[index_estudiante].notas[index_nota].pgr_mod_modulos.idg_codigo
                    });
                }
                if(notas.length == 0)
                {
                    M.toast({html: 'No hay cambios por guardar.'});
                }
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionNotas/' + codipg + '/' + codper + '/' + this.grupo.grh_codigo, notas)
                .then(() => {
                    for(let i = 0; i <= notas.length - 1; i++)
                    {
                        this.setNota(codper, notas[i].npg_codpon);
                        this.setNotaTemp(codper, notas[i].npg_codpon);
                    }
                    M.toast({html: 'Las notas han sido guardadas.'});
                });
                this.$root.stopLoading();
            },
            async guardarNotasGrupo()
            {
                var estudiantes = [];
                for(let i = 0; i <= this.estudiantes.length - 1; i++)
                {
                    let notas = [];                    
                    for(let j = 0; j <= this.ponderaciones.length - 1; j++)
                    {
                        var index_nota = this.estudiantes[i].notas.findIndex(x=>x.pon_codigo == this.ponderaciones[j].pon_codigo);
                        if(index_nota != -1)
                        {
                            let nota = document.querySelector('#txt_' + this.estudiantes[i].per_codigo + '_' + this.ponderaciones[j].pon_codigo).value;
                            notas.push({
                                npg_nota: nota,
                                npg_codpon: this.ponderaciones[j].pon_codigo,
                                idg_codigo: this.estudiantes[i].notas[index_nota].pgr_mod_modulos == null ? 0:this.estudiantes[i].notas[index_nota].pgr_mod_modulos.idg_codigo
                            });
                        }
                    }

                    estudiantes.push({
                        per_codigo: this.estudiantes[i].per_codigo,
                        ipg_codigo: this.estudiantes[i].ipg_codigo,
                        notas: notas
                    });                    
                }                
                this.$root.startLoading();
                await this.axios.post('/ProcesosGraduacionNotas/' + this.grupo.grh_codigo, estudiantes)
                .then(() => {
                    for(let i = 0; i <= estudiantes.length - 1; i++)
                    {
                        for(let j = 0; j <= estudiantes[i].notas.length - 1; j++)
                        {
                            this.setNota(estudiantes[i].per_codigo, estudiantes[i].notas[j].npg_codpon);
                            this.setNotaTemp(estudiantes[i].per_codigo, estudiantes[i].notas[j].npg_codpon);
                        }                   
                    }
                    M.toast({html: 'Las notas han sido guardadas.'});
                });
                this.$root.stopLoading();
            },
            moduloInscrito(tiene_modulos, codper, codpon)
            {
                if(!tiene_modulos) return true;
                var index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == codper);
                if(index_estudiante == -1) return false;
                var index_nota = this.estudiantes[index_estudiante].notas.findIndex(x=>x.pon_codigo == codpon);
                if(index_nota == -1) return false;
                return this.estudiantes[index_estudiante].notas[index_nota].pgr_mod_modulos != null;
            },
            async getModulos(codgrh)
            {
                this.modulos = [];
                await this.axios.get('/ProcesosGraduacionGrupo/' + codgrh + '/Modulos')
                .then(response => {
                    this.modulos = response.data;
                });
            },
            getModuloNombre(orden)
            {
                var result = this.modulos.find(x=>x.moa_orden == orden);
                if(result == undefined) return '';
                return result.mod_nombre;
            }
        },
        computed: {
            _carreras(){
                var carreras = [];
                for(let i = 0; i <= this.grupos.length - 1; i++)
                {
                    if(carreras.findIndex(x=>x.car_codigo == this.grupos[i].car_codigo) == -1)
                    {
                        carreras.push({
                            car_codigo: this.grupos[i].car_codigo,
                            car_nombre: this.grupos[i].car_nombre,
                        })
                    }
                }
                return carreras;
            },
            _tipo_proceso()
            {
                var tipos = [];
                for(let i = 0; i <= this.grupos.length - 1; i++)
                {
                    if(tipos.findIndex(x=>x.tpg_codigo == this.grupos[i].tpg_codigo) == -1)
                    {
                        tipos.push({
                            tpg_codigo: this.grupos[i].tpg_codigo,
                            tpg_descripcion: this.grupos[i].tpg_descripcion,
                        })
                    }
                }
                return tipos;
            },
            _grupos()
            {
                return this.grupos.filter(x=>
                                            x.tpg_codigo == (this.codtpg == '' ? x.tpg_codigo:this.codtpg) &&
                                            x.car_codigo == (this.codcar == 0 ? x.car_codigo:this.codcar) &&
                                            (
                                                x.car_nombre.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
                                                x.tpg_descripcion.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
                                                x.pgr_descripcion.toLowerCase().indexOf(this.search.toLowerCase()) != -1
                                            )
                                         );
            }
        }
    }
</script>