<template>
    <div class="row">
        <div class="col m8">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                <thead>
                    <tr>
                        <th>Codigo</th>
                        <th>Grupo</th>
                        <th>Plan</th>
                        <th>Materia</th>
                        <th>Docente</th>
                        <th>Disponibilidad</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="horario in horarios" :key="horario">
                        <tr>
                            <td>{{ horario.hor_codigo }}</td>
                            <td>{{ horario.hor_descripcion }}</td>
                            <td>{{ horario.pla_nombre }}</td>
                            <td>{{ horario.hor_codmat }}</td>
                            <td>{{ horario.hor_codcat }}</td>
                            <td>{{ horario.hor_cantidad_diponibilidad }}</td>
                        </tr>
                        <tr v-for="docente in horario.disponibilidad" :key="docente">
                            <td colspan="6">
                                <span class="badge new" v-bind:class="{'grey darken-1': !docente.hor_asignado}" data-badge-caption="">
                                    {{ docente.emp_codigo }} - {{ docente.emp_apellidos_nombres }}
                                </span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default
{
    data(){
        return {
            horarios: []
        }
    },
    async mounted()
    {
        await this.getHorarios();
    },
    methods: {
        async getHorarios()
        {
            await this.axios.get('/Horarios/DocentesDisponiblesHorario')
            .then(response => {
                this.horarios = response.data;
            });
        }
    }
}
</script>
