<template>
    <div class="row">
        <div class="col m3">
            <form method="post" @submit.prevent="setPreinscripcion()">
                <div class="card-panel">
                    <div class="row" style="margin-bottom: 0px;">
                        <div class="col m12">
                            <div class="card-title">Periodo de Preinscripción <br><small>Nuevo Ingreso</small></div>
                            <div class="card-panel deep-orange darken-4 white-text" style="font-size: 12px;">IMPORTANTE: Esta opción habilita el ciclo para nuevo ingreso. <i class="material-icons right">warning</i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m6">
                            <label>Desde</label>
                            <datepicker v-model="preinscripcion.pri_fecha_desde" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                        <div class="col m6">
                            <label>Hasta</label>
                            <datepicker v-model="preinscripcion.pri_fecha_hasta" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <label>Estado*</label>
                            <select class="browser-default" v-model="preinscripcion.pri_estado">
                                <option value="A">Abierto</option>
                                <option value="C">Cerrado</option>
                            </select>
                            <small>*Si abre el periodo, se cerrarán los demás de esta regional.</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb" type="submit">Actualizar</button>
                        </div>
                    </div>
                </div>
            </form>
            
            <form method="post" @submit.prevent="setCiclo()">
                <div class="card-panel">
                    <div class="row">
                        <div class="col m12">
                            <div class="card-title">Ciclo Académico</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m6">
                            <label>Ciclo</label>
                            <p>{{ ciclo.cil_codcic }}</p>
                        </div>
                        <div class="col m6">
                            <label>Año</label>
                            <p>{{ ciclo.cil_anio }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m6">
                            <label>Inicio</label>
                            <datepicker v-model="ciclo.cil_inicio" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                        <div class="col m6">
                            <label>Fin</label>
                            <datepicker v-model="ciclo.cil_fin" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m6">
                            <label>Fin Ins. en Línea</label>
                            <datepicker v-model="ciclo.cil_fin_ins_online" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m6">
                            <label>Vigente Global</label>
                            <select class="browser-default" v-model="ciclo.cil_vigente">
                                <option value="S">Si</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                        <div class="col m6">
                            <label>Vigente Portal</label>
                            <select class="browser-default" v-model="ciclo.cil_vigente_portal">
                                <option value="S">Si</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m6">
                            <label>Vigente Proceso Grad.</label>
                            <select class="browser-default" v-model="ciclo.cil_vigente_pre">
                                <option value="S">Si</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                        <div class="col m6">
                            <label>Vigente Prg. Esp.</label>
                            <select class="browser-default" v-model="ciclo.cil_vigente_prg">
                                <option value="S">Si</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb" type="submit">Actualizar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="col m3">
            <form method="post" @submit.prevent="setReposicion()">
                <div class="card-panel">
                    <div class="row">
                        <div class="col m12">
                            <div class="card-title">Periodo de Reposición</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m6">
                            <label>Desde</label>
                            <datepicker v-model="reposicion.psr_fecha_inicio" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                        <div class="col m6">
                            <label>Hasta</label>
                            <datepicker v-model="reposicion.psr_fecha_fin" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb" type="submit">Actualizar</button>
                        </div>
                    </div>
                </div>
            </form>
            <form method="post" @submit.prevent="setDiferido()">
                <div class="card-panel">
                    <div class="row">
                        <div class="col m12">
                            <div class="card-title">Fechas de Diferidos</div>
                        </div>
                    </div>
                    <div class="row" v-for="periodo in periodos_diferido" :key="periodo">
                        <div class="col m12">
                            <label>Cómputo {{ periodo.psd_computo }}</label>
                            <div class="row">
                                <div class="col m6">
                                    <label>Desde</label>
                                    <datepicker v-model="periodo.psd_desde" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                                </div>
                                <div class="col m6">
                                    <label>Hasta</label>
                                    <datepicker v-model="periodo.psd_hasta" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb">Actualizar</button>
                        </div>
                    </div>
                </div>
            </form>
            
            <form method="post" @submit.prevent="setPeriodoCupo()">
                <div class="card-panel">
                    <div class="row">
                        <div class="col m12">
                            <div class="card-title">Solicitud de Cupo</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m6">
                            <label>Desde</label>
                            <datepicker required v-model="cupo.psc_fecha_inicio" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                        <div class="col m6">
                            <label>Hasta</label>
                            <datepicker required v-model="cupo.psc_fecha_fin" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb">Actualizar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="col m4">
            <div class="card-panel">
                <div class="row">
                    <div class="col m12">
                        <div class="card-title">Periodos de Inscripción</div>
                    </div>
                </div>
                <form method="post" @submit.prevent="setPeriodosInscripcion()">
                    <div class="row">
                        <div class="col m4">
                            <label>Facultad</label>
                            <select class="browser-default" v-model="inscripcion.pei_codfac">
                                <option value="0">Seleccione</option>
                                <option v-for="facultad in facultades.filter(x=>periodos_inscripcion.findIndex(y=>y.pei_codfac == x.fac_codigo) == -1 || x.fac_codigo == inscripcion.pei_codfac)" :key="facultad" :value="facultad.fac_codigo">{{ facultad.fac_nombre }}</option>
                            </select>
                        </div>
                        <div class="col m4">
                            <label>Desde</label>
                            <datepicker required v-model="inscripcion.pei_fecha" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                        <div class="col m4">
                            <label>Hasta</label>
                            <datepicker required v-model="inscripcion.pei_fecha_hasta" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb" :disabled="inscripcion.pei_codfac == 0">Guardar</button>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col m12">
                        <table class="table tbl-condensed" style="font-size: 12px">
                            <thead>
                                <tr>
                                    <th>Facultad</th>
                                    <th>Desde</th>
                                    <th>Hasta</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="periodo in periodos_inscripcion" :key="periodo">
                                    <td>{{ periodo.fac_nombre }}</td>
                                    <td>{{ periodo.pei_fecha }}</td>
                                    <td>{{ periodo.pei_fecha_hasta }}</td>
                                    <td><a href="#!" @click="editarPeriodoInscripcion(periodo.pei_codfac)"><i class="material-icons">edit</i></a></td>
                                    <td><a href="#!" @click="eliminarPeriodoInscripcion(periodo.pei_codfac)"><i class="material-icons">delete</i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card-panel">
                <form method="post" @submit.prevent="setIngresoRepo()">
                    <div class="row">
                        <div class="col m12">
                            <div class="card-title">Ingreso de Notas de Reposición (Portal del Talento)</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m3">
                            <label>Tipo</label>
                            <select class="browser-default" v-model="ingreso_repo.pnr_tipo">
                                <option value="E">Enfermería</option>
                                <option value="P">Presencial/Semi-Presencial</option>
                                <option value="V">Virtual</option>
                            </select>
                        </div>
                        <div class="col m3">
                            <label>Desde</label>
                            <datepicker required v-model="ingreso_repo.pnr_fecha_inicio" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                        <div class="col m3">
                            <label>Hasta</label>
                            <datepicker required v-model="ingreso_repo.pnr_fecha_fin" inputFormat="dd/MM/yyyy" :typeable="true" vertical="true" readonly />
                        </div>
                        <div class="col m3">
                            <label>Estado</label>
                            <select class="browser-default" v-model="ingreso_repo.pnr_estado">
                                <option value="A">Abierto</option>
                                <option value="C">Cerrado</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb" v-if="ingresos_repo.findIndex(x=>x.pnr_tipo == ingreso_repo.pnr_tipo) == -1">Guardar</button>
                            <button class="btn blue-ugb" v-if="ingresos_repo.findIndex(x=>x.pnr_tipo == ingreso_repo.pnr_tipo) != -1">Actualizar</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Desde</th>
                                        <th>Hasta</th>
                                        <th>Estado</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="repo in ingresos_repo" :key="repo">
                                        <td class="center">
                                            <select class="browser-default black-text" v-model="repo.pnr_tipo" disabled>
                                                <option>Seleccione</option>
                                                <option value="E">Enfermería</option>
                                                <option value="P">Presencial/Semi-Presencial</option>
                                                <option value="V">Virtual</option>
                                            </select>
                                        </td>
                                        <td class="center">{{ repo.pnr_fecha_inicio }}</td>
                                        <td class="center">{{ repo.pnr_fecha_fin }}</td>
                                        <td class="center">
                                            <select class="browser-default black-text" v-model="repo.pnr_estado" disabled>
                                                <option value="A">Abierto</option>
                                                <option value="C">Cerrado</option>
                                            </select>
                                        </td>
                                        <td><a href="#!" @click="getIngresoRepo(repo.pnr_tipo)"><i class="material-icons">edit</i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        </div>        
    </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import M from 'materialize-css'
    export default
    {
        components: {
            Datepicker
        },
        data(){
            return {
                reposicion: {},
                preinscripcion: {},
                periodos_diferido: [],
                ciclo: {},
                cupo: {},
                inscripcion: {},
                facultades: [],
                periodos_inscripcion: [],
                ingreso_repo: {},
                ingresos_repo: []
            }
        },
        async mounted()
        {
            await this.getReposicion();
            await this.getDiferidos();
            await this.getPreinscripcion();
            await this.getCiclo();
            await this.getPeriodoCupo();
            await this.getFacultades();
            await this.getPeriodosInscripcion();
            await this.getIngresosRepo();
        },
        methods: {
            async getFacultades()
            {
                this.$root.startLoading();
                await this.axios.get('/Facultades')
                .then(response => {
                    this.facultades = response.data;
                    this.inscripcion.pei_codfac = 0;
                });
                this.$root.stopLoading();
            },
            async getReposicion()
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/PeriodoReposicion')
                .then(response => {
                    this.reposicion = {
                        psr_fecha_inicio: new Date(response.data.psr_fecha_inicio),
                        psr_fecha_fin: new Date(response.data.psr_fecha_fin),
                    };
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async setReposicion()
            {
                this.$root.startLoading();
                await this.axios.post('/Fechas/PeriodoReposicion', this.reposicion)
                .then(() => {
                    M.toast({html: 'Las fechas de reposición han sido actualizadas.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async getDiferidos()
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/PeriodoDiferido')
                .then(response => {
                    this.periodos_diferido = response.data;
                    for(let i = 0; i <= this.periodos_diferido.length - 1; i++)
                    {
                        this.periodos_diferido[i].psd_desde = new Date(this.periodos_diferido[i].psd_desde);
                        this.periodos_diferido[i].psd_hasta = new Date(this.periodos_diferido[i].psd_hasta);
                    }
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async setDiferido()
            {
                this.$root.startLoading();
                await this.axios.post('/Fechas/PeriodoDiferido', this.periodos_diferido)
                .then(() => {
                    M.toast({html: 'Las fechas de diferidos han sido actualizadas.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async getPreinscripcion()
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/Preinscripcion')
                .then(response => {
                    this.preinscripcion = {
                        pri_fecha_desde: new Date(response.data.pri_fecha_desde),
                        pri_fecha_hasta: new Date(response.data.pri_fecha_hasta),
                        pri_estado: response.data.pri_estado || 'A'
                    };
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async setPreinscripcion()
            {
                this.$root.startLoading();
                await this.axios.post('/Fechas/Preinscripcion', this.preinscripcion)
                .then(() => {
                    M.toast({html: 'Las fechas de preinscripción han sido actualizadas.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async getCiclo()
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/Ciclo')
                .then(response => {
                    response.data.cil_inicio = new Date(response.data.cil_inicio);
                    response.data.cil_fin = new Date(response.data.cil_fin);
                    response.data.cil_fin_ins_online = new Date(response.data.cil_fin_ins_online);
                    this.ciclo = response.data;
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async setCiclo()
            {
                this.$root.startLoading();
                await this.axios.post('/Fechas/Ciclo', this.ciclo)
                .then(() => {
                    M.toast({html: 'Las fechas del ciclo académico han sido actualizadas.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async getPeriodoCupo()
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/SolicitudCupo')
                .then(response => {
                    this.cupo = {
                        psc_fecha_fin: new Date(response.data.psc_fecha_fin),
                        psc_fecha_inicio: new Date(response.data.psc_fecha_inicio),
                    };
                });
                this.$root.stopLoading();
            },
            async setPeriodoCupo()
            {
                this.$root.startLoading();
                await this.axios.post('/Fechas/SolicitudCupo', this.cupo)
                .then(() => {
                    M.toast({html: 'Las fechas de solicitud de cupo han sido actualizadas.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async getPeriodosInscripcion()
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/PeriodosInscripcion')
                .then(response => {
                    this.periodos_inscripcion = response.data;
                    this.inscripcion.pei_codfac = 0;
                    this.inscripcion.pei_fecha = new Date();
                    this.inscripcion.pei_fecha_hasta = new Date();
                });
                this.$root.stopLoading();
            },
            async setPeriodosInscripcion()
            {
                this.$root.startLoading();
                await this.axios.post('/Fechas/PeriodosInscripcion', this.inscripcion)
                .then(async () => {
                    await this.getPeriodosInscripcion();
                    this.inscripcion = {};
                    this.inscripcion.pei_codfac = 0;
                    M.toast({html: 'Las fechas de inscripción han sido actualizadas.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async eliminarPeriodoInscripcion(codfac)
            {
                if(!confirm('¿Desea eliminar esta fecha de inscripción?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Fechas/PeriodosInscripcion/' + codfac)
                .then(async () => {
                    await this.getPeriodosInscripcion();
                    M.toast({html: 'La fecha de inscripción ha sido eliminada.'});
                });
                this.$root.stopLoading();
            },
            async editarPeriodoInscripcion(codfac)
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/PeriodosInscripcion/' + codfac)
                .then(response => {
                    this.inscripcion = {
                        pei_codfac: response.data.pei_codfac,
                        pei_fecha: new Date(response.data.pei_fecha),
                        pei_fecha_hasta: new Date(response.data.pei_fecha_hasta),
                    };
                });
                this.$root.stopLoading();
            },
            async getIngresosRepo()
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/IngresoReposicion')
                .then(response => {
                    this.ingresos_repo = response.data;
                    this.ingreso_repo.pnr_estado = 'A';
                    this.ingreso_repo.pnr_tipo = 'E';
                    this.ingreso_repo.pnr_fecha_inicio = new Date();
                    this.ingreso_repo.pnr_fecha_fin = new Date();
                });
                this.$root.stopLoading();
            },
            async getIngresoRepo(tipo)
            {
                this.$root.startLoading();
                await this.axios.get('/Fechas/IngresoReposicion/' + tipo)
                .then(response => {
                    this.ingreso_repo = {
                        pnr_estado: response.data.pnr_estado,
                        pnr_fecha_fin: new Date(response.data.pnr_fecha_fin),
                        pnr_fecha_inicio: new Date(response.data.pnr_fecha_inicio),
                        pnr_tipo: response.data.pnr_tipo,
                    };
                });
                this.$root.stopLoading();
            },
            async setIngresoRepo()
            {
                this.$root.startLoading();
                await this.axios.post('/Fechas/IngresoReposicion', this.ingreso_repo)
                .then(async () => {
                    M.toast({html: 'Las fechas de ingreso de notas de reposición ha sido actualizadas.'});
                    await this.getIngresosRepo();
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            }
        }
    }
</script>

<style scoped>
    .card-title
    {
        background-color: rgb(56,142,60);
        padding: 5px;
        border-radius: 2px;
        color: white
    }
</style>