<template>
<div class="row text-center" style="padding: 30px">
    <div class="form-signin blue-ugb" style="padding: 20px; border-radius:5px">
        <form v-on:submit.prevent="login" id="Login" method="post" style="padding: 0px;" autocomplete="false">         
            <div class="row" style="text-align:center">
                <img src="@/assets/logomenu.png">
            </div>
            <br>
            <div class="row">
                <div class="input-field col m12 s12">
                    <input class="white-text" v-model="usuario" type="text" placeholder="Usuario" :disabled="is_login" />
                </div>
            </div>
            <div class="row">
                <div class="input-field col m12 s12">
                    <input class="white-text" v-model="clave" type="password" placeholder="Contraseña" :disabled="is_login" />
                    <span asp-validation-for="clave"></span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col m6 s6">
                    <select v-model="codreg" v-on:change="getCiclos" v-bind:disabled="(cargando_ciclos || is_login)" class="browser-default">
                        <option value="0">Seleccione Regional</option>
                        <option v-for="regional in regionales" v-bind:value="regional.reg_codigo" v-bind:key="regional">{{regional.reg_nombre}}</option>
                    </select>
                </div>
                <div class="col m6 s6">
                    <select v-model="codcil" v-bind:disabled="(cargando_ciclos || codreg == 0 || is_login)" class="browser-default">
                        <option value="0">Seleccione Ciclo</option>
                        <option v-for="ciclo in ciclos" v-bind:value="ciclo.cil_codigo" v-bind:key="ciclo">{{(ciclo.ra_cic_ciclos.cic_nombre + ' - ' + ciclo.cil_anio)}}</option>
                    </select>
                </div>
            </div>
            <br>
            <br>
            <div class="row" v-show="!is_login">
                <button type="submit" v-bind:disabled="(cargando_ciclos || is_login || codcil == 0 || codreg == 0)" class="btn white black-text waves-effect s12 m12" id="btn-login" style="width: 100%; border-radius:50px">Iniciar Sesión <i class="material-icons right">security</i></button>
            </div>
            <div class="row" id="preloader-container" v-show="is_login">
                <div class="progress blue-ugb">
                    <div class="indeterminate blue lighten-4"></div>
                </div>
            </div>
        </form>
    </div>
    <div class="clearfix"></div>
</div>
</template>

<script>
    import M from 'materialize-css'
    export default
    {
        data(){
           return {
                usuario: '',
                clave: '',
                codcil: 0,
                codreg: 0,
                cargando_ciclos: false,
                regionales: [],
                ciclos: [],
                is_login: false
           }
        },
        async mounted()
        {
            this.$root.startLoading();
            await this.getRegionales();
            this.$root.stopLoading();
        },
        methods: {
            async getRegionales()
            {
                await this.axios.get('/Regionales')
                .then(response => {
                    this.regionales = response.data;
                });
            },
            async getCiclos()
            {
                this.codcil = 0;
                this.ciclos = [];
                if(this.codreg == 0)
                {                    
                    return;
                }
                this.cargando_ciclos = true;
                await this.axios.get('/Regionales/' + this.codreg + '/Ciclos')
                .then(response => {
                    this.ciclos = response.data;
                })
                .then(()=>{
                    this.cargando_ciclos = false;
                });
            },
            async login()
            {
                this.is_login = true;
                var payload = {
                    usuario: this.usuario,
                    clave: this.clave,
                    codcil: this.codcil,
                    codreg: this.codreg
                };
                await this.axios.post('/Login', payload)
                .then(response => {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('usuario', response.data.userData.usuario);
                    localStorage.setItem('regional', response.data.userData.regional);
                    localStorage.setItem('codigo', response.data.userData.codigo);
                    localStorage.setItem('nombre', response.data.userData.nombre);
                    localStorage.setItem('codcic', response.data.userData.codcic);
                    localStorage.setItem('anio', response.data.userData.anio);    
                    localStorage.setItem('ciclo', response.data.userData.nombreCiclo);
                    localStorage.setItem('codcil', this.codcil);
                    localStorage.setItem('is_logged', 'S');
                    localStorage.setItem('is_admin', response.data.administrador ? 'S':'N');          
                    localStorage.setItem('is_academica', response.data.academica ? 'S':'N');          
                    localStorage.setItem('is_generador', response.data.generador ? 'S':'N');          
                    localStorage.setItem('procesoGraduacion', JSON.stringify(response.data.procesoGraduacion));
                    localStorage.setItem('menus', JSON.stringify(response.data.menus));
                    console.log(response.data);
                    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                    window.location = '/';
                })
                .catch(error => {
                    var data = error.response.data;
                    M.toast({html: data.message});
                    this.is_login = false;
                })
            }
        }
    }
</script>

<style scoped>
<style>
    html,
    body {
        height: 100%;
    }

    body {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-align: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f5f5f5
    }

    .form-signin {
        width: 100%;
        max-width: 350px;
        padding: 15px;
        margin: 0 auto;
    }

        .form-signin .form-control {
            position: relative;
            box-sizing: border-box;
            height: auto;
            padding: 10px;
            font-size: 16px;
        }

            .form-signin .form-control:focus {
                z-index: 2;
            }

    input[type=text], input[type=password], select, select option {
        padding: 5px !important;
        margin: 0px !important;
    }

    .row
    {
        padding:0px; margin:0px
    }

    .blue-ugb
    {
        background-color: #011B40 !important
    }

    .gray-ugb
    {
        background-color: #707070 !important
    }
    
    .red-ugb
    {
        background-color: #FF0000 !important
    }

    .light-gray-ugb
    {
        background-color: #F0F0F0 !important
    }
</style>